import { Text } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { FileUploadText } from "@funded-here-interface/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useStyle } from "./LoanForm.styles";
import { UploadOptions } from "../../types/uploadtype";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { SKUfileUpload, SKUloan, UserType } from "../../types/login.type";
import LoanFields from "./components/LoanFields";
import BorrowerLayout from "../../layouts/BorrowerLayout/BorrowerLayout";
type Props = {};

const LoanForm: FC<Props> = (props) => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: File }>(
    {}
  );
  const [selectedSharePdf, setSelectedSharePdf] = useState<File | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const token = useSelector((state: RootState) => state.auth.token);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const skuId = queryParams.get("skuId");
  const description = queryParams.get("description");

  const [quantity, setQuantity] = useState<number | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const [sellingPrice, setSellingPrice] = useState<number | null>(null);
  const [discount, setDiscount] = useState<number | null>(null);
  const [totalTax, setTotalTax] = useState<number | null>(null);
  const [reason, setReason] = useState("");
  const [terms, setTerms] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [estimatedSKU, setEstimatedSKU] = useState<number | null>(null);
  const [estimatedDays, setEstimatedDays] = useState<number | null>(null);
  const [firstPayment, setFirstPayment] = useState<number>(0);
  const [balancePayment, setBalancePayment] = useState<number | null>(null);
  const [error, setError] = useState({
    UPC: "",
    quantity: "",
    price: "",
    discount: "",
    dateOfDelivery: "",
    goodsCost: "",
    averageInventory: "",
    estimatedSKU: "",
    paymentTerms: "",
    reason: "",
    estimatedDays: "",
    sellingPrice: "",
    totalTax: "",
  });

  const UploadSkuLoan = async ({
    skuId,
    estimatedSKU,
    estimatedDays,
    price,
    sellingPrice,
    quantity,
    discount,
    paymentTerms,
    totalTax,
    partialPaymentFirstPaymentPercentage,
    token,
  }: SKUloan) => {
    const payload: any = {
      skuId,
      skuReturnPercentage: estimatedSKU,
      estimatedDaysOfDelivery: estimatedDays,
      purchasePricePerUnit: price,
      sellingPricePerUnit: sellingPrice,
      quantityPurchased: quantity,
      discount,
      paymentTerm: paymentTerms,
      tax: totalTax,
      skuReturnMoreThanLimitReason: reason,
    };

    if (paymentTerms === "partial-payment") {
      payload.partialPaymentFirstPaymentPercentage =
        partialPaymentFirstPaymentPercentage;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/sku-finance/loan-application`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      toast.error(
        "Your loan parameters are not valid. Please approach our support group."
      );
      throw new Error("Network response was not ok");
    }
  };

  const UploadLoanFile = async ({ token, id, file }: SKUfileUpload) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/sku-finance/loan-application/upload-po-csv-file`,
        {
          file,
          skuId: id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error("Network response was not ok");
    }
  };

  const handleBack = async () => {
    const customErr = { ...error };
    let valid = true;

    const isNumber = (value: any) => /^[0-9]+$/.test(value);

    if (quantity === null || !isNumber(quantity) || quantity <= 0) {
      valid = false;
      customErr.quantity = "Quantity must be a positive number.";
    }

    if (estimatedSKU === null || !isNumber(estimatedSKU) || estimatedSKU <= 0) {
      valid = false;
      customErr.estimatedSKU = "estimatedSKU must be a positive number.";
    }
    if (
      estimatedDays === null ||
      !isNumber(estimatedDays) ||
      estimatedDays <= 0
    ) {
      valid = false;
      customErr.estimatedDays = "estimatedDays must be a positive number.";
    }
    if (price === null || !isNumber(price) || price <= 0) {
      valid = false;
      customErr.price = "Price must be a positive number.";
    }
    if (sellingPrice === null || !isNumber(sellingPrice) || sellingPrice <= 0) {
      valid = false;
      customErr.sellingPrice = "SellingPrice must be a positive number.";
    }
    if (discount === null || !isNumber(discount) || discount <= 0) {
      valid = false;
      customErr.discount = "Discount must be a positive number.";
    }

    if (totalTax === null || !isNumber(totalTax) || totalTax <= 0) {
      valid = false;
      customErr.totalTax = "Total Tax must be a positive number.";
    }
    if (!paymentTerms) {
      valid = false;
      customErr.paymentTerms = "PaymentTerms can't be empty.";
    }

    setError(customErr);

    if (valid) {
      customErr.quantity = "";
      customErr.price = "";
      customErr.discount = "";
      customErr.goodsCost = "";
      customErr.averageInventory = "";
      customErr.estimatedSKU = "";
      customErr.paymentTerms = "";
      customErr.reason = "";
      customErr.estimatedDays = "";
      customErr.sellingPrice = "";
      customErr.totalTax = "";
    }

    if (paymentTerms == "partial-payment") {
      try {
        const res = await UploadSkuLoan({
          skuId,
          estimatedSKU,
          estimatedDays,
          price,
          sellingPrice,
          quantity,
          discount,
          paymentTerms,
          totalTax,
          partialPaymentFirstPaymentPercentage: firstPayment / 100,
          token,
        });

        if (res) {
          Navigate("/sku-loan-application/new");
        }
      } catch (error) {}
    } else {
      try {
        const res = await UploadSkuLoan({
          skuId,
          estimatedSKU,
          estimatedDays,
          price,
          sellingPrice,
          quantity,
          discount,
          paymentTerms,
          totalTax,
          token,
        });

        if (res) {
          Navigate("/sku-loan-application/new");
        }
      } catch (error) {
        toast.error("error while adding loan application");
      }
    }
  };

  const DownloadLoanFile = async ({ token }: UserType) => {
    try {
      //  Axios will encounter a 403 error, only fetch works
      fetch(
        `${BASE_URL}/sku-finance/loan-application/download-po-csv-file-sample`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary link element
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          // Specify the file name
          link.download = "po_sample_file.csv";

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger a click on the link to start the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        })
        .catch((error) => toast.error(error));
    } catch (error) {
      toast.error("Network response was not ok");
    }
  };
  const handleUploadFile = async ({
    e,
    fileId,
    setUploadedFiles,
  }: UploadOptions) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Update local state
    setFile(file);
    try {
      const res = UploadLoanFile({ token, file, id: skuId });
    } catch (e) {
      toast.error("Error while uploading file.");
    }
  };

  const handleDownload = async () => {
    try {
      DownloadLoanFile({ token });
    } catch (e) {
      toast.error("Error while downloading the File");
    }
  };

  const areFieldsValid = (): boolean => {
    return !(
      !quantity ||
      !price ||
      !sellingPrice ||
      !discount ||
      !totalTax ||
      !estimatedSKU ||
      !estimatedDays ||
      !paymentTerms ||
      !!error.quantity ||
      !!error.price ||
      !!error.sellingPrice ||
      !!error.discount ||
      !!error.totalTax ||
      !!error.estimatedSKU ||
      !!error.estimatedDays ||
      !!error.paymentTerms
    );
  };

  useEffect(() => {
    // Limit firstPayment to maximum of 99
    if (firstPayment > 99) {
      setFirstPayment(99);
    }

    if (estimatedSKU != null && estimatedSKU > 100) {
      setEstimatedSKU(100);
    }

    // Calculate balancePayment with 2 decimal places
    const calculatedBalancePayment = parseFloat(
      (firstPayment !== 0 ? 100.0 - firstPayment : 0).toFixed(2)
    );
    setBalancePayment(calculatedBalancePayment);
  }, [firstPayment, estimatedSKU]);

  return (
    <BorrowerLayout
      content={
        <>
          <div
            className={classes.stepContent}
            style={{ marginTop: "0px", paddingTop: "0px" }}
          >
            <Text className={classes.headerText} style={{ marginTop: "10px" }}>
              Loan Application
            </Text>
            <Text className={classes.subHeading}>
              Kindly complete the provided details to enable us to calculate a
              personalized loan offer tailored to meet your specific
              requirements.
            </Text>
            <Text className={classes.title}>{skuId}</Text>
            <Text className={classes.title}>{description}</Text>

            <LoanFields
              estimatedSKU={estimatedSKU}
              setEstimatedSKU={setEstimatedSKU}
              estimatedDays={estimatedDays}
              setEstimatedDays={setEstimatedDays}
              price={price}
              setPrice={setPrice}
              sellingPrice={sellingPrice}
              setSellingPrice={setSellingPrice}
              quantity={quantity}
              setQuantity={setQuantity}
              discount={discount}
              setDiscount={setDiscount}
              totalTax={totalTax}
              setTotalTax={setTotalTax}
              terms={terms}
              setTerms={setTerms}
              paymentTerms={paymentTerms}
              setPaymentTerms={setPaymentTerms}
              reason={reason}
              setReason={setReason}
              firstPayment={firstPayment}
              setFirstPayment={setFirstPayment}
              balancePayment={balancePayment}
              setBalancePayment={setBalancePayment}
              error={error}
              setError={setError}
            />

            <Text onClick={handleDownload} className={classes.linkText}>
              Download EPO (Electronic PO) format
            </Text>
            <FileUploadText
              key={1}
              subText={"Upload EPO (Electronic PO)"}
              classes={classes}
              fileId={"1"}
              uploadedFile={"EPO"}
              setSelectedFile={setSelectedSharePdf}
              setUploadedFiles={setUploadedFiles}
              token={token}
              onChangeHandler={handleUploadFile}
            />
            <Text className={classes.subHeading}>
              Please note, that for COD option, the Loan amount once funded and
              sanctioned will be disbursed only after the goods are delivered to
              you
            </Text>
            <div className={classes.buttonDiv}>
              <div className={classes.buttonWrapper}>
                <button
                  onClick={handleBack}
                  className={classes.buttonClass}
                  style={{
                    cursor: areFieldsValid() ? "pointer" : "not-allowed",
                    color: "#ffff",
                    width: "254px",
                    border: areFieldsValid()
                      ? "1px solid #338F86"
                      : "1px solid #ccc",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: areFieldsValid()
                      ? "#338F86" // enabled color
                      : "#ccc", // disabled color
                  }}
                  disabled={!areFieldsValid()}
                >
                  Submit
                </button>
              </div>
            </div>
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default LoanForm;
