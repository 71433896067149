import { Text } from "@mantine/core";
import { FC, useState } from "react";
import { Button } from "@funded-here-interface/common";
import { InputGroup } from "@funded-here-interface/common";
import { useStyle } from "./LoanForm.styles";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";

const LTVLoanForm: FC = () => {
  const { classes } = useStyle();
  const [UPC, setUPC] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantityAtEnd, setquantityAtEnd] = useState("");
  const [NoOfDays, setNoOfDays] = useState("");
  const [estSkt, setEstSku] = useState("");

  const [monthlySales, setMonthlySales] = useState("");
  const [error, setError] = useState({
    UPC: "",
    quantity: "",
    quantityAtEnd: "",
    monthlySales: "",
    NoOfDays: "",
    estSkt: "",
    averageInventory: "",
    estimatedSKU: "",
  });

  const handleNext = () => {
    const customErr = { ...error };
    let valid = true;
    if (!quantity) {
      valid = false;
      customErr.quantity =
        "Inventory quantity at the start of a year is required";
    }
    if (!quantityAtEnd) {
      valid = false;
      customErr.quantityAtEnd =
        "Inventory quantity at the end of a year is required";
    }
    if (!monthlySales) {
      valid = false;
      customErr.monthlySales = "Total monthly sales revenues is required";
    }
    if (!NoOfDays) {
      valid = false;
      customErr.NoOfDays = "Number of days from order to delivery is required";
    }
    if (!estSkt) {
      valid = false;
      customErr.estSkt = "Estimated SKU Return (%) is required";
    }
    if (valid) {
    } else {
      setError(customErr);
    }
  };

  return (
    <OnboardingLayout
      step={0}
      content={
        <>
          <div
            className={classes.stepContent}
            style={{ marginTop: "0px", paddingTop: "0px", top: "66px" }}
          >
            <Text
              className={classes.headerText}
              style={{ marginTop: "10px", marginBottom: "30px" }}
            >
              Loan Application
            </Text>
            <Text
              className="subText"
              style={{ marginTop: "10px", marginBottom: "30px" }}
            >
              SKU Information
            </Text>

            <InputGroup
              isErrored={error.UPC}
              onChange={(value: any) => setUPC(value)}
              value={UPC}
              labelText="UPC"
              id="quantity"
            />
            <InputGroup
              isErrored={error.quantity}
              onChange={(value: any) => setQuantity(value)}
              value={quantity}
              labelText="Inventory quantity at the start of a year(for this SKU)"
              id="Inventory quantity at the start of a year"
            />
            <InputGroup
              isErrored={error.quantityAtEnd}
              onChange={(value: any) => setquantityAtEnd(value)}
              value={quantityAtEnd}
              labelText="Inventory quantity at the end of a year (for this SKU)"
              id="Inventory quantity at the end of a year"
            />
            <InputGroup
              isErrored={error.monthlySales}
              onChange={(value: any) => setMonthlySales(value)}
              value={monthlySales}
              labelText="Annual sales revenues(of this SKU)"
              id="monthly sales"
            />
            <InputGroup
              isErrored={error.NoOfDays}
              onChange={(value: any) => setNoOfDays(value)}
              value={NoOfDays}
              labelText="Number of days from order to delivery"
              id="quantityEnd"
            />
            <InputGroup
              isErrored={error.estSkt}
              onChange={(value: any) => setEstSku(value)}
              value={estSkt}
              labelText="Estimated SKU Return (%)( Est percentage of return for this SKU)"
              id="quantityEnd"
            />

            <div className={classes.buttonDiv}>
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={() => handleNext()}
                  textColor="#ffff"
                  backgroundColor="#338F86"
                  children="Next"
                  width="360px"
                />
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};

export default LTVLoanForm;
