import { FC } from "react";
import { useStyles } from "./ShareholdersDirectors.styles";
import { Group, NumberInput, Text } from "@mantine/core";
import { ShareholderDirector } from "../../features/merchant/rep";
import { ShareholderDirectorField } from "@funded-here-interface/common/src/constant/enum";
import {
  EMAIL_REGEX,
  InputGroup,
  NAME_REGEX,
} from "@funded-here-interface/common";

interface IProps {
  numOfShareholdersDirectors: number;
  onNumberOfShareholdersDirectorsChange: (value: number) => void;
  error: string;
  shareholdersDirectors: ShareholderDirector[];
  updateShareholderDirectorValue: (
    index: number,
    field: ShareholderDirectorField,
    value: string
  ) => void;
}

const ShareholdersDirectors: FC<IProps> = (props) => {
  const {
    numOfShareholdersDirectors,
    onNumberOfShareholdersDirectorsChange,
    error,
    shareholdersDirectors,
    updateShareholderDirectorValue,
  } = props;
  const { classes } = useStyles();

  // disable user from typing number. Only allow them to use up/down arrows
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <p className={classes.shareTitle}>Director Info</p>
      <p className={classes.sharePara}>
        To proceed, we require a minimum of 1 and a maximum of 2 directors'
        details to authorize your company's signatory. An online board
        resolution will be sent to them for this purpose. If there are 2
        directors, one of them can act as the authorized representative.
      </p>
      <Group position="left" mb={5} style={{ marginTop: "40px" }}>
        <Text
          className={classes.text}
          component="label"
          htmlFor="shareholder"
          size="sm"
        >
          Total no. of Directors
        </Text>
      </Group>
      <NumberInput
        onKeyDown={handleKeyDown}
        error={error}
        id="shareholderDirector"
        min={1}
        max={2}
        placeholder=""
        className={classes.inputField}
        value={numOfShareholdersDirectors}
        size="lg"
        onChange={onNumberOfShareholdersDirectorsChange}
      />
      {numOfShareholdersDirectors > 0 && (
        <>
          {Array.from({ length: numOfShareholdersDirectors }).map(
            (_, index) => (
              <div key={index} className={classes.shareholderDiv}>
                <InputGroup
                  onChange={(value: string) => {
                    updateShareholderDirectorValue(
                      index,
                      ShareholderDirectorField.FIRSTNAME,
                      value.replace(NAME_REGEX, "")
                    );
                  }}
                  labelText="First / given name (as in passport)"
                  id={`director_name_${index}`}
                  value={shareholdersDirectors[index]?.firstName || ""}
                />
                <InputGroup
                  onChange={(value: string) => {
                    updateShareholderDirectorValue(
                      index,
                      ShareholderDirectorField.LASTNAME,
                      value.replace(NAME_REGEX, "")
                    );
                  }}
                  labelText="Last name (as in passport)"
                  id={`director_name_${index}`}
                  value={shareholdersDirectors[index]?.lastName || ""}
                />
                <InputGroup
                  onChange={(value: string) => {
                    updateShareholderDirectorValue(
                      index,
                      ShareholderDirectorField.EMAIL,
                      value.replace(EMAIL_REGEX, "").trim()
                    );
                  }}
                  labelText="Email"
                  id={`director_email_${index}`}
                  value={shareholdersDirectors[index]?.email || ""}
                />
              </div>
            )
          )}
        </>
      )}
    </>
  );
};

export default ShareholdersDirectors;
