import { FC } from "react";
import { useStyle } from "./RegistrationCompleted.styles";
import { AcknowledgementLayout } from "@funded-here-interface/common";

const RegistrationCompleted: FC = () => {
  const { classes } = useStyle();

  return (
    <AcknowledgementLayout
      showLogo={false}
      content={
        <div className={classes.container}>
          <h2 className={classes.header}>Registration complete!</h2>
          <p className={classes.text}>Thank you for your patience!</p>
        </div>
      }
    />
  );
};

export default RegistrationCompleted;
