import { CompanyRole } from "@funded-here-interface/common/src/constant/enum";
import { createSlice } from "@reduxjs/toolkit";

export interface ShareholderDirector {
  role: CompanyRole;
  firstName: string;
  lastName: string;
  email: string;
}

interface RepState {
  repFirstName: string | null;
  repLastName: string | null;
  repTitle: string | null;
  repEmail: string | null;
  userTitle: string | null;
  shareholdersDirectors: ShareholderDirector[];
}

const repSlice = createSlice({
  name: "institutionalRep",
  initialState: {
    repFirstName: null,
    repLastName: null,
    repTitle: null,
    repEmail: null,
    userTitle: null,
    shareholdersDirectors: [],
  } as RepState,
  reducers: {
    setRepFirstNameRedux: (state, { payload: { repFirstName } }) => {
      state.repFirstName = repFirstName;
    },
    setRepLastNameRedux: (state, { payload: { repLastName } }) => {
      state.repLastName = repLastName;
    },
    setRepTitleRedux: (state, { payload: { repTitle } }) => {
      state.repTitle = repTitle;
    },
    setRepEmailRedux: (state, { payload: { repEmail } }) => {
      state.repEmail = repEmail;
    },
    setUserTitleRedux: (state, { payload: { userTitle } }) => {
      state.userTitle = userTitle;
    },
    setShareholderDirectorRedux: (
      state,
      { payload: { shareholdersDirectors } }
    ) => {
      state.shareholdersDirectors = shareholdersDirectors;
    },
  },
});

export const {
  setRepFirstNameRedux,
  setRepLastNameRedux,
  setRepTitleRedux,
  setRepEmailRedux,
  setUserTitleRedux,
  setShareholderDirectorRedux,
} = repSlice.actions;

export default repSlice.reducer;
