import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

const HeaderInvestorDashboard = () => {
  const [isSticky, setSticky] = useState(false);
  const navigate = useNavigate();

  return (
    <header>
      <section className={isSticky ? "headerPart sticky" : "headerPart"}>
        <div className="container-fluid">
          <div className="headerInn">
            <ul className="headerUl">
              <li>
                <NavLink to="/investor-dashboard/investordashboard">
                  Overall View
                </NavLink>
              </li>
              <li>
                <NavLink to="/investor-dashboard/investanalysis">
                  Investment Analysis
                </NavLink>
              </li>
              <li>
                <NavLink to="/investor-dashboard/snsummaryview">
                  SN Summary View
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </header>
  );
};

export default HeaderInvestorDashboard;
