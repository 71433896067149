import { FC, ReactNode } from "react";
import { useStyles } from "./WipOnboardinglayout.styles";
import { StepsLayout } from "@funded-here-interface/common";
import { ToastContainer } from "react-toastify";
import NavBar from "../../components/NavBar/NavBar";

interface IProps {
  content: ReactNode;
  showSteps?: boolean;
  step: number;
  totalSteps?: number;
  stepContainerTop?: string;
  stepContainerLeft?: number;
}

const WipOnboardingLayout: FC<IProps> = (props) => {
  const {
    content,
    showSteps,
    step,
    totalSteps,
    stepContainerTop,
    stepContainerLeft,
  } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.mainContainer} style={{ backgroundColor: "#fff" }}>
      <NavBar />
      <ToastContainer />

      {showSteps && <StepsLayout currentStep={step} totalSteps={totalSteps} />}
      <div
        className={classes.stepContainer}
        style={{
          top: stepContainerTop,
          left: stepContainerLeft,
          padding: "25px",
          paddingTop: "58px",
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default WipOnboardingLayout;
