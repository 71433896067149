import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  buttonClass: {
    width: "365px",
    height: "45px",
    padding: "13px 87px 13px 87px",
    border: "none",
    gap: "10px",
    fontSize: "16px",

    letterSpacing: "0em",
    textAlign: "center",
  },
}));
