import { MantineTheme, createStyles } from "@mantine/styles";
import { color } from "@funded-here-interface/common";

export const useStyle = createStyles((theme: MantineTheme) => ({
  stepsToComplete: {
    display: "flex",
    flexDirection: "row",
    left: "259px",
  },
  paytext: {
    marginBottom: "10px",
  },
  instruction: {
    width: "508px",
    height: "50px",
    top: "140px",
    left: "259px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    fontStyle: "normal",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subHeading: {
    width: "48px",
    height: "19px",

    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    marginTop: "30px",
  },
  text: {
    height: "19px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#11A77A",
    marginTop: "15px",
    marginLeft: "25px",
  },
  list: {
    width: "100%",
    height: "19px",
    top: "352px",
    left: "286px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "8px",
    paddingLeft: "0px",
  },
  progress: {
    //position:'absolute',
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0px",
    position: "absolute",
    left: "10px",
  },
  eclipse: {
    width: "10px",
    height: "10px",
    top: "380px",
    left: "271px",
    marginTop: "8px",
  },
  line: {
    width: "10px",
    height: "20px",
    justifyContent: "center",
    border: "1px",
    rotate: "none",
    paddingLeft: "4px",
    borderColor: "black",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "end",
    marginTop: "20px",
    msFlexDirection: "row-reverse",
  },
  buttonWrapper: {
    paddingRight: "30px",
  },

  download: {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    alignItems: "center",
    marginBottom: "10px",
  },
  doc: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  checkbox: {
    display: "flex",
    gap: "10px",
    height: "25px",
    marginBottom: "50px",
    marginTop: "30px",
  },
  cbox: {
    height: "23px",
    width: "23px",
  },
  ctext: {
    paddingTop: "3px",
  },

  buttonClass: {
    width: "365px",
    height: "45px",
    padding: "13px 87px 13px 87px",
    border: "none",
    gap: "10px",
    fontSize: "16px",

    letterSpacing: "0em",
    textAlign: "center",
  },
  verify: {
    backgroundColor: "#FFFAF2",
    position: "absolute",
    width: "1300px",
    zIndex: 12345678987655,
  },
  uploadContainer: {
    display: "flex",
    marginBottom: "26px",
    width: "444px",
  },
  fileTitile: {
    fontWeight: 400,
    fontSize: "16px",
    marginRight: "20px",
  },
  mainContainer: {
    width: "100%",
    height: "max-content",
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    margin: 0,
    position: "relative",
  },

  sideBarContainer: {
    position: "fixed",
    height: "100vh",
    backgroundColor: "#338F86",
    zIndex: 9999,
  },

  logoContainer: {
    position: "absolute",
    width: 126,
    height: 45,
    top: 53,
    right: 150,
  },

  stepContainer: {
    position: "absolute",
    width: 406,
    height: 337,
    top: 5,
    left: 0,
    gap: 15,
    marginBottom: 30,
    marginLeft: "50px",
    paddingTop: "20px",
  },

  optionContainer: {
    display: "flex",
    marginTop: "24px",
    marginBottom: "16px",
  },
  optionTextHeading: {
    fontWeight: 700,
    marginRight: "26px",
    cursor: "pointer",
  },

  uline: {
    width: "50px",
    height: "3px",
    background: color.FHADMIN,
    marginLeft: "0px",
  },

  stepContent: {
    width: "Hug (406px)",
    height: "Hug (337px)",
    top: 158,
    left: 270,
    gap: 25,
    paddingBottom: 30,
  },

  headerText: {
    width: 361,
    height: 46,
    top: 95,
    left: 259,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },

  logo: {
    marginTop: 56,
    marginLeft: 18,
  },

  submitButtonDiv: {
    marginTop: 20,
  },

  toggleButton: {
    backgroundColor: "white",
    borderRadius: 50,
    position: "relative",
  },

  navicon: {
    fontSize: 20,
  },

  staticMenu: {
    marginTop: 100,
    width: "100%",
    color: "white",
    gap: 30,
    paddingBottom: 30,
  },

  menuDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    marginLeft: 30,
    color: "white",
    gap: 30,
    rowGap: 20,
    marginBottom: 30,
  },

  textForMenu: {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  iconLabel: {
    marginLeft: 20,
    position: "relative",
    left: 5,
    marginTop: 10,
  },

  hrefDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  input: {
    width: "100%",
    padding: 10,
    marginBottom: 10,
    border: "1px solid #ccc",
    borderRadius: 4,
  },

  headerTitle: {
    color: "#595959",
  },

  ltvaccpDiv: {
    width: "48%",
    boxSizing: "border-box",
    marginTop: 10,
  },

  colDivMain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: 370,
    flexWrap: "wrap",
  },

  ltvaccpDivP: {
    color: "#595959",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: 5,
  },

  ltvaccpDivH3: {
    color: "#151515",
    fontSize: 22,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    margin: 0,
    marginBottom: 10,
  },

  viewAndCond: {
    marginTop: 30,
    cursor: "pointer",
    color: "#338F86",
  },

  viewAndCondSpan: {
    color: "#338f86",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },

  paymentHeading: {
    color: "#151515",
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: 510,
    lineHeight: "normal",
    textAlign: "left",
  },

  leftText: {
    color: "#595959",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: 5,
  },

  paymentInfoLeft: {
    color: "#595959",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: 5,
  },

  paymentInfoRight: {
    color: "#151515",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },

  thh: {
    border: "none",
    padding: "20px",
    overflow: "visible",
    color: color.ADMINHEADING,
    fontSize: "14px",
  },

  th: {
    border: "none",
    padding: "20px",
    overflow: "visible",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: " flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  document: {
    background: "white",
    padding: "20px",
    borderRadius: "5px",
    maxHeight: "80vh",
    overflowY: "auto",
    width: "60%",
    height: "500px",
  },
  docSign: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    color: "#338F86",
  },

  docContain: {
    display: "flex",
    marginTop: "30px",
    marginLeft: "30px",
  },
  docText: {
    fontSize: "18px",
    width: "400px",
  },
  downloadIcon: {
    cursor: "pointer",
    display: "flex",
  },
  signedGroup: {
    marginBottom: "10px",
  },
  panel: {
    backgroundColor: "#f5f2f2",
  },
  item: {
    backgroundColor: "#dee2e6",
    marginBottom: "10px",
  },
}));
