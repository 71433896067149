import { FC, ReactNode } from "react";
import { useStyles } from "./Borrowerlayout.styles";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { IconHome, IconPhotoDollar, IconSignature } from "@tabler/icons-react";
import NavBar from "../../components/NavBar/NavBar";

interface IProps {
  content: ReactNode;
}

const BorrowerLayout: FC<IProps> = ({ content }) => {
  const { classes } = useStyles();
  const { hasCreditLimit } = useSelector((state: RootState) => state.auth);

  const menus = [
    {
      label: "Dashboard",
      path: "/merchant-dashboard/Overall",
      icon: <IconHome />,
    },
    {
      label: "SKU Loan",
      path: "/sku-loan-application/new",
      icon: <IconPhotoDollar />,
    },
    {
      label: "Signed Documents",
      path: "/signed-documents",
      icon: <IconSignature />,
    },
  ];

  return (
    <div className={classes.mainContainer}>
      {/* <div className={classes.sideBarContainer}>
        <div className={classes.iconbox}>
          <img className={classes.logo} alt="LOGO" src={LoginIcon} />
        </div>
        {hasCreditLimit && (
          <div>
            {menus.map((data, index) => (
              <>
                <div
                  key={index}
                  className={classes.sideDiv}
                  onClick={() => toggleSubMenu(index)}
                >
                  <Link to={data.path} className={classes.Link}>
                    {data.icon}
                    <p className={classes.label}> {data.label}</p>
                  </Link>
                </div>
              </>
            ))}
          </div>
        )}

        <div className={classes.labelLog} onClick={handleLogout}>
          <div className={classes.persona}>
            <Text>
              {name.firstName} {name.lastName}
            </Text>
          </div>
          <img src={logoutIcon} alt="Logout Icon" className={classes.icon} />
          Logout
        </div>
      </div> */}
      <NavBar menus={menus} canPassCondition={hasCreditLimit} />
      <ToastContainer />
      <div style={{ paddingBottom: "30px" }} className={classes.hero}>
        {content}
      </div>
    </div>
  );
};

export default BorrowerLayout;
