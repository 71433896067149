import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    minHeight: "100vh",
    background: "#FFFFFF",
  },

  iconContainer: {
    position: "absolute",
    top: "58px",
    left: "85px",
    width: "151px",
    zIndex: 2,
  },
  leftContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 2rem",
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "40px",
    padding: "13px 87px",
  },
  stepContent: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    paddingBottom: "30px",
  },
  backImage: {
    display: "flex",
    width: "full",
  },
  logo: {
    width: 170,
    marginTop: 56,
    marginLeft: 0,
  },
}));
