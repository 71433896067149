import React from "react";
import uploadIcon from "../../assets/cloud_upload_24px.png";
import { Group, Text } from "@mantine/core";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useAppendToBalance,
  useBankArray,
  useBusiness,
  useFinancialStatementArray,
  useFundManagerLicenseArray,
  useInvestmentMandateArray,
  useProofArray,
  useSalaryProofArray,
  useShareArray,
} from "../../hooks/handleBorFIleUpload";
import { RootState } from "../../store";
import { useStyle } from "./FileUpload.styles";
import useUploadOnboarding from "../../hooks/useUploadOnboarding";
import { File as FileEnum } from "@funded-here-interface/common/src/constant/enum";
import { toast } from "react-toastify";
import {
  UploadedFile,
  setBalance,
  setBankDoc,
  setBusinessDoc,
  setFinancialStatementDoc,
  setFundManagerLicenseDoc,
  setInvestmentMandateDoc,
  setProofDoc,
  setSalaryProofDoc,
  setShareDoc,
} from "../../features/merchant/doc";
import useDeleteFile from "../../hooks/useDeleteFile";

type FileUploadComponentProps = {
  fileId: string;
  number: number;
  FileName: string;
};

function FileUploadComponent({
  fileId,
  number,
  FileName,
}: FileUploadComponentProps) {
  const { classes } = useStyle();
  const useUploadOnboardingMutation = useUploadOnboarding();
  const token = useSelector((state: RootState) => state.auth.token);
  const useDeleteFileMutation = useDeleteFile();
  const dispatch = useDispatch();
  const { appendToProof, proof } = useProofArray();
  const { appendToBalance, balance } = useAppendToBalance();
  const { appendToShare, share } = useShareArray();
  const { appendToBusiness, business } = useBusiness();
  const { appendToBank, bank } = useBankArray();
  const { appendToSalaryProof, salaryProof } = useSalaryProofArray();
  const { appendToFinancialStatement, financialStatement } =
    useFinancialStatementArray();
  const { appendToInvestorMandate, investmentMandate } =
    useInvestmentMandateArray();
  const { appendToFundManagerLicense, fundManagerLicense } =
    useFundManagerLicenseArray();

  const getFileState = (file: FileEnum) => {
    switch (file) {
      case FileEnum.BUSINESS_PROFILE:
        return business;
      case FileEnum.SHAREHOLDERS_DIRECTORS_REGISTERS:
        return share;
      case FileEnum.BALANCE_SHEET:
        return balance;
      case FileEnum.PROOF_OF_ADDRESS:
        return proof;
      case FileEnum.BANK_STATEMENT:
        return bank;
      case FileEnum.FINANCIAL_STATEMENT:
        return financialStatement;
      case FileEnum.SALARY_PROOF:
        return salaryProof;
      case FileEnum.INVESTMENT_MANDATE:
        return investmentMandate;
      case FileEnum.FUND_MANAGER_LICENSE:
        return fundManagerLicense;
      default:
        return null;
    }
  };

  const appendFile = (file: UploadedFile) => {
    switch (fileId) {
      case FileEnum.BUSINESS_PROFILE:
        appendToBusiness(file, business);
        break;
      case FileEnum.SHAREHOLDERS_DIRECTORS_REGISTERS:
        appendToShare(file, share);
        break;
      case FileEnum.BALANCE_SHEET:
        appendToBalance(file, balance);
        break;
      case FileEnum.PROOF_OF_ADDRESS:
        appendToProof(file, proof);
        break;
      case FileEnum.BANK_STATEMENT:
        appendToBank(file, bank);
        break;
      case FileEnum.SALARY_PROOF:
        appendToSalaryProof(file, salaryProof);
        break;
      case FileEnum.FINANCIAL_STATEMENT:
        appendToFinancialStatement(file, financialStatement);
        break;
      case FileEnum.INVESTMENT_MANDATE:
        appendToInvestorMandate(file, investmentMandate);
        break;
      case FileEnum.FUND_MANAGER_LICENSE:
        appendToFundManagerLicense(file, fundManagerLicense);
        break;
      default:
        break;
    }
  };

  const dispatchFileUpdate = (updatedFiles: UploadedFile[]) => {
    switch (fileId) {
      case FileEnum.BUSINESS_PROFILE:
        dispatch(setBusinessDoc({ business: updatedFiles }));
        break;
      case FileEnum.SHAREHOLDERS_DIRECTORS_REGISTERS:
        dispatch(setShareDoc({ share: updatedFiles }));
        break;
      case FileEnum.BALANCE_SHEET:
        dispatch(setBalance({ balance: updatedFiles }));
        break;
      case FileEnum.PROOF_OF_ADDRESS:
        dispatch(setProofDoc({ proof: updatedFiles }));
        break;
      case FileEnum.BANK_STATEMENT:
        dispatch(setBankDoc({ bank: updatedFiles }));
        break;
      case FileEnum.SALARY_PROOF:
        dispatch(setSalaryProofDoc({ salaryProof: updatedFiles }));
        break;
      case FileEnum.FINANCIAL_STATEMENT:
        dispatch(
          setFinancialStatementDoc({ financialStatement: updatedFiles })
        );
        break;
      case FileEnum.INVESTMENT_MANDATE:
        dispatch(setInvestmentMandateDoc({ investmentMandate: updatedFiles }));
        break;
      case FileEnum.FUND_MANAGER_LICENSE:
        dispatch(
          setFundManagerLicenseDoc({ fundManagerLicense: updatedFiles })
        );
        break;
      default:
        break;
    }
  };

  const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>(
    getFileState(fileId as FileEnum) ?? []
  );

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files?.length > 0) {
      const file = e.target.files[0];

      const fileNameExists = selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      );

      if (fileNameExists) {
        toast.error("A file with the same name already exists.");

        return;
      }

      useUploadOnboardingMutation.mutate(
        { file, token, name: `${fileId}-${file.name}` },
        {
          onSuccess: (filePath: string) => {
            const newFile: UploadedFile = {
              name: file.name,
              path: filePath,
            };
            appendFile(newFile);

            setSelectedFiles([
              ...selectedFiles,
              { name: file.name, path: filePath },
            ]);

            e.target.value = "";
          },
          onError: (error) => {
            e.target.value = "";
            toast.error((error as Error).message);
          },
        }
      );
    }
  };

  const handleDeleteFile = (filePath: string) => {
    useDeleteFileMutation.mutate(
      { token, filePath },
      {
        onSuccess: () => {
          const updatedFiles = selectedFiles.filter(
            (file) => file.path !== filePath
          );
          dispatchFileUpdate(updatedFiles);

          setSelectedFiles(updatedFiles);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const docsWithShareholderInfo = [
    FileEnum.BUSINESS_PROFILE,
    FileEnum.SHAREHOLDERS_DIRECTORS_REGISTERS,
  ];

  return (
    <>
      <div className={classes.docContainer}>
        <Group style={{ padding: 20 }}>
          <Text className={classes.docName}>{`${number}. ${FileName}`}</Text>

          <div className={classes.uploadButtonContainer}>
            <label htmlFor={fileId} className={classes.label}>
              <input
                type="file"
                name={fileId}
                id={fileId}
                accept=".pdf"
                style={{ display: "none" }}
                onChange={handleUploadFile}
              />
              <img src={uploadIcon} alt="upload" />{" "}
              <span style={{ paddingTop: "-15px" }}> Upload </span>
            </label>
          </div>
        </Group>
        {docsWithShareholderInfo.includes(fileId as FileEnum) && (
          <Text className={classes.additionalInfo}>
            If the shareholder is a corporate entity, it is necessary to provide
            the business profile & shareholder and director register of that
            company. This condition applies until the shareholders are either
            Financial institutions, public listed companies, or individuals.
          </Text>
        )}

        {fileId === FileEnum.BANK_STATEMENT && (
          <Text className={classes.additionalInfo}>
            This is the standard documents required for on-boarding, we may
            request for additional information/documents for due diligence
            purposes.
          </Text>
        )}
      </div>
      {selectedFiles.length > 0 && (
        <div style={{ marginBottom: 20 }}>
          <Text className={classes.uploadedFileHeader}>Uploaded Files:</Text>
          {selectedFiles?.map((file) => (
            <Group>
              <div className={classes.uploadedFileName}>
                <Text className={classes.uploadedDocName}>{file.name}</Text>
                <Text
                  onClick={() => handleDeleteFile(file.path)}
                  className={classes.removeText}
                >
                  Remove
                </Text>
              </div>
            </Group>
          ))}
        </div>
      )}
    </>
  );
}

export default FileUploadComponent;
