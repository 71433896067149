import { useEffect, useRef, useState } from "react";

const usePolling = <R = unknown,>(
  callback: () => Promise<R> | R,
  ms = 1000,
  dependencies: any[] = []
) => {
  const [dead, kill] = useState(false);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (dead) {
      return;
    }
    let _stopped = false;
    // Side note: preceding semicolon needed for IIFEs.
    (async function pollingCallback() {
      try {
        await callback();
      } finally {
        // Set timeout after it finished, unless stopped
        timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, ms);
      }
    })();
    // Clean up if dependencies change
    return () => {
      _stopped = true; // prevent racing conditions
      clearTimeout(timeoutIdRef.current!);
    };
  }, [ms, dead, callback]);

  return [() => kill(true), () => kill(false)];
};

export { usePolling };