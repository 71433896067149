import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  loginContainer: {
    alignItems: "center",
    padding: "60px",
    boxShadow:
      "0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)",
    borderRadius: 4,
  },
  verificationContainer: {
    alignItems: "center",
    padding: "40px 50px 60px 30px",
    width: "495px",
    boxShadow:
      "0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)",
    borderRadius: 4,
  },
  close: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  loginHeading: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  interaction: {
    marginTop: "20px",
    left: "68px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subHeading: {
    marginTop: "20px",
    left: "68px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.71px",
    color: "#3C4257",
  },
  otpWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "50px",
    width: "180px",
    marginTop: "20px",
    left: "65px",
  },
  otpDigit: {
    width: "41px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
    margin: "5px",
    fontSize: "14px",
    border: 0,
  },
  footerText: {
    width: "395px",
    height: "17px",
    marginTop: "60px",
    left: "68px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.71px",
  },
  verifiedHeading: {
    height: "46px",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "28.64px",
  },
  imgWrapper: {
    width: "60px",
    marginTop: "10px",
    marginBottom: "30px",
    height: "60px",
  },
  redirectionMssg: {
    textAlign: "center",
    fontSize: "16px",
    marginTop: "10px",
    fontWeight: 400,
    lineHeight: "19px",
  },
}));
