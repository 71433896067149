import {
  PasswordInput,
  Text,
  Group,
  PasswordInputProps,
  Anchor,
  List,
  createStyles,
  MantineTheme,
} from "@mantine/core";
import { FC } from "react";

type Props = {
  className: string;
  style?: {};
  others?: any;
  name?: string;
  forgotPassword: CallableFunction;
  onChange?: (...args: any) => any;
  isErrored?: string;
};
const ForgotPasswordInput: FC<Props> = ({
  className,
  onChange,
  forgotPassword,
  isErrored,
  ...others
}) => {
  return (
    <div
      className={className}
      style={{ paddingTop: "8px", width: "100%", height: "100%" }}
    >
      <Group position="apart" mb={8}>
        <Text
          component="label"
          htmlFor="your-password"
          size="sm"
          color="#3C4257"
        >
          Password
        </Text>
        <Anchor<"a">
          href="#"
          onClick={(event) => {
            event.preventDefault();
            forgotPassword();
          }}
          sx={(theme) => ({
            paddingTop: 2,
            color: "#338F86",
            fontWeight: 500,
            fontSize: theme.fontSizes.sm,
          })}
        >
          Forgot your password?
        </Anchor>
      </Group>
      <div>
        <PasswordInput
          onChangeCapture={(value) => (onChange ? onChange(value) : "")}
          defaultVisible={false}
          placeholder="Enter Your Password"
          id="your-password"
          size="md"
          {...others}
        />
      </div>
    </div>
  );
};

const useStyles = createStyles((theme: MantineTheme) => ({
  passwordCondition: {
    marginTop: "10px",
    color: "#595959",
    marginBottom: "10px",
  },
}));

export default ForgotPasswordInput;
