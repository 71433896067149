// export const BASE_URL =
//   "http://k8s-default-fundedhe-4267302373-676271173.ap-southeast-1.elb.amazonaws.com";

import { KYCStatus, Persona, SpvFile } from "./enum";

// k8s-default-fundedhe-4267302373-676271173.ap-southeast-1.elb.amazonaws.com/trade-directory
export const BASE_URL = "https://api.fundedhere.com";
// export const BASE_URL = "http://localhost:3001";
export const BASE_URL_LANDING_PAGE = "https://www.fundedhere.com";

export const TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjI2LCJvcmdSb2xlSWQiOjcsImlhdCI6MTcwNTU1OTc4NCwiZXhwIjoxNzA1NjQ2MTg0fQ.hjWLt-BdGlfGQrOG-KJDYtyDEUojYmlCTkctvJXvgNc";

export const merchants = [Persona.MERCHANT, Persona.MERCHANT_SPV];

export const haveDirectors = [...merchants, Persona.INVESTOR];

export const kycStatuses = Object.values(KYCStatus);

export const spvFiles = Object.values(SpvFile);

export const ENV: "local" | "production" = "local";

export const CLIENT_ID_LUMINOR_DID = "6617836d2d4969cd7e3abed9";
