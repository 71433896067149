import { createSlice } from "@reduxjs/toolkit";

interface InstitutionalBusinessState {
  company: string | null;
  regNumber: string | null;
  legalStructure: string | null;
  legalStructureOthers: string | null;
  countryOfIncorp: string | null;
  countryOfDomicile: string | null;
  website: string | null;
  address: string | null;
  postal: string | null;
  phoneNumber: string | null;
  industry: string | null;
  industryOthers: string | null;
  investmentAmount: number | null;
  investorDeclarationQualified: boolean | null;
}

const bizSlice = createSlice({
  name: "insitutionalBiz",
  initialState: {
    company: null,
    regNumber: null,
    legalStructure: null,
    legalStructureOthers: null,
    countryOfIncorp: null,
    countryOfDomicile: null,
    website: null,
    address: null,
    phoneNumber: null,
    postal: null,
    industry: null,
    industryOthers: null,
    investmentAmount: null,
    investorDeclarationQualified: null,
  } as InstitutionalBusinessState,
  reducers: {
    setCompanyRedux: (state, { payload: { company } }) => {
      state.company = company;
    },
    setRegNumberRedux: (state, { payload: { regNumber } }) => {
      state.regNumber = regNumber;
    },
    setLegalStructureRedux: (state, { payload: { legalStructure } }) => {
      state.legalStructure = legalStructure;
    },
    setLegalStructureOthersRedux: (
      state,
      { payload: { legalStructureOthers } }
    ) => {
      state.legalStructureOthers = legalStructureOthers;
    },
    setCountryOfIncorpRedux: (state, { payload: { countryOfIncorp } }) => {
      state.countryOfIncorp = countryOfIncorp;
    },
    setCountryOfDomicileRedux: (state, { payload: { countryOfDomicile } }) => {
      state.countryOfDomicile = countryOfDomicile;
    },
    setAddressRedux: (state, { payload: { address } }) => {
      state.address = address;
    },
    setPhoneNumberRedux: (state, { payload: { phoneNumber } }) => {
      state.phoneNumber = phoneNumber;
    },
    setPostalCodeRedux: (state, { payload: { postal } }) => {
      state.postal = postal;
    },
    setWebsiteRedux: (state, { payload: { website } }) => {
      state.website = website;
    },
    setIndustryRedux: (state, { payload: { industry } }) => {
      state.industry = industry;
    },
    setIndustryOthersRedux: (state, { payload: { industryOthers } }) => {
      state.industryOthers = industryOthers;
    },
    setInvestmentAmountRedux: (state, { payload: { investmentAmount } }) => {
      state.investmentAmount = investmentAmount;
    },
    setInvestorDeclarationQualifiedRedux: (
      state,
      { payload: { investorDeclarationQualified } }
    ) => {
      state.investorDeclarationQualified = investorDeclarationQualified;
    },
  },
});

export const {
  setCompanyRedux,
  setRegNumberRedux,
  setLegalStructureRedux,
  setCountryOfIncorpRedux,
  setAddressRedux,
  setPhoneNumberRedux,
  setPostalCodeRedux,
  setCountryOfDomicileRedux,
  setWebsiteRedux,
  setLegalStructureOthersRedux,
  setIndustryRedux,
  setIndustryOthersRedux,
  setInvestmentAmountRedux,
  setInvestorDeclarationQualifiedRedux,
} = bizSlice.actions;

export default bizSlice.reducer;
