import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  centerText: {
    textAlign: "center",
  },
  headerText: {
    fontSize: "32px",
    fontWeight: 800,
  },
  benefitsAndWorksText: {
    color: "#338F86",
    fontSize: "28px",
    fontWeight: 800,
  },
  separator: {
    color: "#D9DCE1",
    margin: "10px 100px 0px 100px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
    marginTop: 10,
  },
  howitworks: {
    background: "#F9F9F9",
    width: "100%",
    paddingTop: "20px",
    paddingBottom: "25px",
  },
  workstext: {
    display: "flex",
    gap: 20,
    marginTop: 10,
    marginLeft: 150,
  },
}));
