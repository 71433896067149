import { Accordion, Text, ActionIcon } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { useStyle } from "./SignedDocuments.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  useDownloadSignedAgreement,
  useGetSignedDocuments,
  useDownloadSignedDocuments,
  useGetSignedSubscriptionAgreement,
} from "../../../hooks/useGetSignedLoanAgreement";
import { FileType } from "@funded-here-interface/common/src/constant/enum";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { toast } from "react-toastify";
import moment from "moment";

interface DocumentInfo {
  title: string;
  completedAt: string;
}

const InvestorSignedDocuments = () => {
  const { classes } = useStyle();
  const token = useSelector((state: RootState) => state.auth.token);

  const { data, error } = useGetSignedSubscriptionAgreement(token);

  const signedDocuments = useGetSignedDocuments(token);

  const useDownloadSignedAgreementMutation = useDownloadSignedAgreement();
  const useDownloadSignedDocumentMutation = useDownloadSignedDocuments();

  const downloadSignedAgreement = async (path: string, fileName: string) => {
    useDownloadSignedAgreementMutation.mutate(
      { token, path },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, fileName, FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const downloadSignedDocument = async (path: string, fileName: string) => {
    useDownloadSignedDocumentMutation.mutate(
      { token, path },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, fileName, FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const displayDocumentTitle = (info: string) => {
    const updatedInfo: DocumentInfo = JSON.parse(info);
    const completedAt = moment(updatedInfo.completedAt).format(
      "YYYY/MM/DD hh:mm"
    );
    return (
      updatedInfo.title.replaceAll("_", " ") + " [Signed " + completedAt + "]"
    );
  };

  return (
    <div>
      <Text className={classes.heading}>Signed Document</Text>

      <Accordion multiple classNames={classes}>
        <Accordion.Item value="onboardingDocument">
          <Accordion.Control>Onboarding documents</Accordion.Control>
          <Accordion.Panel>
            {signedDocuments.data?.length !== 0 ? (
              <>
                {signedDocuments.data?.map((doc, index) => (
                  <div key={index} className={classes.docContain}>
                    <Text className={classes.docText}>
                      {displayDocumentTitle(doc.info as string)}
                    </Text>
                    <ActionIcon
                      variant="subtle"
                      onClick={() =>
                        downloadSignedDocument(doc.path, doc.docName)
                      }
                    >
                      <IconDownload></IconDownload>
                    </ActionIcon>
                  </div>
                ))}
              </>
            ) : (
              <div>No Signed document</div>
            )}
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="signedSubscriptionAgreement">
          <Accordion.Control>
            Signed Subscription Agreement(s)
          </Accordion.Control>
          <Accordion.Panel>
            {data && Object.keys(data).length !== 0 ? (
              Object.entries(data).map(([key, value], index, array) => (
                <div key={key} className={classes.signedGroup}>
                  {data[key][0].signedAgreements ? (
                    <div>
                      <div className={classes.docContain}>
                        <Text className={classes.docText}>
                          {data[key][0].signedAgreements[0].docName}
                        </Text>
                        <ActionIcon
                          variant="subtle"
                          onClick={() =>
                            downloadSignedAgreement(
                              data[key][0].signedAgreements[0].path,
                              data[key][0].signedAgreements[0].docName
                            )
                          }
                        >
                          <IconDownload></IconDownload>
                        </ActionIcon>
                      </div>
                    </div>
                  ) : (
                    <div>No Signed Agreement</div>
                  )}
                </div>
              ))
            ) : (
              <div>No Signed Agreement</div>
            )}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default InvestorSignedDocuments;
