import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  agreement: {
    cursor: "pointer",
    fontWeight: "bold",
  },
  buttonGroup: {
    marginTop: "30px",
    borderRadius: "10px",
  },
}));
