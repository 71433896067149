import { useMutation } from "react-query";
import postSubscriptionAgreementSigned from "../services/subscriptionService";

export const usePostSubscriptionAgreementSigned = () => {
  return useMutation(
    ["subscription-agreement"],
    postSubscriptionAgreementSigned
  );
};

export default usePostSubscriptionAgreementSigned;
