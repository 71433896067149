import { QueryClient } from "react-query";
import { store } from "../store";
import { resetAuthState } from "../features/auth/authSlice";

const handleOnError = (message: string) => {
  if (message === "Unauthorized") {
    store.dispatch(resetAuthState());
    window.location.replace("/login");

    return;
  }
};

const client = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (e) => {
        handleOnError((e as Error).message);
      },
    },
    queries: {
      onError: (e) => {
        handleOnError((e as Error).message);
      },
    },
  },
});

export { client };
