import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  subHeading: {
    width: "601px",
    height: "19px",

    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: "50px",
  },
  textLabel: {
    marginBottom: "10px",
    paddingLeft: "5px",
    color: "#3C4257",
    //fontFamily:'SF Pro Text',
    textAlign: "left",
    fontSize: "0.87rem"
  },
  text: {
    height: "19px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#11A77A",
    marginTop: "15px",
    marginLeft: "25px",
  },
  list: {
    width: "100%",
    height: "19px",
    top: "352px",
    left: "286px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "8px",
    paddingLeft: "0px",
  },

  download: {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    alignItems: "center",
    marginBottom: "10px",
  },
    stepContent: {
      width: 406,
      height: 337,
      top: 158,
      left: 370,
      gap: 25,
      paddingBottom: 30,
      marginLeft: "50px"
    },
  
    headerText: {
      width: 361,
      height: 46,
      top: 95,
      left: 259,
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "0em",
      textAlign: "left",
      fontStyle: "normal",
    },
    loanDataDiv:{
        display: 'flex',
        justifyContent: "space-between",
        marginTop: "0.7rem",
        textAlign: 'center'
    },
    loanData: {
        width: 110,
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "10px"
    },
    linkText:{
        color: "#338F86",
        marginTop: '88px',
        fontWeight: 700,
        cursor: 'pointer'
      },
      buttonWrapper:{
        marginTop: '10.5rem',
        paddingBottom: "2rem"
      }
  }));