import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  headerText: {
    width: 361,
    height: 46,
    top: 95,
    left: 150,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },
  container: {
    backgroundColor: "#FFF9EF",
    padding: "50px 60px 50px 60px",
  },
  text: {
    marginTop: "10px",
  },
}));
