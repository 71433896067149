import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface ForgotPasswordRequest {
  email: string;
}

const forgetpassword = async ({ email }: ForgotPasswordRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/users/forgot`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export default forgetpassword;
