import { FC, useEffect, useState } from "react";
import { useStyle } from "./IndInvestorDeclaration.style";
import {
  Group,
  List,
  MantineProvider,
  Modal,
  Radio,
  Tooltip,
} from "@mantine/core";
import { Button, color } from "@funded-here-interface/common";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import useInvestorDeclaration from "../../../../hooks/useInvestorDeclaration";
import { setInvestorDeclarationQualifiedRedux } from "../../../../features/indinvestor/profSlice";

interface IProps {
  opened: boolean;
  close: () => void;
  onError: (value: string) => void;
}

enum Question {
  ONE = "one",
  TWO = "two",
  THREE = "three",
}

const IndInvestorDeclarationModal: FC<IProps> = (props) => {
  const { classes } = useStyle();
  const { opened, close, onError } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  const [responses, setResponses] = useState({
    [Question.ONE]: { isChecked: false, value: null },
    [Question.TWO]: { isChecked: false, value: null },
    [Question.THREE]: { isChecked: false, value: null },
  });
  const [isQualified, setIsQualified] = useState(false);
  const useInvestorDeclarationMutation = useInvestorDeclaration();

  useEffect(() => {
    const allChecked = Object.values(responses).every(
      (response) => response.isChecked
    );
    const anyQualified = Object.values(responses).some(
      (response) => response.value === true
    );

    setIsChecked(allChecked);
    setIsQualified(allChecked && anyQualified);
  }, [responses]);

  const handleSubmit = () => {
    setIsLoading(true);
    useInvestorDeclarationMutation.mutate(
      { token, isQualified, checkList: responses },
      {
        onSuccess: () => {
          dispatch(
            setInvestorDeclarationQualifiedRedux({
              investorDeclarationQualified: isQualified,
            })
          );

          close();
          setIsLoading(false);
        },
        onError: (e) => {
          setIsLoading(false);
          onError((e as Error).message);
        },
      }
    );
  };

  const onRadioClick = (questionKey: Question, value: boolean) => {
    setResponses((current) => ({
      ...current,
      [questionKey]: { isChecked: true, value },
    }));
  };

  return (
    <Modal
      size="30rem"
      withCloseButton={false}
      closeOnClickOutside={false}
      opened={opened}
      onClose={close}
    >
      <div className={classes.container}>
        <h1 className={classes.header}>
          Before we proceed please help us answer the following questions
        </h1>
        <List listStyleType="decimal" className={classes.body}>
          <List.Item className={classes.list}>
            My
            <Tooltip
              multiline
              transitionProps={{ duration: 200 }}
              withArrow
              label={
                <div style={{ padding: 20 }}>
                  <p>
                    [1] In determining the value of an individual’s net personal
                    assets, the value of the individual’s primary residence
                    (which the accredited investor lives in most of the time and
                    may be located in Singapore or overseas):
                  </p>
                  <p className={classes.subList}>
                    (a) is to be calculated by deducting any outstanding amounts
                    in respect of any credit facility that is secured by the
                    residence from the estimated fair market value of the
                    residence; and
                  </p>
                  <p className={classes.subList}>
                    (b) is taken to be the lower of the following:
                  </p>
                  <ul style={{ marginLeft: "40px" }}>
                    <li>(i) the value calculated under paragraph (a); or</li>
                    <li>(ii) $1 million.</li>
                  </ul>
                </div>
              }
            >
              <span className={classes.tooltip}> net personal assets </span>
            </Tooltip>
            exceed in value S$2 million (or its equivalent in a foreign
            currency)
            <MantineProvider theme={{ cursorType: "pointer" }}>
              <Radio.Group className={classes.radio}>
                <Group mt="xs">
                  <Radio
                    label="Yes"
                    value="yes"
                    onClick={() => onRadioClick(Question.ONE, true)}
                  />
                  <Radio
                    label="No"
                    value="no"
                    onClick={() => onRadioClick(Question.ONE, false)}
                  />
                </Group>
              </Radio.Group>
            </MantineProvider>
          </List.Item>
          <List.Item className={classes.list}>
            My
            <Tooltip
              multiline
              transitionProps={{ duration: 200 }}
              withArrow
              label={
                <div style={{ padding: 20 }}>
                  <p>[1] “Financial Asset” means:</p>
                  <div style={{ marginLeft: "20px" }}>
                    <p style={{ marginTop: "10px" }}>
                      (a) a deposit as defined in section 4B of the Banking Act
                      1970;
                    </p>
                    <p style={{ marginTop: "10px" }}>
                      (b) an investment product as defined in section 2(1) of
                      the Financial Advisers Act 2001;
                    </p>
                    <p style={{ marginTop: "10px" }}>
                      (c) any other asset as may be prescribed by regulations
                      made under section 341; or
                    </p>
                    <p style={{ marginTop: "10px" }}>
                      (d) whose income in the preceding 12 months is not less
                      than S$300,000 (or its equivalent in a foreign currency)
                      or such other amount as MAS may prescribe in place of the
                      first amount.
                    </p>
                  </div>
                </div>
              }
            >
              <span className={classes.tooltip}> financial assets </span>
            </Tooltip>
            (net of any related liabilities) exceed in value S$1 million (or its
            equivalent in a foreign currency)
            <MantineProvider theme={{ cursorType: "pointer" }}>
              <Radio.Group className={classes.radio}>
                <Group mt="xs">
                  <Radio
                    label="Yes"
                    value="yes"
                    onClick={() => onRadioClick(Question.TWO, true)}
                  />
                  <Radio
                    label="No"
                    value="no"
                    onClick={() => onRadioClick(Question.TWO, false)}
                  />
                </Group>
              </Radio.Group>
            </MantineProvider>
          </List.Item>
          <List.Item className={classes.list}>
            My income in preceding twelve (12) months is not less than S$300,000
            (or its equivalent in a foreign currency)
            <MantineProvider theme={{ cursorType: "pointer" }}>
              <Radio.Group className={classes.radio}>
                <Group mt="xs">
                  <Radio
                    label="Yes"
                    value="yes"
                    onClick={() => onRadioClick(Question.THREE, true)}
                  />
                  <Radio
                    label="No"
                    value="no"
                    onClick={() => onRadioClick(Question.THREE, false)}
                  />
                </Group>
              </Radio.Group>
            </MantineProvider>
          </List.Item>
        </List>

        <div className={classes.buttonGroup}>
          <Button
            disabled={!isChecked || isLoading}
            width="100%"
            textColor={color.WHITE}
            backgroundColor={color.FHGREEN}
            children="OK"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default IndInvestorDeclarationModal;
