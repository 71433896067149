import { Text } from "@mantine/core";
import { FC } from "react";
import { AcknowledgementLayout } from "@funded-here-interface/common";
import { useStyle } from "./PendingShareholderOnboard.styles";

const PendingShareholderOnboard: FC = () => {
  const { classes } = useStyle();

  return (
    <AcknowledgementLayout
      showLogo={false}
      content={
        <div className={classes.container}>
          <Text className={classes.headerText}>
            Pending Shareholders/Directors Onboarding
          </Text>
          <Text className={classes.text}>
            We will email you once all your directors signed the Board
            Resolution and all shareholders complete digital identification
            verification.
          </Text>
          <Text className={classes.text}>Thank you for your patience.</Text>
        </div>
      }
    />
  );
};

export default PendingShareholderOnboard;
