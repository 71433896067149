import { useMutation } from "react-query";
import getSkusPendingMerchantSigning from "../services/getSkusPendingMerchantSigning";

const useGetSkusPendingMerchantSigning = () => {
  return useMutation(
    ["get-sku-pending-merchant-signing"],
    getSkusPendingMerchantSigning
  );
};

export default useGetSkusPendingMerchantSigning;
