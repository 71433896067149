export enum DocuSignEventHandler {
  READY = "ready",
  SESSION_END = "sessionEnd",
}

export enum DocuSignEvent {
  SIGNING_COMPLETE = "signing_complete",
  CANCEL = "cancel",
  DECLINE = "decline",
  EXCEPTION = "exception",
  FAX_PENDING = "fax_pending",
  SESSION_TIMEOUT = "session_timeout",
  TTL_EXPIRED = "ttl_expired",
  VIEWING_COMPLETE = "viewing_complete",
}
