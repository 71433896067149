import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  document: {
    background: "white",
    // padding: "20px",
    borderRadius: "5px",
    maxHeight: "80vh",
    overflowY: "auto",
    height: "500px",
  },
  agreeGroup2: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: "20px",
  },
  disclaimerAccept2: {
    height: "19px",
    top: "545px",
    left: "425px",
    color: "#151515",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "25px",
    lineHeight: "19.09px",
    padding: "5px 0px ",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
    gap: "30px",
  },
  buttonWrapper: {
    paddingTop: "10px",
  },
  headerText: {
    width: "100%",
    height: 46,
    top: 95,
    left: 259,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
    marginTop: "20px",
    marginBottom: "20px",
  },
  linkText: {
    color: "#338F86",
    cursor: "pointer",
    fontWeight: "bold",
    width: "full",
  },
  tempWideDisplay: {
    position: "absolute",
    width: "800px",
  },
}));
