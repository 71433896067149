import { color } from "@funded-here-interface/common";
import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  headerText: {
    fontSize: 24,
    color: color.FHGREEN,
    fontWeight: 700,
    lineHeight: "normal",
  },
  container: {
    backgroundColor: "#FFF9EF",
    padding: "70px",
    width: "460px",
  },
  text: {
    marginTop: "25px",
  },
}));
