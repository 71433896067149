import { createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme) => ({
  container: {
    margin: 0,
    position: "relative",
    width: 1280,
    height: 832,
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
  },

  leftContainer: {
    width: 672,
    height: 496,
    top: 208,
    left: 25,
    gap: 40,
    [theme.fn.smallerThan("md")]: {
      width: "100%",
      height: "auto",
      top: 0,
      left: 0,
    },
  },

  iconContainer: {
    position: "absolute",
    width: 151,
    height: 50.08040237426758,
    top: 58,
    left: 85,
  },

  logo: {
    width: 151,
  },

  h1: {
    textAlign: "center",
    fontSize: 24,
    marginBottom: 20,
  },

  h2: {
    fontSize: 18,
    marginBottom: 10,
  },

  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "13px, 87px, 13px, 87px",
    gap: 40,
    position: "absolute",
    left: "calc(50% - 542px/2 - 284px)",
    top: 208,
    boxShadow:
      "0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)",
    borderRadius: 4,
  },
  newcontainer: {
    margin: 0,
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    backgroundSize: "cover",
    background: "#FFFFFF",
    display: "flex",
    justifyContent: "end",
    [theme.fn.smallerThan("md")]: {
      justifyContent: "center",
      backgroundSize: "cover",
      backgroundPosition: "-170px -58px",
      backgroundRepeat: "repeat",
    },
  },
  newpageContainer: {
    padding: "13px, 87px, 13px, 87px",
    gap: 40,
    position: "absolute",
    top: 158,
    right: 200,
    boxShadow:
      "0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)",
    borderRadius: 4,
    [theme.fn.smallerThan("md")]: {
      alignItems: "center",
      padding: "18px",
      position: "static",
      width: "100%",
      boxShadow: "none",
      marginTop: "55%",
    },
  },
  pageContainer2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "13px, 87px, 13px, 87px",
    gap: 40,
    position: "absolute",
    left: "calc(50% - 542px/2 - 284px)",
    top: 208,
  },

  backImage: {
    width: 665,
    height: "100%",
    left: 780,
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
}));
