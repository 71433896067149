import { FC } from "react";
import { Button } from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useStyle } from "./Deposite.styles";
import { Text } from "@mantine/core";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";

const Deposit: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();

  const handleSubmit = () => {
    Navigate("/loanform");
  };

  return (
    <OnboardingLayout
      step={0}
      content={
        <div className={classes.stepContent} style={{ marginTop: "0px" }}>
          <Text className={classes.headerText} style={{ marginTop: "10px" }}>
            Payment of MOE{" "}
          </Text>
          <Text
            className={classes.instruction}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            To activate your credit limit, kindly provide a deposit equivalent
            to 10 percent of the approved credit limit.{" "}
          </Text>

          <Text className={classes.ctext}>Credit LIne: 500000 SGD</Text>
          <div className={classes.download}>
            <Text className={classes.ctext}>MOE: </Text>
            <Text className={classes.doc}>50000 SGD</Text>
          </div>

          <div className={classes.checkbox}>
            <Text className={classes.ctextTo}>To </Text>
            <div className={classes.download}>
              <Text className={classes.ctext}>Bank Name: </Text>
              <Text className={classes.doc}>UOB</Text>
            </div>
            <div className={classes.download}>
              <Text className={classes.ctext}>Account name: </Text>
              <Text className={classes.doc}>Funded Here</Text>
            </div>
            <div className={classes.download}>
              <Text className={classes.ctext}>Account no: </Text>
              <Text className={classes.doc}>2675643-001</Text>
            </div>
            <Text className={classes.ctext}> </Text>
          </div>

          <div className={classes.buttonDiv}>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={() => Navigate("/loanApplication")}
                backgroundColor="#ffff"
                textColor="#338F86"
                children="Back"
                border="1px solid #338F86"
                width="100%"
              />
            </div>
            <div className={classes.buttonWrapper}>
              <button
                onClick={() => handleSubmit()}
                className={classes.buttonClass}
                style={{
                  cursor: "pointer",
                  color: "#ffff",
                  backgroundColor: "#338F86",
                  width: "210px",
                  borderRadius: "5px",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Next
              </button>
              {/* <Button onClick={()=>handleSubmit()} textColor="#ffff" backgroundColor ="#338F86" children="I've made the payment" width='100%'/> */}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Deposit;
