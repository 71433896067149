import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  tabsOverall: {
    paddingBottom: "20px",
  },
  headerText: {
    fontSize: "36px",
    fontWeight: 700,
    marginBottom: "30px",
  },
  subHeading: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "50px",
  },
  mainText: {
    marginBottom: "10px",
  },
  subText: {
    marginBottom: "15px",
  },
  highlightText: {
    color: "#338F86",
    fontWeight: 800,
  },
  tabHeaderText: {
    marginTop: "20px",
  },

  list: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    marginTop: "24px",
  },
  unOrdered: {
    listStyleType: "none",
    padding: 0,
  },
  instruction: {
    marginLeft: "25px",
  },
  qrcode: {
    marginBottom: "10px",
    marginTop: "10px",
    marginLeft: "50px",
  },
  loader: {
    display: "flex",
    justifyContent: "left",
    marginLeft: "125px",
  },
  qrContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  qrcodeLuminor: {
    width: "20%",
  },
}));
