import { FC, useCallback, useEffect, useState } from "react";
import verifyIcon from "../../assets/verify.svg";
import verifiedIcon from "../../assets/check_circle_24px.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useStyle } from "./VQRVerifyPage.styles";
import { Text } from "@mantine/core";
import { VQRVerify } from "../../shareRing/VQRVerify";
import { useAuthenticateDID } from "../../hooks/useAuthenticateDID";
import { ToastContainer, toast } from "react-toastify";

type Props = {
  onAuthenticatedChange: (isAuthenticated: boolean) => void;
  onNext: () => void;
};

const VQRVerifyPage: FC<Props> = ({ onAuthenticatedChange, onNext }) => {
  const { classes } = useStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [initialAuthentication, setInitialAuthentication] = useState(true);
  const id = useSelector((state: RootState) => state.auth.userId);
  const [scanData, setScanData] = useState<any>(null);
  const authenticateDID = useAuthenticateDID({ scanData });
  const onInit = (data: any) => {
    console.log("onInit", data.detail);
  };

  const onScan = useCallback(
    (data: any) => {
      setIsLoading(true);
      const response = data.detail;
      setScanData(response.result);
    },
    [setScanData]
  );

  useEffect(() => {
    if (initialAuthentication) {
      setInitialAuthentication(false);
      return;
    }
    setIsLoading(false);
    const timeoutId = setTimeout(() => {
      if (authenticateDID) {
        setIsLoading(true);
        onAuthenticatedChange(authenticateDID);
        onNext();
      } else {
        setIsLoading(false);
        toast.error("Verification Failed");
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [authenticateDID, scanData]);

  return (
    <>
      {isLoading && (
        <div className={classes.loading}>
          <div className={classes.loadingContainer}>
            <img src={verifiedIcon} alt="" />
            <Text>
              We have verified you successfully! Let’s proceed with the next
              step
            </Text>
          </div>
        </div>
      )}
      <ToastContainer />
      <div className={classes.outterContainer}>
        <div className={classes.verifyOuterContainer}>
          <div className={classes.verifyInnerContainer}>
            <div className={classes.container}>
              <img className={classes.verifyIcon} src={verifyIcon} alt="" />
              <Text className={classes.verifyText}>Verify its you</Text>
            </div>
            <Text className={classes.verifyPara}>
              Scan this code on your mobile device via the Luminor ID app{" "}
            </Text>
            <VQRVerify
              className={classes.qrcode}
              onInit={onInit}
              onScan={onScan}
              correlationId={id.toString()}
            />
            <Text className={classes.verifyPara}>
              Enter your Identifime user id and password, Authenticate facial
              recognition and come back here
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default VQRVerifyPage;
