import { color } from "@funded-here-interface/common";
import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  container: {
    width: "500px",
    margin: "20px auto",
    padding: "20px",
    backgroundColor: color.WHITE,
    textAlign: "left",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
  header: {
    marginBottom: "10px",
    paddingLeft: "50px",
    paddingTop: "50px",
  },
  text: {
    marginBottom: "10px",
    paddingLeft: "50px",
    paddingBottom: "50px",
  },
}));
