import {
  Button,
  InputGroup,
  InputSelect,
  NO_REGEX,
  SWIFT_CODE_REGEX,
} from "@funded-here-interface/common";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyle } from "../../styles/BankDetailsCss";
import { useDispatch, useSelector } from "react-redux";
import {
  setBankNameRedux,
  setAccountHolderNameRedux,
  setAccountNoRedux,
  setSwiftCodeReduct,
  setAccountTypeRedux,
} from "../../features/merchant/bankSlice";

import { RootState } from "../../store";
import { toast, ToastContainer } from "react-toastify";
import { bankAccountTypes } from "../../constants/bank";
import BankAccNameDisclaimer from "../../components/BankAccNameDisclaimer/BankAccNameDisclaimer";

const BorrowerBank: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();

  const {
    bankName: banknameRedux,
    accountHolderName: accountHolderNameRedux,
    accountNumber: accountNoRedux,
    accountType: accountTypeRedux,
    swiftCode: swiftCodeRedux,
  } = useSelector((state: RootState) => state.BorBank);

  const comp = useSelector((state: RootState) => state.borBiz.company);

  const [bankName, setBankName] = useState(banknameRedux || "");
  const [accountNumber, setAccountNumber] = useState(accountNoRedux || "");
  const [accountType, setAccountType] = useState(accountTypeRedux || "");
  const [swiftCode, setSwiftCode] = useState(swiftCodeRedux || "");
  const [accountHolderName, setAccountHolderName] = useState(
    accountHolderNameRedux || ""
  );

  const [error, setError] = useState({
    bankName: "",
    accountHolderName: "",
    swiftCode: "",
    accountNumber: "",
    accountType: "",
  });
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(setBankNameRedux({ bankName }));
    dispatch(setAccountNoRedux({ accountNumber }));
    dispatch(setAccountHolderNameRedux({ accountHolderName }));
    dispatch(setAccountTypeRedux({ accountType }));
    dispatch(setSwiftCodeReduct({ swiftCode }));

    Navigate("/bor-rep");
  };

  const handleNumericInputChange = (actualOnChange: any) => (value: any) => {
    const numericValue = value.replace(NO_REGEX, "");
    actualOnChange(numericValue);
  };

  const handleNext = () => {
    const customError = { ...error };
    let valid = true;

    if (!bankName) {
      customError.bankName = "Bank Name is required";
      valid = false;
    }
    if (!accountNumber) {
      customError.accountNumber = "Account Number is required";
      valid = false;
    }
    if (!accountType) {
      customError.accountType = "Account Type is required";
      valid = false;
    }
    if (!swiftCode) {
      customError.swiftCode = "Swift Code is required";
      valid = false;
    }
    if (!accountHolderName) {
      customError.accountHolderName = "Account Holder Name is required";
      valid = false;
    }
    if (comp) {
      if (accountHolderName !== comp) {
        valid = false;
        toast.warn("Account Holder Name should be the same as company name");
      }
    }

    if (valid) {
      dispatch(setBankNameRedux({ bankName }));
      dispatch(setAccountNoRedux({ accountNumber }));
      dispatch(setAccountHolderNameRedux({ accountHolderName }));
      dispatch(setAccountTypeRedux({ accountType }));
      dispatch(setSwiftCodeReduct({ swiftCode }));

      Navigate("/bor-doc");
    } else {
      setError(customError);
    }
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <InputGroup
        isErrored={error.bankName}
        onChange={(value: any) => {
          error.bankName = "";
          setBankName(value);
        }}
        labelText="Bank Name"
        id="bank_name"
        value={bankName}
      />
      <InputGroup
        isErrored={error.accountNumber}
        onChange={handleNumericInputChange((value: any) => {
          error.accountNumber = "";
          setAccountNumber(value.replace(NO_REGEX, ""));
        })}
        labelText="Bank Account Number"
        id="account_number"
        value={accountNumber}
      />
      <InputSelect
        isErrored={error.accountType}
        onChange={(value: any) => {
          error.accountType = "";
          setAccountType(value);
        }}
        labelText="Account type"
        id="accountType"
        value={accountType}
        data={bankAccountTypes}
      />
      <InputGroup
        isErrored={error.swiftCode}
        onChange={(value: any) => {
          error.swiftCode = "";
          setSwiftCode(value.replace(SWIFT_CODE_REGEX, ""));
        }}
        labelText="Bank Swift Code"
        value={swiftCode}
        id="swift_code"
      />
      <BankAccNameDisclaimer />
      <InputGroup
        isErrored={error.accountHolderName}
        onChange={(value: any) => {
          error.accountHolderName = "";
          setAccountHolderName(value);
        }}
        labelText="Account Holder Name (should match with institution name)"
        value={accountHolderName}
        id="account_holder_name"
      />
      <div className={classes.buttonDiv}>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={handleBack}
            backgroundColor="#ffff"
            textColor="#338F86"
            children="Back"
            border="1px solid #338F86"
            width="100%"
          />
        </div>
        <ToastContainer />
        <div className={classes.buttonWrapper}>
          <Button
            onClick={handleNext}
            textColor="#ffff"
            backgroundColor="#338F86"
            children="Next"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default BorrowerBank;
