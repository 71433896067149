import { Text } from "@mantine/core";
import { FC } from "react";
import { AcknowledgementLayout } from "@funded-here-interface/common";
import { useStyle } from "./ShareholderSPVBoardResolutionPending.styles";

const ShareholderSPVBoardResolutionPending: FC = () => {
  const { classes } = useStyle();

  return (
    <AcknowledgementLayout
      showLogo={false}
      content={
        <div className={classes.container}>
          <Text className={classes.headerText}>
            Pending SPV Board Resolution
          </Text>
          <Text className={classes.text}>
            We will email you once all your directors signed the SPV Board
            Resolution.
          </Text>
          <Text className={classes.text}>Thank you for your patience.</Text>
        </div>
      }
    />
  );
};

export default ShareholderSPVBoardResolutionPending;
