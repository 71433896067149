import { FC } from "react";
import { CloseButton, Group, Text } from "@mantine/core";
import { useShallowEffect } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./PasswordChangedMsg.styles";

const PasswordChangedMessage: FC = () => {
  const { classes } = useStyles();
  const handleOnClose = () => {
    navigate("/login");
  };
  const navigate = useNavigate();
  useShallowEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  });

  return (
    <div className={classes.resetMssgContainer}>
      <Group>
        <Text className={classes.headingText}>Password changed</Text>
        <CloseButton
          size="md"
          onClick={handleOnClose}
          className={classes.crossIcon}
        />
      </Group>
      <div className={classes.emailSentMessage}>
        <div className={classes.subHeading}>
          Redirecting you to the login page.........
        </div>
      </div>
    </div>
  );
};

export default PasswordChangedMessage;
