import { color } from "@funded-here-interface/common";
import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  stepsToComplete: {
    display: "flex",
    flexDirection: "row",
    left: "259px",
  },
  instruction: {
    width: "508px",
    height: "50px",
    top: "140px",
    left: "259px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    fontStyle: "normal",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subHeading: {
    width: "601px",
    height: "19px",

    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: "30px",
  },
  textLabel: {
    marginBottom: "10px",
    paddingLeft: "5px",
    color: "#3C4257",
    //fontFamily:'SF Pro Text',
    textAlign: "left",
    fontSize: "0.87rem",
  },
  text: {
    height: "19px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#11A77A",
    marginTop: "15px",
    marginLeft: "25px",
  },
  list: {
    width: "100%",
    height: "19px",
    top: "352px",
    left: "286px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "8px",
    paddingLeft: "0px",
  },
  progress: {
    //position:'absolute',
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0px",
    position: "absolute",
    left: "10px",
  },
  eclipse: {
    width: "10px",
    height: "10px",
    top: "380px",
    left: "271px",
    marginTop: "8px",
  },
  line: {
    width: "10px",
    height: "20px",
    justifyContent: "center",
    border: "1px",
    rotate: "none",
    paddingLeft: "4px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
    marginTop: "50px",
    msFlexDirection: "row-reverse",
  },
  buttonWrapper: {
    paddingRight: "30px",
  },

  download: {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    alignItems: "center",
    marginBottom: "10px",
  },
  doc: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  checkbox: {
    display: "flex",
    gap: "10px",
    height: "25px",
    marginBottom: "50px",
    marginTop: "30px",
  },
  cbox: {
    height: "23px",
    width: "23px",
  },
  ctext: {
    paddingTop: "3px",
  },

  buttonClass: {
    width: "365px",
    height: "45px",
    padding: "13px 87px 13px 87px",
    border: "none",
    gap: "10px",
    fontSize: "16px",

    letterSpacing: "0em",
    textAlign: "center",
  },
  verify: {
    backgroundColor: "#FFFAF2",
    position: "absolute",
    width: "1300px",
    zIndex: 12345678987655,
  },
  uploadContainer: {
    display: "flex",
    marginBottom: "26px",
  },
  fileTitile: {
    fontWeight: 400,
    fontSize: "16px",
    marginRight: "20px",
  },
  formContainer: {
    height: "850px",
    width: "100vh",
    marginTop: "30px",
    // overflowX: 'auto'
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height: "850px",
    width: "700px",
  },
  formInput: {
    width: "400px",
    marginRight: "50px",
  },
  title: {
    fontWeight: 700,
    width: "600px",
    marginBottom: "0.5rem",
  },
  linkText:{
    color: color.FHGREEN,
    marginBottom: '10px',
    cursor: "pointer",
  },
  stepContent: {
    width: 406,
    height: 337,
    top: 158,
    left: 370,
    gap: 25,
    paddingBottom: 30,
    marginLeft: "50px"
  },

  headerText: {
    width: 361,
    height: 46,
    top: 95,
    left: 259,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },
}));
