import { Industry, LegalStructure } from "./enums";

export const legalStructures = [
  LegalStructure.ASSOCIATION_OR_NONPROFIT_ORGANIZATION,
  LegalStructure.COOPERATIVE,
  LegalStructure.CORPORATION_INC_OR_LTD,
  LegalStructure.FOUNDATION,
  LegalStructure.LICENSED_FUND,
  LegalStructure.LIMITED_LIABILITY_COMPANY_LLC,
  LegalStructure.LIMITED_LIABILITY_PARTNERSHIP_LLP,
  LegalStructure.LIMITED_PARTNERSHIP_LP,
  LegalStructure.PRIVATE_LIMITED,
  LegalStructure.REAL_ESTATE_INVESTMENT_TRUST_REIT,
  LegalStructure.SOVEREIGN_WEALTH_FUND,
  LegalStructure.TRUST,
  LegalStructure.OTHERS,
];

export const funds = [
  LegalStructure.LICENSED_FUND,
  LegalStructure.SOVEREIGN_WEALTH_FUND,
];

export const industry = [
  Industry.AGRICULTURE,
  Industry.CONSULTING,
  Industry.EDUCATION,
  Industry.ENERGY,
  Industry.ENTERTAINMENT,
  Industry.FINANCE,
  Industry.FOOD_AND_BEVERAGE,
  Industry.HEALTHCARE,
  Industry.HOSPITALITY,
  Industry.MANUFACTURING,
  Industry.MEDIA_AND_COMMUNICATIONS,
  Industry.REAL_ESTATE,
  Industry.RETAIL,
  Industry.TECHNOLOGY,
  Industry.TRANSPORTATION,
  Industry.OTHERS,
];
