export const stepsJson = [
  {
    steps_id: 1,
    text: "Your Profile",
  },

  {
    steps_id: 2,
    text: "Bank Details",
  },
  {
    steps_id: 3,
    text: "Document Upload",
  },
  {
    steps_id: 4,
    text: "Declaration of AI",
  },
];
