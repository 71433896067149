import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  loginContainer: {
    alignItems: "center",
    padding: "50px",
    boxShadow:
      "0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)",
    borderRadius: 4,
  },
  loginHeading: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subHeading: {
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  inputGroupContainer: {
    width: "406px",
    height: "38%",
    marginTop: "20px",
  },
  checkboxContainer: {
    width: "247px",
    height: "22px",
    gap: "6px",
  },
  buttonDiv: {
    paddingTop: "10px",
    marginTop: "20px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  signInFooter: {
    width: "209px",
    height: "17px",
    top: "350px",
    position: "absolute",
    marginTop: "30px",
    gap: "10px",

    display: "flex",
    flexDirection: "row",
    marginLeft: "72px",
    justifyContent: "flex-start",
  },
  noAccount: {
    width: "155px",
    height: "25px",
    /*typegraphy*/
    fontFamily: "SF Pro Text",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16.71px",
    color: "#4F566B",
  },
  signUp: {
    width: "50px",
    height: "17px",
    /*typegraphy*/
    fontFamily: "SF Pro Text",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16.71px",
    color: "#338F86",
    left: "135px",
    position: "absolute",
    cursor: "pointer",
    paddingLeft: "18px",
  },
  body: {
    fontFamily: "sans-serif",
    backgroundColor: "#fff",
  },

  fhGreen: {
    color: "#338F86",
  },

  fhGrey: {
    color: "#4F566B",
  },

  h1: {
    textAlign: "center",
    fontSize: 24,
    marginBottom: 20,
  },

  h2: {
    fontSize: 18,
    marginBottom: 10,
  },

  input: {
    padding: 10,
    marginBottom: 10,
    backgroundColor: "#338F86",
    color: "white",
    border: "none",
    borderRadius: 4,
    cursor: "pointer",
    width: "90%",
    height: 45,
    marginTop: 10,
  },

  a: {
    textDecoration: "none",
    color: "#4CAF50",
  },

  BackImage: {
    position: "absolute",
    width: 623,
    height: 832,
    left: 657,
    top: 0,
  },

  linkText: {
    fontFamily: "SF Pro Text",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 17,
    display: "flex",
    alignItems: "center",
  },

  smallText: {
    fontFamily: "SF Pro Text",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 17,
    display: "flex",
    alignItems: "center",
  },

  LoginForgotPasswordInput: {
    marginTop: -20,
  },
}));
