import { useQuery } from "react-query";
import getUser from "../services/getUser";

function useGetUser(token: string) {
  return useQuery(["get-user"], () => getUser({ token }), {
    enabled: false,
  });
}

export default useGetUser;
