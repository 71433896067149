import {
  Button,
  InputGroup,
  InputSelect,
  NO_REGEX,
  SWIFT_CODE_REGEX,
  color,
} from "@funded-here-interface/common";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyle } from "../../styles/BankDetailsCss";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import {
  setBankNameRedux,
  setAccountHolderNameRedux,
  setAccountNoRedux,
  setSwiftCodeReduct,
  setAccountTypeRedux,
} from "../../features/institutionalInvestor/bankSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bankAccountTypes } from "../../constants/bank";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";
import BankAccNameDisclaimer from "../../components/BankAccNameDisclaimer/BankAccNameDisclaimer";
import { Persona } from "@funded-here-interface/common/src/constant/enum";

const BankDetails: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const type = useSelector((state: RootState) => state.auth.type);

  const {
    bankName: bank_name,
    accountType: accounttype,
    accountNumber: bankAccountNo,
    accountHolderName: account_holder_name,
    swiftCode: swiftCodeRedux,
  } = useSelector((state: RootState) => state.bank);

  const comp = useSelector((state: RootState) => state.biz.company);

  const [bankName, setBankName] = useState(bank_name || "");
  const [accountNumber, setAccountNumber] = useState(bankAccountNo || "");
  const [accountType, setAccountType] = useState(accounttype || "");
  const [swiftCode, setSwiftCode] = useState(swiftCodeRedux || "");
  const [accountHolderName, setAccountHolderName] = useState(
    account_holder_name || ""
  );

  const handleBack = () => {
    dispatch(setBankNameRedux({ bankName }));
    dispatch(setAccountNoRedux({ accountNumber }));
    dispatch(setAccountHolderNameRedux({ accountHolderName }));
    dispatch(setSwiftCodeReduct({ swiftCode }));
    dispatch(setAccountTypeRedux({ accountType }));

    if (type === Persona.INVESTOR) {
      Navigate("/institutional-rep");
    } else {
      Navigate("/representative-details");
    }
  };

  const [error, setError] = useState({
    bank_name: "",
    bankAccountNo: "",
    account_type: "",
    swift_code: "",
    account_holder_name: "",
    accountType: "",
  });

  const handleNumericInputChange = (actualOnChange: any) => (value: any) => {
    const numericValue = value.replace(NO_REGEX, "");
    actualOnChange(numericValue);
  };

  const handleNext = () => {
    const customErr = { ...error };
    let valid = true;
    if (!bankName) {
      valid = false;
      customErr.bank_name = "Bank Name is required";
    }
    if (!swiftCode) {
      valid = false;
      customErr.swift_code = "Swift Code is required";
    }
    if (!accountNumber) {
      valid = false;
      customErr.bankAccountNo = "Bank Account no is required";
    }

    if (!accountType) {
      valid = false;

      customErr.accountType = "Account Type is required";
    }
    if (!accountHolderName) {
      valid = false;

      customErr.account_holder_name = "Account Holder Name is required";
    }

    if (comp) {
      if (accountHolderName !== comp) {
        valid = false;
        toast.warn("Account Holder Name should be the same as company name");
      }
    }

    if (valid) {
      dispatch(setBankNameRedux({ bankName }));
      dispatch(setAccountNoRedux({ accountNumber }));
      dispatch(setAccountHolderNameRedux({ accountHolderName }));
      dispatch(setSwiftCodeReduct({ swiftCode }));
      dispatch(setAccountTypeRedux({ accountType }));

      Navigate("/doc-upload");
    } else {
      setError(customErr);
    }
  };

  return (
    <OnboardingLayout
      step={3}
      showSteps={true}
      content={
        <div style={{ marginTop: "70px" }}>
          <InputGroup
            isErrored={error.bank_name}
            onChange={(value: string) => {
              error.bank_name = "";
              setBankName(value);
            }}
            labelText="Bank Name"
            id="bank_name"
            value={bankName}
          />
          <InputGroup
            isErrored={error.swift_code}
            onChange={(value: string) => {
              error.swift_code = "";
              setSwiftCode(value.replace(SWIFT_CODE_REGEX, ""));
            }}
            labelText="Bank Swift Code"
            value={swiftCode}
            id="swift_code"
          />

          <InputGroup
            isErrored={error.bankAccountNo}
            onChange={handleNumericInputChange((value: any) => {
              error.bankAccountNo = "";
              setAccountNumber(value.replace(NO_REGEX, ""));
            })}
            labelText="Bank Account no."
            id="account_number"
            value={accountNumber}
          />
          <InputSelect
            isErrored={error?.accountType}
            onChange={(value: string) => {
              error.accountType = "";
              setAccountType(value);
            }}
            labelText="Account Type"
            id="accountType"
            value={accountType}
            data={bankAccountTypes}
          />
          <BankAccNameDisclaimer />
          <InputGroup
            isErrored={error.account_holder_name}
            onChange={(value: string) => {
              error.account_holder_name = "";
              setAccountHolderName(value);
            }}
            labelText="Account Holder Name (should match with institution name)"
            value={accountHolderName}
            id="account_holder_name"
          />
          <div className={classes.buttonDiv}>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleBack}
                backgroundColor={color.WHITE}
                textColor={color.FHGREEN}
                children="Back"
                border="1px solid #338F86"
                width="100%"
              />
            </div>
            <ToastContainer />
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleNext}
                textColor={color.WHITE}
                backgroundColor={color.FHGREEN}
                children="Next"
                width="100%"
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default BankDetails;
