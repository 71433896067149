import { useQuery, useMutation } from "react-query";
import {
  getSignedLoanAgreement,
  downloadSignedAgreement,
  getSignedDocuments,
  downloadSignedDocuments,
  getSignedSubscriptionAgreement,
} from "../services/getSignedLoanAgreement";
import { OrgDocsUploadSignedDocuments } from "../types/org.type";

const useGetSignedLoanAgreement = (token: string) => {
  return useQuery(
    ["get-signed-loan-agreement"],
    () => getSignedLoanAgreement(token),
    {
      retry: false,
    }
  );
};

const useDownloadSignedAgreement = () => {
  return useMutation(["download-signed-agreement"], downloadSignedAgreement);
};

const useGetSignedDocuments = (token: string) => {
  return useQuery<OrgDocsUploadSignedDocuments[]>(
    ["get-signed-documents"],
    () => getSignedDocuments(token),
    {
      retry: false,
    }
  );
};

const useDownloadSignedDocuments = () => {
  return useMutation(["download-signed-documents"], downloadSignedDocuments);
};

const useGetSignedSubscriptionAgreement = (token: string) => {
  return useQuery(
    ["get-signed-subscription-agreement"],
    () => getSignedSubscriptionAgreement(token),
    {
      retry: false,
    }
  );
};

export {
  useGetSignedLoanAgreement,
  useDownloadSignedAgreement,
  useGetSignedDocuments,
  useDownloadSignedDocuments,
  useGetSignedSubscriptionAgreement,
};
