import { useStyles } from "./DynamicTable.styles";
import { Button, color } from "@funded-here-interface/common";
import { useState, useEffect } from "react";

export interface DynamicTableSettings {
  data: any;
  column: DynamicTableColumnSettings[];
  hasCheckbox?: boolean;
  enableHeaderCheckbox?: boolean;
  disableAllCheckboxes?: boolean;
  emitCheckBoxChangedMessage?: (data: any) => void;
  emitAllCheckBoxChangedMessage?: (isAllChecked: boolean, data: any) => void;
  emitDatabaseViewButtonClickedMessage?: (data: any) => void;
  isBorderRequired?: boolean;
}

export interface DynamicTableColumnSettings {
  heading: string;
  dataKey: string;
  clickable?: boolean;
  isButtonColumn?: boolean;
  buttonText?: string;
}

export interface DynamicTableRowSettings {
  data: any;
}

const DynamicTable = ({
  data,
  column,
  hasCheckbox = false,
  enableHeaderCheckbox = false,
  disableAllCheckboxes = false,
  emitCheckBoxChangedMessage,
  emitAllCheckBoxChangedMessage,
  emitDatabaseViewButtonClickedMessage,
  isBorderRequired = true,
}: DynamicTableSettings) => {
  const { classes } = useStyles();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRowIndices, setSelectedRowIndices] = useState<number[]>([]);

  const handleTableButtonClicked = (row: any) => {
    if (emitDatabaseViewButtonClickedMessage) {
      emitDatabaseViewButtonClickedMessage(row);
    }
  };

  const uppercaseFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.substring(1);
  };

  const handleAllCheckboxSelected = () => {
    const newSelectAllChecked = !selectAllChecked;

    if (newSelectAllChecked) {
      // If "Select All" is checked, select all rows
      setSelectedRowIndices(data.map((_: any, index: any) => index));
    } else {
      // If "Select All" is unchecked, deselect all rows
      setSelectedRowIndices([]);
    }

    if (emitAllCheckBoxChangedMessage) {
      emitAllCheckBoxChangedMessage(newSelectAllChecked, data);
    }
  };

  const handleCheckboxChange = (row: any, index: number) => {
    const updatedSelectedRowIndices = [...selectedRowIndices];

    if (updatedSelectedRowIndices.includes(index)) {
      // If row index is already selected, remove it
      updatedSelectedRowIndices.splice(
        updatedSelectedRowIndices.indexOf(index),
        1
      );
    } else {
      // If row index is not selected, add it
      updatedSelectedRowIndices.push(index);
    }

    setSelectedRowIndices(updatedSelectedRowIndices);

    if (emitCheckBoxChangedMessage) {
      emitCheckBoxChangedMessage(row);
    }
  };

  useEffect(() => {
    if (selectedRowIndices) {
      if (data.length > 0 && data.length === selectedRowIndices.length) {
        setSelectAllChecked(true);
      } else {
        setSelectAllChecked(false);
      }
    }
  }, [selectedRowIndices]);

  //  Reset checkboxes if a new data set is loaded
  useEffect(() => {
    setSelectedRowIndices([]);
    setSelectAllChecked(false);
  }, [data]);

  return (
    <table className={classes.csvTable}>
      <thead>
        <tr className={isBorderRequired ? classes.tr : ""}>
          <td
            className={isBorderRequired ? classes.td : classes.tdWithoutBorder}
          >
            {enableHeaderCheckbox === true && (
              <input
                disabled={disableAllCheckboxes}
                type="checkbox"
                checked={
                  selectAllChecked || data.length === selectedRowIndices.length
                }
                onChange={handleAllCheckboxSelected}
              />
            )}
          </td>

          {column.map((column: DynamicTableColumnSettings, index: number) => (
            <th
              key={index}
              className={
                isBorderRequired ? classes.th : classes.thWithoutBorder
              }
            >
              <div className={classes.sortableHeader}>
                <span className={classes.header}>
                  {uppercaseFirstLetter(column.heading)}
                </span>
                {isBorderRequired && (
                  <div className={classes.icons}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        d="M6.81171 6.2168H1.60281C1.44657 6.2168 1.35933 6.05184 1.45609 5.93922L4.06054 2.9192C4.13509 2.83276 4.27863 2.83276 4.35398 2.9192L6.95843 5.93922C7.05518 6.05184 6.96794 6.2168 6.81171 6.2168Z"
                        fill="#343A40"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        d="M4.20726 5.57069L1.95535 2.95947H6.45916L4.20726 5.57069Z"
                        fill="#EAEAEA"
                        stroke="#EAEAEA"
                        stroke-width="0.676755"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((rowData: any, index: number) => (
          <tr
            className={isBorderRequired ? classes.tr : ""}
            key={index}
            style={{
              color:
                rowData && rowData.error && rowData.error.length > 0
                  ? "red"
                  : "#6c757d",
            }}
          >
            {hasCheckbox && (
              <td
                className={
                  isBorderRequired ? classes.td : classes.tdWithoutBorder
                }
              >
                <input
                  disabled={
                    disableAllCheckboxes ||
                    (rowData && rowData.errors && rowData.errors.length) > 0
                      ? true
                      : false
                  }
                  type="checkbox"
                  checked={selectedRowIndices.includes(index)}
                  onChange={() => handleCheckboxChange(rowData, index)}
                />
              </td>
            )}

            {column.map(
              (columnData: DynamicTableColumnSettings, colIndex: number) => {
                if (!columnData.isButtonColumn) {
                  return (
                    <td
                      key={colIndex}
                      className={
                        rowData && rowData.errors && rowData.errors.length > 0
                          ? classes.tdWithError
                          : isBorderRequired
                          ? classes.td
                          : classes.tdWithoutBorder
                      }
                    >
                      {rowData[columnData.dataKey]
                        ? rowData[columnData.dataKey]
                        : "-"}
                    </td>
                  );
                } else {
                  return (
                    <td key={colIndex} className={classes.td}>
                      <Button
                        textColor={color.WHITE}
                        onClick={() => handleTableButtonClicked(rowData.errors)}
                        backgroundColor={color.FHGREEN}
                        border={`1px solid ${color.FHGREEN}`}
                        width="50%"
                      >
                        {columnData.buttonText}
                      </Button>
                    </td>
                  );
                }
              }
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;
