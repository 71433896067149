import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme:MantineTheme)=>({
    buttonDiv:{
        display:'flex',
        flexDirection:'row',
        paddingLeft:'0px',
        gap:"30px"
    },
    buttonWrapper:{
        paddingTop:'10px'
    }
}))