export const NAME_REGEX = /[^A-Za-z0-9\s]/g;

export const NO_REGEX = /[^0-9]/g;
export const EMAIL_REGEX = /[^\w@.+-]/g;

export const ADDRESS_REGEX = /[^a-zA-Z0-9-.-\s]/g;
export const SWIFT_CODE_REGEX = /[^a-zA-Z0-9]/g;
export const SITE_REGEX = /[^a-zA-Z0-9-.]|(\.(?=.*\.))/g;

export const DOMAIN_REGEX =
  /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/g;

export const COMPANY_DOMAIN_REGEX =
  /^(www\.|https?:\/\/|http?:\/\/)?(.*?)(\.com|[^.]+)$/i;

export const isValidNumber = /^-?\d*\.?\d{0,2}$/;
