import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  headerText: {
    width: 361,
    height: 46,
    fontSize: 24,
    color: "#338F86",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },
  container: {
    backgroundColor: "#FFF9EF",
    padding: "40px 60px 40px 60px",
  },
  text: {
    marginTop: "10px",
  },
}));
