import { Text } from "@mantine/core";
import { FC } from "react";
import { AcknowledgementLayout } from "@funded-here-interface/common";
import { useStyle } from "./MerchantCompleteOnboarding.styles";

const MerchantCompleteOnboarding: FC = () => {
  const { classes } = useStyle();

  return (
    <AcknowledgementLayout
      showLogo={false}
      content={
        <div>
          <Text className={classes.headerText}>Onboarding Completed!</Text>
          <Text className={classes.text}>
            Thank you for the time and effort you've invested in providing the
            necessary information. We're nearly ready to bring pivotal
            enhancements to your system. Our technical team will soon reach out
            to assist with setting up the following integrations:
          </Text>
          <ul className={classes.list}>
            <li>
              <Text className={classes.text}>
                WMS Integration: This will grant us seamless access to your
                inventory data and manage returns more efficiently, streamlining
                your operations.
              </Text>
            </li>
            <li>
              <Text className={classes.text}>
                Payment Integration: Specifically designed to benefit you, this
                integration will allow us to tag your online sales accurately,
                facilitate the collection of repayments, and meticulously track
                each repayment.
              </Text>
            </li>
          </ul>
          <Text className={classes.text}>
            We're excited to proceed to this next phase and will contact you
            shortly to guide you through the integration process.
          </Text>
        </div>
      }
    />
  );
};

export default MerchantCompleteOnboarding;
