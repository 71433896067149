import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  loadingScreen: {
    backgroundColor: "rgba(243, 236, 218, .5)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingIcon: {
    width: "269px",
    height: "184px",
  },
}));
