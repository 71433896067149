import { ComponentPropsWithoutRef, FC } from "react";
import VQR from "./VQR";
import {
  BASE_LUMINOR_ID_URL,
  CLIENT_ID_LUMINOR_DID,
  QUERY_OWNER_LUMINOR_DID,
  VERIFY_LUMINOR_DID_QUERY_ID,
} from "../constants/domain";

interface VQRVerifyProps extends ComponentPropsWithoutRef<"div"> {
  onScan?: (data: any) => void;
  onInit?: (data: any) => void;
  appSchemePrefix?: string;
  appSchemeSuffix?: string;
  correlationId?: string;
}

const queryId = VERIFY_LUMINOR_DID_QUERY_ID + "," + CLIENT_ID_LUMINOR_DID;

export const VQRVerify: FC<VQRVerifyProps> = ({
  onScan,
  onInit,
  correlationId,
  ...props
}) => {
  const metadataObj = {
    correlationId: correlationId,
  };

  const metadata = correlationId
    ? JSON.stringify(metadataObj).replace(/"/g, "'")
    : undefined;
  return (
    <VQR
      style={{ display: "flex", justifyContent: "center" }}
      queryId={encodeURIComponent(queryId)}
      mode="dynamic"
      qrcodeOwner={QUERY_OWNER_LUMINOR_DID}
      appSchemePrefix={encodeURIComponent(
        `${BASE_LUMINOR_ID_URL}/?link=https%3A%2F%2Fsharering.network%2Fvql%3F`
      )}
      appSchemeSuffix={encodeURIComponent(
        "apn=network.sharering.luminor.id&isi=6474107601&ibi=network.sharering.luminor.id"
      )}
      onInit={onInit}
      onScan={onScan}
      metadata={metadata}
      {...props}
    />
  );
};
