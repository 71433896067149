import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../../Utils/HttpClient";

// Helpers

let initialState = {
  status: "idle",
  merchantDetails: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getMerchant = createAsyncThunk(
  "get_all_merchant",
  async ({ token }) => {
    let response = await ApiHelperFunction({
      urlPath: "view-merchant-data",
      method: "GET",
      token: token,
    });
    console.log("response", response);
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const MerchantSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.merchantDetails = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder

      /*------------------ get sub admin  --------------- */
      .addCase(getMerchant.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getMerchant.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        console.log("merchant_list", payload?.data);
        state.merchantDetails = payload?.data;
      })
      .addCase(getMerchant.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      });
  },
});

export const { clearState } = MerchantSlice.actions;

export default MerchantSlice.reducer;
