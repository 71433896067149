export enum Industry {
  AGRICULTURE = "Agriculture",
  CONSULTING = "Consulting",
  EDUCATION = "Education",
  ENERGY = "Energy",
  ENTERTAINMENT = "Entertainment",
  FINANCE = "Finance",
  FOOD_AND_BEVERAGE = "Food and Beverage",
  HEALTHCARE = "Healthcare",
  HOSPITALITY = "Hospitality",
  MANUFACTURING = "Manufacturing",
  MEDIA_AND_COMMUNICATIONS = "Media and Communications",
  REAL_ESTATE = "Real Estate",
  RETAIL = "Retail",
  TECHNOLOGY = "Technology",
  TRANSPORTATION = "Transportation",
  OTHERS = "Others",
}

export enum LegalStructure {
  ASSOCIATION_OR_NONPROFIT_ORGANIZATION = "Association or Nonprofit Organization",
  COOPERATIVE = "Cooperative",
  CORPORATION_INC_OR_LTD = "Corporation (Inc. or Ltd.)",
  FOUNDATION = "Foundation",
  LICENSED_FUND = "Licensed Fund",
  LIMITED_LIABILITY_COMPANY_LLC = "Limited Liability Company (LLC)",
  LIMITED_LIABILITY_PARTNERSHIP_LLP = "Limited Liability Partnership (LLP)",
  PRIVATE_LIMITED = "Pte Ltd",
  LIMITED_PARTNERSHIP_LP = "Limited Partnership (LP)",
  REAL_ESTATE_INVESTMENT_TRUST_REIT = "Real Estate Investment Trust (REIT)",
  SOVEREIGN_WEALTH_FUND = "Sovereign Wealth Fund",
  TRUST = "Trust",
  OTHERS = "Others",
}

export enum BankAccount {
  CURRENT = "Current",
  JOINT = "Joint",
  SAVING = "Saving",
}
