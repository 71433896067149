import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  mainContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "max-content",
    background: "#FFFFFF",
    margin: 0,
  },

  hero: {
    position: "absolute",
    left: 270,
    width: "calc(100vw - 270px - 116px)",
    padding: "58px 48px",
  },
}));
