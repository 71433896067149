import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import toast from "react-hot-toast";
import AuthServices from "../../../../services/AuthServicesInv";
import ErrorBoundary from "@funded-here-interface/common/src/dashboard/components/ErrorBoundry";
import HttpClientXmInv from "../../../../Utils/HttpClientXmInv";
import { useSelector } from "react-redux";

const DataTableownInterest = ({ columns, data }) => {
  console.log("columns", data);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  console.log("datadata", data);
  console.log("headerGroupsheaderGroups", headerGroups);

  return (
    <table {...getTableProps()} style={{ borderSpacing: "0", width: "100%" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  borderBottom: "1px solid black",
                  background: "#f2f2f2",
                  padding: "8px",
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={{ borderBottom: "1px solid black", padding: "8px" }}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const App4 = () => {
  const [paymentdata, setPaymentData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const res = await HttpClientXmInv.requestData(
          `view-note-payment-bySnId-byDate`,
          {},
          token,
          "POST"
        );
        if (res && res.status) {
          console.log("res.datares.data", res.data);
          setPaymentData(res.data);
        } else {
          toast.error("Failed to fetch amount data");
        }
      } catch (error) {
        console.error("Error fetching payment data: ", error);
        toast.error("Failed to fetch amount data");
      }
    };

    fetchPaymentData();
  }, []);

  // const testData = [
  //   {
  //     _id: {
  //       snId: 20,

  //       paymentDate: "2024-12-02",
  //     },
  //     repaidAmount: 21,
  //   },
  //   {
  //     _id: {
  //       snId: 20,

  //       paymentDate: "2023-12-02",
  //     },
  //     repaidAmount: 21,
  //   },
  // ];

  const columns = [
    { Header: "Note ID", accessor: (row) => row._id.snId },
    { Header: "Repayment Amount", accessor: "repaidAmount" },
    {
      Header: "Date",
      accessor: (row) => new Date(row._id.paymentDate).toLocaleDateString(),
    },
  ];

  return (
    <ErrorBoundary>
      <DataTableownInterest columns={columns} data={paymentdata} />
    </ErrorBoundary>
  );
};

export default App4;
