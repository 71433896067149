import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface LoginBodyType {
  email: string;
  password: string;
  persona?: string;
}

const fetchAuth = async ({ email, password, persona }: LoginBodyType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/auth/login`,
      {
        email,
        password,
        persona,
      },
      {
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default fetchAuth;
