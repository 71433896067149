import { CustomTablePM } from "@funded-here-interface/common";
import { FC, useEffect, useState } from "react";
import { useStyle } from "./SkuLoanApplicationSubmitted.style";
import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface SkuApiResponse {
  skuId: string;
  upc: string;
  status: string;
  description: string;
  skuReturnPercentage: string;
  skuReturnMoreThanLimitReason: string;
  purchasePricePerUnit: string;
  sellingPricePerUnit: string;
  discount: string;
  tax: string;
  paymentTerm: string;
  partialPaymentFirstPaymentPercentage: string;
  nettInvoiceAmount: string;
  otherCosts: string;
  poNumber: string;
  poDate: string;
  deliveryDate: string;
  expectedPaymentDate: string;
  quantityPurchased: string;
  loanApplicationCurrency: string;
}

type LoanSubmittedSummaryProps = {};

const SkuLoanApplicationSubmitted: FC<LoanSubmittedSummaryProps> = ({}) => {
  const { classes } = useStyle();
  const token = useSelector((state: RootState) => state.auth.token);
  const [data, setData] = useState<SkuApiResponse[]>([]);

  const getData = async () => {
    try {
      let queryUrl = `${BASE_URL}/sku-finance/loan-application/get-sku-loan-applications-pending-pm-verification`;

      await axios
        .get<SkuApiResponse[]>(queryUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const formattedSkuData: SkuApiResponse[] = response.data.map(
            (entry) => ({
              skuId: entry.skuId,
              upc: entry.upc,
              status: entry.status,
              description: entry.description,
              poNumber: entry.poNumber,
              poDate: entry.poDate,
              deliveryDate: entry.deliveryDate,
              expectedPaymentDate: entry.expectedPaymentDate,
              purchasePricePerUnit: entry.purchasePricePerUnit,
              sellingPricePerUnit: entry.sellingPricePerUnit,
              quantityPurchased: entry.quantityPurchased,
              discount: entry.discount,
              otherCosts: entry.otherCosts,
              tax: entry.tax,
              nettInvoiceAmount: entry.nettInvoiceAmount,
              paymentTerm: entry.paymentTerm,
              partialPaymentFirstPaymentPercentage:
                entry.partialPaymentFirstPaymentPercentage,
              skuReturnPercentage: entry.skuReturnPercentage,
              skuReturnMoreThanLimitReason: entry.skuReturnMoreThanLimitReason,
              loanApplicationCurrency: entry.loanApplicationCurrency,
            })
          );
          setData(formattedSkuData);
        })
        .catch(() => {
          toast.error("Unable to Fetch the data");
        });
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  const columnHeadings = [
    "SKU ID",
    "UPC",
    "Status",
    "Description",
    "PO Number",
    "PO Date",
    "Delivery Date",
    "Expected Payment Date",
    "Purchase Price Per Unit",
    "Selling Price Per Unit",
    "Quantity Ordered",
    "Discount (if any)",
    "Other Cost",
    "Tax",
    "Total Invoice",
    "Terms of Payment",
    "First payment % (if partial)",
    "Estimated SKU Return %",
    "Estimated SKU Return % Reason",
  ];

  useEffect(() => {}, []);

  return (
    <div>
      <div className={classes.list}>
        <CustomTablePM
          data={data}
          columnHeadings={columnHeadings}
          enableCheckbox={false}
        />
      </div>
    </div>
  );
};

export default SkuLoanApplicationSubmitted;
