import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  list: {
    marginLeft: "0px",
    overflow: "scroll",
    maxWidth: "100%",
    height: "calc(100vh - 360px)",
  },
}));
