import { Checkbox, MantineProvider, Text } from "@mantine/core";
import { FC, useState } from "react";
import { Button, color } from "@funded-here-interface/common";
import downloadbutton from "../../assets/download_24px.svg";
import { useStyle } from "./SetupSpv.styles";
import useInitiateSpvMutation from "../../hooks/useInitiateSpv";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDisclosure } from "@mantine/hooks";
import MembershipAgreement from "../../components/MembershipAgreement/MembershipAgreement";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";
import useGetFhDocument from "../../hooks/useFhDocument";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";

const SetupSpvPage: FC = () => {
  const { classes } = useStyle();
  const [isChecked, setIsChecked] = useState(false);
  const initiateSpvMutation = useInitiateSpvMutation();
  const { token, agreedToMembershipAgreement } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [opened, { close }] = useDisclosure(
    agreedToMembershipAgreement !== true
  );
  const useGetFhDocumentMutation = useGetFhDocument();

  const handleSubmit = () => {
    setIsLoading(true);
    initiateSpvMutation.mutate(
      { token },
      {
        onSuccess: () => {
          setIsLoading(false);
          navigate("/pending-spv");
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleDownload = () => {
    useGetFhDocumentMutation.mutate(
      { fileName: "privacy_policy.pdf" },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, "Privacy Policy", FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <OnboardingLayout
      step={0}
      content={
        <div>
          <MembershipAgreement
            onError={(value: string) => {
              toast.error(value);
            }}
            opened={opened}
            close={close}
          />
          <Text className={classes.docHead}>Setting up an SPV</Text>
          <Text className={classes.instruction}>
            So as to be able to sign the Borrower Service agreement, we will
            require to set up a Singapore registered company for you, to be the
            borrowing entity and execute the agreements. This has to be 100%
            owned by your company
          </Text>
          <Text className={classes.instruction}>
            Our partner (3rd party service provider) will assist you in setting
            this up.
          </Text>

          <div className={classes.download1}>
            <Text className={classes.doc}>PDPA T&C</Text>
            <img
              style={{ cursor: "pointer" }}
              src={downloadbutton}
              onClick={handleDownload}
              alt="down"
            />
          </div>

          <div className={classes.checkbox}>
            <MantineProvider theme={{ cursorType: "pointer" }}>
              <Checkbox
                defaultChecked={isChecked}
                label="I have read & understood the above. "
                onChange={(event) => setIsChecked(event.currentTarget.checked)}
              />
            </MantineProvider>
          </div>
          <ToastContainer />

          <div className={classes.buttonWrapper}>
            <Button
              disabled={!isChecked || isLoading}
              onClick={handleSubmit}
              textColor={color.WHITE}
              backgroundColor={color.FHGREEN}
              children="Next"
              width="100%"
            />
          </div>
        </div>
      }
    />
  );
};

export default SetupSpvPage;
