import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { SKUUpload } from "../types/sku.type";

const UploadSKU = async ({
  selectedSKUId,
  selectedPOFile,
  token,
}: SKUUpload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/loan-disbursement/bulk-verify-invoices`,
      {
        skuIds: selectedSKUId,
        file: selectedPOFile,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default UploadSKU;
