import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { CreateUserRequest } from "../types/user.type";

const createUser = async ({
  email,
  firstName,
  lastName,
  password,
  persona,
  entityType,
}: CreateUserRequest) => {
  try {
    await axios.post(
      `${BASE_URL}/trade-directory/users`,
      {
        email,
        password,
        firstName,
        lastName,
        persona,
        entityType,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default createUser;
