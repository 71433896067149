import { FC, useEffect, useRef, useState } from "react";
import { useStyles } from "./ShareholderBoardResolution.styles";
import { Button } from "@funded-here-interface/common";
import { Text } from "@mantine/core";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import useCompleteOnboarding from "../../hooks/useCompleteOnboarding";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { DocuSignEvent, DocuSignEventHandler } from "../../constants/docuSign";
import { COLORS } from "../../constants/colors";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import { useBoardResolutionSign } from "../../hooks/useDocuSign";
import { ENV } from "@funded-here-interface/common/src/constant/constant";

const DirectorBoardResolution: FC = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const skipDidVerify = location.state?.skipDidVerify;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    skipDidVerify ?? false
  );
  const useCompleteOnboardingMutation = useCompleteOnboarding();
  const { token, orgId, userEmail } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const boardResolutionMutation = useBoardResolutionSign();
  const boardResolutionRef = useRef(null);
  const [directCreateDocusign, setDirectCreateDocusign] =
    useState<boolean>(false);

  const getBoardResolutionDoc = () => {
    toast.info("Document is loading");

    boardResolutionMutation.mutate(
      {
        token,
        webDomain: WEB_DOMAIN,
        redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
        directCreateDocusign,
      },
      {
        onSuccess: async (data) => {
          setDirectCreateDocusign(false);
          // const { url, docuSignEnvelopeId } = data;
          // Handle error if docusign not create document
          if (data.docuSignEnvelopeId === "Source/Unique id not found") {
            setDirectCreateDocusign(true);
            return;
          }

          let url = "";
          for (const signerData of data.signerUrls) {
            if (signerData.email.toLowerCase() == userEmail?.toLowerCase()) {
              url = signerData.url;
              break;
            }
          }
          window.DocuSign.loadDocuSign(data.docuSignEnvelopeId)
            .then((docusign: any) => {
              const signing = docusign.signing({
                url: url,
                displayFormat: "focused",
                style: {
                  branding: {
                    primaryButton: {
                      backgroundColor: COLORS.primaryButtonBackground,
                      color: COLORS.primaryButtonText,
                    },
                  },
                  signingNavigationButton: {
                    finishText: "Finish Signing",
                    position: "bottom-center",
                  },
                },
              });
              signing.on(DocuSignEventHandler.READY, () => {
                toast.info("Sign the Document using E-signature");
              });

              signing.on(
                DocuSignEventHandler.SESSION_END,
                async (event: any) => {
                  // setDirectCreateDocusign(false);
                  if (event.sessionEndType === DocuSignEvent.SIGNING_COMPLETE) {
                    await handleNext();
                  } else {
                    toast.error("Signing incomplete, please try again.");
                  }
                }
              );

              if (boardResolutionRef.current) {
                signing.mount(boardResolutionRef.current);
              } else {
                toast.error("Agreement container not found");
              }
            })
            .catch((e: any) => {
              const message = getApiResponseErrorMsg(e);

              toast.error(message);
            });
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  function loadDocuSignScript() {
    let source = "https://js-d.docusign.com/bundle.js";
    if (ENV === "production") {
      source = "https://js.docusign.com/bundle.js";
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = source;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    loadDocuSignScript();
    if (skipDidVerify) {
      getBoardResolutionDoc();
    }
  }, []);

  useEffect(() => {
    if (directCreateDocusign) {
      getBoardResolutionDoc();
    }
  }, [directCreateDocusign]);

  const handleNext = () => {
    useCompleteOnboardingMutation.mutate(
      { token },
      {
        onSuccess: () => {
          navigate("/registration-completed");
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
      getBoardResolutionDoc();
    }
  };

  // incase verify me dont work
  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  return (
    <>
      <ToastContainer />
      {!isAuthenticated && (
        <VQRVerifyPage
          onAuthenticatedChange={handleAuthenticatedChange}
          onNext={handleOnVerifyMeNext}
        />
      )}

      {isAuthenticated && (
        <>
          <div>
            <Text className={classes.heading}>
              Please complete the online board resolution to authorize the
              designated signatory for document signing
            </Text>

            <div
              className={classes.contentPadding}
              id="boardResolution"
              ref={boardResolutionRef}
            >
              {/* DocuSign will be mounted here */}
            </div>

            {/* <div className={classes.buttonContainer}>
              <Button
                onClick={handleNext}
                textColor="white"
                backgroundColor="#338F86"
                children="Next"
              />
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default DirectorBoardResolution;
