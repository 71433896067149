import { FC, ReactNode } from "react";

import { useStyles } from "./InvestorLayout.styles";
import { ToastContainer } from "react-toastify";

import { IconBrowser, IconHome, IconSignature } from "@tabler/icons-react";
import NavBar from "../../components/NavBar/NavBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { EntityTypeEnum } from "@funded-here-interface/common/src/constant/enum";

interface IProps {
  content: ReactNode;
}

const mainMenus = [
  {
    label: "Dashboard",
    path: "/investor-dashboard/investordashboard",
    icon: <IconHome />,
  },
  {
    label: "Offer Room",
    submenu: [
      { label: "View Offer Room", path: "/note-offer-room" },
      {
        label: "Subscribed/Invested Notes",
        path: "/subscribed-invested-notes",
      },
    ],
    icon: <IconBrowser />,
  },
  {
    label: "Signed Documents",
    path: "/investor-signed-documents",
    icon: <IconSignature />,
  },
];

const institutionAuthRepMenus = [
  {
    label: "Offer Room",
    submenu: [
      { label: "View Offer Room", path: "/note-offer-room" },
      {
        label: "Subscribed/Invested Notes",
        path: "/subscribed-invested-notes",
      },
    ],
    icon: <IconBrowser />,
  },
];

const institutionAdminNonAuthRepMenus = [
  {
    label: "Dashboard",
    path: "/investor-dashboard/investordashboard",
    icon: <IconHome />,
  },
  {
    label: "Offer Room",
    path: "/subscribed-invested-notes",
    icon: <IconBrowser />,
  },
  {
    label: "Signed Documents",
    path: "/investor-signed-documents",
    icon: <IconSignature />,
  },
];

const InvestorLayout: FC<IProps> = ({ content }) => {
  const { entityType, isAdminAuthRep, isAuthRep } = useSelector(
    (state: RootState) => state.auth
  );
  const { classes } = useStyles();

  let menus = mainMenus;
  const isInstitutionalInvestor = entityType === EntityTypeEnum.INSTITUTION;

  if (isInstitutionalInvestor && isAuthRep && !isAdminAuthRep) {
    menus = institutionAuthRepMenus;
  }

  if (isInstitutionalInvestor && !isAuthRep && !isAdminAuthRep) {
    menus = institutionAdminNonAuthRepMenus;
  }

  return (
    <div className={classes.mainContainer}>
      <NavBar menus={menus} />
      <ToastContainer />
      <div className={classes.hero}>{content}</div>
    </div>
  );
};

export default InvestorLayout;
