import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  agreement: {
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "none",
    color: "#338F86",
  },
}));
