import axios from "axios";
import { UserType } from "../types/login.type";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

const downloadMembership = async ({ token }: UserType) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/trade-directory/upload/download-membership`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      }
    );
    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export default downloadMembership;
