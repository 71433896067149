import { Text } from "@mantine/core";
import { FC, useEffect, useRef, useState } from "react";
import { useStyle } from "./BMCASigning.styles";
import { Button } from "@funded-here-interface/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import useCompleteOnboarding from "../../hooks/useCompleteOnboarding";
import { DocuSignEvent, DocuSignEventHandler } from "../../constants/docuSign";
import { useMasterSkuBorrowerSign } from "../../hooks/useDocuSign";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { COLORS } from "../../constants/colors";
import { ENV } from "@funded-here-interface/common/src/constant/constant";

const BMCASigning: FC = () => {
  const location = useLocation();
  const skipDidVerify = location.state?.skipDidVerify;
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const token = useSelector((state: RootState) => state.auth.token);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    skipDidVerify ?? false
  );
  const masterSkuBorrowerMutation = useMasterSkuBorrowerSign();
  const useCompleteOnboardingMutation = useCompleteOnboarding();

  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
      handleOK();
    }
  };

  function loadDocuSignScript() {
    let source = "https://js-d.docusign.com/bundle.js";
    if (ENV === "production") {
      source = "https://js.docusign.com/bundle.js";
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = source;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    loadDocuSignScript();
  }, []);

  const handleOK = async () => {
    toast.info("Document is loading");
    try {
      masterSkuBorrowerMutation.mutate(
        {
          token,
          webDomain: WEB_DOMAIN,
          redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
        },
        {
          onSuccess: async (data) => {
            const { url, docuSignEnvelopeId } = data;

            window.DocuSign.loadDocuSign(docuSignEnvelopeId)
              .then((docusign: any) => {
                const signing = docusign.signing({
                  url: url,
                  displayFormat: "focused",
                  style: {
                    branding: {
                      primaryButton: {
                        backgroundColor: COLORS.primaryButtonBackground,
                        color: COLORS.primaryButtonText,
                      },
                    },
                    signingNavigationButton: {
                      finishText: "Finish Signing",
                      position: "bottom-center",
                    },
                  },
                });
                signing.on(DocuSignEventHandler.READY, (event: any) => {
                  toast.info("Sign the Document using E-signature");
                });
                signing.on(
                  DocuSignEventHandler.SESSION_END,
                  async (event: any) => {
                    if (
                      event.sessionEndType === DocuSignEvent.SIGNING_COMPLETE
                    ) {
                      await handleNext();
                    } else {
                      toast.error("Signing incomplete, please try again.");
                    }
                  }
                );
                if (agreementRef.current) {
                  signing.mount(agreementRef.current);
                } else {
                  toast.error("Agreement container not found");
                }
              })
              .catch((e: any) => {
                const message = getApiResponseErrorMsg(e);

                toast.error(message);
              });
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    } catch (ex) {
      toast.error("Error initializing DocuSign");
    }
  };

  const agreementRef = useRef(null);

  // TODO: remove once docu sign is setup but please make sure to make this endpoint call to complete onboarding
  const handleNext = async () => {
    useCompleteOnboardingMutation.mutate(
      { token },
      {
        onSuccess: () => {
          Navigate("/merchant-complete-onboarding");
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  return (
    <>
      {!isAuthenticated && (
        <VQRVerifyPage
          onAuthenticatedChange={handleAuthenticatedChange}
          onNext={handleOnVerifyMeNext}
        />
      )}
      {isAuthenticated && (
        <div className={classes.tempWideDisplay}>
          <Text className={classes.headerText}>
            Borrower Master Credit Agreement
          </Text>
          <Text>
            This Borrower Service Agreement outlines the terms and conditions
            governing our lending services. Please download, review, and sign to
            signify your acceptance.
          </Text>
          <div className={classes.agreeGroup2}>
            <label className={classes.disclaimerAccept2} htmlFor="agree">
              Borrower Service Agreement
            </label>
            <Text onClick={handleOK} className={classes.linkText}>
              (Sign with DocuSign)
            </Text>
          </div>
          <div ref={agreementRef} className={classes.document} id="agreement">
            {/* DocuSign will be mounted here */}
          </div>
          {/* <div className={classes.buttonDiv}>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleNext}
                textColor="#ffff"
                backgroundColor="#338F86"
                children="Next"
                width="100%"
              />
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default BMCASigning;
