import {
  TextInput,
  createStyles,
  Text,
  Group,
  MantineTheme,
} from "@mantine/core";
import { CSS } from "@mantine/styles/lib/tss/types";
import { ChangeEvent, FC } from "react";

type Props = {
  labelText: string;
  id?: string;
  placeHolder?: string;
  onChange?: CallableFunction;
  style?: CSS;
  isErrored?: string;
  value: string;
  type?: string;
};

const InputGroup: FC<Props> = ({
  id,
  labelText,
  placeHolder,
  type = "text",
  value,
  isErrored,
  onChange,
}) => {
  const useStyles = createStyles((theme: MantineTheme) => ({
    mainContainer: {},
    text: {
      paddingBottom: "0px",
      paddingLeft: "0px",
      color: "inherit",
      //fontFamily:'SF Pro Text',
      textAlign: "left",
      overflow: "visible",
    },
    inputField: {
      paddingTop: "2px",
      height: "45px",
      width: "100%",
      marginBottom: "10px",
    },
    errorMessage: {
      color: "red",
      position: "relative",
      bottom: "10px",
      fontSize: "14px",
    },
  }));
  const { classes } = useStyles();

  const OnChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onChange ? onChange(e.target.value) : "";
  };

  return (
    <div className={classes.mainContainer}>
      <Group position="left" mb={5}>
        <Text className={classes.text} component="label" htmlFor={id} size="sm">
          {labelText}
        </Text>
      </Group>
      <TextInput
        id={id}
        type={type}
        placeholder={placeHolder}
        className={classes.inputField}
        value={value}
        size="md"
        onChange={OnChangeInput}
      />
      {isErrored && <div className={classes.errorMessage}>{isErrored}</div>}
    </div>
  );
};

export default InputGroup;
