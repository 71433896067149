import { EntityTypeEnum } from "@funded-here-interface/common/src/constant/enum";
import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  token: string;
  userId: string;
  spvStatus: string | null;
  type: string | null;
  userEmail: string | null;
  role: string | null;
  orgId: number | null;
  orgRoleId: number | null;
  userFirstName: string | null;
  userLastName: string | null;
  agreedToMembershipAgreement: boolean | null;
  isAuthRep: boolean | null;
  hasCreditLimit: boolean | null;
  entityType: EntityTypeEnum | null;
  isAdminAuthRep: boolean | null;
  spvName: string | null;
}

const initialState: AuthState = {
  token: "none",
  userId: "",
  spvStatus: null,
  type: null,
  userEmail: null,
  role: null,
  orgId: null,
  orgRoleId: null,
  userFirstName: null,
  userLastName: null,
  agreedToMembershipAgreement: null,
  isAuthRep: null,
  hasCreditLimit: null,
  entityType: null,
  isAdminAuthRep: null,
  spvName: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      const { token } = action.payload;
      state.token = token;
    },
    setUserID: (state, action) => {
      const { userId } = action.payload;
      state.userId = userId;
    },
    setUserSpv: (state, action) => {
      const { spvStatus } = action.payload;
      state.spvStatus = spvStatus;
    },
    setOnboardingType: (state, action) => {
      const { type } = action.payload;
      state.type = type;
    },
    setUserEmail: (state, action) => {
      const { userEmail } = action.payload;
      state.userEmail = userEmail;
    },
    setRole: (state, action) => {
      const { role } = action.payload;
      state.role = role;
    },
    setOrgId: (state, action) => {
      const { orgId } = action.payload;
      state.orgId = orgId;
    },
    setOrgRoleId: (state, action) => {
      const { currentOrgRoleId } = action.payload;
      state.orgRoleId = currentOrgRoleId;
    },
    setUserFirstName: (state, action) => {
      const { userFirstName } = action.payload;
      state.userFirstName = userFirstName;
    },
    setUserLastName: (state, action) => {
      const { userLastName } = action.payload;
      state.userLastName = userLastName;
    },
    setAgreedToMembershipAgreement: (state, action) => {
      const { agreedToMembershipAgreement } = action.payload;
      state.agreedToMembershipAgreement = agreedToMembershipAgreement;
    },
    setIsAuthRep: (state, action) => {
      const { isAuthRep } = action.payload;
      state.isAuthRep = isAuthRep;
    },
    setHasCreditLimit: (state, action) => {
      const { hasCreditLimit } = action.payload;
      state.hasCreditLimit = hasCreditLimit;
    },
    setEntityType: (state, action) => {
      const { entityType } = action.payload;
      state.entityType = entityType;
    },
    setIsAdminAuthRep: (state, action) => {
      const { isAdminAuthRep } = action.payload;
      state.isAdminAuthRep = isAdminAuthRep;
    },
    setSpvName: (state, action) => {
      const { spvName } = action.payload;
      state.spvName = spvName;
    },
    resetAuthState: () => initialState,
  },
});

export const {
  setToken,
  setUserID,
  setUserSpv,
  setOnboardingType,
  setUserEmail,
  setRole,
  setOrgId,
  setOrgRoleId,
  setUserFirstName,
  setUserLastName,
  setAgreedToMembershipAgreement,
  setIsAuthRep,
  setHasCreditLimit,
  setEntityType,
  setIsAdminAuthRep,
  setSpvName,
  resetAuthState,
} = authSlice.actions;
export default authSlice.reducer;
