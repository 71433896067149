import { FC } from "react";
import { Notification } from "@mantine/core";

const BankAccNameDisclaimer: FC = () => {
  return (
    <Notification
      withCloseButton={false}
      color="teal"
      style={{ marginBottom: "20px", marginTop: "20px" }}
      title="Account Holder Name"
    >
      Kindly verify that your bank account name corresponds to the name
      registered on our platform.
    </Notification>
  );
};

export default BankAccNameDisclaimer;
