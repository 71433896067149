import { FC, useEffect, useState } from "react";
import HeaderInvestorDashboard from "@funded-here-interface/common/src/dashboard/components/HeaderInvestorDashboard";
import { useParams } from "react-router-dom";
import InvestorDashboard from "./tabs/InvestorDashboard";
import InvestAnalysis from "./tabs/InvestAnalysis";
import SnSummaryView from "./tabs/SnSummaryView";
import { sendKafkaGetUser } from "@funded-here-interface/common/src/services/dashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ToastContainer, toast } from "react-toastify";

const InvestorDashboardScreen: FC = () => {
  let { subpath } = useParams();
  const { token } = useSelector((state: RootState) => state.auth);
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    sendKafkaGetUser(token as unknown as string)
      .then((result) => {
        if (result === 404 && !toastShown) {
          toast.error("User not in Dashboard");
          setToastShown(true);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [token]);

  return (
    <div className="mainContainer">
      <ToastContainer />
      <HeaderInvestorDashboard />
      {subpath === "investanalysis" ? (
        <InvestAnalysis />
      ) : subpath === "snsummaryview" ? (
        <SnSummaryView />
      ) : subpath === "investordashboard" ? (
        <InvestorDashboard />
      ) : null}
    </div>
  );
};

export default InvestorDashboardScreen;
