import React from "react";
import DataTableownInvest from "../components/DataTableownInvest";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DataTableownCloseInvest from "../components/DataTableownCloseInvest";

const InvestAnalysis = () => {
  return (
    <>
      <div className="RestPage">
        <div className="container-fluid">
          <div className="row investRow2">
            <div className="col">
              <div className="eStateTableDiv">
                <Tabs>
                  <TabList>
                    <Tab>
                      Active Investment
                      <span class="ReturnAnTooltip">
                        Click here to follow link
                      </span>
                    </Tab>
                    <Tab>
                      Closed Investment
                      <span class="ReturnAnTooltip">
                        Click here to follow link
                      </span>
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div>
                      <p className="eStateHead">Active Investment</p>
                      <div className="dataTableDiv">
                        <DataTableownInvest />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      <p className="eStateHead">Closed Investment</p>
                      <div className="dataTableDiv">
                        <DataTableownCloseInvest />
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestAnalysis;
