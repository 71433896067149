// eslint-disable-next-line import/no-anonymous-default-export
export const color = {
  WHITE: "#FFFFFF",
  FHGREEN: "#338F86",
  TEXTGREY: "#4F566B",
  FHADMIN: "#3786D6",
  ADMINHEADING: "#595959",
  DISABLED: "#cccccc",
  REJECT: "#FF0000",
  LOANTEXT: "#31332C",
  GREYBG: "#D9DCE1",
  BGGREY: "#D9DCE1",
  primaryButtonBackground: "#333",
};
