import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface CheckDetail {
  isChecked: boolean;
  value: boolean | null;
}
interface InvestorDeclarationRequest {
  token: string;
  isQualified: boolean;
  checkList?: Record<string, CheckDetail>;
}

const investorDeclaration = async ({
  token,
  isQualified,
  checkList,
}: InvestorDeclarationRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/users/investor-declaration`,
      { isQualified, checkList },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default investorDeclaration;
