import { createSlice } from "@reduxjs/toolkit";

const bizSlice = createSlice({
  name: "prof",
  initialState: {
    phoneNumber: null,
    industry: null,
    industryOthers: null,
    occupation: null,
    exp: null,
    nationality: null,
    countryOfResidence: null,
    address: null,
    postalCode: null,
    investmentAmount: null,
    investorDeclarationQualified: null,
  },
  reducers: {
    setIndustryRedux: (state, { payload: { industry } }) => {
      state.industry = industry;
    },
    setIndustryOthersRedux: (state, { payload: { industryOthers } }) => {
      state.industryOthers = industryOthers;
    },
    setOccupationeRedux: (state, { payload: { occupation } }) => {
      state.occupation = occupation;
    },
    setEXPRedux: (state, { payload: { exp } }) => {
      state.exp = exp;
    },
    setPhoneRedux: (state, { payload: { phoneNumber } }) => {
      state.phoneNumber = phoneNumber;
    },
    setNationalityRedux: (state, { payload: { nationality } }) => {
      state.nationality = nationality;
    },
    setCountryOfResidenceRedux: (
      state,
      { payload: { countryOfResidence } }
    ) => {
      state.countryOfResidence = countryOfResidence;
    },
    setAddressRedux: (state, { payload: { address } }) => {
      state.address = address;
    },
    setPostalCodeRedux: (state, { payload: { postalCode } }) => {
      state.postalCode = postalCode;
    },
    setInvestmentAmountRedux: (state, { payload: { investmentAmount } }) => {
      state.investmentAmount = investmentAmount;
    },
    setInvestorDeclarationQualifiedRedux: (
      state,
      { payload: { investorDeclarationQualified } }
    ) => {
      state.investorDeclarationQualified = investorDeclarationQualified;
    },
  },
});

export const {
  setIndustryRedux,
  setOccupationeRedux,
  setEXPRedux,
  setPhoneRedux,
  setNationalityRedux,
  setCountryOfResidenceRedux,
  setAddressRedux,
  setPostalCodeRedux,
  setIndustryOthersRedux,
  setInvestmentAmountRedux,
  setInvestorDeclarationQualifiedRedux,
} = bizSlice.actions;

export default bizSlice.reducer;
