import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  container: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  leftContainer: {
    marginLeft: "5em",
  },

  iconContainer: {
    position: "absolute",
    width: 151,
    height: 50.08040237426758,
    top: 58,
    left: 85,
  },

  logo: {
    width: 151,
  },

  h1: {
    textAlign: "center",
    fontSize: 24,
    marginBottom: 20,
  },

  h2: {
    fontSize: 18,
    marginBottom: 10,
  },

  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "13px, 87px, 13px, 87px",
    gap: 40,
    left: "5em",
    top: 208,
    boxShadow:
      "0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)",
    borderRadius: 4,
  },

  pageContainer2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "13px, 87px, 13px, 87px",
    gap: 40,
    left: "5em",
    top: 208,
  },

  backImage: {
    width: 665,
    height: "100%",
    left: 780,
  },
}));
