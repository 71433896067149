export const stepsJson = [
  {
    steps_id: 1,
    text: "Business Profile",
  },
  {
    steps_id: 2,
    text: "Bank Details",
  },
];
