import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
  },
  buttonWrapper: {
    marginTop: "20px",
    paddingRight: "10px",
  },
  inputBox: {
    width: "406px",
  },
  container: {
    marginTop: "70px",
    width: "500px",
  },
}));
