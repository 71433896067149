import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  loginContainer: {
    width: "542px",
    height: "450px",
    gap: "5px",
    padding: "35px 5px",
    boxShadow:
      "0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)",
    borderRadius: 4,
  },
  loginHeading: {
    color: "#151515",
    padding: "10px 80px 0px 75px",
    fontSize: "24px",
    textAlign: "left",
    fontWeight: 700,
    lineHeight: "29px",
  },
  inputGroupContainer: {
    marginTop: "20px",
    height: "221px",
    padding: "10px 80px 0px 75px",
    display: "flex",
    flexDirection: "column",
  },

  checkboxContainer: {
    marginTop: "5px",
    width: "247px",
    height: "15px",
    gap: "6px",
  },
  buttonDiv: {
    marginTop: "20px",
    paddingTop: "5px",
  },
  input: {
    padding: 10,
    marginBottom: 10,
    backgroundColor: "#338F86",
    color: "white",
    border: "none",
    borderRadius: 4,
    cursor: "pointer",
    width: "90%",
    height: 45,
    marginTop: 10,
  },
  tooltipContainer: {
    position: "relative",
    display: "inline-block",
  },
  inputbox: {
    width: "100%",
    marginBottom: "10px",
  },
  tooltipText: {
    visibility: "hidden",
    width: "220px",
    backgroundColor: "#D9DCE1",
    color: "#000",
    textAlign: "center",
    borderRadius: "6px",
    padding: "5px",
    position: "absolute",
    zIndex: 1,
    bottom: "-60%",
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: "5px",

    "&::before": {
      content: '""',
      position: "absolute",
      top: "-10px",
      left: "50%",
      marginLeft: "-5px",
      width: "0",
      height: "0",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #D9DCE1",
    },
  },

  tooltipContainerHover: {
    "&:hover $tooltipContainer": {
      visibility: "visible",
    },
  },
  checkbox: {
    marginTop: "20px",
  },
}));
