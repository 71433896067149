import { Text, Group } from "@mantine/core";
import { CSS } from "@mantine/styles/lib/tss/types";
import { FC } from "react";
import { isValidNumber } from "../../regex/InputRegex";
import { useStyles } from "./NumberInput.styles";

type Props = {
  labelText: string;
  id?: string;
  placeHolder?: string;
  onChange?: CallableFunction;
  style?: CSS;
  isErrored?: string;
  value: number | null;
};

const NumberInputGroup: FC<Props> = ({
  labelText,
  id,
  placeHolder,
  onChange,
  isErrored,
  value,
}) => {
  const { classes } = useStyles();

  const OnChange = (event: any) => {
    const newValue = event.target.value;

    if (isValidNumber.test(newValue)) {
      // Convert input value to number and pass it to onChange
      const parsedValue = parseFloat(newValue);
      onChange?.(parsedValue);
    }
    // onChange ? onChange(newValue) : 0;
  };
  return (
    <div className={classes.mainContainer}>
      <Group position="left" mb={5}>
        <Text className={classes.text} component="label" htmlFor={id} size="sm">
          {labelText}
        </Text>
      </Group>
      <input
        type="number"
        id={id}
        placeholder={placeHolder}
        className={classes.inputField}
        value={value === null ? undefined : value}
        onChange={OnChange}
      />
      {isErrored && <div className={classes.errorMessage}>{isErrored}</div>}
    </div>
  );
};

export default NumberInputGroup;
