import axios from "axios";
import { VerfiyotpType } from "../types/login.type";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

const verifyOTP = async ({ otp }: VerfiyotpType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/users/verify-otp`,
      {
        otp,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default verifyOTP;
