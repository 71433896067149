import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface SubscriptionArgeementPostData {
  structuredNoteId: number;
  token: string;
  investorSubscribedAmount: string;
}

const postSubscriptionAgreementSigned = async ({
  token,
  structuredNoteId,
  investorSubscribedAmount,
}: SubscriptionArgeementPostData) => {
  try {
    const formattedInvestorSubscribedAmount = parseFloat(
      investorSubscribedAmount
    );

    const response = await axios.post(
      `${BASE_URL}/sku-finance/sn/subscription-agreement-signed`,
      {
        structuredNoteId,
        investorSubscribedAmount: formattedInvestorSubscribedAmount,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default postSubscriptionAgreementSigned;
