import { FC } from "react";
import { useStyle } from "./FundingIssuingSpvCompleteOnboard.styles";
import { AcknowledgementLayout } from "@funded-here-interface/common";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDisclosure } from "@mantine/hooks";
import MembershipAgreement from "../../components/MembershipAgreement/MembershipAgreement";
import { ToastContainer, toast } from "react-toastify";

const FundingIssuingSpvCompleteOnboard: FC = () => {
  const { classes } = useStyle();
  const { agreedToMembershipAgreement } = useSelector(
    (state: RootState) => state.auth
  );
  const [opened, { close }] = useDisclosure(
    agreedToMembershipAgreement !== true
  );

  return (
    <AcknowledgementLayout
      showLogo={false}
      content={
        <div className={classes.container}>
          <ToastContainer />
          <MembershipAgreement
            toCompleteOnboard={true}
            onError={(value: string) => {
              toast.error(value);
            }}
            opened={opened}
            close={close}
          />
          <h2 className={classes.header}>Onboarding is Completed!</h2>
          <p className={classes.text}>Thank you for your patience!</p>
        </div>
      }
    />
  );
};

export default FundingIssuingSpvCompleteOnboard;
