import React, { useContext, useEffect, useState } from "react";
import { useTable } from "react-table";
import { useSelector, useDispatch } from "react-redux";
import { UserContext } from "../../../../Context/Context";
import AuthServices from "../../../../services/AuthServices";

const DefaultPaymentTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="DataTableInn">
      <table {...getTableProps()} style={{ borderSpacing: "0", width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  <i class="fa-solid fa-caret-up"></i>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ padding: "8px" }}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Myapp = () => {
  const [defaultPayment, setDefaultPayment] = useState([]);
  // const dispatch = useDispatch();
  // const { closePaymentDetails } = useSelector((state) => state.paymentReducer);
  // const { OrgRoleId } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AuthServices.defaultPayment();
        setDefaultPayment(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { Header: "SKU ID", accessor: "sku_id" },
    { Header: "SKU Name", accessor: "sku_name" },
    { Header: "Sector", accessor: "sector" },
    { Header: "Country", accessor: "country" },
    { Header: "Tenure", accessor: "tenure" },
    { Header: "Days Exceeding Maturity", accessor: "days_exceed" },
    { Header: "Projected Sales", accessor: "projected_sales" },
    { Header: "Expected RBP", accessor: "rbp" },
    { Header: "Loan Amount", accessor: "rbu" },
    { Header: "Repaid Amount", accessor: "repaid_amount" },
    { Header: "Default Balance Amount (DLO)", accessor: "default_bal" },
    { Header: "Borrower Topup Amount", accessor: "borrower_topup" },
    { Header: "Add Admin Fee", accessor: "admin_fee" },
    { Header: "Additional Interest", accessor: "additional_interest" },
    { Header: "QC", accessor: "qc" },
    { Header: "SF", accessor: "sf" },
    { Header: "ASF", accessor: "asf" },
    { Header: "Balance Quantity (in units)", accessor: "balance_quantity" },
    { Header: "Selling Price", accessor: "selling_price" },
    { Header: "Force Sell Amount ($)", accessor: "force_sell" },
  ];

  const data2 =
    defaultPayment
      ?.map((item) => ({
        sku_id: item?.skuId,
        sku_name: item?.skuName,
        country: item?.country,
        sector: item?.sector,
        tenure: item?.tenure,
        days_exceed: item?.daysPastMaturityDate, // Update this accessor
        projected_sales: item?.projectedSales,
        rbp: item?.expectedRBP, // Update this accessor
        rbu: item?.loanAmount, // Update this accessor
        repaid_amount: item?.repaidAmount, // Update this accessor
        default_bal: item?.defaultBalanceAmount, // Update this accessor
        borrower_topup: item?.borrowerTopupAmount, // Update this accessor
        admin_fee: item?.addAdminFee, // Update this accessor
        additional_interest: item?.additionalInterest, // Update this accessor
        qc: item?.moe, // Update this accessor
        sf: item?.sf,
        asf: item?.asf,
        balance_quantity: item?.balanceQuantity, // Update this accessor
        selling_price: item?.sellingPrice, // Update this accessor
        force_sell: item?.forceSellAmount, // Update this accessor
      }))
      .sort((a, b) => {
        // Extract numeric part from SKU ID
        const skuIdA = parseInt(a.sku_id.split("-")[1]);
        const skuIdB = parseInt(b.sku_id.split("-")[1]);
        // Compare the numeric part
        return skuIdA - skuIdB;
      }) ?? [];

  return <DefaultPaymentTable columns={columns} data={data2} />;
};

export default Myapp;
