import { Text } from "@mantine/core";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useShallowEffect } from "@mantine/hooks";
import { useStyles } from "./PasswordReset.styles";

type Props = {};

const PasswordResetMsg: FC<Props> = (props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  useShallowEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 2000);
  });

  return (
    <div>
      <div className={classes.loginContainer}>
        <div style={{ textAlign: "left" }}>
          <Text className={classes.loginHeading}>Reset your password</Text>
          <Text className={classes.subHeading}>
            We have sent an email to your mail with a code to reset your
            password.
          </Text>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetMsg;
