import { useState, CSSProperties } from "react";
import { useStyles } from "./List.styles";
import { Button, color } from "@funded-here-interface/common";
import {
  formatDate,
  formatNumbersWithThousandSeperators,
  formatPercentageFromRatio,
} from "../../Utils/formatter";

const CustomTablePM = ({
  data,
  columnHeadings,
  enableCheckbox = true,
  onDisburseButtonClicked,
}: any) => {
  const { classes } = useStyles();
  const [selectedRows, setSelectedRows] = useState(new Set<number>());
  const [sortDirection, setSortDirection] = useState<string | null>(null);
  const [sortedData, setSortedData] = useState([...data]);
  const handleSort = (columnName: string) => {
    let newSortDirection = "asc";
    if (sortDirection === "asc") {
      newSortDirection = "desc";
    }

    const sorted = sortedData.slice().sort((a, b) => {
      if (newSortDirection === "asc") {
        return a[columnName].localeCompare(b[columnName]);
      } else {
        return b[columnName].localeCompare(a[columnName]);
      }
    });

    setSortDirection(newSortDirection);
    setSortedData(sorted);
  };

  function isStringNumberZero(str: string) {
    // Parse the string into a number
    const num = parseFloat(str);

    // Check if the number is exactly 0
    return num === 0;
  }

  const handleCheckboxChange = (index: number) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(index)) {
      newSelectedRows.delete(index);
    } else {
      newSelectedRows.add(index);
    }
    setSelectedRows(newSelectedRows);
  };
  const getStatusStyle = (status: string): CSSProperties => {
    switch (status) {
      case "Pending":
        return {
          padding: "1px 2px",
          borderRadius: "16.919px",
          background: "#F6BD21",
          textAlign: "center",
          color: "white",
        };
      case "Approved":
        return {
          padding: "1px 2px",
          borderRadius: "16.919px",
          background: "#0ACD93",
          textAlign: "center",
          color: "white",
        };
      case "Rejected":
        return {
          padding: "1px 2px",
          borderRadius: "16.919px",
          background: "#DC3545",
          textAlign: "center",
          color: "white",
        };
      default:
        return {};
    }
  };

  const handleSelectAll = () => {
    const allRowsSelected = selectedRows.size === sortedData.length;
    if (allRowsSelected) {
      setSelectedRows(new Set<number>());
    } else {
      const allRowIndices = Array.from(
        { length: sortedData.length },
        (_, i) => i
      );
      setSelectedRows(new Set(allRowIndices));
    }
  };

  const displayValue = (row: any, key: string): any => {
    if (row[key] === undefined || row[key] === null || row[key] === "") {
      return "-";
    }

    let valueToDisplay: string = "-";

    switch (key) {
      case "invoiceValue":
      case "availableCreditLine":
      case "loanAmount2":
      case "qc":
      case "sinkingFund":
      case "additionalSinkingFund":
      case "sellingPricePerUnit":
      case "purchasePricePerUnit":
      case "discount":
      case "otherCosts":
      case "nettInvoiceAmount":
      case "tax":
      case "distributorPartialFundFirstAmountDispersed":
      case "distributorPartialFundSecondAmountDispersed":
      case "preFinancingFees":
      case "topUpAmount":
      case "distributorFundAmountDispersed":
        valueToDisplay =
          row["loanApplicationCurrency"] +
          " " +
          formatNumbersWithThousandSeperators(row[key]);
        break;
      case "skuReturn":
      case "skuReturnPercentage":
        valueToDisplay = row[key] + "%";
        break;
      case "distributorInvoiceVerificationStatus":
        valueToDisplay = row[key].toUpperCase();
        break;
      case "grossMargin":
      case "loanToValuePercentage":
      case "interestRate":
      case "partialPaymentFirstPaymentPercentage":
        valueToDisplay = formatPercentageFromRatio(row[key]) + "%";
        break;
      case "productSalesVolume":
      case "skuSalesVolume":
      case "quantityPurchased":
      case "shortfall":
      case "wmsQuantityDelivered":
      case "quantityOrderedPo":
        valueToDisplay = formatNumbersWithThousandSeperators(row[key]);
        break;
      case "poDate":
      case "deliveryDate":
      case "expectedPaymentDate":
      case "distributorInvoiceUploadedDate":
      case "distributorFundDispersedDate":
        valueToDisplay = formatDate(row[key]);
        break;
      case "pendingDistributorFundsDispersal":
        try {
          let htmlToDispay = null;

          switch (row["paymentTerm"]) {
            case "cash-on-delivery":
            case "payment-on-purchase-order":
              let enableDisbursementButton: boolean = false;
              let disbursementButtonText: string = "Pending";

              if (
                row["pendingDistributorFundsDispersal"] === false &&
                !isStringNumberZero(row["distributorFundAmountDispersed"])
              ) {
                disbursementButtonText = "Disbursed";
              }

              if (row["pendingDistributorFundsDispersal"] === true) {
                enableDisbursementButton = true;
                disbursementButtonText = "Disburse";
              }

              htmlToDispay = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={() => {
                      onDisburseButtonClicked(row["skuId"]);
                    }}
                    backgroundColor={color.FHGREEN}
                    textColor={color.WHITE}
                    children={disbursementButtonText}
                    width="50%"
                    disabled={!enableDisbursementButton}
                  />
                </div>
              );
              break;
            case "partial-payment":
              let enableFirstDisbursementButton: boolean = false;
              let enableSecondDisbursementButton: boolean = false;
              let firstDisbursementButtonText: string = "Pending";
              let secondDisbursementButtonText: string = "Pending";

              if (row["pendingDistributorFundsDispersal"] === true) {
                if (
                  isStringNumberZero(
                    row["distributorPartialFundFirstAmountDispersed"]
                  ) &&
                  isStringNumberZero(
                    row["distributorPartialFundSecondAmountDispersed"]
                  )
                ) {
                  enableFirstDisbursementButton = true;
                  firstDisbursementButtonText = "Disburse 1st";
                }
              } else {
                if (
                  !isStringNumberZero(
                    row["distributorPartialFundFirstAmountDispersed"]
                  ) &&
                  !isStringNumberZero(
                    row["distributorPartialFundSecondAmountDispersed"]
                  )
                ) {
                  secondDisbursementButtonText = "Disbursed 2nd";
                }
              }

              if (
                !isStringNumberZero(
                  row["distributorPartialFundFirstAmountDispersed"]
                )
              ) {
                firstDisbursementButtonText = "Disbursed 1st";

                if (
                  row["pendingDistributorFundsDispersal"] === true &&
                  isStringNumberZero(
                    row["distributorPartialFundSecondAmountDispersed"]
                  )
                ) {
                  secondDisbursementButtonText = "Disburse 2nd";
                  enableSecondDisbursementButton = true;
                }
              }

              htmlToDispay = (
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Button
                    onClick={() => {
                      onDisburseButtonClicked(row["skuId"]);
                    }}
                    backgroundColor={color.FHGREEN}
                    textColor={color.WHITE}
                    children={firstDisbursementButtonText}
                    width="45%"
                    disabled={!enableFirstDisbursementButton}
                  />
                  <Button
                    onClick={() => {
                      onDisburseButtonClicked(row["skuId"]);
                    }}
                    backgroundColor={color.FHGREEN}
                    textColor={color.WHITE}
                    children={secondDisbursementButtonText}
                    width="45%"
                    disabled={!enableSecondDisbursementButton}
                  />
                </div>
              );
              break;
          }

          return htmlToDispay;
        } catch (error) {
          return "-";
        }

      case "grnNo":
        return (
          <ol>
            {" "}
            {Object.keys(row[key]).map((grn: string, index: number) => (
              <li key={index}>{grn}</li>
            ))}
          </ol>
        );
      case "grnSubmittedDeliveredQuantity":
        return (
          <ol>
            {" "}
            {Object.keys(row[key]).map((grn: string, index: number) => (
              <li key={index}>
                {formatNumbersWithThousandSeperators(
                  row[key][grn]["quantityDelivered"]
                )}
              </li>
            ))}
          </ol>
        );
      case "grnSubmittedDate":
        return (
          <ol>
            {" "}
            {Object.keys(row[key]).map((grn: string, index: number) => (
              <li key={index}>
                {formatDate(row[key][grn]["grnUploadedDate"])}
              </li>
            ))}
          </ol>
        );
      default:
        valueToDisplay = row[key];
        break;
    }

    return valueToDisplay;
  };

  return (
    <table className={classes.csvTable}>
      <thead>
        <tr className={classes.tr}>
          {enableCheckbox === true && (
            <th className={classes.th}>
              <input type="checkbox" onChange={handleSelectAll} />
            </th>
          )}
          {columnHeadings.map((heading: string, index: number) => (
            <th
              key={index}
              className={classes.th}
              onClick={() => handleSort(heading)}
            >
              <div className={classes.sortableHeader}>
                <span className={classes.header}>{heading}</span>
                <div className={classes.icons}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 9 9"
                    fill="none"
                  >
                    <path
                      d="M6.81171 6.2168H1.60281C1.44657 6.2168 1.35933 6.05184 1.45609 5.93922L4.06054 2.9192C4.13509 2.83276 4.27863 2.83276 4.35398 2.9192L6.95843 5.93922C7.05518 6.05184 6.96794 6.2168 6.81171 6.2168Z"
                      fill="#343A40"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 9 9"
                    fill="none"
                  >
                    <path
                      d="M4.20726 5.57069L1.95535 2.95947H6.45916L4.20726 5.57069Z"
                      fill="#EAEAEA"
                      stroke="#EAEAEA"
                      stroke-width="0.676755"
                    />
                  </svg>
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row: any, index: number) => (
          <tr className={classes.tr} key={index}>
            {enableCheckbox === true && (
              <td className={classes.td}>
                <input
                  type="checkbox"
                  checked={selectedRows.has(index)}
                  onChange={() => handleCheckboxChange(index)}
                />
              </td>
            )}
            {Object.keys(row).map(
              (key: string, index: number) =>
                key !== "loanApplicationCurrency" && (
                  <td className={classes.td} key={index}>
                    {displayValue(row, key)}
                  </td>
                )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTablePM;
