import { InputGroup, NAME_REGEX } from "@funded-here-interface/common";
import { Checkbox, MantineProvider } from "@mantine/core";
import { FC } from "react";

interface IProps {
  authChecked: boolean;
  setAuthChecked: (value: boolean) => void;
  error: {
    userFirstName: string;
    userLastName: string;
    merchantTitle: string;
  };
  setMerchantFirstName: (value: string) => void;
  merchantFirstName: string;
  setMerchantLastName: (value: string) => void;
  merchantLastName: string;
  setMerchantTitle: (value: string) => void;
  merchantTitle: string;
}

const User: FC<IProps> = (props) => {
  const {
    authChecked,
    setAuthChecked,
    error,
    setMerchantFirstName,
    merchantFirstName,
    setMerchantLastName,
    merchantLastName,
    setMerchantTitle,
    merchantTitle,
  } = props;
  return (
    <>
      <MantineProvider theme={{ cursorType: "pointer" }}>
        <Checkbox
          style={{ marginBottom: "20px" }}
          defaultChecked={authChecked}
          labelPosition="left"
          label={
            <span style={{ fontWeight: "bold" }}>
              Will you be acting as an Authorized Representative?
            </span>
          }
          onChange={(event) => setAuthChecked(event.currentTarget.checked)}
        />
      </MantineProvider>
      <InputGroup
        isErrored={error.userFirstName}
        onChange={(value: string) => {
          error.userFirstName = "";
          setMerchantFirstName(value.replace(NAME_REGEX, ""));
        }}
        labelText="Your first / given name (as in passport)"
        id="firstName"
        value={merchantFirstName}
      />
      <InputGroup
        isErrored={error.userLastName}
        onChange={(value: string) => {
          error.userLastName = "";
          setMerchantLastName(value.replace(NAME_REGEX, ""));
        }}
        labelText="Your last name (as in passport)"
        id="lastName"
        value={merchantLastName}
      />
      <InputGroup
        isErrored={error.merchantTitle}
        onChange={(value: string) => {
          error.merchantTitle = "";
          setMerchantTitle(value.replace(NAME_REGEX, ""));
        }}
        labelText="Your role in the company"
        id="merchantTitle"
        value={merchantTitle}
      />
    </>
  );
};

export default User;
