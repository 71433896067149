import { createSlice } from "@reduxjs/toolkit";

const disBankSlice = createSlice({
  name: "disBank",
  initialState: {
    bankName: null,
    accountNumber: null,
    accountType: null,
    accountHolderName: null,
    swiftCode: null,
  },
  reducers: {
    setBankNameRedux: (state, { payload: { bankName } }) => {
      state.bankName = bankName;
    },
    setBankNoRedux: (state, { payload: { accountNumber } }) => {
      state.accountNumber = accountNumber;
    },
    setAccountTypeRedux: (state, { payload: { accountType } }) => {
      state.accountType = accountType;
    },
    setAccountHolderNameRedux: (state, { payload: { accountHolderName } }) => {
      state.accountHolderName = accountHolderName;
    },
    setSwiftCodeRedux: (state, { payload: { swiftCode } }) => {
      state.swiftCode = swiftCode;
    },
  },
});

export const {
  setBankNameRedux,
  setBankNoRedux,
  setAccountHolderNameRedux,
  setSwiftCodeRedux,
  setAccountTypeRedux,
} = disBankSlice.actions;

export default disBankSlice.reducer;
