import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  container: {
    marginTop: "70px",
    width: "500px",
  },
  inputBox: {
    width: "406px",
  },
  buttonWrapper: {
    paddingTop: "10px",
  },
  buttonDivv: {
    marginTop: "60px",
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
    gap: "30px",
    marginBottom: 20,
  },
}));
