import { FC, useEffect, useState } from "react";
import { useStyles } from "./InstitutionalRep.styles";
import AuthRepInfo from "../../components/AuthRepInfo/AuthRepInfo";
import { Checkbox, MantineProvider } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Button, InputGroup, NAME_REGEX } from "@funded-here-interface/common";
import Line from "../../assets/LineHorizontal.png";
import ShareholdersDirectors from "../../components/ShareholdersDirectors/ShareholdersDirectors";
import Auth from "../../components/Auth/Auth";
import {
  CompanyRole,
  ShareholderDirectorField,
} from "@funded-here-interface/common/src/constant/enum";
import {
  ShareholderDirector,
  setRepEmailRedux,
  setRepFirstNameRedux,
  setRepLastNameRedux,
  setRepTitleRedux,
  setShareholderDirectorRedux,
  setUserTitleRedux,
} from "../../features/institutionalInvestor/repSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const InstitutionalRep: FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userFirstName, userLastName, userEmail } = useSelector(
    (state: RootState) => state.auth
  );
  const {
    repFirstName: repFirstNameRedux,
    repLastName: repLastNameRedux,
    repTitle: repTitleRedux,
    userTitle: userTitleRedux,
    shareholdersDirectors: shareholdersDirectorsRedux,
    repEmail: repEmailRedux,
  } = useSelector((state: RootState) => state.rep);

  const wasAuthCheckboxChecked =
    repEmailRedux !== null &&
    repFirstNameRedux === userFirstName &&
    repLastNameRedux === userLastName &&
    userEmail === repEmailRedux;

  const directorEmailsRedux = shareholdersDirectorsRedux.map(
    (user: ShareholderDirector) => user?.email
  );

  const wasDirectorChecboxChecked = directorEmailsRedux.includes(
    repEmailRedux ?? ""
  );

  const [authChecked, setAuthChecked] = useState(wasAuthCheckboxChecked);
  const [userTitle, setUserTitle] = useState(userTitleRedux || "");
  const [directorChecked, setDirectorChecked] = useState(
    wasDirectorChecboxChecked
  );
  const [numOfShareholdersDirectors, setNumOfShareholdersDirectors] = useState(
    shareholdersDirectorsRedux?.length || 1
  );
  const [shareholdersDirectors, setShareholdersDirectors] = useState<
    ShareholderDirector[]
  >(shareholdersDirectorsRedux || []);
  const [repEmail, setRepEmail] = useState(repEmailRedux || "");
  const [representativeFirstName, setRepresentativeFirstName] = useState(
    repFirstNameRedux || ""
  );
  const [representativeLastName, setRepresentativeLastName] = useState(
    repLastNameRedux || ""
  );
  const [repTitle, setRepTitle] = useState(repTitleRedux || "");
  const [error, setError] = useState({
    representative_first_name: "",
    representative_last_name: "",
    repEmail: "",
    representative_title: "",
    userTitle: "",
    shareholderDirector: "",
  });

  const directorEmails = shareholdersDirectors
    .map((user) => user?.email)
    .filter((email) => email && email.trim() !== "");
  const allDirectorsEmailPresent = directorEmails.length > 0;

  const onNumberOfShareholdersDirectorsChange = (value: number) => {
    error.shareholderDirector = "";
    setNumOfShareholdersDirectors(Number(value));

    if (value < numOfShareholdersDirectors && shareholdersDirectors[value]) {
      const updatedShareholdersDirectors = [...shareholdersDirectors];
      updatedShareholdersDirectors.pop();

      setShareholdersDirectors(updatedShareholdersDirectors);
    }
  };

  const updateShareholderDirectorValue = (
    index: number,
    field: ShareholderDirectorField,
    value: string
  ) => {
    if (shareholdersDirectors[index]?.email === repEmail && directorChecked) {
      if (field === ShareholderDirectorField.EMAIL) {
        setRepEmail(value);
      }
      if (field === ShareholderDirectorField.FIRSTNAME) {
        setRepresentativeFirstName(value);
      }
      if (field === ShareholderDirectorField.LASTNAME) {
        setRepresentativeLastName(value);
      }
    }

    setShareholdersDirectors((prev) => {
      const updatedShareholdersDirectors: ShareholderDirector[] = [...prev];
      updatedShareholdersDirectors[index] = {
        ...updatedShareholdersDirectors[index],
        [field]: value,
        [ShareholderDirectorField.ROLE]: CompanyRole.DIRECTOR,
      };
      return updatedShareholdersDirectors;
    });
  };

  const handleAuthDirectorEmailChange = (email: string) => {
    const directorInfo = shareholdersDirectors.filter(
      (user) => user.email === email
    )[0];

    setRepresentativeLastName(directorInfo.lastName);
    setRepresentativeFirstName(directorInfo.firstName);
    setRepTitle("Director");
    setRepEmail(email);
  };

  const handleBack = () => {
    dispatch(
      setRepFirstNameRedux({ repFirstName: representativeFirstName.trim() })
    );
    dispatch(
      setRepLastNameRedux({ repLastName: representativeLastName.trim() })
    );
    dispatch(setRepEmailRedux({ repEmail }));
    dispatch(setRepTitleRedux({ repTitle }));
    dispatch(setUserTitleRedux({ userTitle }));
    dispatch(setShareholderDirectorRedux({ shareholdersDirectors }));

    navigate("/biz-profile");
  };

  const handleSubmit = () => {
    const customErr = { ...error };
    let valid = true;

    if (!representativeFirstName) {
      valid = false;
      customErr.representative_first_name =
        "Representative's First Name is required";
    }
    if (!representativeLastName) {
      valid = false;
      customErr.representative_last_name =
        "Representative's Last Name is required";
    }
    if (!repEmail) {
      valid = false;
      customErr.repEmail = "Representative's Email is required";
    }
    if (!repTitle) {
      valid = false;
      customErr.representative_title = "Representative's Role is required";
    }
    if (!userTitle) {
      valid = false;
      customErr.userTitle = "Your Role is required";
    }

    if (!shareholdersDirectors || shareholdersDirectors?.length < 1) {
      valid = false;
      customErr.shareholderDirector = "Directors are required";
    } else {
      customErr.shareholderDirector = "";
    }

    if (shareholdersDirectors) {
      const emails: string[] = [];

      shareholdersDirectors.forEach((user, index) => {
        if (emails.includes(user.email)) {
          valid = false;
          toast.warn("Different Directors cannot have the same email");
        }

        emails.push(user.email);

        if (!user.role) {
          valid = false;
          toast.warn(`Director ${index + 1} Role is required`);
        }

        if (!user.firstName) {
          valid = false;
          toast.warn(`Director ${index + 1} First Name is required`);
        }

        if (!user.lastName) {
          valid = false;
          toast.warn(`Director ${index + 1} Last Name is required`);
        }

        if (!user.email) {
          valid = false;
          toast.warn(`Director ${index + 1} Email required`);
        }

        if (userEmail === user.email) {
          valid = false;
          toast.warn(`Director ${index + 1} cannot have the same email as you`);
        }

        if (!directorChecked && user.email === repEmail) {
          valid = false;
          toast.warn(
            `Representative's email cannot be the same as Director ${
              index + 1
            } Email unless "Is the authorized representative one of the above directors?" checkbox is checked`
          );
        }
      });

      if (numOfShareholdersDirectors < 1) {
        valid = false;
        toast.warn(`Requires at least 1 Director`);
      }

      if (numOfShareholdersDirectors > 2) {
        valid = false;
        toast.warn(`Maximun Directors cannot exceed 2`);
      }
    }

    if (userEmail === repEmail && !authChecked) {
      valid = false;
      toast.warn(
        `Representative's email cannot be the same as your email unless "Will you be acting as an Authorized Representative?" checkbox is checked`
      );
    }

    if (valid) {
      dispatch(
        setRepFirstNameRedux({ repFirstName: representativeFirstName.trim() })
      );
      dispatch(
        setRepLastNameRedux({ repLastName: representativeLastName.trim() })
      );
      dispatch(setRepEmailRedux({ repEmail }));
      dispatch(setRepTitleRedux({ repTitle }));
      dispatch(setUserTitleRedux({ userTitle }));
      dispatch(setShareholderDirectorRedux({ shareholdersDirectors }));

      navigate("/bank-details");
    } else {
      setError(customErr);
    }
  };

  useEffect(() => {
    if (authChecked) {
      setRepresentativeLastName(userLastName!);
      setRepresentativeFirstName(userFirstName!);
      setRepTitle(userTitle);
      setRepEmail(userEmail as string);
      error.representative_last_name = "";
      error.representative_first_name = "";
      error.representative_title = "";
      error.repEmail = "";
    } else if (directorChecked) {
      if (!directorEmails.includes(repEmail)) {
        setRepresentativeLastName("");
        setRepresentativeFirstName("");
        setRepTitle("");
        setRepEmail("");
      }

      if (directorEmails.includes(repEmail)) {
        const directorInfo = shareholdersDirectors.filter(
          (user) => user.email === repEmail
        )[0];
        setRepresentativeLastName(directorInfo.lastName);
        setRepresentativeFirstName(directorInfo.firstName);
        setRepTitle("Director");
        setRepEmail(directorInfo.email);
        error.representative_last_name = "";
        error.representative_first_name = "";
        error.representative_title = "";
        error.repEmail = "";
      }

      if (!allDirectorsEmailPresent) {
        setDirectorChecked(false);
      }
    }
  }, [
    shareholdersDirectors,
    directorEmails,
    repEmail,
    repTitleRedux,
    authChecked,
    userLastName,
    userFirstName,
    repLastNameRedux,
    repFirstNameRedux,
    userEmail,
    userTitle,
    repEmailRedux,
    directorChecked,
    allDirectorsEmailPresent,
    error,
  ]);

  return (
    <div className={classes.container}>
      <AuthRepInfo />
      <div className={classes.inputBox}>
        <MantineProvider theme={{ cursorType: "pointer" }}>
          <Checkbox
            style={{ marginBottom: "20px" }}
            defaultChecked={authChecked}
            labelPosition="left"
            label={
              <span style={{ fontWeight: "bold" }}>
                Will you be acting as an Authorized Representative?
              </span>
            }
            onChange={(event) => setAuthChecked(event.currentTarget.checked)}
          />
        </MantineProvider>
        <InputGroup
          isErrored={error.userTitle}
          onChange={(value: string) => {
            error.userTitle = "";
            setUserTitle(value.replace(NAME_REGEX, ""));
          }}
          labelText="Your role in the company"
          id="userTitle"
          value={userTitle}
        />
        <img src={Line} alt="line" />
        <ShareholdersDirectors
          numOfShareholdersDirectors={numOfShareholdersDirectors}
          onNumberOfShareholdersDirectorsChange={
            onNumberOfShareholdersDirectorsChange
          }
          error={error.shareholderDirector}
          shareholdersDirectors={shareholdersDirectors}
          updateShareholderDirectorValue={updateShareholderDirectorValue}
        />
        <img src={Line} alt="line" />

        {!authChecked && (
          <Auth
            numOfDirectors={numOfShareholdersDirectors}
            allDirectorsEmailPresent={allDirectorsEmailPresent}
            directorChecked={directorChecked}
            setDirectorChecked={setDirectorChecked}
            error={error}
            repEmail={repEmail}
            directorEmails={directorEmails}
            handleAuthDirectorEmailChange={handleAuthDirectorEmailChange}
            setRepEmail={setRepEmail}
            setRepresentativeFirstName={setRepresentativeFirstName}
            representativeFirstName={representativeFirstName}
            setRepresentativeLastName={setRepresentativeLastName}
            representativeLastName={representativeLastName}
            setRepresentativeTitle={setRepTitle}
            representative_title={repTitle}
          />
        )}
        <div className={classes.buttonDivv}>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={handleBack}
              backgroundColor="#ffff"
              textColor="#338F86"
              children="Back"
              border="1px solid #338F86"
              width="100%"
            />
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={handleSubmit}
              textColor="#ffff"
              backgroundColor="#338F86"
              children="Next"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstitutionalRep;
