import { Text, List } from "@mantine/core";
import { FC } from "react";
import { AcknowledgementLayout } from "@funded-here-interface/common";
import { useStyle } from "./ThanksPage.styles";

const { Item } = List;

const ThanksPage: FC = () => {
  const { classes } = useStyle();

  return (
    <AcknowledgementLayout
      showLogo={false}
      content={
        <div className={classes.container}>
          <Text className={classes.headerText}>Thank You!</Text>
          <Text className={classes.text}>
            We appreciate the time & effort you have taken to submit the
            required information to us.
          </Text>
          <Text className={classes.text}>
            Our team is reviewing your application. We will get back to you as
            soon as possible via Email, so you can continue your journey with
            us.
          </Text>
          <Text className={classes.text}>Thank you for your patience.</Text>
        </div>
      }
    />
  );
};

export default ThanksPage;
