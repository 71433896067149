import { CompanyRole } from "@funded-here-interface/common/src/constant/enum";
import { createSlice } from "@reduxjs/toolkit";

export interface ShareholderDirector {
  role: CompanyRole;
  firstName: string;
  lastName: string;
  email: string;
}

interface RepState {
  repFirstName: string | null;
  repLastName: string | null;
  repEmail: string | null;
  userFirstName: string | null;
  userLastName: string | null;
  repTitle: string | null;
  merchantTitle: string | null;
  shareholdersDirectors: ShareholderDirector[];
}

const repSlice = createSlice({
  name: "rep",
  initialState: {
    repFirstName: null,
    repLastName: null,
    repEmail: null,
    userFirstName: null,
    userLastName: null,
    repTitle: null,
    merchantTitle: null,
    shareholdersDirectors: [],
  } as RepState,
  reducers: {
    setRepFirstName: (state, { payload: { repFirstName } }) => {
      state.repFirstName = repFirstName;
    },
    setRepLastName: (state, { payload: { repLastName } }) => {
      state.repLastName = repLastName;
    },
    setRepEmail: (state, { payload: { repEmail } }) => {
      state.repEmail = repEmail;
    },
    setUserFirstName: (state, { payload: { userFirstName } }) => {
      state.userFirstName = userFirstName;
    },
    setUserLastName: (state, { payload: { userLastName } }) => {
      state.userLastName = userLastName;
    },
    setRepTitle: (state, { payload: { repTitle } }) => {
      state.repTitle = repTitle;
    },
    setMerchantTitle: (state, { payload: { merchantTitle } }) => {
      state.merchantTitle = merchantTitle;
    },
    setShareholderDirector: (state, { payload: { shareholdersDirectors } }) => {
      state.shareholdersDirectors = shareholdersDirectors;
    },
  },
});

export const {
  setRepFirstName,
  setRepLastName,
  setRepEmail,
  setUserFirstName,
  setUserLastName,
  setRepTitle,
  setMerchantTitle,
  setShareholderDirector,
} = repSlice.actions;

export default repSlice.reducer;
