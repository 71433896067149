import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface DocuSignResponse {
  docuSignEnvelopeId: string;
  signerUrls: {
    url: string;
    email: string;
    name: string;
  }[];
}

export interface DocuSignPostData {
  webDomain: string;
  redirectUrl: string;
  token: string;
  directCreateDocusign?: boolean;
}
export interface LoanSignPostData {
  poolingSkuIds: any;
  webDomain: string;
  redirectUrl: string;
  token: string;
}
export interface SubscriptionDocuSignPostData {
  webDomain: string;
  redirectUrl: string;
  token: string;
  structuredNoteId: number;
  investedAmount: string;
}

export interface signer {
  name: string;
  email: string;
  status: string;
}

const fetchDocuSignData = async ({
  webDomain,
  redirectUrl,
  token,
}: DocuSignPostData) => {
  try {
    const response = await axios.post<DocuSignResponse>(
      `${BASE_URL}/trade-directory/agreement/master-sku-borrower-url`,
      { webDomain, redirectUrl },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    const url = response.data.signerUrls?.[0]?.url || "";
    const { docuSignEnvelopeId } = response.data;
    return { docuSignEnvelopeId, url };
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

const fetchLoanSignData = async ({
  poolingSkuIds,
  webDomain,
  redirectUrl,
  token,
}: LoanSignPostData) => {
  try {
    const response = await axios.post<DocuSignResponse>(
      `${BASE_URL}/sku-finance/sn/get-loan-agreement-url`,
      { poolingSkuIds, webDomain, redirectUrl },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    const url = response.data.signerUrls?.[0]?.url || "";
    const { docuSignEnvelopeId } = response.data;
    return { docuSignEnvelopeId, url };
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

const fetchSubscriptionDocumentData = async ({
  structuredNoteId,
  investedAmount,
  webDomain,
  redirectUrl,
  token,
}: SubscriptionDocuSignPostData) => {
  try {
    const response = await axios.post<DocuSignResponse>(
      `${BASE_URL}/sku-finance/sn/get-subscription-agreement-url`,
      { structuredNoteId, investedAmount, webDomain, redirectUrl },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    const url = response.data.signerUrls?.[0]?.url || "";
    const { docuSignEnvelopeId } = response.data;
    return { docuSignEnvelopeId, url };
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

const fetchSpvBoardResolutionSignData = async ({
  webDomain,
  redirectUrl,
  token,
  directCreateDocusign,
}: DocuSignPostData) => {
  try {
    const response = await axios.post<DocuSignResponse>(
      `${BASE_URL}/trade-directory/agreement/get-spv-board-resolution-doc`,
      { webDomain, redirectUrl, directCreateDocusign },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

const fetchBoardResolutionSignData = async ({
  webDomain,
  redirectUrl,
  token,
  directCreateDocusign,
}: DocuSignPostData) => {
  try {
    const response = await axios.post<DocuSignResponse>(
      `${BASE_URL}/trade-directory/agreement/get-board-resolution-doc`,
      { webDomain, redirectUrl, directCreateDocusign },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

const fetchMasterSkuBorrowerSignData = async ({
  webDomain,
  redirectUrl,
  token,
}: DocuSignPostData) => {
  try {
    const response = await axios.post<DocuSignResponse>(
      `${BASE_URL}/trade-directory/agreement/master-sku-borrower-url`,
      { webDomain, redirectUrl },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    const url = response.data.signerUrls?.[0]?.url || "";
    const { docuSignEnvelopeId } = response.data;
    return { docuSignEnvelopeId, url };
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

const fetchDeclarationOfAISignData = async ({
  webDomain,
  redirectUrl,
  token,
}: DocuSignPostData) => {
  try {
    const response = await axios.post<DocuSignResponse>(
      `${BASE_URL}/trade-directory/agreement/declaration-of-ai-url`,
      { webDomain, redirectUrl },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    const url = response.data.signerUrls?.[0]?.url || "";
    const { docuSignEnvelopeId } = response.data;
    return { docuSignEnvelopeId, url };
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export {
  fetchDocuSignData,
  fetchLoanSignData,
  fetchSubscriptionDocumentData,
  fetchSpvBoardResolutionSignData,
  fetchBoardResolutionSignData,
  fetchMasterSkuBorrowerSignData,
  fetchDeclarationOfAISignData,
};
