import { useMutation } from "react-query";
import completeMembershipAgreement from "../services/completeMembershipAgreement";

function useCompleteMembershipAgreement() {
  return useMutation(
    ["compelte-membership-agreement"],
    completeMembershipAgreement
  );
}

export default useCompleteMembershipAgreement;
