import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  mainContainer: {},
  text: {
    paddingBottom: "0px",
    paddingLeft: "0px",
    color: "#3C4257",
    textAlign: "left",
  },
  inputField : {
    marginTop: "2px",
    paddingLeft: "14px",
    paddingRight: "26px",
    height: "38px",
    width: "360px",
    marginBottom: "35px",
    border: "0.0625rem solid #ced4da;",
    borderRadius: "0.25rem",
    fontSize: '1rem',

    '&:focus': {
      outline: "none",
      borderColor: "#228be6", // Change border color on focus
    },

    //border:props.isErrored?'1px red solid':''
  },
  innerContainer: {
    display: "flex",
  },
  selectField: {
    width: "135px",
    paddingTop: "2px",
  },
  errorMessage: {
    color: "red",
    marginTop: "-20px",
    marginBottom: '15px',
    fontSize: "12px",
  },
}));
