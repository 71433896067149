import HttpClientXml from "../Utils/HttpClientXml";

const login = async (data) => {
  let endPoint = "login";
  return HttpClientXml.post(endPoint, data);
};

const salesComparisonReport = async (id, data) => {
  // let endPoint = `sales-comparison-report-inventory-turn-over-type-byMarchent/${id}`
  let endPoint = "sales-comparison-report-inventory-turn-over-type-byMarchent";
  //  let endPoint = `sales-comparison-report-inventory-turn-over-type-byMarchent/75`;
  return HttpClientXml.post(endPoint, data);
};

const getAllSKUdropdown = async (token) => {
  let endPoint = "view-drop-down-all-sku";
  return HttpClientXml.get(endPoint, undefined, token);
};

const dailySalesReport = async (data, id, token) => {
  // let endPoint = `sales-comparison-report-inventory-turn-over-type-byMarchent/${id}`
  let endPoint = "daily-sales-report-bySku";
  return HttpClientXml.post(endPoint, data, token);
};

const amountdue = async (token) => {
  // let endPoint = `sales-comparison-report-inventory-turn-over-type-byMarchent/${id}`
  let endPoint = "ammout-due-analysis-report-byMarchant";
  return HttpClientXml.get(endPoint, undefined, token);
};

const getMerchant = async (token) => {
  // let endPoint = `sales-comparison-report-inventory-turn-over-type-byMarchent/${id}`
  let endPoint = "view-merchant-data";
  return HttpClientXml.get(endPoint, undefined, token);
};

const activePayment = async (token) => {
  console.log("activePayment called", token);
  // let endPoint = `sales-comparison-report-inventory-turn-over-type-byMarchent/${id}`
  let endPoint = "view-payment-tracking-byMarchent";
  return HttpClientXml.post(endPoint, undefined, token);
};

const closedPayment = async (token) => {
  console.log("closedPayment called");
  try {
    const requestBody = {
      status: "closed",
    };
    const res = await HttpClientXml.post(
      "view-payment-tracking-byMarchent",
      requestBody,
      token
    );
    return res;
  } catch (error) {
    // Handle any errors
    console.error("Error fetching closed payment data:", error);
    throw error;
  }
};

const defaultPayment = async () => {
  // let endPoint = `sales-comparison-report-inventory-turn-over-type-byMarchent/${id}`
  let endPoint = "merchant-default-payment-in-merchant-summary";
  return HttpClientXml.get(endPoint);
};

export default {
  login,
  salesComparisonReport,
  getAllSKUdropdown,
  dailySalesReport,
  amountdue,
  getMerchant,
  activePayment,
  defaultPayment,
  closedPayment,
};
