import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  stepContent: {
    width: "852px",
    height: "450px",
    top: "190px",
    left: "245px",
    gap: "5px",
    backgroundColor: "#FFF",
  },
  docContainer: {
    width: "852px",
    height: "66px",
    backgroundColor: "#F9F8F7",
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    borderRadius: "10px"
  },
  docName: {
    width: "421px",
    height: "19px",
    position: "absolute",
    left: "30px",
    color: "#000000",
    fontFamily: "SF Pro Display",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.09px",
    alignSelf: "center",
  },
  uploadButtonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "150px",
    height: "15px",
    left: "650px",
    position: "absolute",
    padding: "20px",
    borderRadius: "12px",
    border: "1px black solid",
    gap: "10px",
    alignItems: "center",
    marginTop: "8px",
    cursor: "pointer"
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
    marginTop: "30px",
    gap: "40px",
  },
  buttonDiv2: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
    marginTop: "30px",
    gap: "40px",
  },
  buttonWrapper: {
    paddingTop: "10px",
  },
  uploadedFileName: {
    marginLeft: "450px",
    display: "flex",
    marginTop: "5px",
  },
  headinfo: {
    marginTop: "100px",
    width: "100vw",
    fontSize: "14px",
    fontWeight: 400,
  },
}));
