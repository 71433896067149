import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  mainContainer: {
    width: "100%",
    height: "max-content",
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    margin: 0,
    position: "relative",
  },
  stepContainer: {
    position: "absolute",
    width: 406,
    height: 337,
    top: 95,
    left: 302,
    gap: 15,
    marginBottom: 30,
  },
}));
