import axios from "axios";
import { ResetPasswordType } from "../types/login.type";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

const resetpassword = async ({
  password,
  confirm_password,
  token,
}: ResetPasswordType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/users/reset`,
      {
        token,
        password,
        confirmPassword: confirm_password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default resetpassword;
