import { FC, useEffect, useState } from "react";
import { Text } from "@mantine/core";
import Line from "../../assets/LineHorizontal.png";
import InProgress from "../../assets/EllipseYellow.png";
import NextStep from "../../assets/EllipseGrey.png";
import rightArrow from "../../assets/chevron_right_24px_outlined.png";
import SuccessStep from "../../assets/Group 37153.png";
import { useStyles } from "./DistributorSteps.styles";
import { stepsJson } from "./Data";

type Props = {
  currentStep: number;
};

type stepType = {
  steps_id: number;
  text: string;
};

const DistributorStep: FC<Props> = ({ currentStep }) => {
  const { classes } = useStyles();
  const [CurrentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    setCurrentStep(currentStep);
  }, [currentStep]);

  return (
    <div className={classes.container}>
      <div className={classes.horizontalSteps}>
        <div className={classes.horizontalSteps}>
          {stepsJson &&
            stepsJson.map((item: stepType, key: number) => {
              return (
                <div className={classes.stepGroup} key={"row_" + key}>
                  <div className={classes.imgGroup} key={"div_" + key}>
                    <img
                      style={{ height: "25px", width: "25px" }}
                      alt="img"
                      src={
                        item.steps_id < CurrentStep
                          ? SuccessStep
                          : item.steps_id === CurrentStep
                          ? InProgress
                          : NextStep
                      }
                    />
                    {item.steps_id >= CurrentStep ? (
                      <Text
                        className={classes.stepText}
                        style={{
                          color:
                            item.steps_id === CurrentStep
                              ? "#FFFFFF"
                              : "#6F6C90",
                        }}
                      >
                        {item.steps_id}
                      </Text>
                    ) : (
                      ""
                    )}
                    <Text
                      className={classes.text}
                      style={{
                        fontWeight: item.steps_id === CurrentStep ? 700 : 400,
                      }}
                    >
                      {item.text}
                    </Text>
                  </div>
                  {item.steps_id < stepsJson.length && (
                    <img
                      src={rightArrow}
                      alt="arrow"
                      className={classes.arrowContainer}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className={classes.horizontalLine}>
        <img alt="line" src={Line} />
      </div>
    </div>
  );
};

export default DistributorStep;
