import { FC, ReactNode } from "react";
import { useStyles } from "./AcknowledgementLayout.styles";
import Flogo from "../../assets/logo2.png";
import BackImage from "../../assets/sideBackground.svg";
import { ToastContainer } from "react-toastify";

interface IProps {
  content: ReactNode;
  showLogo?: boolean;
}

const AcknowledgementLayout: FC<IProps> = ({ content, showLogo = true }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <ToastContainer />

      {showLogo && (
        <div className={classes.iconContainer}>
          <img className={classes.logo} src={Flogo} alt="" />
        </div>
      )}
      <div className={classes.leftContainer}>
        <div className={classes.pageContainer}>
          <div className={classes.stepContent}>{content}</div>
        </div>
      </div>
      <div className={classes.backImage}>
        <img src={BackImage} alt="" />
      </div>
    </div>
  );
};

export default AcknowledgementLayout;
