import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface DownloadLoanAgreement {
  token: string;
  path: string;
}

const getSignedLoanAgreement = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/sn/get-signed-loan-agreement`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

const downloadSignedAgreement = async ({
  token,
  path,
}: DownloadLoanAgreement) => {
  try {
    const PathSplit = path.split("\\");
    const bucketName = PathSplit[0];
    const trimUpTo = bucketName;
    const trimIndex = path.indexOf(trimUpTo) + trimUpTo.length;
    const pathAfterTrim = path.substring(trimIndex);

    const response = await axios.get(
      `${BASE_URL}/sku-finance/sn/download-signed-agreement/${bucketName}\\${pathAfterTrim}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

const getSignedDocuments = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/trade-directory/agreement/get-signed-doc`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

const downloadSignedDocuments = async ({
  token,
  path,
}: DownloadLoanAgreement) => {
  try {
    const PathSplit = path.split("\\");
    const bucketName = PathSplit[0];
    const trimUpTo = bucketName;
    const trimIndex = path.indexOf(trimUpTo) + trimUpTo.length;
    const pathAfterTrim = path.substring(trimIndex);

    const response = await axios.get(
      `${BASE_URL}/trade-directory/agreement/download-signed-documents/${bucketName}\\${pathAfterTrim}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

const getSignedSubscriptionAgreement = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/sn/get-signed-subscription-agreement`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export {
  getSignedLoanAgreement,
  downloadSignedAgreement,
  getSignedDocuments,
  downloadSignedDocuments,
  getSignedSubscriptionAgreement,
};
