import { Text } from "@mantine/core";
import { InputGroup } from "@funded-here-interface/common";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmailValid } from "../../Utils/formValidation";
import useForgetPasswordMutation from "../../hooks/useForgetPassword";
import { useStyles } from "./PasswordReset.styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {};

const PasswordReset: FC<Props> = (props) => {
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const Navigate = useNavigate();
  const mutation = useForgetPasswordMutation();

  const handleSubmit = () => {
    if (!email) {
      setError("Email is required");
    } else if (!isEmailValid(email)) {
      setError("Email is invalid");
    } else {
      mutation.mutate(
        { email },
        {
          onSuccess: () => {
            Navigate("/reset-message");
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    }
  };

  return (
    <div>
      <div className={classes.loginContainer}>
        <div>
          <Text className={classes.loginHeading}>Reset your password</Text>
          <Text className={classes.subHeading}>
            Enter the email associated with your account and <br /> we will send
            you a link to reset your password
          </Text>
        </div>
        <div className={classes.inputGroupContainer}>
          <div style={{ marginLeft: "0px" }}>
            <InputGroup
              labelText="Email"
              id="your-email"
              value={email}
              placeHolder="Enter Email Address"
              onChange={(value: string) => setEmail(value)}
              isErrored={error}
            />
          </div>

          <div className={classes.buttonDiv}>
            <input
              className={classes.input}
              type="submit"
              value="Continue"
              style={{ width: "100%", fontSize: "17px", fontWeight: 700 }}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
