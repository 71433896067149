import { reactLocalStorage } from "reactjs-localstorage";
// const BASE_URL = 'http://65.2.10.224:4049/api/admin/';
// const BASE_URL = 'https://admin.deshvidesh.ca/login'; newUrl
const BASE_URL = "https://api.dashboard.fundedhere.com/api/user/";
const ALLOW_ORIGIN = "https://api.dashboard.fundedhere.com";
const USER_TYPE = "Investor";
// import { BASE_URL, API_ENDPOINT_PATH } from "../constants/config";

function get(endpoint, params) {
  console.log("params", params);
  return requestData(endpoint, params);
}

function post(endpoint, params) {
  return requestData(endpoint, params, "POST");
}

function put(endpoint, params) {
  return requestData(endpoint, params, "PUT");
}

function deletemethod(endpoint, params) {
  return requestData(endpoint, params, "DELETE");
}

async function requestData(url, data = {}, token, method = "GET") {
  let xhr = new XMLHttpRequest();
  xhr.open(method, BASE_URL + url);

  console.log("token inside", token, url);
  // xhr.setRequestHeader("authorization", await checkingAuth(token));
  // xhr.setRequestHeader("Content-Type", "application/json");
  // xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  // xhr.setRequestHeader("userType", USER_TYPE);

  if (checkingAuth(token))
    xhr.setRequestHeader("authorization", "Bearer " + token);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  xhr.setRequestHeader("userType", USER_TYPE);

  return new Promise((resolve, reject) => {
    // console.log("string mal", JSON.stringify(data));
    if (method == "GET") {
      xhr.send();
    } else {
      xhr.send(JSON.stringify(data));
    }
    xhr.onload = () => {
      console.log(xhr.status);
      // console.log("jdjgfdgdfgdgfdgfdgdf", xhr);
      let response = JSON.parse(xhr.response);
      // console.log(response ,"sdgdgdfgdfgdf");

      if (
        xhr.status === 200 ||
        xhr.status === 400 ||
        xhr.status === 403 ||
        xhr.status === 500 ||
        xhr.status === 406
      ) {
        resolve(response);
      } else {
        reject(response);
      }
    };
  });
}

async function fileUplode(
  url,
  method,
  file,
  object_get = {},
  tokenCustom = null
) {
  let token = "";
  let user = reactLocalStorage.get("Auth");

  if (user && user != null && Object.keys(user).length > 0) {
    token = user;
  }
  // let bash_url = "http://13.127.101.102:5011/api/v1/";
  let apiUrl = BASE_URL + url;

  if (token != "") {
    var toooo = token;
  }
  //  console.log("tokenn",toooo);
  let headers = {
    // 'Accept': 'application/json',
    // "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "http://13.127.101.102:5008",
    authorization: toooo,
    userType: "Admin",
    // 'Authorization': 'Bearer ' + login_status,
  };

  return await fetch(apiUrl, {
    method: method,
    body: file,
    redirect: "follow",
    headers: headers,
  })
    .then((response) => {
      console.log("responseee", response, headers);
      return response.json();
    })
    .then(
      (result) => {
        console.log("ResponIMG", result);
        return result;
      },
      (error) => {
        return error;
      }
    );
}

async function fileVideoUplode(
  url,
  method,
  file,
  object_get = {},
  tokenCustom = null
) {
  let token = "";
  let user = reactLocalStorage.get("Auth");
  // console.log("UserTok", user);
  if (user && user != null && Object.keys(user).length > 0) {
    token = user;
  }
  // let bash_url = "http://13.127.101.102:5011/api/v1/";
  let apiUrl = BASE_URL + url;
  // let data = new FormData();
  // data.append("image", file);
  // Object.keys(object_get).forEach(function (key) {
  //   let ddd = object_get[key];
  //   data.append(key, ddd);
  // });

  if (token != "") {
    var toooo = token;
  }
  //  console.log("tokenn",toooo);
  let headers = {
    // 'Accept': 'application/json',
    // "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "http://13.127.101.102:5008",
    authorization: toooo,
    userType: "Admin",
    // 'Authorization': 'Bearer ' + login_status,
  };

  return await fetch(apiUrl, {
    method: method,
    body: file,
    redirect: "follow",
    headers: headers,
  })
    .then((response) => {
      // console.log("responseee", response, headers);
      return response.json();
    })
    .then(
      (result) => {
        console.log("ResponIMG", result);
        return result;
      },
      (error) => {
        return error;
      }
    );
}

async function checkingAuth(token) {
  if (token) {
    return token;
  } else {
    return false;
  }
  return;
}

async function loginAs(token) {
  // const data = {
  //   email: "john.doe@example.com",
  //   password: "123456",
  // };

  // // toast.success("Login Successfully");

  // const res = await requestData("login", data, token, "POST");
  // console.log("klnfjks8667", res);
  // // console.log("resCat", res);
  // if (res && res.status) {
  //   // console.log("orgidLog", res?.data?.orgRoleId);
  //   // reactLocalStorage.set("orgRoleId", res?.data?.orgRoleId);
  //   return res?.data?.token;
  // }

  reactLocalStorage.set("Auth", token);
  return;
}

export default {
  requestData,
  get,
  post,
  put,
  deletemethod,
  fileUplode,
  fileVideoUplode,
  loginAs,
};
