import { FC, ReactNode } from "react";
import { useStyles } from "./Distributorlayout.styles";
import DistributorStep from "@funded-here-interface/common/src/Layouts/DistributorSteps/DistributorStep";
import { ToastContainer } from "react-toastify";
import NavBar from "../../components/NavBar/NavBar";

interface IProps {
  content: ReactNode;
  showSteps?: boolean;
  step: number;
}

const DistributorLayout: FC<IProps> = ({ content, showSteps, step }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.mainContainer}>
      <NavBar />
      <ToastContainer />

      {showSteps && <DistributorStep currentStep={step} />}
      <div className={classes.stepContainer}>{content}</div>
    </div>
  );
};

export default DistributorLayout;
