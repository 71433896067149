import {
  CurrencyInputGroup,
  InputGroup,
  InputSelect,
  NumberInputGroup,
} from "@funded-here-interface/common";
import { NumberInput } from "@mantine/core";
import { Dispatch, FC, SetStateAction } from "react";
import { useStyle } from "../LoanForm.styles";

interface ValueMapping {
  [key: string]: string;
}

interface ErrorState {
  UPC: string;
  quantity: string;
  price: string;
  discount: string;
  dateOfDelivery: string;
  goodsCost: string;
  averageInventory: string;
  estimatedSKU: string;
  paymentTerms: string;
  reason: string;
  estimatedDays: string;
  sellingPrice: string;
  totalTax: string;
}

type Props = {
  estimatedSKU: number | null;
  setEstimatedSKU: Dispatch<SetStateAction<number | null>>;
  estimatedDays: number | null;
  setEstimatedDays: Dispatch<SetStateAction<number | null>>;
  price: number | null;
  setPrice: Dispatch<SetStateAction<number | null>>;
  sellingPrice: number | null;
  setSellingPrice: Dispatch<SetStateAction<number | null>>;
  quantity: number | null;
  setQuantity: Dispatch<SetStateAction<number | null>>;
  discount: number | null;
  setDiscount: Dispatch<SetStateAction<number | null>>;
  totalTax: number | null;
  setTotalTax: Dispatch<SetStateAction<number | null>>;
  terms: string;
  setTerms: Dispatch<SetStateAction<string>>;
  paymentTerms: string;
  setPaymentTerms: Dispatch<SetStateAction<string>>;
  reason: string;
  setReason: Dispatch<SetStateAction<string>>;
  firstPayment: number;
  setFirstPayment: Dispatch<SetStateAction<number>>;
  balancePayment: number | null;
  setBalancePayment: Dispatch<SetStateAction<number | null>>;
  error: ErrorState;
  setError: Dispatch<SetStateAction<ErrorState>>;
};

const LoanFields: FC<Props> = ({
  estimatedSKU,
  setEstimatedSKU,
  estimatedDays,
  setEstimatedDays,
  price,
  setPrice,
  sellingPrice,
  setSellingPrice,
  quantity,
  setQuantity,
  discount,
  setDiscount,
  totalTax,
  setTotalTax,
  terms,
  setTerms,
  paymentTerms,
  setPaymentTerms,
  reason,
  setReason,
  firstPayment,
  setFirstPayment,
  balancePayment,
  setBalancePayment,
  error,
  setError,
}) => {
  const { classes } = useStyle();

  const valueMapping: ValueMapping = {
    COD: "cash-on-delivery",
    "Partial payment": "partial-payment",
    "Payment on PO": "payment-on-purchase-order",
  };

  function getBackendValue(uiValue: string) {
    return valueMapping[uiValue] || uiValue;
  }

  const handleDiscountChange = (value: any) => {
    const isNumber = /^[0-9]+$/.test(value);
    const positiveNumber = Number(value) > 0;

    if (!isNumber || !positiveNumber) {
      setError((prevError) => ({
        ...prevError,
        discount: "Discount must be a positive number.",
      }));
    } else {
      setError((prevError) => ({ ...prevError, discount: "" }));
    }

    setDiscount(value);
  };

  const handleTotalTaxChange = (value: any) => {
    const isNumber = /^[0-9]+$/.test(value);
    const positiveNumber = Number(value) > 0;

    if (!isNumber || !positiveNumber) {
      setError((prevError) => ({
        ...prevError,
        totalTax: "Total Tax must be a positive number.",
      }));
    } else {
      setError((prevError) => ({ ...prevError, totalTax: "" }));
    }

    setTotalTax(value);
  };

  const handleEstimatedSKUChange = (value: any) => {
    const isNumber = /^[0-9]+$/.test(value);
    const positiveNumber = Number(value) > 0;

    if (!isNumber || !positiveNumber) {
      setError((prevError) => ({
        ...prevError,
        estimatedSKU: "Estimated SKU must be a positive number.",
      }));
    } else {
      setError((prevError) => ({ ...prevError, estimatedSKU: "" }));
    }

    setEstimatedSKU(value);
  };

  const handleEstimatedDaysChange = (value: any) => {
    const isNumber = /^[0-9]+$/.test(value);
    const positiveNumber = Number(value) > 0;

    if (!isNumber || !positiveNumber) {
      setError((prevError) => ({
        ...prevError,
        estimatedDays: "Estimated days must be a positive number.",
      }));
    } else {
      setError((prevError) => ({ ...prevError, estimatedDays: "" }));
    }

    setEstimatedDays(value);
  };

  const handlePriceChange = (value: any) => {
    const isNumber = /^[0-9]+$/.test(value);
    const positiveNumber = Number(value) > 0;

    if (!isNumber || !positiveNumber) {
      setError((prevError) => ({
        ...prevError,
        price: "Price must be a positive number.",
      }));
    } else {
      setError((prevError) => ({ ...prevError, price: "" }));
    }

    setPrice(value);
  };

  const handleSellingPriceChange = (value: any) => {
    const isNumber = /^[0-9]+$/.test(value);
    const positiveNumber = Number(value) > 0;

    if (!isNumber || !positiveNumber) {
      setError((prevError) => ({
        ...prevError,
        sellingPrice: "Selling price must be a positive number.",
      }));
    } else {
      setError((prevError) => ({ ...prevError, sellingPrice: "" }));
    }

    setSellingPrice(value);
  };

  const handleQuantityChange = (value: any) => {
    const isNumber = /^[0-9]+$/.test(value);
    const positiveNumber = Number(value) > 0;

    if (!isNumber || !positiveNumber) {
      setError((prevError) => ({
        ...prevError,
        quantity: "Quantity must be a positive number.",
      }));
    } else {
      setError((prevError) => ({ ...prevError, quantity: "" }));
    }

    setQuantity(value);
  };

  const handlePaymentTermsChange = (value: any) => {
    setError((prevError) => ({ ...prevError, paymentTerms: "" }));
    setTerms(value);
    const backendValue = getBackendValue(value);
    setPaymentTerms(backendValue);

    if (!backendValue) {
      setError((prevError) => ({
        ...prevError,
        paymentTerms: "PaymentTerms can't be empty.",
      }));
    } else {
      setError((prevError) => ({ ...prevError, paymentTerms: "" }));
    }
  };

  return (
    <div
      className={classes.formContainer}
      style={paymentTerms === "partial-payment" ? { height: "1090px" } : {}}
    >
      <div
        className={classes.form}
        style={paymentTerms === "partial-payment" ? { height: "1090px" } : {}}
      >
        <div className={classes.formInput}>
          <NumberInputGroup
            isErrored={error.estimatedSKU}
            onChange={handleEstimatedSKUChange}
            value={estimatedSKU}
            labelText="Estimated SKU Return (%)( Est percentage of return for this SKU)"
            id="id_number"
          />
        </div>
        <div className={classes.formInput}>
          <NumberInputGroup
            isErrored={error.estimatedDays}
            onChange={handleEstimatedDaysChange}
            value={estimatedDays}
            labelText="Estimated number of days from the desired order to delivery"
            id="quantity"
          />
        </div>
        <div className={classes.formInput}>
          <CurrencyInputGroup
            isErrored={error.price}
            onChange={handlePriceChange}
            value={price}
            labelText="Purchase price/unit"
            id="Purchase_price"
          />
        </div>
        <div className={classes.formInput}>
          <CurrencyInputGroup
            isErrored={error.sellingPrice}
            onChange={handleSellingPriceChange}
            value={sellingPrice}
            labelText="Selling price per unit (for this SKU)"
            id="id_type"
          />
        </div>
        <div className={classes.formInput}>
          <NumberInputGroup
            isErrored={error.quantity}
            onChange={handleQuantityChange}
            value={quantity}
            labelText="Quantity of stock needed"
            id="quantity"
          />
        </div>
        <div className={classes.formInput}>
          <CurrencyInputGroup
            isErrored={error.discount}
            onChange={handleDiscountChange}
            value={discount}
            labelText="Discount"
            id="id_number"
          />
        </div>
        <div className={classes.formInput}>
          <CurrencyInputGroup
            isErrored={error.totalTax}
            onChange={handleTotalTaxChange}
            value={totalTax}
            labelText="Total Tax"
            id="id_number"
          />
        </div>
        <div className={classes.formInput}>
          <InputSelect
            isErrored={error.paymentTerms}
            onChange={handlePaymentTermsChange}
            labelText="Payment Terms"
            id="legal_structure"
            value={terms}
            placeHolder="COD"
            data={["COD", "Partial payment", "Payment on PO"]}
          />
        </div>
        {paymentTerms === "partial-payment" && (
          <div style={{ marginTop: "20px" }}>
            <div className={classes.formInput}>
              <NumberInputGroup
                isErrored={error.quantity}
                onChange={(value: any) => setFirstPayment(value)}
                value={firstPayment}
                labelText="First Payment (%)"
                id="quantity"
              />
            </div>
            <div className={classes.formInput}>
              <label className={classes.textLabel} htmlFor="">
                Balace Payment (%)
              </label>
              <NumberInput
                style={{ marginTop: "10px" }}
                size="md"
                onChange={(value: any) => setBalancePayment(value)}
                value={balancePayment === null ? 0 : balancePayment}
                id="quantity"
                name="lastPayment"
                disabled
              />
            </div>
          </div>
        )}
        <div className={classes.formInput}>
          <InputGroup
            isErrored={error.reason}
            onChange={(value: any) => setReason(value)}
            value={reason}
            labelText="Reason for more than a 3% SKU return"
            id="reason"
          />
        </div>
      </div>
    </div>
  );
};

export default LoanFields;
