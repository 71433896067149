import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  stepsToComplete: {
    display: "flex",
    flexDirection: "row",
    left: "259px",
  },
  instruction: {
    width: "508px",
    height: "50px",
    top: "140px",
    left: "259px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    fontStyle: "normal",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subHeading: {
    width: "48px",
    height: "19px",
    top: "320px",
    left: "259px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    marginTop: "30px",
  },
  text: {
    height: "19px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#11A77A",
    marginTop: "15px",
    marginLeft: "25px",
  },
  list: {
    width: "100%",
    height: "19px",
    top: "352px",
    left: "286px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "8px",
    paddingLeft: "0px",
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0px",
    position: "absolute",
    left: "10px",
  },
  eclipse: {
    width: "10px",
    height: "10px",
    top: "380px",
    left: "271px",
    marginTop: "8px",
  },
  line: {
    width: "10px",
    height: "20px",
    justifyContent: "center",
    border: "1px",
    rotate: "none",
    paddingLeft: "4px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
    marginTop: "30px",
  },
  buttonWrapper: {
    padding: "10px",
  },
  headerText: {
    width: 361,
    height: 46,
    top: 95,
    left: 150,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },
}));
