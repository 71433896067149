import { FC } from "react";
import { Text, List } from "@mantine/core";
import { useStyles } from "./PendingSpv.styles";
import { AcknowledgementLayout } from "@funded-here-interface/common";

const { Item } = List;

const PendingSPV: FC = () => {
  const { classes } = useStyles();

  return (
    <AcknowledgementLayout
      showLogo={false}
      content={
        <div className={classes.container}>
          <Text className={classes.headerText}>Thank You!</Text>
          <Text className={classes.text}>
            We are currently in the process of setting up a Special Purpose
            Vehicle (SPV):
          </Text>
          <Text className={classes.text}>
            1. Our team will promptly send your SPV setup request to the third
            party.
          </Text>
          <Text className={classes.text}>
            2. The third party may reach out to you directly for additional
            information or verification during this process.
          </Text>
          <Text className={classes.text}>
            3. Once the SPV setup is complete, you will receive a notification
            prompting you to log back into your account.
          </Text>
          <Text className={classes.text}>Thank you for your patience.</Text>
        </div>
      }
    />
  );
};

export default PendingSPV;
