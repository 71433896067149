import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DataTableown from "../Repayment/DataTableown";
import ClosedPaymentTable from "../Repayment/ClosedPaymentTable";
import { Header } from "@funded-here-interface/common";
import DefaultPaymentTable from "./DefaultPaymentTable";

const Repayment = () => {
  return (
    <>
      <Header />
      <div className="RestPage">
        <div className="container-fluid">
          <div className="repayDataTable">
            {/* <p className='overallChartPara'>*All amounts are in USD</p> */}
            <Tabs>
              <TabList>
                <Tab>
                  Active Payment
                  <span className="RepayTooltip">
                    Click here to follow link
                  </span>
                </Tab>
                <Tab>
                  Closed Payment
                  <span className="RepayTooltip">
                    Click here to follow link
                  </span>
                </Tab>
                <Tab>
                  Default Payment
                  <span className="RepayTooltip">
                    Click here to follow link
                  </span>
                </Tab>
              </TabList>

              <TabPanel>
                <p className="RepayTableHead">Active Payment Tracking</p>
                <div className="activePayTable">
                  <DataTableown />
                </div>
              </TabPanel>

              <TabPanel>
                <p className="RepayTableHead">Closed Payment Tracking</p>
                <div className="closePayTable">
                  <ClosedPaymentTable />
                </div>
              </TabPanel>
              <TabPanel>
                <p className="RepayTableHead">Default Payment Tracking</p>
                <div className="activePayTable">
                  <DefaultPaymentTable />
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Repayment;
