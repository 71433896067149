import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  parentContainer: {
    paddingTop: '110px',
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    height: "100vh",
    alignItems: 'flex-start',
    boxSizing: "border-box",
    overflowY: 'auto',
    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      justifyContent: 'flex-start',
    },
  },

  container: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
   
    padding: "10px",
    paddingBottom: 0,
    boxSizing: "border-box",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      padding: "20px",
    },
  },
  headingContainer: {
    color: "#151515",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "center",
    fontStyle: "normal",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      height: "auto",
      marginLeft: "0",
      marginRight: "0",
      fontSize: "20px",
    },
  },
  tooltipContainer: {
    position: "relative",
    display: "inline-block",
  },
  inputbox: {
    width: "100%",
  },
  radioGroup: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
    gap: "20px",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
  inputGroup: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
  agreeGroup: {
    display: 'flex',
    justifyContent: 'center',
  },
  checkBox: {
    width: "24px",
    height: "24px",
    padding: "5px 0px",
  },
  buttonGroup: {
    textAlign: 'center',
    borderRadius: "10px",
    [theme.fn.smallerThan("sm")]: {
    },
  },
  btn: {
    fontSize: "18px",
    padding: '1rem 4rem',
    [theme.fn.smallerThan("sm")]: {
      fontSize: "16px",
      fontWeight: 700,
    },
  },
  subtext: {
    fontSize: "13px",
    marginTop: "10px",
  },
}));
