import { createSlice } from "@reduxjs/toolkit";

const docSlice = createSlice({
  name: "doc",
  initialState: {
    register: [],

    share: [],
    fund: [],
    bank: [],
    finance: [],
  },
  reducers: {
    setRegisterDoc: (state, { payload: { register } }) => {
      state.register = register;
    },

    setShareDoc: (state, { payload: { share } }) => {
      state.share = share;
    },
    setFundDoc: (state, { payload: { fund } }) => {
      state.fund = fund;
    },
    setBankDoc: (state, { payload: { bank } }) => {
      state.bank = bank;
    },
    setFinanceDoc: (state, { payload: { finance } }) => {
      state.finance = finance;
    },
  },
});

export const {
  setRegisterDoc,
  setShareDoc,
  setBankDoc,
  setFinanceDoc,
  setFundDoc,
} = docSlice.actions;

export default docSlice.reducer;
