import { useSelector, useDispatch } from "react-redux";
import {
  setBalance,
  setBusinessDoc,
  setProofDoc,
  setShareDoc,
  setBankDoc,
  setSalaryProofDoc,
  setFinancialStatementDoc,
  setInvestmentMandateDoc,
  setFundManagerLicenseDoc,
  UploadedFile,
} from "../features/merchant/doc";

import { RootState } from "@funded-here-interface/investor-onboarding/src/store";

// Create a custom hook for the 'register' array
export const useAppendToBalance = () => {
  const dispatch = useDispatch();
  const balance = useSelector((state: RootState) => state.bordoc.balance);

  const appendToBalance = (
    newValue: UploadedFile,
    original: UploadedFile[] | null
  ) => {
    if (original !== null) {
      dispatch(setBalance({ balance: [...original, newValue] }));
    } else {
      dispatch(setBalance({ balance: [newValue] }));
    }
  };

  return { balance, appendToBalance };
};

// Create similar custom hooks for other arrays ('mAnda', 'share', 'fund', 'bank', 'finance', 'resolution')

export const useShareArray = () => {
  const dispatch = useDispatch();
  const share = useSelector((state: RootState) => state.bordoc.share);

  const appendToShare = (
    newValue: UploadedFile,
    original: UploadedFile[] | null
  ) => {
    if (original !== null) {
      dispatch(setShareDoc({ share: [...original, newValue] }));
    } else {
      dispatch(setShareDoc({ share: [newValue] }));
    }
  };

  return { share, appendToShare };
};
export const useBusiness = () => {
  const dispatch = useDispatch();
  const business = useSelector((state: RootState) => state.bordoc.business);

  const appendToBusiness = (
    newValue: UploadedFile,
    original: UploadedFile[] | null
  ) => {
    if (original !== null) {
      dispatch(setBusinessDoc({ business: [...original, newValue] }));
    } else {
      dispatch(setBusinessDoc({ business: [newValue] }));
    }
  };

  return { business, appendToBusiness };
};
export const useProofArray = () => {
  const dispatch = useDispatch();
  const proof = useSelector((state: RootState) => state.bordoc.proof);

  const appendToProof = (
    newValue: UploadedFile,
    original: UploadedFile[] | null
  ) => {
    if (original !== null) {
      dispatch(setProofDoc({ proof: [...original, newValue] }));
    } else {
      dispatch(setProofDoc({ proof: [newValue] }));
    }
  };

  return { proof, appendToProof };
};
export const useBankArray = () => {
  const dispatch = useDispatch();
  const bank = useSelector((state: RootState) => state.bordoc.bank);

  const appendToBank = (
    newValue: UploadedFile,
    original: UploadedFile[] | null
  ) => {
    if (original !== null) {
      dispatch(setBankDoc({ bank: [...original, newValue] }));
    } else {
      dispatch(setBankDoc({ bank: [newValue] }));
    }
  };

  return { bank, appendToBank };
};

export const useSalaryProofArray = () => {
  const dispatch = useDispatch();
  const salaryProof = useSelector(
    (state: RootState) => state.bordoc.salaryProof
  );

  const appendToSalaryProof = (
    newValue: UploadedFile,
    original: UploadedFile[] | null
  ) => {
    if (original !== null) {
      dispatch(setSalaryProofDoc({ salaryProof: [...original, newValue] }));
    } else {
      dispatch(setSalaryProofDoc({ salaryProof: [newValue] }));
    }
  };

  return { salaryProof, appendToSalaryProof };
};

export const useFinancialStatementArray = () => {
  const dispatch = useDispatch();
  const financialStatement = useSelector(
    (state: RootState) => state.bordoc.financialStatement
  );

  const appendToFinancialStatement = (
    newValue: UploadedFile,
    original: UploadedFile[] | null
  ) => {
    if (original !== null) {
      dispatch(
        setFinancialStatementDoc({
          financialStatement: [...original, newValue],
        })
      );
    } else {
      dispatch(setFinancialStatementDoc({ financialStatement: [newValue] }));
    }
  };

  return { financialStatement, appendToFinancialStatement };
};

export const useInvestmentMandateArray = () => {
  const dispatch = useDispatch();
  const investmentMandate = useSelector(
    (state: RootState) => state.bordoc.investmentMandate
  );

  const appendToInvestorMandate = (
    newValue: UploadedFile,
    original: UploadedFile[] | null
  ) => {
    if (original !== null) {
      dispatch(
        setInvestmentMandateDoc({
          investmentMandate: [...original, newValue],
        })
      );
    } else {
      dispatch(setInvestmentMandateDoc({ investmentMandate: [newValue] }));
    }
  };

  return { investmentMandate, appendToInvestorMandate };
};

export const useFundManagerLicenseArray = () => {
  const dispatch = useDispatch();
  const fundManagerLicense = useSelector(
    (state: RootState) => state.bordoc.fundMangerLicense
  );

  const appendToFundManagerLicense = (
    newValue: UploadedFile,
    original: UploadedFile[] | null
  ) => {
    if (original !== null) {
      dispatch(
        setFundManagerLicenseDoc({
          fundMangerLicense: [...original, newValue],
        })
      );
    } else {
      dispatch(setFundManagerLicenseDoc({ fundMangerLicense: [newValue] }));
    }
  };

  return { fundManagerLicense, appendToFundManagerLicense };
};
