import { Text } from "@mantine/core";
import { CSSProperties, FC, useEffect, useState } from "react";
import { useStyle } from "./StructureNote.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInvestorFunds from "../../hooks/useInvestorAccount";
import { COLORS } from "../../constants/colors";
import useInvestorSubscribedNotes from "../../hooks/useInvestorSubscribedNotes";
import { useDisclosure } from "@mantine/hooks";
import MembershipAgreement from "../../components/MembershipAgreement/MembershipAgreement";
import { EntityTypeEnum } from "@funded-here-interface/common/src/constant/enum";

declare global {
  interface Window {
    DocuSign: any;
  }
}

interface InvestmentOpportunity {
  id: number;
  noteId: number;
  currency: string;
  tenor: string;
  snInterestRate: string;
  totalSKU: number;
  amountAvailableForInvestment: string;
  totalMerchants: number;
  allowUnsubscribe: boolean;
}

const ConfirmNote: FC = () => {
  const { classes } = useStyle();
  const [offerRoomData, setOfferRoomData] = useState<any>([]);
  const InvestorFundsMutation = useInvestorFunds();
  const [accountData, setAccountData] = useState<any>([]);
  const investorSubscribedNotesMutation = useInvestorSubscribedNotes();
  const [reloadOfferRoomData, setReloadOfferRoomData] = useState(true);
  const { token, agreedToMembershipAgreement } = useSelector(
    (state: RootState) => state.auth
  );
  const [opened, { close }] = useDisclosure(
    agreedToMembershipAgreement !== true
  );

  useEffect(() => {
    if (offerRoomData.length === 0 && reloadOfferRoomData) {
      //  Get the latest investor data, VA account balance may have been updated
      InvestorFundsMutation.mutate(
        { token },
        {
          onSuccess: (data) => {
            setAccountData(data);
          },
          onError: (error: any) => {
            toast.error(error);
          },
        }
      );

      investorSubscribedNotesMutation.mutate(
        { token },
        {
          onSuccess: (data) => {
            setOfferRoomData(data);
            setVisibleStartIndex(0);
            setReloadOfferRoomData(false);
          },
          onError: (error: any) => {
            toast.error(error);
          },
        }
      );
    }
  }, [offerRoomData, reloadOfferRoomData]);

  const [hoveredId, setHoveredId] = useState<number | null>(null);
  const [selectedData, setSelectedData] =
    useState<InvestmentOpportunity | null>(null);

  const handleBoxSelect = (data: InvestmentOpportunity) => {
    setSelectedData(data);
  };

  const getBoxStyle = (data: InvestmentOpportunity): CSSProperties => ({
    cursor: "pointer",
    border: "1px solid #ccc",
    padding: "30px",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    flex: 1,
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: 25,
    margin: "10px",
    backgroundColor:
      selectedData && selectedData.id === data.id
        ? "rgba(0, 0, 0, 0.25)"
        : hoveredId === data.id
        ? "rgba(0, 0, 0, 0.25)"
        : "transparent",
    color:
      hoveredId === data.id || (selectedData && selectedData.id === data.id)
        ? COLORS.whiteColor
        : undefined,
    transition: "background-color 0.3s ease",
  });

  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const canNavigateBackward = visibleStartIndex > 0;
  const canNavigateForward = visibleStartIndex < offerRoomData.length - 3;
  const handleNext = () => {
    if (canNavigateForward) {
      setVisibleStartIndex(visibleStartIndex + 1);
    }
  };
  const handlePrev = () => {
    if (canNavigateBackward) {
      setVisibleStartIndex(visibleStartIndex - 1);
    }
  };
  const visibleItems = offerRoomData.slice(
    visibleStartIndex,
    visibleStartIndex + 3
  );

  return (
    <>
      <div
        className={classes.stepContent}
        style={{ marginTop: "0px", paddingTop: "0px" }}
      >
        <MembershipAgreement
          toCompleteOnboard={false}
          onError={(value: string) => {
            toast.error(value);
          }}
          opened={opened}
          close={close}
        />
        <Text className={classes.headerText} style={{ marginTop: "10px" }}>
          Offer Room
        </Text>
        <>
          <Text style={{ color: "black", fontWeight: "bold" }}>
            Balance in your VA{" "}
          </Text>
          <Text
            style={{
              color: COLORS.agreementFont,
              fontSize: "28px",
              marginTop: "5px",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            SGD {accountData.accountBalance?.SGD || "0"}{" "}
          </Text>
          <Text
            style={{
              color: COLORS.agreementFont,
              fontSize: "28px",
              marginTop: "5px",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            USD {accountData.accountBalance?.USD || "0"}{" "}
          </Text>

          <Text style={{ color: "black", fontWeight: "bold" }}>
            Subscribed / Invested Notes
          </Text>

          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {offerRoomData.length > 1 && canNavigateBackward && (
              <button
                onClick={handlePrev}
                style={{
                  cursor: canNavigateBackward ? "pointer" : "not-allowed",
                  color: COLORS.agreementFont,
                  marginRight: "10px",
                  padding: "10px",
                  fontSize: "30px",
                  fontWeight: "bolder",
                  borderRadius: "80px",
                  border: "none",
                }}
              >
                ←
              </button>
            )}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: offerRoomData?.length > 1 ? "100%" : "350px",
                justifyContent: "space-between",
              }}
            >
              {visibleItems.map((data: any) => (
                <div
                  key={data.id}
                  onMouseEnter={() => {
                    setHoveredId(data.id);
                  }}
                  onMouseLeave={() => setHoveredId(null)}
                  onClick={() => handleBoxSelect(data)}
                  style={getBoxStyle(data)}
                >
                  <p style={{ fontWeight: "bold" }}>Amount Invested</p>
                  <p
                    style={{
                      color: COLORS.agreementFont,
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                  >
                    {data.currency} {data?.investorDepositedAmount || "0"}
                  </p>
                  <p style={{ fontWeight: "bold" }}>Amount Subscribed</p>
                  <p
                    style={{
                      color: COLORS.agreementFont,
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                  >
                    {data.currency} {data?.investorSubscribedAmount || "0"}
                  </p>
                  <p>Note: {data.noteId}</p>
                  <p>Tenor: {data.tenor}</p>
                  <p>Interest Rate: {data.interestRate}</p>
                  <p>Total SKU’s: {data.totalSkus}</p>
                  <p>Total Merchants: {data.totalMerchants}</p>
                </div>
              ))}
            </div>
            {offerRoomData.length > 1 && canNavigateForward && (
              <button
                onClick={handleNext}
                style={{
                  cursor: canNavigateForward ? "pointer" : "not-allowed",
                  color: COLORS.agreementFont,
                  marginRight: "10px",
                  padding: "10px",
                  fontSize: "30px",
                  fontWeight: "bolder",
                  borderRadius: "80px",
                  border: "none",
                }}
              >
                →
              </button>
            )}
          </div>
          <ToastContainer />
        </>
      </div>
    </>
  );
};
export default ConfirmNote;
