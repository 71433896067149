import { createSlice } from "@reduxjs/toolkit";

export interface UploadedFile {
  name: string;
  path: string;
}

const docBorrowerSlice = createSlice({
  name: "bordoc",
  initialState: {
    business: [] as UploadedFile[],
    share: [] as UploadedFile[],
    balance: [] as UploadedFile[],
    proof: [] as UploadedFile[],
    bank: [] as UploadedFile[],
    salaryProof: [] as UploadedFile[],
    financialStatement: [] as UploadedFile[],
    investmentMandate: [] as UploadedFile[],
    fundMangerLicense: [] as UploadedFile[],
  },
  reducers: {
    setBusinessDoc: (state, { payload: { business } }) => {
      state.business = business;
    },
    setBalance: (state, { payload: { balance } }) => {
      state.balance = balance;
    },
    setShareDoc: (state, { payload: { share } }) => {
      state.share = share;
    },
    setProofDoc: (state, { payload: { proof } }) => {
      state.proof = proof;
    },
    setBankDoc: (state, { payload: { bank } }) => {
      state.bank = bank;
    },
    setSalaryProofDoc: (state, { payload: { salaryProof } }) => {
      state.salaryProof = salaryProof;
    },
    setFinancialStatementDoc: (state, { payload: { financialStatement } }) => {
      state.financialStatement = financialStatement;
    },
    setInvestmentMandateDoc: (state, { payload: { investmentMandate } }) => {
      state.investmentMandate = investmentMandate;
    },
    setFundManagerLicenseDoc: (state, { payload: { fundMangerLicense } }) => {
      state.fundMangerLicense = fundMangerLicense;
    },
  },
});

export const {
  setBusinessDoc,
  setShareDoc,
  setBalance,
  setProofDoc,
  setBankDoc,
  setSalaryProofDoc,
  setFinancialStatementDoc,
  setInvestmentMandateDoc,
  setFundManagerLicenseDoc,
} = docBorrowerSlice.actions;

export default docBorrowerSlice.reducer;
