import "./App.css";
import Login from "./Screens/Login/Login";
import { QueryClientProvider } from "react-query";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  AcknowledgementLayout,
  PasswordReset,
  PasswordResetMsg,
  Navbar,
} from "@funded-here-interface/common";
import NewPassword from "./Screens/NewPassword/NewPassword";
import PasswordChangedMessage from "./Screens/NewPassword/PasswordChangedMessage";
import Registration from "./Screens/Registration/Registration";
import WelcomePage from "./Screens/WelcomePage/WelcomePage";
import OtpVerification from "./Screens/OTPverification/OtpVerification";
import BizProfile from "./Screens/BizProfile/BizProfile";
import BankDetails from "./Screens/BankDetails/BankDetails";
import InvestorDocsUpload from "./Screens/DocUpload/InvestorDocsUpload";
import ThanksPage from "./Screens/ThanksPage/ThanksPage";
import RepresentativeDetails from "./Screens/RepresentativeDetails/RepresentativeDetails";
import IdentifyMe from "./Screens/IdentifyMe/IdentifyMe";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import WelcomeBorrowerPage from "./Screens/WelcomeBorrower/WelcomeBorrower";
import BorrowerIdentifyMe from "./Screens/BorrowerIdentify/BorrowerIdentify";
import SetupSpvPage from "./Screens/SettingSPV/SetupSpv";
import LoanAgreement from "./Screens/LoanAggrement/LoanAgreement";
import Deposit from "./Screens/Deposit/Deposit";
import BorrowerBizProfile from "./Screens/BizProfile/BorrowerBizProfile";
import BorrowerRep from "./Screens/BorrowerRep/BorrowerRep";
import BorrowerBank from "./Screens/BorrowerBank/BorrowerBank";
import BorrowerDocsUpload from "./Screens/DocUpload/BorrowerDoc";
import LoanApplication from "./Screens/LoanApplication/LoanApplication";
import WelcomeIssuingSPV from "./Screens/WelcomeIssuingSPV/WelcomeIssuingSPV";
import IssuingSPVBank from "./Screens/IssuingSPVBank/IssuingSPVBank";
import IssuingSPVBizProfile from "./Screens/BizProfile/IssuingSPVBizProfile";
import LTVLoanForm from "./Screens/LoanForm/LTVLoanForm";
import LoanAppSku from "./Screens/LoanApplicationSKU/LoanAppSku";
import LoanForm from "./Screens/LoanForm/LoanForm";
import LoanSigning from "./Screens/LoanSigning/LoanSigning";
import UploadInvoice from "./Screens/UploadInvoice/UploadInvoice";
import StructureNote from "./Screens/StructuredNote/StructureNote";
import WelcomeIndividual from "./Screens/WelcomePage/WelcomeIndividual";
import InvestorDashboardScreen from "./Screens/Dashboard/InvestorDashboard/InvestorDashboardScreen";
import BorrowerDashboard from "./Screens/Dashboard/BorrowerDashboard";
import BizProfileInd from "./Screens/BizProfile/BizProfileIndividual";
import BankDetailsInd from "./Screens/BankDetails/BankDetailsInd";
import IndInvestorDoc from "./Screens/DocUpload/IndInvestorDoc";
import SkuLoanApplication from "./Screens/SkuLoanApplication/SkuLoanApplication";
import BMCASigning from "./Screens/MerchantSigning/BMCASigning";
import PendingSPV from "./Screens/PendingSpv/PendingSPV";
import ConfirmNote from "./Screens/StructuredNote/ConfirmNote";
import Repayment from "./Screens/Dashboard/MerchantDashboard/Repayment/Repayment";
import ShareholderDIDSetup from "./Screens/ShareholderDIDSetup/ShareholderDIDSetup";
import D2Zguide from "./Screens/D2Zguide/Onboardingguide";
import RegistrationCompleted from "./Screens/RegistrationCompleted/RegistrationCompleted";
import ShareholderSPVBoardResolutionPending from "./Screens/ShareholderSPVBoardResolutionPending/ShareholderSPVBoardResolutionPending";
import PendingShareholderOnboard from "./Screens/PendingShareholderOnboard/PendingShareholderOnboard";
import MerchantCompleteOnboarding from "./Screens/MerchantCompleteOnboarding/MerchantCompleteOnboarding";
import AuthRepDIDSetup from "./Screens/AuthRepDIDSetup/AuthRepDIDSetup";
import D2ZguideMerchantPage1 from "./Screens/D2Zguide/D2ZguideMerchantPage1";
import D2ZguideMerchantPage2 from "./Screens/D2Zguide/D2ZguideMerchantPage2";
import DirectorDIDSetup from "./Screens/DirectorDIDSetup/DirectorDIDSetup";
import DirectorSPVBoardResolution from "./Screens/ShareholderSPVBoardResolution/ShareholderSPVBoardResolution";
import DirectorBoardResolution from "./Screens/ShareholderBoardResolution/ShareholderBoardResolution";
import FundingIssuingSpvCompleteOnboard from "./Screens/FundingIssuingSpvCompleteOnboard/FundingIssuingSpvCompleteOnboard";
import OnboardingLayout from "./layouts/OnboardingLayout/OnboardingLayout";
import NewOnboardingLayout from "./layouts/OnboardingLayout/NewOnboardingLayout";
import IndividualInvestor from "./layouts/IndividualInvestor/IndividualInvestor";
import BorrowerLayout from "./layouts/BorrowerLayout/BorrowerLayout";
import InvestorLayout from "./layouts/InvestorLayout/InvestorLayout";
import DistributorLayout from "./layouts/DistributorLayout/DistributorLayout";
import { useEffect, useState } from "react";
import UnqualifiedInvestor from "./Screens/UnqualifiedInvestor/UnqualifiedInvestor";
import AuthRepLoanAgreement from "./Screens/AuthRepLoanAgreement/AuthRepLoanAgreement";
import SignedDocuments from "./Screens/Merchant/SignedDocuments/SignedDocuments";
import DeclarationAccreditedInvestor from "./Screens/DeclarationAccreditedInvestor/DeclarationAccreditedInvestor";
import InvestorSignedDocuments from "./Screens/Investor/SignedDocuments/SignedDocuments";
import InstitutionalRep from "./Screens/InstitutionalRep/InstitutionalRep";
import SpvKycPending from "./Screens/SpvKycPending/SpvKycPending";
import { client } from "./reactQuery";

const PrivateRoute = ({ children }: any) => {
  const token = store.getState().auth.token;

  return !token || token === "none" ? <Navigate to={"/login"} /> : children;
};

function App() {
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route path="*" Component={() => <Navigate to={"/login"} />} />
              <Route
                path="/registration"
                element={<Navbar content={<Registration />} />}
              />
              <Route
                path="/login/:redirectPath?"
                element={<Navbar content={<Login />} />}
              />
              <Route
                path="/onboarding-guide-investor/:type"
                element={
                  <PrivateRoute>
                    <NewOnboardingLayout
                      step={0}
                      showSteps={false}
                      content={<D2Zguide />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/onboarding-guide-merchant-loan"
                element={
                  <PrivateRoute>
                    <NewOnboardingLayout
                      step={0}
                      showSteps={false}
                      content={<D2ZguideMerchantPage1 />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/onboarding-guide-merchant-repayment"
                element={
                  <PrivateRoute>
                    <NewOnboardingLayout
                      step={0}
                      showSteps={false}
                      content={<D2ZguideMerchantPage2 />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/password-reset"
                element={<AcknowledgementLayout content={<PasswordReset />} />}
              />
              <Route
                path="/reset-message"
                element={
                  <AcknowledgementLayout content={<PasswordResetMsg />} />
                }
              />
              <Route
                path="/reset-password"
                element={
                  <AcknowledgementLayout
                    content={<NewPassword title="Reset Your Password" />}
                  />
                }
              />
              <Route
                path="/new-password"
                element={
                  <AcknowledgementLayout
                    content={<NewPassword title="Password Creation" />}
                  />
                }
              />
              <Route
                path="/password-changed"
                element={
                  <AcknowledgementLayout content={<PasswordChangedMessage />} />
                }
              />
              <Route path="/otp-verification" element={<OtpVerification />} />
              <Route
                path="/welcome"
                element={
                  <PrivateRoute>
                    <WelcomePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/welcome-individual-investor"
                element={
                  <PrivateRoute>
                    <WelcomeIndividual />
                  </PrivateRoute>
                }
              />
              <Route
                path="/biz-profile"
                element={
                  <PrivateRoute>
                    <BizProfile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ind-biz-profile"
                element={
                  <PrivateRoute>
                    <IndividualInvestor
                      step={1}
                      showSteps={true}
                      content={<BizProfileInd />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/representative-details"
                element={
                  <PrivateRoute>
                    <RepresentativeDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/institutional-rep"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={2}
                      showSteps={true}
                      content={<InstitutionalRep />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bank-details"
                element={
                  <PrivateRoute>
                    <BankDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bank-details-ind"
                element={
                  <PrivateRoute>
                    <IndividualInvestor
                      step={2}
                      showSteps={true}
                      content={<BankDetailsInd />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/doc-upload"
                element={
                  <PrivateRoute>
                    <InvestorDocsUpload />
                  </PrivateRoute>
                }
              />
              <Route
                path="/shareholder-did-setup"
                element={
                  <PrivateRoute>
                    <ShareholderDIDSetup />
                  </PrivateRoute>
                }
              />
              <Route
                path="/director-did-setup"
                element={
                  <PrivateRoute>
                    <DirectorDIDSetup />
                  </PrivateRoute>
                }
              />
              <Route
                path="/auth-rep-did-setup"
                element={
                  <PrivateRoute>
                    <AuthRepDIDSetup />
                  </PrivateRoute>
                }
              />
              <Route
                path="/director-board-resolution-spv"
                element={
                  <PrivateRoute>
                    <NewOnboardingLayout
                      step={0}
                      showSteps={false}
                      content={<DirectorSPVBoardResolution />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/director-board-resolution"
                element={
                  <PrivateRoute>
                    <NewOnboardingLayout
                      step={0}
                      showSteps={false}
                      content={<DirectorBoardResolution />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bmca-form"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      content={<BMCASigning />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/thanks-page"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      stepContainerTop="0"
                      content={<ThanksPage />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/spv-kyc-pending"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      stepContainerTop="0"
                      content={<SpvKycPending />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/merchant-complete-onboarding"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      stepContainerTop="0"
                      content={<MerchantCompleteOnboarding />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/registration-completed"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      stepContainerTop="0"
                      content={<RegistrationCompleted />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/funding-issuing-spv-onboard-completed"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      stepContainerTop="0"
                      content={<FundingIssuingSpvCompleteOnboard />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pending-spv-board-resolution"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      stepContainerTop="0"
                      content={<ShareholderSPVBoardResolutionPending />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pending-shareholder-onboard"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      stepContainerTop="0"
                      content={<PendingShareholderOnboard />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/identify-me"
                element={
                  <PrivateRoute>
                    <IdentifyMe />
                  </PrivateRoute>
                }
              />
              <Route
                path="/investor-dashboard/:subpath"
                element={
                  <PrivateRoute>
                    <InvestorLayout content={<InvestorDashboardScreen />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/merchant-dashboard/Overall"
                element={
                  <PrivateRoute>
                    <BorrowerLayout content={<BorrowerDashboard />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/merchant-dashboard/Repayment"
                element={
                  <PrivateRoute>
                    <BorrowerLayout content={<Repayment />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/signed-documents"
                element={
                  <PrivateRoute>
                    <BorrowerLayout content={<SignedDocuments />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/investor-signed-documents"
                element={
                  <PrivateRoute>
                    <InvestorLayout content={<InvestorSignedDocuments />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/welcome-borrower"
                element={
                  <PrivateRoute>
                    <WelcomeBorrowerPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bor-biz-profile"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={1}
                      totalSteps={4}
                      showSteps={true}
                      content={<BorrowerBizProfile />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bor-rep"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={2}
                      totalSteps={4}
                      showSteps={true}
                      content={<BorrowerRep />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bor-bank"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={3}
                      totalSteps={4}
                      showSteps={true}
                      content={<BorrowerBank />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bor-doc"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={4}
                      totalSteps={4}
                      showSteps={true}
                      content={<BorrowerDocsUpload />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/doc-upload-ind"
                element={
                  <PrivateRoute>
                    <IndividualInvestor
                      step={3}
                      showSteps={true}
                      content={<IndInvestorDoc />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/declaration-ai"
                element={
                  <PrivateRoute>
                    <IndividualInvestor
                      step={4}
                      showSteps={false}
                      content={<DeclarationAccreditedInvestor />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/borrower-identify-me"
                element={
                  <PrivateRoute>
                    <BorrowerIdentifyMe />
                  </PrivateRoute>
                }
              />
              <Route
                path="/setup-spv"
                element={
                  <PrivateRoute>
                    <SetupSpvPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pending-spv"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      stepContainerTop="0"
                      content={<PendingSPV />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loanAgreement"
                element={
                  <PrivateRoute>
                    <LoanAgreement />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loanApplication"
                element={
                  <PrivateRoute>
                    <BorrowerLayout content={<LoanApplication />} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/deposit"
                element={
                  <PrivateRoute>
                    <Deposit />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loanform"
                element={
                  <PrivateRoute>
                    <LoanForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/welcome-trustee"
                element={
                  <PrivateRoute>
                    <WelcomeIssuingSPV />
                  </PrivateRoute>
                }
              />
              <Route
                path="/issuing-spv-biz-profile"
                element={
                  <PrivateRoute>
                    <DistributorLayout
                      step={1}
                      showSteps={true}
                      content={<IssuingSPVBizProfile />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/issuing-spv-bank-details"
                element={
                  <PrivateRoute>
                    <DistributorLayout
                      step={2}
                      showSteps={true}
                      content={<IssuingSPVBank />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ltvsku"
                element={
                  <PrivateRoute>
                    <LTVLoanForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loansku"
                element={
                  <PrivateRoute>
                    <LoanAppSku />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sku-loan-application/:tabValue"
                element={
                  <PrivateRoute>
                    <BorrowerLayout content={<SkuLoanApplication />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loan-signing"
                element={
                  <PrivateRoute>
                    <LoanSigning />
                  </PrivateRoute>
                }
              />

              {/* // Disabled, not needed for MVP, needed for P1 */}
              {/* <Route path="/upload-grn" element={<UploadGRN />} /> */}

              <Route
                path="/upload-invoice"
                element={
                  <PrivateRoute>
                    <UploadInvoice />
                  </PrivateRoute>
                }
              />
              <Route
                path="/note-offer-room"
                element={
                  <PrivateRoute>
                    <InvestorLayout content={<StructureNote />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/subscribed-invested-notes"
                element={
                  <PrivateRoute>
                    <InvestorLayout content={<ConfirmNote />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/unqualified-investor"
                element={
                  <PrivateRoute>
                    <OnboardingLayout
                      step={0}
                      showSteps={false}
                      stepContainerTop="0"
                      content={<UnqualifiedInvestor />}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loan-agreement"
                element={
                  <PrivateRoute>
                    <BorrowerLayout content={<AuthRepLoanAgreement />} />
                  </PrivateRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
