import { FC, useState } from "react";
import {
  Button,
  InputGroup,
  InputSelect,
  PhoneInputGroup,
} from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import {
  setCompanyRedux,
  setRegNumberRedux,
  setLegalStructureRedux,
  setTypeOfInstituteRedux,
  setAddressRedux,
  setPostalRedux,
  setTurnOverRedux,
  setWebsiteRedux,
  setLegalStructureOthersRedux,
  setIndustryOthersRedux,
  setCountryOfIncorpRedux,
  setCountryOfDomicileRedux,
  setPhoneNumber,
} from "../../features/merchant/biz";
import {
  CountryCode,
  countries,
  countryCodes,
} from "@funded-here-interface/common/src/constant/country";
import "react-toastify/dist/ReactToastify.css";
import { NO_REGEX } from "@funded-here-interface/common";
import { Industry, LegalStructure } from "../../constants/enums";
import { industry, legalStructures } from "../../constants/dropdownValues";

const BorrowerBizProfile: FC = () => {
  const Navigate = useNavigate();

  const {
    company: comp,
    regNumber: regno,
    legalStructure,
    legalStructureOthers: legalStructureOthersRedux,
    industryOthers: industryOthersRedux,
    typeOfInstitute,
    countryOfDomicile: countryOfDomicileRedux,
    countryOfIncorp: countryOfIncorpRedux,
    address: addressRedux,
    postal: postalRedux,
    website: companysiteRedux,
    turnover: turnOverRedux,
    phoneNumber: phoneNumberRedux,
  } = useSelector((state: RootState) => state.borBiz);

  const [company, setCompany] = useState(comp || "");
  const [isOtherLegalStrcuture, setIsOtherLegalStructure] = useState(
    legalStructure === LegalStructure.OTHERS
  );
  const [isOtherIndustry, setIsOtherIndustry] = useState(
    typeOfInstitute === Industry.OTHERS
  );
  const [registration_number, setRegNumber] = useState(regno || "");
  const [legal_structure, setLegStr] = useState(legalStructure || "");
  const [legalStructureOthers, setLegalStructurOthers] = useState(
    legalStructureOthersRedux || ""
  );
  const [institution_type, setInstitute] = useState(typeOfInstitute || "");
  const [industryOthers, setIndustryOthers] = useState(
    industryOthersRedux || ""
  );
  const [countryOfIncorp, setCountryCountryOfIncorp] = useState(
    countryOfIncorpRedux || ""
  );
  const [countryOfDomicile, setCountryCountryOfDomicile] = useState(
    countryOfDomicileRedux || ""
  );
  const [address, setAddress] = useState(addressRedux || "");
  const [postalcode, setPostalCode] = useState(postalRedux || "");
  const [companysite, setcompanysite] = useState(companysiteRedux || "");
  const [turnover, setTurnover] = useState(turnOverRedux || "");
  const [phoneNumber, setPhone] = useState(phoneNumberRedux || "");

  const [error, setError] = useState({
    company: "",
    registration_number: "",
    legal_structure: "",
    legal_structure_others: "",
    institution_type: "",
    industry_others: "",
    countryOfIncorp: "",
    countryOfDomicile: "",
    address: "",
    phone_number: "",
    postalcode: "",
    companysite: "",
    turnover: "",
  });
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const customError = { ...error };
    let valid = true;
    if (!company) {
      valid = false;
      customError.company = "Company Name is required";
    }
    if (!registration_number) {
      valid = false;
      customError.registration_number = "Registration Number is required";
    }
    if (!legal_structure) {
      valid = false;
      customError.legal_structure = "Legal Structure is required";
    }
    if (isOtherLegalStrcuture && !legalStructureOthers) {
      valid = false;
      customError.legal_structure_others =
        "Legal Strcuture Specification is required";
    }
    if (!institution_type) {
      valid = false;
      customError.institution_type = "Industry is required";
    }
    if (isOtherIndustry && !industryOthers) {
      valid = false;
      customError.industry_others = "Industry Specification is required";
    }
    if (!countryOfIncorp) {
      valid = false;
      customError.countryOfIncorp = "Country of Incorporation is required";
    }
    if (!countryOfDomicile) {
      valid = false;
      customError.countryOfDomicile = "Country of Domicile is required";
    }
    if (!address) {
      valid = false;
      customError.address = "Address is required";
    }
    if (!postalcode) {
      valid = false;
      customError.postalcode = "Postal Code is required";
    }
    if (!companysite) {
      valid = false;
      customError.companysite = "Company Website is required";
    }

    if (!turnover) {
      valid = false;
      customError.turnover = "Financial Turnover is required";
    }

    if (!phoneNumber) {
      valid = false;
      customError.phone_number = "Phone Number is required";
    }

    if (valid) {
      dispatch(setCompanyRedux({ company }));
      dispatch(setRegNumberRedux({ regNumber: registration_number }));
      dispatch(setLegalStructureRedux({ legalStructure: legal_structure }));
      dispatch(
        setLegalStructureOthersRedux({
          legalStructureOthers,
        })
      );
      dispatch(
        setIndustryOthersRedux({
          industryOthers,
        })
      );
      dispatch(setCountryOfIncorpRedux({ countryOfIncorp }));
      dispatch(setCountryOfDomicileRedux({ countryOfDomicile }));
      dispatch(setAddressRedux({ address }));
      dispatch(setTypeOfInstituteRedux({ typeOfInstitute: institution_type }));
      dispatch(setPostalRedux({ postal: postalcode }));
      dispatch(setWebsiteRedux({ website: companysite }));
      dispatch(setTurnOverRedux({ turnover }));
      dispatch(setPhoneNumber({ phoneNumber }));

      Navigate("/bor-rep");
    } else {
      setError(customError);
    }
  };

  const onLegalStrcutureChange = (value: LegalStructure) => {
    if (value === LegalStructure.OTHERS) {
      setIsOtherLegalStructure(true);
    } else {
      setIsOtherLegalStructure(false);
      setLegalStructurOthers("");
    }

    setLegStr(value);
    error.legal_structure = "";
  };

  const onIndustryChange = (value: Industry) => {
    if (value === Industry.OTHERS) {
      setIsOtherIndustry(true);
    } else {
      setIsOtherIndustry(false);
      setIndustryOthers("");
    }

    setInstitute(value);
    error.institution_type = "";
  };
  const [countryCode, setCountryCode] = useState<CountryCode | undefined>();

  const handleCountryOfDomicileChange = (value: string) => {
    error.countryOfDomicile = "";
    setCountryCountryOfDomicile(value);
    const code = countryCodes[value as keyof typeof countryCodes];
    setCountryCode(code as CountryCode);
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <InputGroup
        isErrored={error.company}
        onChange={(value: any) => {
          error.company = "";
          setCompany(value);
        }}
        labelText="Company Name"
        id="company"
        value={company}
      />
      <InputGroup
        isErrored={error.registration_number}
        onChange={(value: string) => {
          error.registration_number = "";
          setRegNumber(value);
        }}
        labelText="Registration Number"
        id="registration_number"
        value={registration_number}
      />
      <InputSelect
        isErrored={error.legal_structure}
        onChange={onLegalStrcutureChange}
        labelText="Legal Structure"
        id="legal_structure"
        value={legal_structure}
        data={legalStructures}
      />
      {isOtherLegalStrcuture && (
        <InputGroup
          isErrored={error.legal_structure_others}
          onChange={(value: string) => {
            error.legal_structure_others = "";
            setLegalStructurOthers(value);
          }}
          labelText="Please Specify Legal Structure"
          id="legal_structure_others"
          value={legalStructureOthers}
        />
      )}
      <InputSelect
        isErrored={error.institution_type}
        onChange={onIndustryChange}
        labelText="Industry"
        value={institution_type}
        id="institution_type"
        data={industry}
      />
      {isOtherIndustry && (
        <InputGroup
          isErrored={error.industry_others}
          onChange={(value: string) => {
            error.industry_others = "";
            setIndustryOthers(value);
          }}
          labelText="Please Specify Industry"
          id="industry_others"
          value={industryOthers}
        />
      )}
      <InputSelect
        isErrored={error?.countryOfIncorp}
        onChange={(value: string) => {
          error.countryOfIncorp = "";
          setCountryCountryOfIncorp(value);
        }}
        labelText="Country of Incorporation"
        id="country"
        value={countryOfIncorp}
        data={countries}
      />
      <InputSelect
        isErrored={error?.countryOfDomicile}
        onChange={handleCountryOfDomicileChange}
        labelText="Country of Domicile"
        id="countryOfDomicile"
        value={countryOfDomicile}
        data={countries}
      />
      <InputGroup
        isErrored={error.address}
        onChange={(value: any) => {
          error.address = "";
          setAddress(value);
        }}
        labelText="Address"
        id="address"
        value={address}
      />
      <InputGroup
        isErrored={error.postalcode}
        onChange={(value: any) => {
          error.postalcode = "";
          setPostalCode(value.replace(NO_REGEX, ""));
        }}
        labelText="Postal Code"
        id="postalcode"
        value={postalcode}
      />
      <InputGroup
        isErrored={error.companysite}
        onChange={(value: any) => {
          error.companysite = "";
          setcompanysite(value);
        }}
        labelText="Company website / Online Marketplace Link"
        id="companywebsite"
        value={companysite}
      />
      <InputGroup
        isErrored={error.turnover}
        onChange={(value: any) => {
          error.turnover = "";
          setTurnover(value.replace(NO_REGEX, ""));
        }}
        labelText="Previous year closing inventory amount (as per Balance sheet)"
        id="turnover"
        value={turnover}
      />
      <div style={{ marginBottom: "20px" }}>
        <PhoneInputGroup
          isErrored={error.phone_number}
          value={phoneNumber}
          country={countryCode}
          onChange={(value: string) => {
            error.phone_number = "";
            setPhone(value.replace(NO_REGEX, ""));
          }}
        />
      </div>
      <Button
        onClick={handleSubmit}
        textColor="#FFFF"
        backgroundColor="#338F86"
        children="Next"
        width="100%"
      />
    </div>
  );
};

export default BorrowerBizProfile;
