import { Text } from "@mantine/core";
import guide from "../../assets/guidebenefits.svg";
import works from "../../assets/howitwork.svg";
import { Button } from "@funded-here-interface/common";
import { useStyles } from "./D2Zguidecss";
import { useNavigate, useParams } from "react-router-dom";
import { FC } from "react";
import { EntityTypeEnum } from "@funded-here-interface/common/src/constant/enum";

const D2Zguide: FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  let { type } = useParams();

  const handleOnClick = () => {
    if (type === EntityTypeEnum.INDIVIDUAL) {
      navigate("/welcome-individual-investor");

      return;
    }

    if (type === EntityTypeEnum.INSTITUTION) {
      navigate("/welcome");

      return;
    }
  };

  return (
    <div>
      <div className={classes.centerText}>
        <Text className={classes.headerText}>d2z Funding</Text>
        <Text>Welcome to FundedHere!</Text>
        <Text>
          {" "}
          We’d like to introduce to a first of its kind financing solution
        </Text>
        <hr className={classes.separator} />
      </div>
      <div className={classes.centerText} style={{ marginTop: "50px" }}>
        <Text className={classes.benefitsAndWorksText}>Benefits</Text>
        <div>
          <img src={guide} alt="" />
        </div>
        <div
          style={{
            background: "#F9F9F9",
            width: "100%",
            paddingTop: "20px",
            paddingBottom: "25px",
          }}
        >
          <Text className={classes.benefitsAndWorksText}>How it works</Text>

          <img src={works} style={{ width: "auto", marginTop: 20 }} alt="" />
          <div className={classes.buttonContainer}>
            <Button
              textColor="white"
              children="Start Onboarding!"
              onClick={handleOnClick}
              backgroundColor="#F6BD21"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default D2Zguide;
