import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  container: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    width: "814.0024239706642px",
    height: "60px",
    top: "25px",
    left: "200px",
  },
  horizontalSteps: {
    display: "flex",
    flexDirection: "row",
  },
  horizontalLine: {
    position: "absolute",
    width: "600px",
    top: "106px",
    marginLeft: "100px",
    border: "1px",
  },
  text: {
    position: "absolute",
    width: "79px",
    height: "2px",
    top: "60px",
    //left: '250.0078125px',
    fontFamily: "SF Pro Text",
    fontSize: "13.5px",
    fontWeight: 700,
    lineHeight: "14px",
    textAlign: "center",
    color: "#151515",
  },
  activeText: {
    fontFamily: "SF Pro Display",
  },
  stepGroup: {
    marginLeft: "105px",
    display: "flex",
    flexDirection: "row",
  },
  imgGroup: {
    display: "flex",
    flexDirection: "column",
    width: "24px",
    height: "24px",
    top: "51px",
    left: "285px",
    padding: "15px",
    alignItems: "center",
    justifyContent: "center",
  },
  stepText: {
    position: "absolute",
    width: "7.199999809265137px",
    height: "16.799999237060547px",

    textAlign: "center",

    fontFamily: " DM Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "17.074px",
  },
  arrowContainer: {
    margin: "55px -25px 1px 40px",
    alignSelf: "center",
    width: "20px",
    height: "20px",
  },
}));
