import { FC, useEffect, useState } from "react";
import {
  Button,
  InputGroup,
  InputSelect,
  NO_REGEX,
  PhoneInputGroup,
  color,
} from "@funded-here-interface/common";

import { useNavigate } from "react-router-dom";
import { useStyles } from "../../styles/BizProfileCss";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import {
  setAddressRedux,
  setCountryOfResidenceRedux,
  setEXPRedux,
  setIndustryOthersRedux,
  setIndustryRedux,
  setNationalityRedux,
  setOccupationeRedux,
  setPhoneRedux,
  setPostalCodeRedux,
  setInvestmentAmountRedux,
} from "../../features/indinvestor/profSlice";
import { Industry } from "../../constants/enums";
import { industry as industryDropdownValues } from "../../constants/dropdownValues";
import {
  CountryCode,
  countries,
  countryCodes,
} from "@funded-here-interface/common/src/constant/country";
import { useDisclosure } from "@mantine/hooks";
import { toast } from "react-toastify";
import IndInvestorDeclarationModal from "./Components/IndInvestorDeclarationModal/IndInvestorDeclarationModal";

const BizProfileInd: FC = () => {
  const { classes } = useStyles();
  const Navigate = useNavigate();

  const {
    nationality: nationalityRedux,
    address: addressRedux,
    countryOfResidence: countryOfResidenceRedux,
    industry: ind,
    industryOthers: industryOthersRedux,
    phoneNumber: pn,
    postalCode: postalRedux,
    exp: expR,
    occupation: occ,
    investmentAmount: investmentAmountRedux,
    investorDeclarationQualified,
  } = useSelector((state: RootState) => state.prof);

  const [opened, { close }] = useDisclosure(!investorDeclarationQualified);
  const [nationality, setNationality] = useState(nationalityRedux || "");
  const [countryOfResidence, setCountryOfResidence] = useState(
    countryOfResidenceRedux || ""
  );
  const [postalCode, setPostalCode] = useState(postalRedux || "");
  const [address, setAddress] = useState(addressRedux || "");
  const [industry, setIndustry] = useState(ind || "");
  const [occupation, setOccupation] = useState(occ || "");
  const [exp, setExp] = useState(expR || "");
  const [phoneNumber, setPhone] = useState(pn || "");
  const [industryOthers, setIndustryOthers] = useState(
    industryOthersRedux || ""
  );
  const [isOtherIndustry, setIsOtherIndustry] = useState(
    ind === Industry.OTHERS
  );
  const [investmentAmount, setInvestmentAmount] = useState(
    investmentAmountRedux || 0
  );
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    industry: "",
    industryOthers: "",
    occupation: "",
    exp: "",
    nationality: "",
    countryOfResidence: "",
    phone_number: "",
    address: "",
    postalCode: "",
    investmentAmount: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (investorDeclarationQualified === false) {
      Navigate("/unqualified-investor");
    }
  }, [investorDeclarationQualified]);

  const handleSubmit = () => {
    const customError = { ...error };
    let valid = true;

    if (!industry) {
      valid = false;
      customError.industry = "Industry is required";
    }
    if (isOtherIndustry && !industryOthers) {
      valid = false;
      customError.industryOthers = "Industry Specification is required";
    }
    if (!occupation) {
      valid = false;
      customError.occupation = "Occupation is required";
    }
    if (!exp) {
      valid = false;
      customError.exp = "Experience is required";
    }
    if (!phoneNumber) {
      valid = false;
      customError.phone_number = "Phone Number is required";
    }
    if (!nationality) {
      valid = false;
      customError.nationality = "Nationality is required";
    }
    if (!countryOfResidence) {
      valid = false;
      customError.countryOfResidence = "Country of Residence is required";
    }
    if (!address) {
      valid = false;
      customError.address = "Address is required";
    }
    if (!postalCode) {
      valid = false;
      customError.postalCode = "Postal Code is required";
    }
    if (!investmentAmount) {
      valid = false;
      customError.investmentAmount = "Planned Investment Amount is required";
    }

    if (valid) {
      dispatch(setIndustryRedux({ industry }));
      dispatch(setIndustryOthersRedux({ industryOthers }));
      dispatch(setEXPRedux({ exp }));
      dispatch(setOccupationeRedux({ occupation }));
      dispatch(setPhoneRedux({ phoneNumber }));
      dispatch(setNationalityRedux({ nationality }));
      dispatch(setCountryOfResidenceRedux({ countryOfResidence }));
      dispatch(setAddressRedux({ address }));
      dispatch(setPostalCodeRedux({ postalCode }));
      dispatch(
        setInvestmentAmountRedux({
          investmentAmount,
        })
      );

      Navigate("/bank-details-ind");
    } else {
      setError(customError);
    }
  };

  const onIndustryChange = (value: Industry) => {
    if (value === Industry.OTHERS) {
      setIsOtherIndustry(true);
    } else {
      setIsOtherIndustry(false);
      setIndustryOthers("");
    }

    setIndustry(value);
    error.industry = "";
  };

  const [countryCode, setCountryCode] = useState<CountryCode | undefined>();

  const handleCountryChange = (value: string) => {
    error.countryOfResidence = "";
    setCountryOfResidence(value);
    const code = countryCodes[value as keyof typeof countryCodes];
    setCountryCode(code as CountryCode);
  };

  return (
    <>
      <IndInvestorDeclarationModal
        onError={(value: string) => {
          toast.error(value);
        }}
        opened={opened}
        close={close}
      />
      <div style={{ marginTop: "70px" }}>
        <InputSelect
          isErrored={error.industry}
          onChange={onIndustryChange}
          labelText="Industry"
          value={industry}
          id="institution_type"
          data={industryDropdownValues}
        />
        {isOtherIndustry && (
          <InputGroup
            isErrored={error.industryOthers}
            onChange={(value: string) => {
              error.industryOthers = "";
              setIndustryOthers(value);
            }}
            labelText="Please Specify Industry"
            id="industry_others"
            value={industryOthers}
          />
        )}
        <InputGroup
          isErrored={error?.occupation}
          onChange={(value: string) => {
            error.occupation = "";
            setOccupation(value);
          }}
          labelText="Specify Occupation"
          id="name"
          value={occupation}
        />
        <InputGroup
          isErrored={error?.exp}
          onChange={(value: string) => {
            error.exp = "";
            setExp(value.replace(NO_REGEX, ""));
          }}
          labelText="No of years in this occupation"
          id="exp"
          value={exp}
        />
        <InputSelect
          isErrored={error.nationality}
          onChange={(value: string) => {
            error.nationality = "";
            setNationality(value);
          }}
          labelText="Nationality"
          id="nationality"
          value={nationality}
          data={countries}
        />
        <InputSelect
          isErrored={error.countryOfResidence}
          onChange={handleCountryChange}
          labelText="Country of residence"
          id="countryOfResidence"
          value={countryOfResidence}
          data={countries}
        />
        <InputGroup
          isErrored={error.address}
          onChange={(value: string) => {
            error.address = "";
            setAddress(value);
          }}
          labelText="Address"
          id="address"
          value={address}
        />
        <InputGroup
          isErrored={error.postalCode}
          onChange={(value: string) => {
            error.postalCode = "";
            setPostalCode(value.replace(NO_REGEX, ""));
          }}
          labelText="Postal Code"
          id="postalcode"
          value={postalCode}
        />
        <InputGroup
          isErrored={error.investmentAmount}
          onChange={(value: string) => {
            error.investmentAmount = "";
            setInvestmentAmount(parseInt(value.replace(NO_REGEX, "")));
          }}
          labelText="Planned Investment Amount"
          id="investmentAmount"
          value={`${investmentAmount || 0}`}
        />
        <div className={classes.phoneNumberWrapper}>
          <PhoneInputGroup
            isErrored={error?.phone_number}
            value={phoneNumber}
            country={countryCode}
            onChange={(value: string) => {
              error.phone_number = "";
              setPhone(value.replace(NO_REGEX, ""));
            }}
          />
        </div>
        <Button
          onClick={handleSubmit}
          textColor={color.WHITE}
          backgroundColor={color.FHGREEN}
          children="Next"
          width="100%"
        />
      </div>
    </>
  );
};

export default BizProfileInd;
