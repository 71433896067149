import { useMutation } from "react-query";
import getInvestorSubscribedNotes from "../services/getInvestorSubscribedNotes";

const useInvestorSubscribedNotes = () => {
  return useMutation(
    ["use-investor-subscribed-notes"],
    getInvestorSubscribedNotes
  );
};

export default useInvestorSubscribedNotes;
