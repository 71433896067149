import { color } from "@funded-here-interface/common";
import { MantineTheme, createStyles } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "24px",
  },
  menu: {
    display: "flex",
    gap: "25px",
  },
  inActiveTab: {
    fontWeight: 400,
    fontSize: "16px",
    cursor: "pointer",
  },

  activeTab: {
    fontWeight: 700,
    fontSize: "16px",
    cursor: "pointer",
  },
  innerDiv: {
    display: "flex",
    flexDirection: "column",
  },
  colorDiv: {
    height: "4px",
    width: "30px",
    backgroundColor: color.FHGREEN,
    marginTop: "3px",
  },
  loanApplicationContainer: {
    width: "calc(100vw - 270px - 96px)",
  },
}));
