import { tab } from "@testing-library/user-event/dist/tab";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PortfolioReturnAnalysis from "./PortfolioReturnAnalysis";
import NotesReturnAnalysis from "./NotesReturnAnalysis";

const ReturnAnalysis = () => {
  const state = {
    series: [
        {
            name: "Effective ROR(%)",
            data: ['5.11%', '7.83%', '8.25%', '3.49%', '9.81%', '1.44%',],
        },
    ],
    options: {
        chart: {
            type: 'area',
            // height: 500,
            zoom: {
                enabled: false
            }
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value + "%";
                },
            },
        },

        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            lineCap: 'butt',
            width: 2,
            colors: ['#f0e199'],
        },
        fill: {
            colors: '#f9f3d6',
            opacity: 0.9,
            type: 'solid',
            gradient: {
                shade: 'dark',
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100],
                colorStops: []
            },
        },

        markers: {
            size: 3,
            colors: '#f0e199',
            strokeColors: '#f0e199',
            hover: {
                size: 3,
            }
        },

        title: {
            text: 'SN Return Analysis',
            align: 'left'
        },
        labels: ['Jun 2023', 'Jul 2023', 'Aug 2023', 'Sep 2023', 'Oct 2023', 'Nov 2023'],
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
        }
    },


};


  return (
    <>
      <div className="returnAnDiv">
        <Tabs>
          <TabList>
            <Tab>
              Notes Return Analysis
              <span class="ReturnAnTooltip">Click here to follow link</span>
            </Tab>
            <Tab>
              Portfolio Return Analysis
              <span class="ReturnAnTooltip">Click here to follow link</span>
            </Tab>
          </TabList>

          <TabPanel>
            <NotesReturnAnalysis />
          </TabPanel>
          <TabPanel>
            <PortfolioReturnAnalysis />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default ReturnAnalysis;
