import { createStyles } from "@mantine/styles";

export const useStyle = createStyles(() => ({
  stepContent: {
    width: "852px",
    height: "290px",
    top: "190px",
    left: "245px",
    gap: "5px",
    backgroundColor: "#FFF",
  },
  headinfo: {
    marginTop: "100px",
    marginLeft: "50px",
    width: "100vw",
    fontSize: "14px",
    fontWeight: 400,
  },

  topDiv: {
    marginTop: "50px",
  },
  buttonDiv2: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
    marginTop: "80px",
    gap: "40px",
  },
  buttonWrapper: {
    paddingTop: "10px",
  },
  upload: {
    display: "flex",
    gap: "10px",
    marginTop: "20px",
    alignItems: "center",
    marginBottom: "10px",
  },
}));
