import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import authenticateDID from "../services/authenticateDID";
import { useSelector } from "react-redux";
import { RootState } from "../store";

interface AuthenticateDIDProps {
  scanData: string;
}

export const useAuthenticateDID = ({ scanData }: AuthenticateDIDProps) => {
  const [ledgerAddress, setLedgerAddress] = useState("");
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (!scanData) return;

    const result = JSON.parse(scanData);
    const ledgerAddress = result.qrRes
      .split("LedgerAddress= ")[1]
      .split(",")[0];

    setLedgerAddress(ledgerAddress);
  }, [scanData]);

  // authenticateDID
  const { isSuccess, isError, data } = useQuery(
    ["ledgerAddress"],
    () => authenticateDID({ ledgerAddress, token }),
    {
      // The query will not execute until the ledgerAddress exists
      enabled: !!ledgerAddress,
    }
  );

  // disable multiple calls authenticateDID
  useEffect(() => {
    if (isSuccess || isError) {
      setLedgerAddress("");
    }
  }, [isSuccess, isError, data]);

  return isSuccess ? data : false;
};
