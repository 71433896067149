import { createStyles, Text, Group, MantineTheme, Select } from "@mantine/core";
import { CSS } from "@mantine/styles/lib/tss/types";
import { FC } from "react";

type Props = {
  labelText: string;
  id?: string;
  placeHolder?: string;
  onChange?: CallableFunction;
  style?: CSS;
  isErrored?: string;
  data: string[];
  value: string | null;
  searchable?: boolean;
};

const InputSelect: FC<Props> = ({
  labelText,
  id,
  placeHolder,
  onChange,
  style,
  isErrored,
  data,
  value,
  searchable = true,
}) => {
  const useStyles = createStyles((theme: MantineTheme) => ({
    mainContainer: {},
    text: {
      color: "#3C4257",
      textAlign: "left",
    },
    inputField: {
      padding: "2px",
      height: "45px",
      width: "100%",
      marginBottom: "10px",

      //border:props.isErrored?'1px red solid':''
    },
    errorMessage: {
      color: "red",
      position: "relative",
      fontSize: "14px",
      top: "-10px",
      left: "0px",
    },
  }));
  const { classes } = useStyles();

  return (
    <div className={classes.mainContainer}>
      <Group position="left" mb={5}>
        <Text className={classes.text} component="label" htmlFor={id} size="sm">
          {labelText}
        </Text>
      </Group>
      <Select
        className={classes.inputField}
        placeholder={placeHolder}
        size="md"
        searchable={searchable}
        value={value}
        data={data}
        filter={(value, item) =>
          searchable
            ? (item.label as string)
                .toLowerCase()
                .startsWith(value.toLowerCase())
            : true
        }
        onChange={(e: string) => {
          onChange ? onChange(e) : "";
        }}
      />

      {isErrored && <div className={classes.errorMessage}>{isErrored}</div>}
    </div>
  );
};

export default InputSelect;
