import React, { useEffect, useRef, useState } from "react";
import { useStyle } from "./DeclarationAccreditedInvestor.styles";
import { Text } from "@mantine/core";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDeclarationOfAISign } from "../../hooks/useDocuSign";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import { COLORS } from "../../constants/colors";
import { DocuSignEvent, DocuSignEventHandler } from "../../constants/docuSign";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import useSignedDeclarationOfAI from "../../hooks/useSignedDeclarationOfAI";
import { useLocation, useNavigate } from "react-router-dom";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import useCompleteOnboarding from "../../hooks/useCompleteOnboarding";
import { EntityTypeEnum } from "@funded-here-interface/common/src/constant/enum";
import { ENV } from "@funded-here-interface/common/src/constant/constant";

const DeclarationAccreditedInvestor = () => {
  const location = useLocation();
  const skipDidVerify = location.state?.skipDidVerify;
  const { classes } = useStyle();
  const declarationOfAIMutation = useDeclarationOfAISign();
  const { token, entityType } = useSelector((state: RootState) => state.auth);
  const useSignedDeclarationOfAIMutation = useSignedDeclarationOfAI();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    skipDidVerify ?? false
  );
  const navigate = useNavigate();
  const useCompleteOnboardingMutation = useCompleteOnboarding();

  const declarationAIRef = useRef(null);

  function loadDocuSignScript() {
    let source = "https://js-d.docusign.com/bundle.js";
    if (ENV === "production") {
      source = "https://js.docusign.com/bundle.js";
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = source;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
      getDeclarationOfAI();
    }
  };

  useEffect(() => {
    loadDocuSignScript();
    if (skipDidVerify) {
      getDeclarationOfAI();
    }
  }, []);

  const getDeclarationOfAI = () => {
    toast.info("Document is loading");
    try {
      declarationOfAIMutation.mutate(
        {
          token,
          webDomain: WEB_DOMAIN,
          redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
        },
        {
          onSuccess: async (data) => {
            const { url, docuSignEnvelopeId } = data;

            window.DocuSign.loadDocuSign(docuSignEnvelopeId)
              .then((docusign: any) => {
                const signing = docusign.signing({
                  url: url,
                  displayFormat: "focused",
                  style: {
                    branding: {
                      primaryButton: {
                        backgroundColor: COLORS.primaryButtonBackground,
                        color: COLORS.primaryButtonText,
                      },
                    },
                    signingNavigationButton: {
                      finishText: "Finish Signing",
                      position: "bottom-center",
                    },
                  },
                });
                signing.on(DocuSignEventHandler.READY, (event: any) => {
                  toast.info("Sign the Document using E-signature");
                });
                signing.on(
                  DocuSignEventHandler.SESSION_END,
                  async (event: any) => {
                    if (
                      event.sessionEndType === DocuSignEvent.SIGNING_COMPLETE
                    ) {
                      await handleNext();
                    } else {
                      toast.error("Signing incomplete, please try again.");
                    }
                  }
                );
                if (declarationAIRef.current) {
                  signing.mount(declarationAIRef.current);
                } else {
                  toast.error("declaration container not found");
                }
              })
              .catch((e: any) => {
                const message = getApiResponseErrorMsg(e);

                toast.error(message);
              });
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    } catch (ex) {
      toast.error("Error initializing DocuSign");
    }
  };

  const handleNext = () => {
    useSignedDeclarationOfAIMutation.mutate(
      { token },
      {
        onSuccess: () => {
          if (entityType === EntityTypeEnum.INSTITUTION) {
            useCompleteOnboardingMutation.mutate(
              { token },
              {
                onSuccess: () => {
                  navigate("/registration-completed");
                },
                onError: (e) => {
                  toast.error((e as Error).message);
                },
              }
            );
          } else {
            navigate("/thanks-page");
          }
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  return (
    <>
      {!isAuthenticated && (
        <div className={classes.topVerifyDiv}>
          <VQRVerifyPage
            onAuthenticatedChange={handleAuthenticatedChange}
            onNext={handleOnVerifyMeNext}
          />
        </div>
      )}
      {isAuthenticated && (
        <div className={classes.tempWideDisplay}>
          <div className={classes.topDiv}>
            <p className={classes.headinfo}>Declaration of AI</p>

            <Text onClick={getDeclarationOfAI} className={classes.linkText}>
              (Sign with DocuSign)
            </Text>
          </div>

          <div
            ref={declarationAIRef}
            className={classes.document}
            id="declaration"
          >
            {/* DocuSign will be mounted here */}
          </div>
        </div>
      )}
    </>
  );
};

export default DeclarationAccreditedInvestor;
