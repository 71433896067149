import { useMutation } from "react-query";
import {
  fetchDocuSignData,
  fetchLoanSignData,
  fetchSubscriptionDocumentData,
  fetchSpvBoardResolutionSignData,
  fetchBoardResolutionSignData,
  fetchMasterSkuBorrowerSignData,
  fetchDeclarationOfAISignData,
} from "../services/docusignService";

const useDocuSign = () => {
  return useMutation(["docu-sign"], fetchDocuSignData);
};

const useLoanSign = () => {
  return useMutation(["loan-sign"], fetchLoanSignData);
};

const useSubcriptionDocuSign = () => {
  return useMutation(["subscription-docu-sign"], fetchSubscriptionDocumentData);
};

const useSpvBoardResolutionSign = () => {
  return useMutation(
    ["spv-board-resolution-sign"],
    fetchSpvBoardResolutionSignData
  );
};

const useBoardResolutionSign = () => {
  return useMutation(["board-resolution-sign"], fetchBoardResolutionSignData);
};

const useMasterSkuBorrowerSign = () => {
  return useMutation(
    ["master-sku-borrower-sign"],
    fetchMasterSkuBorrowerSignData
  );
};

const useDeclarationOfAISign = () => {
  return useMutation(["declaration-of-ai-sign"], fetchDeclarationOfAISignData);
};

export {
  useDocuSign,
  useSubcriptionDocuSign,
  useLoanSign,
  useSpvBoardResolutionSign,
  useBoardResolutionSign,
  useMasterSkuBorrowerSign,
  useDeclarationOfAISign,
};
