import { Button, InputGroup, color } from "@funded-here-interface/common";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import { NAME_REGEX } from "@funded-here-interface/common";
import { useStyle } from "./RepresentativeDetails.styles";
import {
  setRepFirstNameRedux,
  setRepLastNameRedux,
  setRepTitleRedux,
} from "../../features/institutionalInvestor/repSlice";
import { Checkbox, MantineProvider } from "@mantine/core";
import AuthRepInfo from "../../components/AuthRepInfo/AuthRepInfo";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";

const RepresentativeDetails: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { userFirstName, userLastName } = useSelector(
    (state: RootState) => state.auth
  );

  const {
    repFirstName: repFirstNameRedux,
    repLastName: repLastNameRedux,
    repTitle: representative_titleRedux,
  } = useSelector((state: RootState) => state.rep);

  const firstRender = !repFirstNameRedux && !repLastNameRedux;
  const wasCheckboxChecked =
    repFirstNameRedux === userFirstName && repLastNameRedux === userLastName;

  const [checked, setChecked] = useState(
    firstRender ? true : wasCheckboxChecked
  );
  const [repFirstName, setRepFirstName] = useState(repFirstNameRedux || "");
  const [repLastName, setRepLastName] = useState(repLastNameRedux || "");
  const [representative_title, setRepresentativeTitle] = useState(
    representative_titleRedux || ""
  );

  const [error, setError] = useState({
    repFirstName: "",
    repLastName: "",
    repTitle: "",
  });

  const handleBack = () => {
    dispatch(setRepFirstNameRedux({ repFirstName: repFirstName.trim() }));
    dispatch(setRepLastNameRedux({ repLastName: repLastName.trim() }));
    dispatch(setRepTitleRedux({ repTitle: representative_title }));

    Navigate("/biz-profile");
  };

  const handleNext = () => {
    const customErr = { ...error };
    let valid = true;

    if (!repFirstName) {
      valid = false;
      customErr.repFirstName = "Representative's First Name is required";
    }
    if (!repLastName) {
      valid = false;
      customErr.repLastName = "Representative's Last Name is required";
    }
    if (!representative_title) {
      valid = false;
      customErr.repTitle = "Representative's Title is required";
    }

    if (valid) {
      dispatch(setRepFirstNameRedux({ repFirstName }));
      dispatch(setRepLastNameRedux({ repLastName }));
      dispatch(setRepTitleRedux({ repTitle: representative_title }));

      Navigate("/bank-details");
    } else {
      setError(customErr);
    }
  };

  useEffect(() => {
    if (checked) {
      setRepFirstName(userFirstName as string);
      setRepLastName(userLastName as string);
    } else {
      setRepFirstName(repFirstName || "");
      setRepLastName(repLastName || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, repFirstName, repLastName]);

  return (
    <OnboardingLayout
      step={2}
      showSteps={true}
      content={
        <div className={classes.container}>
          <AuthRepInfo />
          <div className={classes.inputBox}>
            <MantineProvider theme={{ cursorType: "pointer" }}>
              <Checkbox
                style={{ marginBottom: "20px" }}
                defaultChecked={checked}
                label="I'm the authorized representative"
                onChange={(event) => setChecked(event.currentTarget.checked)}
              />
            </MantineProvider>

            {!checked && (
              <InputGroup
                isErrored={error.repFirstName}
                onChange={(value: string) => {
                  error.repFirstName = "";
                  setRepFirstName(value.replace(NAME_REGEX, ""));
                }}
                labelText="First Name- Authorized Representative"
                id="firstName"
                value={repFirstName}
              />
            )}
            {!checked && (
              <InputGroup
                isErrored={error.repLastName}
                onChange={(value: any) => {
                  error.repLastName = "";
                  setRepLastName(value.replace(NAME_REGEX, ""));
                }}
                labelText="Last Name- Authorized Representative"
                id="lastName"
                value={repLastName}
              />
            )}
            <InputGroup
              isErrored={error.repTitle}
              onChange={(value: string) => {
                error.repTitle = "";
                setRepresentativeTitle(value.replace(NAME_REGEX, ""));
              }}
              labelText="Title of Representative"
              id="representative_title"
              value={representative_title}
            />
            <div className={classes.buttonDiv}>
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={handleBack}
                  backgroundColor={color.WHITE}
                  textColor={color.FHGREEN}
                  border="1px solid #338F86"
                  width="100%"
                >
                  Back
                </Button>
              </div>
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={handleNext}
                  textColor={color.WHITE}
                  backgroundColor={color.FHGREEN}
                  width="100%"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default RepresentativeDetails;
