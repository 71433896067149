import { createSlice } from "@reduxjs/toolkit";

interface DisBusinessState {
  company: string | null;
  regNumber: string | null;
  legalStructure: string | null;
  legalStructureOthers: string | null;
  typeOfInstitute: string | null;
  industryOthers: string | null;
  country: string | null;
  address: string | null;
  postal: string | null;
  userFirstName: string | null;
  userLastName: string | null;
}

const bizDisSlice = createSlice({
  name: "disBiz",
  initialState: {
    company: null,
    regNumber: null,
    legalStructure: null,
    legalStructureOthers: null,
    typeOfInstitute: null,
    industryOthers: null,
    country: null,
    address: null,
    postal: null,
    userFirstName: null,
    userLastName: null,
  } as DisBusinessState,
  reducers: {
    setCompanyRedux: (state, { payload: { company } }) => {
      state.company = company;
    },
    setRegNumberRedux: (state, { payload: { regNumber } }) => {
      state.regNumber = regNumber;
    },
    setLegalStructureRedux: (state, { payload: { legalStructure } }) => {
      state.legalStructure = legalStructure;
    },
    setLegalStructureOthersRedux: (
      state,
      { payload: { legalStructureOthers } }
    ) => {
      state.legalStructureOthers = legalStructureOthers;
    },
    setIndustryOthersRedux: (state, { payload: { industryOthers } }) => {
      state.industryOthers = industryOthers;
    },
    setTypeOfInstituteRedux: (state, { payload: { typeOfInstitute } }) => {
      state.typeOfInstitute = typeOfInstitute;
    },
    setAddressRedux: (state, { payload: { address } }) => {
      state.address = address;
    },
    setCountryRedux: (state, { payload: { country } }) => {
      state.country = country;
    },
    setPostalRedux: (state, { payload: { postal } }) => {
      state.postal = postal;
    },
    setUserFirstNameRedux: (state, { payload: { userFirstName } }) => {
      state.userFirstName = userFirstName;
    },
    setUserLastNameRedux: (state, { payload: { userLastName } }) => {
      state.userLastName = userLastName;
    },
  },
});

export const {
  setCompanyRedux,
  setRegNumberRedux,
  setLegalStructureRedux,
  setLegalStructureOthersRedux,
  setTypeOfInstituteRedux,
  setIndustryOthersRedux,
  setCountryRedux,
  setPostalRedux,
  setAddressRedux,
  setUserFirstNameRedux,
  setUserLastNameRedux,
} = bizDisSlice.actions;

export default bizDisSlice.reducer;
