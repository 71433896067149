import { PasswordInput, Text, Group, Tooltip } from '@mantine/core';
import { FC } from 'react';

type Props = {
  className: string;
  style?: React.CSSProperties;
  label?: string;
  onChange?: (value: string) => void;
  error?: string;
  tooltipContent?: string;
  name?: string;
};

const PasswordField: FC<Props> = ({
  className,
  style,
  label,
  error,
  onChange,
  tooltipContent,
  name,
}) => {
  const formattedTooltipContent = tooltipContent ? (
    <div style={{
      maxWidth: '320px',
      whiteSpace: 'normal' 
    }}>
      {tooltipContent}
    </div>
  ) : null;

  const inputElement = (
    <PasswordInput
      error={error}
      onChange={(event) => onChange?.(event.currentTarget.value)}
      size="md"
      defaultVisible={false}
      placeholder="Enter Your Password"
      id={name}
      name={name}
    />
  );

  return (
    <div className={className} style={style}>
      <Group position="apart" mb={8} grow>
        <Text component="label" htmlFor={name} size="sm">
          {label}
        </Text>
      </Group>
      {tooltipContent ? (
        <Tooltip
          label={formattedTooltipContent}
          withArrow
          position="bottom"
          color="#D9DCE1"
          styles={{
            tooltip: {
              backgroundColor: '#D9DCE1', 
              color:"black",
              borderRadius: '6px',
              padding: '15px 20px ',
            },
            arrow: {
              backgroundColor: '#D9DCE1', 
              padding:"10px 10px 0px 10px"
            },
          }}
        >
          {inputElement}
        </Tooltip>
      ) : inputElement}
    </div>
  );
};


export default PasswordField;
