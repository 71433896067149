import { FC } from "react";
import { MantineTheme, createStyles, Text } from "@mantine/core";

const useStyles = createStyles((theme: MantineTheme) => ({
  errorText: {
    color: "red",
    fontSize: "0.813rem",
    fontWeight: 400,
    lineHeight: "1.044rem",
    marginLeft: "0px",
    marginBottom: "10px",
  },
}));

type Props = {
  errorText: string;
  style?: any;
};

const ErrorMessage: FC<Props> = ({style,errorText}) => {
  const { classes } = useStyles();
  return (
    <Text className={classes.errorText} style={style}>
      {errorText}
    </Text>
  );
};

export default ErrorMessage;
