import { FC, useEffect, useState } from "react";
import ezgif from "../../assets/ezgif1.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SetupDID from "../../components/SetupDID/SetupDID";
import { Persona } from "@funded-here-interface/common/src/constant/enum";
import { useStyle } from "./DirectorDIDSetup.styles";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";
import useGetUser from "@funded-here-interface/common/src/hooks/useGetUser";
import { PASSPORT_LUMINOR_DID_QUERY_ID } from "../../constants/domain";
import WipOnboardingLayout from "../../layouts/OnboardingLayout/WipOnboardingLayout";
import { Button, Flex, Modal } from "@mantine/core";
import useOverwriteName from "../../hooks/useOverwriteName";

const DirectorDIDSetup: FC = () => {
  const { classes } = useStyle();
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = useNavigate();
  const { userId, role, token, spvName } = useSelector(
    (state: RootState) => state.auth
  );
  // const getUserMutation = useGetUser();
  const { data, error, refetch } = useGetUser(token);
  const [counter, setCounter] = useState(0);
  const [nameModal, setNameModal] = useState<boolean>(false);
  const overwriteNameMutation = useOverwriteName();
  const [overwriteBtnLoading, setOverwriteBtnLoading] =
    useState<boolean>(false);

  if (error) {
    setIsLoading(false);
    toast.error((error as Error).message);
  }

  const onInit = (data: any) => {
    console.log("onInit", data.detail);
  };

  const onScan = (data: any) => {
    setIsLoading(true);
    console.log("onScan", data);
    const response = data.detail;
    if (response.query_id === PASSPORT_LUMINOR_DID_QUERY_ID) {
      setTimeout(() => {
        setIsLoading(false);
        Navigate("/thanks-page");
      }, 2000);
    }

    const result = JSON.parse(response.result);
    const ledgerAddress = result.qrRes
      .split("LedgerAddress= ")[1]
      .split(",")[0];

    if (!ledgerAddress) {
      toast.error("ledgerAddressKey is not present");
    }

    setCounter(0);
    refetch();
  };

  useEffect(() => {
    if (data?.didRegistrationId === null && !data?.didErrorMsg) {
      const interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter + 1);

        refetch();
      }, 3000); // Adjust the interval as needed

      if (counter > 20) {
        toast.error("Server is busy. Please refresh and try again");
        setIsLoading(false);

        clearInterval(interval);
      }

      return () => clearInterval(interval); // Clear interval on component unmount
    }
    if (data?.didRegistrationId && data?.didRegistrationId !== null) {
      scanCompletedRouting();
    }

    if (data?.didErrorMsg) {
      toast.error(data?.didErrorMsg ?? "DID Registration failed");
      setIsLoading(false);

      if (data?.didErrorMsg.includes("Name does not match")) {
        setNameModal(true);
      }
    }
  }, [data, refetch, counter]);

  const scanCompletedRouting = () => {
    if (role === Persona.MERCHANT_SPV) {
      if (!spvName) {
        Navigate("/director-board-resolution-spv", {
          state: { skipDidVerify: true },
        });
      } else {
        Navigate("/director-board-resolution", {
          state: { skipDidVerify: true },
        });
      }
    } else {
      Navigate("/director-board-resolution", {
        state: { skipDidVerify: true },
      });
    }
  };

  const onOverwriteNameClick = () => {
    setOverwriteBtnLoading(true);
    overwriteNameMutation.mutate(
      { token },
      {
        onSuccess: () => {
          setOverwriteBtnLoading(false);
          setNameModal(false);
          scanCompletedRouting();
        },
        onError: (e) => {
          setOverwriteBtnLoading(false);
          toast.error((e as Error).message);
          setNameModal(false);
        },
      }
    );
  };

  return (
    <WipOnboardingLayout
      step={0}
      content={
        <div>
          {isLoading && (
            <div className={classes.loadingScreen}>
              <img className={classes.loadingIcon} src={ezgif} alt="" />
            </div>
          )}

          <SetupDID
            header="ID Setup"
            onInit={onInit}
            onScan={onScan}
            id={userId}
          />

          <Modal
            opened={nameModal}
            onClose={() => {
              setNameModal(false);
            }}
            withCloseButton={false}
            centered
          >
            <Flex gap="md" direction="column">
              <div>
                We will proceed to overwrite the previously provided name with
                your passport name.
              </div>
              <Flex direction="row-reverse">
                <Button
                  onClick={onOverwriteNameClick}
                  loading={overwriteBtnLoading}
                >
                  Okay
                </Button>
              </Flex>
            </Flex>
          </Modal>
        </div>
      }
    />
  );
};

export default DirectorDIDSetup;
