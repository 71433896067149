import { FC } from "react";
import { Notification } from "@mantine/core";

const AuthRepInfo: FC = () => {
  return (
    <Notification
      withCloseButton={false}
      color="teal"
      style={{ marginBottom: "30px" }}
      title="Authorized Representative"
    >
      Please note that the Authorized Representative is authorized by the
      directors in the board resolution to sign all loan agreements with
      FundedHere.
    </Notification>
  );
};

export default AuthRepInfo;
