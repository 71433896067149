import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

type SignedBoardResolutionRequest = {
  orgId: number;
  token: string;
};

const signedBoardResolution = async ({
  token,
  orgId,
}: SignedBoardResolutionRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/organization/signed-spv-board-resolution`,
      { orgId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export default signedBoardResolution;
