import { FileType } from "../constant/enum";

export const downloadFile = (
  data: Blob,
  fileName: string,
  fileType: FileType
) => {
  const blob = new Blob([data], { type: "application/octet-stream" });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.${fileType}`;
  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
