import axios from "axios";
import { CreditLimitType } from "../types/login.type";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";

const creditLimit = async ({ limit, token }: CreditLimitType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/organization/credit-limit`,
      { creditLimit: limit },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Network response was not ok");
  }
};

export default creditLimit;
