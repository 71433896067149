import { FC, useState } from "react";
import {
  Button,
  InputGroup,
  InputSelect,
  NAME_REGEX,
  NO_REGEX,
} from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import { countries } from "@funded-here-interface/common/src/constant/country";
import { industry, legalStructures } from "../../constants/dropdownValues";
import {
  setAddressRedux,
  setCompanyRedux,
  setCountryRedux,
  setIndustryOthersRedux,
  setLegalStructureOthersRedux,
  setLegalStructureRedux,
  setPostalRedux,
  setRegNumberRedux,
  setTypeOfInstituteRedux,
  setUserFirstNameRedux,
  setUserLastNameRedux,
} from "../../features/distributor/biz";
import { Industry, LegalStructure } from "../../constants/enums";

const DistributorBizProfile: FC = () => {
  const Navigate = useNavigate();

  const {
    company: comp,
    regNumber: regno,
    legalStructure,
    legalStructureOthers: legalStructureOthersRedux,
    typeOfInstitute,
    industryOthers: industryOthersRedux,
    country: countryRedux,
    address: addressRedux,
    postal: postalRedux,
    userFirstName: userFirstNameRedux,
    userLastName: userLastNameRedux,
  } = useSelector((state: RootState) => state.disBiz);

  const [company, setCompany] = useState(comp || "");
  const [registration_number, setRegNumber] = useState(regno || "");
  const [legal_structure, setLegStr] = useState(legalStructure || "");
  const [isOtherLegalStrcuture, setIsOtherLegalStructure] = useState(
    legalStructure === LegalStructure.OTHERS
  );
  const [legalStructureOthers, setLegalStructurOthers] = useState(
    legalStructureOthersRedux || ""
  );
  const [institution_type, setInstitute] = useState(typeOfInstitute || "");
  const [isOtherIndustry, setIsOtherIndustry] = useState(
    typeOfInstitute === Industry.OTHERS
  );
  const [industryOthers, setIndustryOthers] = useState(
    industryOthersRedux || ""
  );
  const [country, setCountry] = useState(countryRedux || "");
  const [address, setAddress] = useState(addressRedux || "");
  const [postalcode, setPostalCode] = useState(postalRedux || "");
  const [userFirstName, setUserFirstName] = useState(userFirstNameRedux || "");
  const [userLastName, setUserLastName] = useState(userLastNameRedux || "");
  const [error, setError] = useState({
    company: "",
    registration_number: "",
    legal_structure: "",
    legal_structure_others: "",
    institution_type: "",
    industry_others: "",
    country: "",
    address: "",
    phone_number: "",
    postalcode: "",
    companySite: "",
    userFirstName: "",
    userLastName: "",
  });
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const customError = { ...error };
    let valid = true;
    if (!company) {
      valid = false;
      customError.company = "Company Name is required";
    }
    if (!registration_number) {
      valid = false;
      customError.registration_number = "Registration Name is required";
    }
    if (!legal_structure) {
      valid = false;
      customError.legal_structure = "Legal Structure is required";
    }
    if (isOtherLegalStrcuture && !legalStructureOthers) {
      valid = false;
      customError.legal_structure_others =
        "Legal Strcuture Specification is required";
    }
    if (!institution_type) {
      valid = false;
      customError.institution_type = "Industry is required";
    }
    if (isOtherIndustry && !industryOthers) {
      valid = false;
      customError.industry_others = "Industry Specification is required";
    }
    if (!country) {
      valid = false;
      customError.country = "Country is required";
    }
    if (!address) {
      valid = false;
      customError.address = "Address is required";
    }
    if (!postalcode) {
      valid = false;
      customError.postalcode = "Postal Code is required";
    }
    if (!userFirstName) {
      valid = false;
      customError.userFirstName = "First Name is required";
    }
    if (!userLastName) {
      valid = false;
      customError.userLastName = "Last Name is required";
    }

    if (valid) {
      dispatch(setCompanyRedux({ company: company }));
      dispatch(setRegNumberRedux({ regNumber: registration_number }));
      dispatch(setLegalStructureRedux({ legalStructure: legal_structure }));
      dispatch(
        setLegalStructureOthersRedux({
          legalStructureOthers,
        })
      );
      dispatch(
        setIndustryOthersRedux({
          industryOthers,
        })
      );
      dispatch(setCountryRedux({ country: country }));
      dispatch(setAddressRedux({ address: address }));
      dispatch(setTypeOfInstituteRedux({ typeOfInstitute: institution_type }));
      dispatch(setPostalRedux({ postal: postalcode }));
      dispatch(setUserFirstNameRedux({ userFirstName: userFirstName.trim() }));
      dispatch(setUserLastNameRedux({ userLastName: userLastName.trim() }));

      Navigate("/issuing-spv-bank-details");
    } else {
      setError(customError);
    }
  };

  const onLegalStrcutureChange = (value: LegalStructure) => {
    if (value === LegalStructure.OTHERS) {
      setIsOtherLegalStructure(true);
    } else {
      setIsOtherLegalStructure(false);
      setLegalStructurOthers("");
    }

    setLegStr(value);
    error.legal_structure = "";
  };

  const onIndustryChange = (value: Industry) => {
    if (value === Industry.OTHERS) {
      setIsOtherIndustry(true);
    } else {
      setIsOtherIndustry(false);
      setIndustryOthers("");
    }

    setInstitute(value);
    error.institution_type = "";
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <InputGroup
        isErrored={error.userFirstName}
        onChange={(value: string) => {
          error.userFirstName = "";
          setUserFirstName(value.replace(NAME_REGEX, ""));
        }}
        labelText="First Name"
        id="firstName"
        value={userFirstName}
      />
      <InputGroup
        isErrored={error.userLastName}
        onChange={(value: string) => {
          error.userLastName = "";
          setUserLastName(value.replace(NAME_REGEX, ""));
        }}
        labelText="Last Name"
        id="lastName"
        value={userLastName}
      />
      <InputGroup
        isErrored={error.company}
        onChange={(value: any) => {
          error.company = "";
          setCompany(value);
        }}
        labelText="Company Name"
        id="company"
        value={company}
      />
      <InputGroup
        isErrored={error.registration_number}
        onChange={(value: string) => {
          error.registration_number = "";
          setRegNumber(value);
        }}
        labelText="Registration Number"
        id="registration_number"
        value={registration_number}
      />
      <InputSelect
        isErrored={error.legal_structure}
        onChange={onLegalStrcutureChange}
        labelText="Legal Structure"
        id="legal_structure"
        value={legal_structure}
        data={legalStructures}
      />
      {isOtherLegalStrcuture && (
        <InputGroup
          isErrored={error.legal_structure_others}
          onChange={(value: string) => {
            error.legal_structure_others = "";
            setLegalStructurOthers(value);
          }}
          labelText="Please Specify Legal Structure"
          id="legal_structure_others"
          value={legalStructureOthers}
        />
      )}
      <InputSelect
        isErrored={error.institution_type}
        onChange={onIndustryChange}
        labelText="Industry"
        value={institution_type}
        id="institution_type"
        data={industry}
      />
      {isOtherIndustry && (
        <InputGroup
          isErrored={error.industry_others}
          onChange={(value: string) => {
            error.industry_others = "";
            setIndustryOthers(value);
          }}
          labelText="Please Specify Industry"
          id="industry_others"
          value={industryOthers}
        />
      )}
      <InputSelect
        isErrored={error.country}
        onChange={(value: any) => {
          error.country = "";
          setCountry(value);
        }}
        labelText="Country"
        id="country"
        value={country}
        data={countries}
      />

      <InputGroup
        isErrored={error.address}
        onChange={(value: string) => {
          error.address = "";
          setAddress(value);
        }}
        labelText="Address"
        id="address"
        value={address}
      />
      <InputGroup
        isErrored={error.postalcode}
        onChange={(value: any) => {
          error.postalcode = "";
          setPostalCode(value.replace(NO_REGEX, ""));
        }}
        labelText="Postal Code"
        id="postalcode"
        value={postalcode}
      />
      <Button
        onClick={handleSubmit}
        textColor="#FFFF"
        backgroundColor="#338F86"
        children="Next"
        width="100%"
      />
    </div>
  );
};

export default DistributorBizProfile;
