import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
    marginTop: "30px",
    msFlexDirection: "row-reverse",
  },
  buttonWrapper: {
    paddingRight: "30px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  verifyIcon: {
    width: "72px",
    height: "74px",
  },
  verifyOuterContainer: {
    backgroundColor: "#FFFAF2",
    display: "flex",
    width: "1500px",
    alignItems: "center",
    justifyContent: "center",
  },
  outterContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    height: "600px",
  },
  verifyInnerContainer: {
    zIndex: 12345678987655,
    padding: "30px 70px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "30px",
    textAlign: "left",
  },
  verifyText: {
    fontWeight: 700,
    fontSize: "28px",
    color: "#338F86",
    textAlign: "left",
  },
  verifyPara: {
    fontSize: "16px",
    marginTop: "15px",
    textAlign: "center",
  },
  qrcode: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  loading: {
    backgroundColor: "#414244B2",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    backgroundColor: "#D9DCE1",
    width: "345px",
    height: "237px",
    padding: "30px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
