import { Button } from "@funded-here-interface/common";
import { Text } from "@mantine/core";
import { FC, useEffect, useRef, useState, CSSProperties } from "react";
import { useStyle } from "./StructureNote.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import { useSubcriptionDocuSign } from "../../hooks/useDocuSign";
import usePostSubscriptionAgreementSigned from "../../hooks/usePostSubscriptionAgreementSigned";
import { COLORS } from "../../constants/colors";
import useOfferRoom from "../../hooks/useOfferRoom";
import useInvestorFunds from "../../hooks/useInvestorAccount";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import { DocuSignEvent, DocuSignEventHandler } from "../../constants/docuSign";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { useNavigate } from "react-router-dom";
import { ENV } from "@funded-here-interface/common/src/constant/constant";
import MembershipAgreement from "../../components/MembershipAgreement/MembershipAgreement";
import { useDisclosure } from "@mantine/hooks";

declare global {
  interface Window {
    DocuSign: any;
  }
}

interface InvestmentOpportunity {
  id: number;
  currency: string;
  tenor: string;
  amountAvailableForInvestment: string;
  snInterestRate: string;
  totalSKU: number;
  totalMerchants: number;
}

const StructureNote: FC = () => {
  const { classes } = useStyle();
  const [category, setCategory] = useState("view");
  const [offerRoomData, setOfferRoomData] = useState<any>([]);
  const [accountData, setAccountData] = useState<any>([]);
  const docuSignMutation = useSubcriptionDocuSign();
  const offerRoomMutation = useOfferRoom();
  const InvestorFundsMutation = useInvestorFunds();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [hoveredId, setHoveredId] = useState<number | null>(null);
  const [selectedData, setSelectedData] =
    useState<InvestmentOpportunity | null>(null);
  const { token, agreedToMembershipAgreement, userEmail } = useSelector(
    (state: RootState) => state.auth
  );
  const subscriptionAgreementMutation = usePostSubscriptionAgreementSigned();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [investorSubscribeAmount, setInvestorSubscribeAmount] = useState("");
  const [selectedNoteCurrency, setSelectedNoteCurrency] = useState("");
  const [offerSigned, setOfferSigned] = useState(false);
  const navigation = useNavigate();
  const [opened, { close }] = useDisclosure(
    agreedToMembershipAgreement !== true
  );
  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
  };

  function loadDocuSignScript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://js-d.docusign.com/bundle.js";
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  const agreementRef = useRef(null);

  useEffect(() => {
    loadDocuSignScript();
    InvestorFundsMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setAccountData(data);
        },
        onError: (error: any) => {
          toast.error(error);
        },
      }
    );
    offerRoomMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setOfferRoomData(data);
        },
        onError: (error: any) => {
          toast.error(error);
        },
      }
    );
  }, []);

  //  Check inputValue matches the money format (positive number with up to two decimal places)
  const validateInvestorSubscribeAmount = (inputValue: string) => {
    const isValidFormat = /^\d*\.?\d{0,2}$/.test(inputValue);

    if (!isValidFormat) {
      return false;
    }

    const inputNumber = parseFloat(inputValue);
    const amountAvailable = parseFloat(
      selectedData?.amountAvailableForInvestment || "0"
    );

    if (inputNumber > amountAvailable) {
      return false;
    }

    return true;
  };

  const handleOK = async () => {
    if (!selectedData) {
      toast.error("No investment opportunity selected.");
      return;
    }

    toast.info("Document is loading");
    setIsOverlayVisible(true);
    // // Simulate Docusign is completed
    // await handleSubscriptionAgreementSigned();

    docuSignMutation.mutate(
      {
        token,
        structuredNoteId: selectedData.id,
        investedAmount: investorSubscribeAmount,
        webDomain: WEB_DOMAIN,
        redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
      },
      {
        onSuccess: async (data) => {
          const { docuSignEnvelopeId, url } = data;

          window.DocuSign.loadDocuSign(docuSignEnvelopeId)
            .then((docusign: any) => {
              const signing = docusign.signing({
                url: url,
                displayFormat: "focused",
                style: {
                  branding: {
                    primaryButton: {
                      backgroundColor: COLORS.primaryButtonBackground,
                      color: COLORS.primaryButtonText,
                    },
                  },
                  signingNavigationButton: {
                    finishText: "Sign",
                    position: "bottom-center",
                  },
                },
              });
              signing.on(DocuSignEventHandler.READY, () => {
                toast.info("Sign the Document using E-signature");
              });

              signing.on(
                DocuSignEventHandler.SESSION_END,
                async (event: any) => {
                  if (event.sessionEndType === DocuSignEvent.SIGNING_COMPLETE) {
                    setIsOverlayVisible(false);
                    //Let backend to handle the webhook of the signing complete
                    await handleSubscriptionAgreementSigned();
                  } else {
                    setIsOverlayVisible(false);
                    toast.error("Signing incomplete, please try again");
                  }
                }
              );

              if (agreementRef.current) {
                signing.mount(agreementRef.current);
              } else {
                toast.error("Agreement container not found");
              }
            })
            .catch((e: any) => {
              const message = getApiResponseErrorMsg(e);

              toast.error(message);
            });
        },
        onError: (e) => {
          toast.error((e as Error).message);
          setIsOverlayVisible(false);
        },
      }
    );
  };
  const handleSubscriptionAgreementSigned = async () => {
    if (!selectedData) {
      toast.error("No investment opportunity selected.");
      return;
    }
    subscriptionAgreementMutation.mutate(
      {
        token,
        structuredNoteId: selectedData.id,
        investorSubscribedAmount: investorSubscribeAmount,
      },
      {
        onSuccess: () => {
          toast.success("Document Signed Successfully");
          setIsOverlayVisible(false);
          setOfferSigned(true);
        },
        onError: (e) => {
          toast.error((e as Error).message);
          setIsOverlayVisible(false);
        },
      }
    );
  };

  const handleBoxSelect = (data: InvestmentOpportunity) => {
    setSelectedData(data);
    setSelectedNoteCurrency(data.currency);

    switch (data.currency) {
      case "SGD":
        accountData.bankName = accountData.investorSgdBankName;
        accountData.accountHolder = accountData.investorSgdBankAccountHolder;
        accountData.accountNumber = accountData.investorSgdBankAccountId;
        accountData.bicSwift = accountData.investorSgdBicSwift;
        accountData.branchCode = accountData.investorSgdBranchCode;
        accountData.bankCode = accountData.investorSgdBankCode;
        accountData.bankAddress = accountData.investorSgdBankAddress;
        break;
      case "USD":
        accountData.bankName = accountData.investorUsdBankName;
        accountData.accountHolder = accountData.investorUsdBankAccountHolder;
        accountData.accountNumber = accountData.investorUsdBankAccountId;
        accountData.bicSwift = accountData.investorUsdBicSwift;
        accountData.branchCode = accountData.investorUsdBranchCode;
        accountData.bankCode = accountData.investorUsdBankCode;
        accountData.bankAddress = accountData.investorUsdBankAddress;
        break;
    }

    setAccountData(accountData);
  };

  const handleSubscribeClick = () => {
    if (investorSubscribeAmount === "") {
      toast.error("Please enter an investment/subscription amount.");
      return;
    }

    setCategory("subscribe");
  };

  const getBoxStyle = (data: InvestmentOpportunity): CSSProperties => ({
    cursor: "pointer",
    border: "1px solid #ccc",
    padding: "30px",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    flex: 1,
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: 25,
    margin: "10px",
    backgroundColor:
      selectedData && selectedData.id === data.id
        ? "rgba(0, 0, 0, 0.25)"
        : hoveredId === data.id
        ? "rgba(0, 0, 0, 0.25)"
        : "transparent",
    color:
      hoveredId === data.id || (selectedData && selectedData.id === data.id)
        ? COLORS.whiteColor
        : undefined,
    transition: "background-color 0.3s ease",
  });

  const [visibleStartIndex, setVisibleStartIndex] = useState(0);

  const canNavigateBackward = visibleStartIndex > 0;
  const canNavigateForward = visibleStartIndex < offerRoomData.length - 3;

  const handleNext = () => {
    if (canNavigateForward) {
      setVisibleStartIndex(visibleStartIndex + 1);
    }
  };

  const handlePrev = () => {
    if (canNavigateBackward) {
      setVisibleStartIndex(visibleStartIndex - 1);
    }
  };
  const visibleItems = offerRoomData.slice(
    visibleStartIndex,
    visibleStartIndex + 3
  );

  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  const handleBackSubscribedNote = () => {
    navigation("/subscribed-invested-notes");
  };

  return (
    <>
      <div
        className={classes.stepContent}
        style={{ marginTop: "0px", paddingTop: "0px" }}
      >
        <MembershipAgreement
          toCompleteOnboard={false}
          onError={(value: string) => {
            toast.error(value);
          }}
          opened={opened}
          close={close}
        />
        <Text className={classes.headerText} style={{ marginTop: "10px" }}>
          {/* Hide the Offer Room title when authenticating */}
          {category === "subscribe" && isAuthenticated ? "" : "Offer Room"}
        </Text>

        {category === "view" && (
          <>
            <Text style={{ fontWeight: 400 }}>
              Start now by depositing funds into your VA account. You can then
              come back anytime to select from a range of investment
              opportunities outlined in the Notes below.{" "}
            </Text>
            <div>
              <Text
                style={{
                  fontWeight: 800,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                VA Account details
              </Text>
              <div style={{ display: "flex", gap: 30 }}>
                <div
                  style={{
                    backgroundColor: "#D9D9D980",
                    borderRadius: 5,
                    padding: "10px 30px",
                  }}
                >
                  <Text>
                    Bank Name: {accountData?.investorSgdBankName ?? "-"}
                  </Text>
                  <Text>
                    Account name:{" "}
                    {accountData?.investorSgdBankAccountHolder ?? "-"}
                  </Text>
                  <Text>
                    <span
                      style={{
                        color: COLORS.agreementFont,
                        fontWeight: "bold",
                      }}
                    >
                      SGD Account:{" "}
                    </span>
                    {accountData?.investorSgdBankAccountId ?? "-"}
                  </Text>
                  <Text>
                    Swift code: {accountData?.investorSgdBicSwift ?? "-"}
                  </Text>
                </div>
                <div>
                  <div
                    style={{
                      backgroundColor: "#D9D9D980",
                      borderRadius: 5,
                      padding: "10px 30px",
                    }}
                  >
                    <Text>
                      Bank Name: {accountData?.investorUsdBankName ?? "-"}
                    </Text>
                    <Text>
                      Account name:{" "}
                      {accountData?.investorUsdBankAccountHolder ?? "-"}
                    </Text>
                    <Text>
                      <span
                        style={{
                          color: COLORS.agreementFont,
                          fontWeight: "bold",
                        }}
                      >
                        USD Account:{" "}
                      </span>
                      {accountData?.investorUsdBankAccountId ?? "-"}
                    </Text>
                    <Text>
                      Swift code: {accountData?.investorUsdBicSwift ?? "-"}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                marginBottom: 10,
                gap: 10,
                alignItems: "center",
              }}
            >
              <Text
                style={{ fontWeight: 800, marginTop: 12, marginBottom: 10 }}
              >
                Balance in your account{" "}
              </Text>
              <Text
                style={{
                  color: COLORS.agreementFont,
                  fontWeight: "bold",
                  fontSize: "22px",
                }}
              >
                <div style={{ display: "flex", gap: 10 }}>
                  <span>SGD: {accountData?.accountBalance?.SGD ?? "0"}</span>
                  <span>USD: {accountData?.accountBalance?.USD ?? "0"}</span>
                </div>
              </Text>
            </div>

            {(ENV !== "production" ||
              userEmail === "bryan+954@fundedhere.com" ||
              userEmail === "bryan+955@fundedhere.com" ||
              userEmail === "heoruien@gmail.com" ||
              userEmail === "sonali.rai+1@synlian.net") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {offerRoomData?.length > 1 && canNavigateBackward && (
                  <button
                    onClick={handlePrev}
                    style={{
                      cursor: canNavigateBackward ? "pointer" : "not-allowed",
                      color: COLORS.agreementFont,
                      marginRight: "10px",
                      padding: "10px",
                      fontSize: "30px",
                      fontWeight: "bolder",
                      borderRadius: "80px",
                      border: "none",
                    }}
                  >
                    ←
                  </button>
                )}

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: offerRoomData?.length > 1 ? "100%" : "350px",
                    justifyContent: "space-between",
                  }}
                >
                  {visibleItems.map((data: any) => (
                    <div
                      key={data.id}
                      onMouseEnter={() => setHoveredId(data.id)}
                      onMouseLeave={() => setHoveredId(null)}
                      onClick={() => handleBoxSelect(data)}
                      style={getBoxStyle(data)}
                    >
                      <p style={{ fontWeight: "bold" }}>
                        Amount Available for
                        <br /> Investment
                      </p>
                      <p
                        style={{
                          color: COLORS.agreementFont,
                          fontWeight: "bold",
                          fontSize: "22px",
                        }}
                      >
                        {data?.currency}{" "}
                        {data?.amountAvailableForInvestment ?? "0"}
                      </p>
                      <p>Note : {data?.id}</p>
                      <p>Tenor: {data?.tenor} days</p>
                      <p>Interest Rate: {data?.snInterestRate} % p.a.</p>
                      <p>Total SKU’s: {data?.totalSKU}</p>
                      <p>Total Merchants: {data?.totalMerchants}</p>
                    </div>
                  ))}
                </div>
                {offerRoomData?.length > 1 && canNavigateForward && (
                  <button
                    onClick={handleNext}
                    style={{
                      cursor: canNavigateForward ? "pointer" : "not-allowed",
                      color: COLORS.agreementFont,
                      marginRight: "10px",
                      padding: "10px",
                      fontSize: "30px",
                      fontWeight: "bolder",
                      borderRadius: "80px",
                      border: "none",
                    }}
                  >
                    →
                  </button>
                )}
              </div>
            )}

            {selectedNoteCurrency !== "" && (
              <div>
                <Text
                  style={{
                    fontWeight: 800,
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  {selectedNoteCurrency} Amount to Invest
                </Text>
                <input
                  type="text"
                  value={investorSubscribeAmount}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Check if the input is empty or matches the money format (positive number with up to two decimal places)
                    if (
                      inputValue === "" ||
                      validateInvestorSubscribeAmount(inputValue)
                    ) {
                      setInvestorSubscribeAmount(inputValue);
                    }
                  }}
                  style={{
                    padding: "7px 2px 7px 2px",
                    outline: "none",
                    borderColor: "#D9DCE1",
                    marginBottom: 20,
                    width: "40%",
                    borderRadius: 3,
                  }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  color: "white",
                  backgroundColor: COLORS.agreementFont,
                  borderRadius: "5px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: "12px 80px",
                  fontSize: "20px",
                  border: "none",
                  cursor: selectedData ? "pointer" : "not-allowed",
                  opacity: selectedData ? 1 : 0.5,
                }}
                onClick={handleSubscribeClick}
                disabled={
                  !selectedData &&
                  validateInvestorSubscribeAmount(investorSubscribeAmount)
                }
              >
                Subscribe
              </button>

              <ToastContainer />
            </div>
          </>
        )}

        {category === "subscribe" && !isAuthenticated && (
          <VQRVerifyPage
            onNext={handleOnVerifyMeNext}
            onAuthenticatedChange={handleAuthenticatedChange}
          />
        )}

        {category === "subscribe" && isAuthenticated && (
          <div className={classes.SubscribeOutter}>
            <div>
              <div className={classes.subscriptionAgreement}>
                <Text className={classes.aggrementTitle}>
                  Sign Subscription Agreement
                </Text>
                <Text className={classes.linkText}>Sign with Docusign</Text>
              </div>
              <Text>
                Please review and sign the subscription agreement attached.
              </Text>
            </div>

            <div className={classes.fundAccount}>
              <Text className={classes.fundAccountText}>Fund your account</Text>
              <Text>
                Please make sure you have transferred up to{" "}
                {selectedNoteCurrency} {investorSubscribeAmount} into your
                account
              </Text>
              <div className={classes.paymentDetails}>
                <Text>Payment Details:</Text>
                <ul className={classes.listControl}>
                  <li>Bank Name: {accountData?.bankName ?? "N/A"}</li>
                  <li>Account Holder: {accountData?.accountHolder ?? "N/A"}</li>
                  <li>Account Number: {accountData?.accountNumber ?? "N/A"}</li>
                  <li>BIC/SWIFT: {accountData?.bicSwift ?? "N/A"}</li>
                  <li>Branch Code: {accountData?.branchCode ?? "N/A"}</li>
                  <li>Bank Code: {accountData?.bankCode ?? "N/A"}</li>
                  <li>Bank Address: {accountData?.bankAddress ?? "N/A"}</li>
                </ul>
              </div>
            </div>
            {isOverlayVisible && (
              <div className={classes.overlay}>
                <div
                  className={classes.document}
                  id="agreement"
                  ref={agreementRef}
                >
                  {/* DocuSign will be mounted here */}
                </div>
              </div>
            )}
            <ToastContainer />
            <div className={classes.buttonWrapper}>
              {offerSigned ? (
                <Button
                  onClick={handleBackSubscribedNote}
                  backgroundColor="#338F86"
                  textColor="#ffff"
                  children="Subscribed Notes"
                  border="1px solid #338F86"
                  width="300px"
                />
              ) : (
                <Button
                  onClick={handleOK}
                  backgroundColor="#338F86"
                  textColor="#ffff"
                  children="Sign Agreement"
                  border="1px solid #338F86"
                  width="300px"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default StructureNote;
