import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  phoneNumberWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
  },
  countryCode: {
    backgroundColor: "green",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
  },
  buttonWrapper: {
    paddingTop: "10px",
  },
  buttonDivv: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0px",
    gap: "30px",
  },
}));
