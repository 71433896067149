import { FC, ReactNode } from "react";
import { useStyles } from "./Newonboardinglayout.styles";
import { StepsLayout } from "@funded-here-interface/common";
import { ToastContainer } from "react-toastify";
import NavBar from "../../components/NavBar/NavBar";

interface IProps {
  content: ReactNode;
  showSteps?: boolean;
  step: number;
  totalSteps?: number;
}

const NewOnboardingLayout: FC<IProps> = (props) => {
  const { content, showSteps, step, totalSteps } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.mainContainer} style={{ backgroundColor: "#fff" }}>
      <NavBar />
      <ToastContainer />

      {showSteps && <StepsLayout currentStep={step} totalSteps={totalSteps} />}
      <div className={classes.stepContainer2}>{content}</div>
    </div>
  );
};

export default NewOnboardingLayout;
