import { color } from "@funded-here-interface/common";
import { createStyles } from "@mantine/styles";

export const useStyle = createStyles(() => ({
  docContainer: {
    backgroundColor: "#F9F8F7",
    display: "inline-block",
    paddingTop: "5px",
    marginTop: "5px",
    borderRadius: "10px",
  },
  docName: {
    display: "table",
    width: "421px",
    height: "19px",
    marginLeft: "30px",
    color: "#000000",
    fontFamily: "SF Pro Display",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.09px",
    alignSelf: "center",
  },
  additionalInfo: {
    width: "421px",
    marginLeft: "60px",
    marginBottom: "20px",
    fontFamily: "SF Pro Display",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19.09px",
  },
  uploadButtonContainer: {
    display: "flex",
    marginRight: "30px",
    justifyContent: "center",
    width: "150px",
    height: "10px",
    padding: "15px",
    borderRadius: "12px",
    border: "1px black solid",
    gap: "10px",
    alignItems: "center",
  },
  uploadedFileName: {
    display: "flex",
    marginTop: "10px",
    marginLeft: "60px",
  },
  uploadedFileHeader: {
    marginLeft: "50px",
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: 700,
    color: color.FHGREEN,
  },
  uploadedDocName: {
    fontSize: "14px",
    width: "300px",
  },
  removeText: {
    fontSize: "14px",
    cursor: "pointer",
    fontWeight: 600,
    color: color.REJECT,
  },
  label: {
    width: "200px",
    height: "50px",
    paddingTop: "15px",
    paddingLeft: "15px",
    fontSize: "16px",
    fontWeight: 400,
    cursor: "pointer",
  },
}));
