import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  headerText: {
    width: 361,
    height: 46,
    top: 95,
    left: 259,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },
  subHeaderText: {
    width: 361,
    height: 46,
    top: 95,
    left: 259,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },

  stepContent: {
    width: "calc(100vw - 270px - 116px)",
    height: "fit-content",
    paddingBottom: 20,
    overflow: "scroll",
  },
  formContainer: {
    marginTop: "30px",
  },
  buttonWrapper: {
    width: "205px",
    marginTop: "40px",
  },
  btnContainer: {
    paddingTop: "40px",
  },
}));
