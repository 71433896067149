import React, { useState, FC } from "react";
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useStyles } from "./Navbar.styles";
import logo from "../../assets/logo.png";
import { IconChevronCompactRight, IconChevronRight } from "@tabler/icons-react";
import { BASE_URL_LANDING_PAGE } from "../../constant/constant";

interface IProps {
  content?: React.ReactNode;
}

const Navbar: FC<IProps> = (props) => {
  const { content } = props;
  const { classes } = useStyles();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <header className={classes.header}>
        <nav className={classes.navbar} aria-label="Global">
          <div style={{ display: "flex", padding: "10px" }}>
            <a href="#">
              <Link to={BASE_URL_LANDING_PAGE}>
                <img className={classes.logo} src={logo} alt="" />
              </Link>
            </a>
          </div>
          {/* <div style={{ padding: "15px 10px" }}>
            <Bars3Icon
              onClick={() => setMobileMenuOpen(true)}
              className={classes.icon}
              aria-hidden="true"
            />
          </div> */}
        </nav>
      </header>

      {mobileMenuOpen && (
        <Dialog
          as="div"
          className={classes.menu}
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          style={{ zIndex: 9999 }}
        >
          <div
            className={classes.overlay}
            onClick={() => setMobileMenuOpen(false)}
          ></div>

          <div className={classes.root}>
            <div className={classes.container}>
              <div style={{ padding: 20 }} className=" p-4">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Link to="/">
                    <img
                      className={classes.logo}
                      src={logo}
                      alt="Company Logo"
                    />
                  </Link>

                  <XMarkIcon
                    className={classes.icon}
                    onClick={() => setMobileMenuOpen(false)}
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div className={classes.menuItem}>
                <Link to="/login" className={classes.menuItem}>
                  <p className={classes.item}>
                    <IconChevronRight style={{ verticalAlign: "middle" }} />{" "}
                    Login
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      <div>{content}</div>
    </div>
  );
};

export default Navbar;
