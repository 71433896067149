import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  text: {
    paddingBottom: "0px",
    paddingLeft: "5px",
    color: "#3C4257",
    textAlign: "left",
  },
  inputField: {
    padding: "2px",
    height: "45px",
    width: "100%",
    marginBottom: "35px",
  },
  shareholderDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "900px",
  },
  shareTitle: {
    marginBottom: "20px",
    fontWeight: "bold",
    marginTop: "10px",
  },
  sharePara: {
    width: "550px",
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: "40px",
  },
  dropdown: {
    width: "150px",
  },
}));
