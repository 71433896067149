import { Text } from "@mantine/core";
import { FC, useState } from "react";
import { Button, CurrencyInputGroup } from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import {
  InputGroup,
  InputSelect,
  InputGroupDisable,
} from "@funded-here-interface/common";
import { useStyle } from "./LoanSKU.styles";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";

const LoanAppSku: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const [quantity, setQuantity] = useState("");
  const [skureturn, setSkuReturn] = useState("");
  const [estimatedDays, setEstimatedDays] = useState("");
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [dateOfDelivery, setDateOfDelivery] = useState("");

  const [error, setError] = useState({
    UPC: "",
    quantity: "",
    price: "",
    discount: "",
    dateOfDelivery: "",
    goodsCost: "",
    averageInventory: "",
    estimatedSKU: "",
  });

  const handleNext = () => {
    const customErr = { ...error };
    let valid = true;
    if (!quantity) {
      valid = false;
      customErr.quantity = "Representative is required";
    }
    if (!price) {
      valid = false;
      customErr.price = "Representative Title is required";
    }
    if (!discount) {
      valid = false;
      customErr.discount = "Id Type is required";
    }
    if (!dateOfDelivery) {
      valid = false;
      customErr.dateOfDelivery = "Id Name is required";
    }
    if (valid) {
      Navigate("/bank-details");
    } else {
      setError(customErr);
    }
  };

  return (
    <OnboardingLayout
      step={0}
      content={
        <>
          <div
            className={classes.stepContent}
            style={{ marginTop: "0px", paddingTop: "0px" }}
          >
            <Text
              className={classes.headerText}
              style={{ marginTop: "10px", marginBottom: "30px" }}
            >
              Loan Application
            </Text>

            <Text
              className=""
              style={{
                marginTop: "10px",
                marginBottom: "30px",
                width: "650px",
              }}
            >
              Kindly complete the provided details to enable us to calculate a
              personalized loan offer tailored to meet your specific
              requirements.
            </Text>

            <Text className={classes.headerSubText}>FG 82766</Text>
            <Text
              className={classes.headerSubText}
              style={{ marginTop: "0px", marginBottom: "20px" }}
            >
              Adidas Shoes Red Size 38
            </Text>
            <InputGroup
              isErrored={error.quantity}
              onChange={(value: any) => setSkuReturn(value)}
              value={skureturn}
              labelText="Estimated SKU Return (%)( Est percentage of return for this SKU)"
              id="skureturn"
            />
            <div className={classes.twiceInput}>
              {/* <InputGroup
                isErrored={error.quantity}
                onChange={(value: any) => setSkuReturn(value)}
                value={skureturn}
                labelText="Estimated SKU Return (%)( Est percentage of return for this SKU)"
                id="skureturn"
                name="quantity"
                placeHolder=""
              /> */}
            </div>
            <InputGroup
              isErrored={error.quantity}
              onChange={(value: any) => setEstimatedDays(value)}
              value={estimatedDays}
              labelText="Estimated number of days from the desired order to delivery"
              id="estimatednoofdays"
            />

            <CurrencyInputGroup
              isErrored={error.price}
              onChange={(value: any) => setPrice(value)}
              value={price}
              labelText="Purchase price"
              id="Purchase_price"
            />
            <CurrencyInputGroup
              isErrored={error.price}
              onChange={(value: any) => setSellingPrice(value)}
              value={sellingPrice}
              labelText="Selling price per unit (for this SKU)"
              id="Selling_price"
            />

            <InputGroup
              isErrored={error.quantity}
              onChange={(value: any) => setQuantity(value)}
              value={quantity}
              labelText="Quantity of stock needed"
              id="quantity"
            />
            <CurrencyInputGroup
              isErrored={error.discount}
              onChange={(value: any) => setDiscount(value)}
              value={discount}
              labelText="Discount"
              id="Discount"
            />
            <CurrencyInputGroup
              isErrored={error.discount}
              onChange={(value: any) => setTax(value)}
              value={tax}
              labelText="Total Taxes"
              id="taxes"
            />
            <InputSelect
              isErrored={error.dateOfDelivery}
              onChange={(value: any) => setDateOfDelivery(value)}
              value={dateOfDelivery}
              labelText="Payment Terms"
              id="id_number"
              data={["Cash on Delivery", "Partial Payment", "Payment on PO"]}
            />

            {dateOfDelivery === "Partial Payment" ? (
              <>
                <InputGroup
                  isErrored={error.quantity}
                  onChange={(value: any) => setQuantity(value)}
                  value={quantity}
                  labelText="First Payment (%)"
                  id="first_payment"
                />

                <div style={{ width: "100%" }}>
                  <InputGroupDisable
                    isErrored={error.quantity}
                    value={quantity ? 100 - parseInt(quantity) : ""}
                    labelText="Balace Payment (%)"
                    id="balance"
                  />
                </div>
              </>
            ) : (
              ""
            )}

            <div>
              <div className={classes.uploadContainer}>
                <Text className={classes.fileTitile}>
                  Download EPO (Electronic PO) format
                </Text>
              </div>
              <div className={classes.uploadContainer}>
                <Text className={classes.fileTitile}>
                  Upload EPO (Electronic PO){" "}
                </Text>
              </div>
            </div>
            <div className={classes.buttonDiv}>
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={() => handleNext()}
                  textColor="#ffff"
                  backgroundColor="#338F86"
                  children="Save"
                  width="300px"
                />
              </div>
            </div>

            <Text
              className=""
              style={{
                marginTop: "10px",
                paddingBottom: "10px",

                width: "650px",
              }}
            >
              Please note, that for COD option, the Loan amount once funded and
              sanctioned will be disbursed only after the goods are delivered to
              you
            </Text>
          </div>
        </>
      }
    />
  );
};

export default LoanAppSku;
