import { Group, Select, Text } from "@mantine/core";
import { CSS } from "@mantine/styles/lib/tss/types";
import { FC } from "react";
import { useStyles } from "./CurrencyInputGroup.styles";
import { isValidNumber } from "../../regex/InputRegex";

type Props = {
  labelText: string;
  id: string;
  placeHolder?: string;
  onChange?: CallableFunction;
  style?: CSS;
  isErrored?: string;
  value: number | null;
};

const CurrencyInputGroup: FC<Props> = ({
  labelText,
  id,
  placeHolder,
  onChange,
  isErrored,
  value,
}) => {
  const { classes } = useStyles();

  const OnChange = (event: any) => {
    const newValue = event.target.value;

    if (isValidNumber.test(newValue)) {
      // Convert input value to number and pass it to onChange
      const parsedValue = parseFloat(newValue);
      onChange?.(parsedValue);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Group position="left" mb={5}>
        <Text className={classes.text} component="label" htmlFor={id} size="sm">
          {labelText}
        </Text>
      </Group>
      <div className={classes.innerContainer}>
        <Select
          className={classes.selectField}
          size="md"
          placeholder="Choose currency"
          data={["SGD", "MYR", "IDR", "USD"]}
          defaultValue="SGD"
        />
        <input
          type="number"
          id={id}
          placeholder={placeHolder}
          className={classes.inputField}
          value={value === null ? undefined : value}
          onChange={OnChange}
        />
      </div>
      {isErrored && <div className={classes.errorMessage}>{isErrored}</div>}
    </div>
  );
};

export default CurrencyInputGroup;
