import { Text } from "@mantine/core";
import { FC, useEffect, useRef, useState } from "react";
import { Button } from "@funded-here-interface/common";
import { useStyle } from "./LoanApplication.styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { COLORS } from "../../constants/colors";
import { useLoanSign } from "../../hooks/useDocuSign";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import useGetSkusPendingMerchantSigning from "../../hooks/useGetSkusPendingMerchantSigning";
import { DocuSignEvent, DocuSignEventHandler } from "../../constants/docuSign";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import { useNavigate } from "react-router-dom";

const LoanApplication: FC = () => {
  const { classes } = useStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const docuSignMutation = useLoanSign();
  const useGetSkusPendingMerchantSigningMutation =
    useGetSkusPendingMerchantSigning();
  const [skuIds, setSkuIds] = useState<any>([]);
  const navigate = useNavigate();

  const { token, hasCreditLimit } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    loadDocuSignScript();
    setIsLoading(true);
    useGetSkusPendingMerchantSigningMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          const skuIds = data.map((sku: any) => sku.id.toString());
          setSkuIds(skuIds);
          setIsLoading(false);
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  }, []);
  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
  };
  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  function loadDocuSignScript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://js-d.docusign.com/bundle.js";
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  const handleOK = async () => {
    toast.info("Document is loading");
    setIsOverlayVisible(true);

    docuSignMutation.mutate(
      {
        token,
        poolingSkuIds: skuIds,
        webDomain: WEB_DOMAIN,
        redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
      },
      {
        onSuccess: async (data) => {
          const { url, docuSignEnvelopeId } = data;

          window.DocuSign.loadDocuSign(docuSignEnvelopeId)
            .then((docusign: any) => {
              const signing = docusign.signing({
                url: url,
                displayFormat: "focused",
                style: {
                  branding: {
                    primaryButton: {
                      backgroundColor: COLORS.primaryButtonBackground,
                      color: COLORS.primaryButtonText,
                    },
                  },
                  signingNavigationButton: {
                    finishText: "Sign",
                    position: "bottom-center",
                  },
                },
              });
              signing.on(DocuSignEventHandler.READY, () => {
                toast.info("Sign the Document using E-signature");
              });

              signing.on(
                DocuSignEventHandler.SESSION_END,
                async (event: any) => {
                  if (event.sessionEndType === DocuSignEvent.SIGNING_COMPLETE) {
                    setIsOverlayVisible(false);
                    await handleLoanAgreementSigned();
                  } else {
                    setIsOverlayVisible(false);
                    toast.error("Signing incomplete, please try again.");
                  }
                }
              );

              if (agreementRef.current) {
                signing.mount(agreementRef.current);
              } else {
                toast.error("Agreement container not found");
              }
            })
            .catch((e: any) => {
              const message = getApiResponseErrorMsg(e);

              toast.error(message);
            });
        },
        onError: (e) => {
          toast.error((e as Error).message);
          setIsOverlayVisible(false);
        },
      }
    );
  };

  const handleLoanAgreementSigned = async () => {
    submitAgreements()
      .then(() => {
        toast.success("Document Signed Successfully");
        setIsOverlayVisible(false);

        if (hasCreditLimit) {
          navigate("/sku-loan-application/confirmed");
        } else {
          navigate("/loan-agreement");
        }
      })
      .catch((error) => {
        toast.error("Unable to submit agreements");
      });
  };

  const submitAgreements = async () => {
    try {
      await axios.post(
        `${BASE_URL}/sku-finance/sn/sku-loans-docs-signed`,
        {
          skuIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (e) {
      throw new Error("Network response was not ok");
    }
  };

  const agreementRef = useRef(null);

  return (
    <>
      {isAuthenticated && (
        <div>
          <VQRVerifyPage
            onAuthenticatedChange={handleAuthenticatedChange}
            onNext={handleOnVerifyMeNext}
          />
        </div>
      )}
      {!isAuthenticated && (
        <div className={classes.stepContent} style={{ marginTop: "0px" }}>
          <Text
            className={classes.headerText}
            style={{ marginTop: "10px", width: "140%" }}
          >
            SKU Supplemental Agreement & Deed of Charge
          </Text>
          <div className={classes.download}>
            <Text className={classes.docSign}>Sign with DocuSign</Text>
          </div>
          {isOverlayVisible && (
            <div className={classes.overlay}>
              <div
                className={classes.document}
                id="agreement"
                ref={agreementRef}
              >
                {/* DocuSign will be mounted here */}
              </div>
            </div>
          )}
          <div className={classes.buttonDiv}>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={isLoading}
                onClick={handleOK}
                textColor="#ffff"
                backgroundColor="#338F86"
                children="Sign agreement"
                width="100%"
              />
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default LoanApplication;
