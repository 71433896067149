import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import AuthServices from "../../../../services/AuthServices";
import { useSelector, useDispatch } from "react-redux";

const ClosedPaymentTable = ({ columns, data }) => {
  // const { OrgRoleId } = useContext(UserContext);
  // alert("Hii");
  // console.log("fsfdsdfsdfsdf", OrgRoleId);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="DataTableInn">
      <div className="tableWrapper">
        <table
          {...getTableProps()}
          style={{ borderSpacing: "0", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                    <i class="fa-solid fa-caret-up"></i>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} style={{ padding: "8px" }}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Myapp = () => {
  const [closedPayment, setClosedPayment] = useState([]);
  const token = useSelector((state) => state.auth.token);
  // const { closePaymentDetails } = useSelector((state) => state.paymentReducer);
  // const { OrgRoleId } = useContext(UserContext);
  // const orgRoleId = localStorage?.getItem("orgRoleId");
  // const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AuthServices.closedPayment(token);
        setClosedPayment(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { Header: "SKU ID", accessor: "sku_id" },
    { Header: "SKU Name", accessor: "sku_name" },
    { Header: "Sector", accessor: "sector" },
    { Header: "Country", accessor: "country" },
    { Header: "Loan Amount", accessor: "loan_Amt" },
    { Header: "Repaid Amount", accessor: "repaid_amt" },
    { Header: "Interest Amount", accessor: "int_amt" },
    { Header: "SKU Live DOI", accessor: "doi" },
    { Header: "Expected RBP", accessor: "rbp" },
    { Header: "Tenure", accessor: "tenure" },
    { Header: "RBU", accessor: "rbu" },
    { Header: "Quantity Sold (in units)", accessor: "quantity_sold" },
    { Header: "Selling Price", accessor: "selling_price" },
  ];

  const data2 =
    closedPayment
      ?.map((item) => ({
        sku_id: item?.skuId,
        sku_name: item?.skuName,
        country: item?.country,
        sector: item?.sector,
        doi: item?.skuLiveDOI,
        tenure: item?.tenure,
        days_to_maturity: item?.daysToMaturity,
        rbp: item?.rbp,
        loan_Amt: item?.loan_amount,
        repaid_amt: item?.repaid_amount,
        int_amt: item?.interestAmount,
        balance_Amt: item?.balanceAmount,
        total_Sales_proceed: item?.totalSalesProceed,
        rbu: item?.rbu,
        quantity_sold: item?.quantitySold,
        selling_price: item?.sellingPrice,
        topup_amt: item?.topupAmount,
      }))
      .sort((a, b) => {
        // Extract numeric part from SKU ID
        const skuIdA = parseInt(a.sku_id.split("-")[1]);
        const skuIdB = parseInt(b.sku_id.split("-")[1]);
        // Compare the numeric part
        return skuIdA - skuIdB;
      }) ?? [];

  return <ClosedPaymentTable columns={columns} data={data2} />;
};

export default Myapp;
