import { Text } from "@mantine/core";
import { Button, PasswordField, color } from "@funded-here-interface/common";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isPasswordValid } from "../../Utils/formValidation";
import { useStyles } from "./NewPassword.styles";
import useResetPasswordMutation from "../../hooks/useResetPassword";
import { ToastContainer, toast } from "react-toastify";
import SignupCheckbox from "../../components/SignupCheckbox/SignupCheckbox";
import "react-toastify/dist/ReactToastify.css";

interface IProps {
  title: string;
}

const NewPassword: FC<IProps> = ({ title }) => {
  const { classes } = useStyles();
  const [password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [tokenvalue, setTokenValue] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const mutation = useResetPasswordMutation();

  const setPassword = () => {
    if (!tokenvalue) {
      toast.error("Token is missing!");
      return;
    }

    if (!password) {
      toast.error("New password is required");
      return;
    }

    if (!isPasswordValid(password)) {
      toast.error("Invalid password");
      return;
    }

    if (!confirm_password) {
      toast.error("Comfirm password is required");
      return;
    }

    if (password !== confirm_password) {
      toast.error("Password does not match");
      return;
    }

    const token = tokenvalue;

    setIsLoading(true);
    mutation.mutate(
      { password, confirm_password, token },
      {
        onSuccess: () => {
          setIsLoading(false);
          navigate("/password-changed");
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
          if ((e as Error).message === "Invalid Token") {
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
        },
      }
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      const cleanedToken = token.replace(/^"(.*)"$/, "$1");
      setTokenValue(cleanedToken);
    }
  }, []);

  const handleCheckboxChange = (isChecked: boolean) => {
    setIsChecked(isChecked);
  };

  return (
    <div className={classes.loginContainer}>
      <Text className={classes.loginHeading}>{title}</Text>

      <div className={classes.inputGroupContainer}>
        <div className={classes.tooltipContainer}>
          <PasswordField
            onChange={setNewPassword}
            tooltipContent="At least one lowercase letter, one uppercase letter, one number, one special character, minimum of 8 characters."
            className={classes.inputbox}
            label="New Password"
            name="password"
          />
        </div>
        <div className={classes.checkboxContainer}></div>
        <PasswordField
          onChange={setConfirmPassword}
          className=""
          label="Confirm Password"
          name="confirm_password"
        />
        <div className={classes.checkbox}>
          <SignupCheckbox onChange={handleCheckboxChange} />
        </div>

        <div className={classes.buttonDiv}>
          <Button
            disabled={!isChecked || isLoading}
            width="100%"
            textColor={color.WHITE}
            backgroundColor={color.FHGREEN}
            children="Continue"
            onClick={setPassword}
          />
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
