import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  mainContainer: {
    width: "100%",
    height: "100vh",
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    margin: 0,
    position: "relative",
  },
  stepContainer: {
    marginLeft: "240px",
    flex: 1,
    overflow: "auto",
  },
}));
