import { FC, ReactNode } from "react";
import { MantineTheme, createStyles } from "@mantine/core";
import { color } from "../../constant/color";

const useStyles = createStyles((theme: MantineTheme) => ({
  buttonClass: {
    width: "250px",
    height: "45px",
    padding: "13px 87px 13px 87px",
    border: "none",
    gap: "10px",
    fontSize: "16px",

    letterSpacing: "0em",
    textAlign: "center",
  },
}));

type Props = {
  textColor: string;
  backgroundColor: string;
  children: ReactNode;
  width?: string;
  border?: string;
  onClick?: CallableFunction;
  disabled?: boolean;
};

const LargeBtn: FC<Props> = ({
  disabled,
  textColor,
  backgroundColor,
  children,
  width,
  border,
  onClick,
}) => {
  const { classes } = useStyles();
  return (
    <button
      onClick={() => onClick && onClick()}
      className={classes.buttonClass}
      disabled={disabled}
      style={{
        cursor: "pointer",
        color: textColor,
        backgroundColor: disabled ? color.DISABLED : backgroundColor,
        width: width,
        border: border,
        borderRadius: "5px",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </button>
  );
};

export default LargeBtn;
