import { FC, ReactNode } from "react";
import { useStyles } from "./Button.styles";
import { color } from "../../constant/color";

type Props = {
  textColor: string;
  backgroundColor: string;
  children: ReactNode;
  width?: string;
  border?: string;
  onClick: CallableFunction;
  disabled?: boolean;
};

const Button: FC<Props> = (props) => {
  const { classes } = useStyles();
  const {
    textColor,
    backgroundColor,
    children,
    width,
    border,
    onClick,
    disabled,
  } = props;

  return (
    <button
      onClick={() => onClick()}
      className={classes.buttonClass}
      disabled={disabled}
      style={{
        cursor: "pointer",
        color: textColor,
        backgroundColor: disabled ? color.DISABLED : backgroundColor,
        width: width,
        border: border,
        borderRadius: "5px",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </button>
  );
};

export default Button;
