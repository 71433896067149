import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "../Utils/apiHelper";

type GetUserRequest = {
  token: string;
};

const getUser = async ({ token }: GetUserRequest) => {
  try {
    const response = await axios.get(`${BASE_URL}/trade-directory/users`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getUser;
