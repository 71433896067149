import { Text } from "@mantine/core";
import { FC, useState } from "react";
import line from "@funded-here-interface/common/src/assets/Line6.png";
import { Button } from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import downArrow from "../../assets/keyboard_arrow_down_24px.svg";
import { useStyle } from "./WelcomeBorrower.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import useLogoutMutation from "../../hooks/useLogout";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";

const WelcomeBorrowerPage: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const [isOpen, setIsopen] = useState(false);
  const token = useSelector((state: RootState) => state.auth.token);
  const logoutMutation = useLogoutMutation();

  const handleSubmit = () => {
    Navigate("/bor-biz-profile");
  };

  const handleLogout = async () => {
    logoutMutation.mutate(
      { token },
      {
        onSuccess: () => {
          Navigate("/login");
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <OnboardingLayout
      step={0}
      content={
        <div className="stepContent" style={{ marginTop: "0px" }}>
          <Text className={classes.headerText}>Welcome to FundedHere!</Text>
          <Text className={classes.instruction} style={{ marginTop: "10px" }}>
            As part of our commitment to maintaining the highest standards of
            security and compliance, we kindly request you to submit your
            details.
          </Text>
          <Text className={classes.subHeading}>
            First set of Steps (approx 20 minutes)
          </Text>

          <div className={classes.stepsToComplete}>
            <div className={classes.progress}>
              <div className={classes.eclipse}>
                {/* <img src={eclipse}/> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <circle cx="5" cy="5" r="5" fill="#151515" />
                </svg>
              </div>
              <div className={classes.line}>
                <img src={line} alt="line" />
              </div>
            </div>
            <ol
              style={{
                paddingLeft: "50px",
                paddingTop: "0px",
                marginTop: "10px",
              }}
            >
              <li>
                <Text className={classes.list}>Company Profile</Text>
              </li>
              <li>
                <Text className={classes.list}>
                  Authorised Representative & Shareholder profile
                </Text>
              </li>
              <li>
                <Text className={classes.list}>Bank Details</Text>
              </li>
              <li>
                <Text className={classes.list}>Documents Upload</Text>
              </li>
              <li>
                <Text className={classes.list}>DID Setup</Text>
              </li>
            </ol>
          </div>
          <Text className={classes.para}>
            After one working day for KYC processing, we'll proceed with the
            second set of steps.
          </Text>
          <div className={classes.download}>
            <Text className={classes.doc}>Second set of steps</Text>
            {isOpen ? (
              <img
                style={{ cursor: "pointer" }}
                onClick={() => setIsopen(!isOpen)}
                src={downArrow}
                alt="down"
              />
            ) : (
              <img
                style={{ cursor: "pointer" }}
                onClick={() => setIsopen(!isOpen)}
                src={downArrow}
                alt="down"
              />
            )}
          </div>
          {isOpen && (
            <ol
              style={{
                paddingLeft: "50px",
                paddingTop: "0px",
                marginTop: "10px",
              }}
            >
              <li>
                <Text className={classes.list}>
                  SPV Setup (For Non Singaporean companies)
                </Text>
              </li>
              <li>
                <Text className={classes.list}>Directors Resolution</Text>
              </li>
              <li>
                <Text className={classes.list}>
                  Borrower Master Credit Agreement
                </Text>
              </li>
            </ol>
          )}
          <div className={classes.buttonDiv}>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleSubmit}
                textColor="#ffff"
                backgroundColor="#338F86"
                children="Let's Go"
                width="254px"
              />
            </div>
            <ToastContainer />
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleLogout}
                backgroundColor="#ffff"
                textColor="#338F86"
                children="I'll do it later"
                border="1px solid #338F86"
                width="300px"
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default WelcomeBorrowerPage;
