import { color } from "@funded-here-interface/common";
import { Anchor, Checkbox, Group, MantineProvider } from "@mantine/core";
import { FC } from "react";
import { useStyle } from "./SignupCheckbox.styles";
import useGetFhDocument from "../../hooks/useFhDocument";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { ToastContainer, toast } from "react-toastify";
import { FileType } from "@funded-here-interface/common/src/constant/enum";

interface IProps {
  onChange: (checked: boolean) => void;
}

const SignupCheckbox: FC<IProps> = ({ onChange }) => {
  const { classes } = useStyle();
  const useGetFhDocumentMutation = useGetFhDocument();
  const enum File {
    RISK_WARNING = "risk_warning.pdf",
    TERMS_OF_USE = "terms_of_use.pdf",
    PRIVACY_POLICY = "privacy_policy.pdf",
  }
  const fileNameToDownloadName = {
    [File.RISK_WARNING]: "Risk Warning",
    [File.TERMS_OF_USE]: "Terms of Use",
    [File.PRIVACY_POLICY]: "Privacy Policy",
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.checked);
  };

  const handleDownload = (fileName: File) => {
    useGetFhDocumentMutation.mutate(
      { fileName },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, fileNameToDownloadName[fileName], FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <Group position="left" spacing="xs" noWrap>
      <MantineProvider theme={{ cursorType: "pointer" }}>
        <Checkbox onChange={handleChange} />
      </MantineProvider>
      <ToastContainer />
      <div style={{ fontSize: "14px" }}>
        Please confirm you agree to our{" "}
        <Anchor
          color={color.FHGREEN}
          underline={false}
          onClick={() => handleDownload(File.RISK_WARNING)}
          className={classes.agreement}
        >
          Risk Warning
        </Anchor>
        ,{" "}
        <Anchor
          color={color.FHGREEN}
          underline={false}
          onClick={() => handleDownload(File.TERMS_OF_USE)}
          className={classes.agreement}
        >
          Terms of Use
        </Anchor>
        , and{" "}
        <Anchor
          color={color.FHGREEN}
          underline={false}
          onClick={() => handleDownload(File.PRIVACY_POLICY)}
          className={classes.agreement}
        >
          Privacy Policy
        </Anchor>
      </div>
    </Group>
  );
};

export default SignupCheckbox;
