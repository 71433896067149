import { File } from "@funded-here-interface/common/src/constant/enum";

export const data = [
  {
    number: 1,
    id: File.BUSINESS_PROFILE,
    name: File.BUSINESS_PROFILE,
    heading: "Business profile",
  },
  {
    number: 2,
    id: File.SHAREHOLDERS_DIRECTORS_REGISTERS,
    name: File.SHAREHOLDERS_DIRECTORS_REGISTERS,
    heading: "Shareholders and Directors Register",
  },
  {
    number: 3,
    id: File.BALANCE_SHEET,
    name: File.BALANCE_SHEET,
    heading: "Balance Sheet & P&L statement (Last one year)",
  },
  {
    number: 4,
    id: File.PROOF_OF_ADDRESS,
    name: File.PROOF_OF_ADDRESS,
    heading:
      "Proof of address- All shareholders & directors (Bank statement, Utility bills/Ownership Tenancy Agreement)",
  },
  {
    number: 5,
    id: File.BANK_STATEMENT,
    name: File.BANK_STATEMENT,
    heading: "Bank Statement (Last 3 months)",
  },
];

export const investordata = [
  {
    number: 1,
    id: File.BUSINESS_PROFILE,
    name: File.BUSINESS_PROFILE,
    heading: "Business Profile",
  },
  {
    number: 2,
    id: File.SHAREHOLDERS_DIRECTORS_REGISTERS,
    name: File.SHAREHOLDERS_DIRECTORS_REGISTERS,
    heading: "Shareholders & Directors Registers",
  },

  {
    number: 3,
    id: File.FINANCIAL_STATEMENT,
    name: File.FINANCIAL_STATEMENT,
    heading: "Financial Statements/Income statement",
  },
  {
    number: 4,
    id: File.PROOF_OF_ADDRESS,
    name: File.PROOF_OF_ADDRESS,
    heading:
      "Proof of Address- All shareholders & directors(Bank statement, Utility bills/Ownership Tenancy Agreement",
  },
];
export const fundInvestorData = [
  {
    number: 1,
    id: File.BUSINESS_PROFILE,
    name: File.BUSINESS_PROFILE,
    heading: "Business Profile",
  },
  {
    number: 2,
    id: File.SHAREHOLDERS_DIRECTORS_REGISTERS,
    name: File.SHAREHOLDERS_DIRECTORS_REGISTERS,
    heading: "Shareholders & Directors Registers",
  },
  {
    number: 3,
    id: File.FINANCIAL_STATEMENT,
    name: File.FINANCIAL_STATEMENT,
    heading: "Financial Statements/Income statement",
  },
  {
    number: 4,
    id: File.PROOF_OF_ADDRESS,
    name: File.PROOF_OF_ADDRESS,
    heading:
      "Proof of Address- All shareholders & directors(Bank statement, Utility bills/Ownership Tenancy Agreement",
  },
  {
    number: 5,
    id: File.INVESTMENT_MANDATE,
    name: File.INVESTMENT_MANDATE,
    heading: "Investment Mandate",
  },
  {
    number: 6,
    id: File.FUND_MANAGER_LICENSE,
    name: File.FUND_MANAGER_LICENSE,
    heading: "Fund Manager License",
  },
];
export const investorinddata = [
  {
    number: 1,
    id: File.PROOF_OF_ADDRESS,
    name: File.PROOF_OF_ADDRESS,
    heading:
      "Proof of address- Certified true copy (Utility bills/Ownership Tenancy Agreement)",
  },
  {
    number: 2,
    id: File.SALARY_PROOF,
    name: File.SALARY_PROOF,
    heading:
      "Salary proof (Employment letter OR Income tax filing OR Pay slip)",
  },

  {
    number: 3,
    id: File.BANK_STATEMENT,
    name: File.BANK_STATEMENT,
    heading:
      "Bank statement/Investment account & Income statement (last one year)",
  },
];
