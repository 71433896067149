import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  // navbar styling
  sideBarContainer: {
    position: "fixed",
    height: "100vh",
    backgroundColor: "#15292B",
    width: 240,
    padding: "0px 20px",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    width: "100%",
  },
  dropdown: {
    paddingLeft: "5px",
    cursor: "pointer",
  },
  Link: {
    textDecoration: "none",
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  label: {
    paddingLeft: "10px",
  },
  submenu: {
    display: "flex",
    alignItems: "left",
    padding: 10,
    fontSize: "16px",
    flexDirection: "column",
    color: "white",
    marginLeft: 50,
  },
  mainSideBar: {
    flex: 1,
  },

  menuItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
    fontWeight: 400,
    padding: 10,

    "&:hover": {
      borderRadius: "10px",
      backgroundColor: "#223e41",
    },
  },

  personItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 400,
    padding: 10,
    cursor: "default",
  },
  footer: {
    fontSize: "16px",
    fontWeight: 400,
    color: "white",
    paddingBottom: 60,
  },
  icon: {
    marginRight: "8px",
    verticalAlign: "middle",
  },
}));
