import { createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme) => ({
  header: {
    inset: 0,
    top: 0,
    zIndex: 50,
    position: 'fixed',
    height: '100px',
  },
  logo: {
    height: 50,
    with: 50,
  },
  root: {
    display: "flex",
    minHeight: "100vh",
    alignItems: "start",
    justifyContent: "center",
    textAlign: "center",
  },
  container: {
    position: "relative",
    display: "inline-block",
    minHeight: "100vh",
    width: "100%",
    verticalAlign: "middle",
    borderRadius: "0.5rem",
    overflow: "hidden",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
    transition: "all 0.3s ease-in-out",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#15292B",
  },
  navbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#15292B",
    padding: 10,
    height: '100%',
  },
  btn: {
    margin: `-2px`,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2px",
    padding: `20px`,
    color: "gray",
  },
  item: {
    color: "white",
  },
  icon: {
    width: 40,
    height: 30,
    color: "gray",
  },
  menu: {
    position: "fixed",
    inset: "0",
    zIndex: 50,
    overflowY: "auto",
  },
  menuItem: {
    marginTop: 90,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    gap: 20,
    padding: 30,
    color: "white",
    fontSize: 20,
    textAlign: "center",
  },
}));
