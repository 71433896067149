import { FC, ReactNode, useEffect, useState } from "react";
import Logo from "@funded-here-interface/common/src/assets/logo2.png";
import { Link, useNavigate } from "react-router-dom"; // Import Link from react-router-dom for navigation
import { IconPower, IconUserCircle } from "@tabler/icons-react";
import arrowUp from "@funded-here-interface/common/src/assets/arrow_up.svg";
import arrowDown from "@funded-here-interface/common/src/assets/arrow_down.svg";

import useLogoutMutation from "../../hooks/useLogout";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

import useGetUser from "@funded-here-interface/common/src/hooks/useGetUsers";
import { toast } from "react-toastify";
import { useStyles } from "./NavBar.styles";

export interface IMenuItem {
  label: string;
  path?: string;
  submenu?: ISubMenu[];
  icon?: ReactNode;
}

interface ISubMenu {
  label: string;
  path: string;
}

interface INavBar {
  menus?: IMenuItem[];
  canPassCondition?: boolean | null;
}

const NavBar: FC<INavBar> = ({ menus, canPassCondition = true }) => {
  const { classes } = useStyles();

  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const navigation = useNavigate();
  const token = useSelector((state: RootState) => state.auth.token);
  const getUserMutation = useGetUser();
  const [name, setName] = useState({ firstName: "", lastName: "" });

  const logoutMutation = useLogoutMutation();
  const getUserName = () => {
    getUserMutation.mutate(
      { token },
      {
        onSuccess: (userDetail) => {
          setName({
            firstName: userDetail?.firstName,
            lastName: userDetail?.lastName,
          });
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  useEffect(() => {
    getUserName();
  }, []);

  const handleLogout = () => {
    logoutMutation.mutate(
      { token },
      {
        onSuccess: () => {
          sessionStorage.removeItem("Auth");
          sessionStorage.removeItem("orgRoleId");

          navigation("/login");
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const toggleSubMenu = (index: any) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  return (
    <nav className={classes.sideBarContainer}>
      <div className={classes.mainSideBar}>
        <div>
          <img className={classes.logo} alt="LOGO" src={Logo} />
        </div>
        {canPassCondition && (
          <div>
            {menus &&
              menus.map((data, index) => (
                <>
                  <div key={index} className={classes.menuItem}>
                    {data.path ? (
                      <Link to={data.path} className={classes.Link}>
                        {data.icon}
                        <p className={classes.label}> {data.label}</p>
                      </Link>
                    ) : (
                      <div
                        className={classes.Link}
                        onClick={() => toggleSubMenu(index)}
                      >
                        {data.icon}
                        <p className={classes.label}> {data.label}</p>
                        {data.submenu && (
                          <span
                            className={classes.dropdown}
                            onClick={() => toggleSubMenu(index)}
                          >
                            {activeSubMenu === index ? (
                              <img
                                src={arrowDown}
                                className={classes.icon}
                                alt="Arrow Down Icon"
                              />
                            ) : (
                              <img
                                src={arrowUp}
                                className={classes.icon}
                                alt="Arrow Up Icon"
                              />
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <div>
                    {activeSubMenu === index && data.submenu && (
                      <div>
                        {data.submenu.map((subItem, subIndex) => (
                          <Link
                            key={subIndex}
                            to={subItem.path}
                            className={classes.submenu}
                          >
                            {subItem.label}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              ))}
          </div>
        )}
      </div>
      <div className={classes.footer}>
        {name.firstName !== "" && (
          <div className={classes.personItem}>
            <IconUserCircle />
            <p className={classes.label}>
              {name.firstName} {name.lastName}
            </p>
          </div>
        )}

        <div className={classes.menuItem} onClick={handleLogout}>
          <IconPower />
          <p className={classes.label}>Logout</p>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
