import {
  EntityTypeEnum,
  KYCStatus,
  Persona,
  SVPCategory,
  SpvStatus,
} from "@funded-here-interface/common/src/constant/enum";
import { toast } from "react-toastify";

type PersonaType =
  | Persona.INVESTOR
  | Persona.DISTRIBUTOR
  | Persona.MERCHANT
  | Persona.MERCHANT_SPV
  | Persona.MERCHANT_SHAREHOLDER
  | Persona.AUTH_REP
  | Persona.INVESTOR_DIRECTOR
  | Persona.INVESTOR_SHAREHOLDER
  | SVPCategory.ISPV
  | SVPCategory.FUNDINGSPV;

export const roleToPath = (userDetail: any) => {
  const personaPathMap = {
    [Persona.INVESTOR]: () => getInvestorPath(userDetail),
    [Persona.DISTRIBUTOR]: () => getDistributorPath(userDetail),
    [Persona.MERCHANT]: () => getMerchantPath(userDetail),
    [Persona.MERCHANT_SPV]: () => getMerchantSpvPath(userDetail),
    [Persona.AUTH_REP]: () => getAuthRepPath(userDetail),
    [Persona.MERCHANT_SHAREHOLDER]: () =>
      getMerchantShareholderPath(userDetail),
    [Persona.MERCHANT_DIRECTOR]: () => getMerchantDirectorPath(userDetail),
    [SVPCategory.ISPV]: () => getIspvFundingSpvPath(userDetail),
    [SVPCategory.FUNDINGSPV]: () => getIspvFundingSpvPath(userDetail),
    [Persona.INVESTOR_SHAREHOLDER]: () =>
      getInvestorShareholderPath(userDetail),
    [Persona.INVESTOR_DIRECTOR]: () => getInvestorDirectorPath(userDetail),
  };

  const getPath =
    userDetail?.pendingOnBoardingAs === null
      ? () => navigateBasedOnCompletedOnboarding(userDetail)
      : personaPathMap[userDetail?.pendingOnBoardingAs as PersonaType];

  return getPath();
};

const getInvestorPath = (userDetail: any) => {
  if (userDetail?.unqualifiedInvestor === true) {
    return "/unqualified-investor";
  }

  if (userDetail?.organization === undefined) {
    if (
      userDetail?.entityType === EntityTypeEnum.INDIVIDUAL ||
      userDetail?.investorCategory?.entityType === EntityTypeEnum.INDIVIDUAL
    ) {
      return `/onboarding-guide-investor/${EntityTypeEnum.INDIVIDUAL}`;
    }

    if (
      userDetail?.entityType === EntityTypeEnum.INSTITUTION ||
      userDetail?.investorCategory?.entityType === EntityTypeEnum.INSTITUTION
    ) {
      return `/onboarding-guide-investor/${EntityTypeEnum.INSTITUTION}`;
    }
  }

  if (userDetail?.organization !== undefined) {
    if (userDetail?.entityType === EntityTypeEnum.INDIVIDUAL) {
      if (!userDetail?.didRegistrationId) {
        if (userDetail?.didPassportType) {
          return "/thanks-page";
        } else {
          return "/identify-me";
        }
      }

      if (!userDetail?.declarationAISubmitted) {
        return "/declaration-ai";
      } else if (userDetail?.kycStatus === KYCStatus.PENDING) {
        return "/thanks-page";
      }
    }

    if (userDetail?.entityType === EntityTypeEnum.INSTITUTION) {
      if (!userDetail?.didRegistrationId && userDetail?.isAuthRep) {
        if (userDetail?.didPassportType) {
          return "/thanks-page";
        } else {
          return "/identify-me";
        }
      } else if (userDetail?.kycStatus === KYCStatus.PENDING) {
        return "/thanks-page";
      }

      if (
        userDetail?.kycStatus === KYCStatus.APPROVED &&
        !userDetail?.allShareHoldersDirectorsOnBoarded
      ) {
        return "/pending-shareholder-onboard";
      }

      if (
        userDetail?.kycStatus === KYCStatus.APPROVED &&
        userDetail?.allShareHoldersDirectorsOnBoarded &&
        userDetail?.isAuthRep
      ) {
        return "/declaration-ai";
      }
    }
  }
};

const getDistributorPath = (userDetail: any) => {
  if (userDetail?.orgRole?.length === 0) {
    return "/welcome-trustee";
  }

  if (userDetail?.orgRole?.length !== 0) {
    if (userDetail?.kycStatus === KYCStatus.PENDING) {
      return "/thanks-page";
    }

    if (userDetail?.kycStatus === KYCStatus.APPROVED) {
      return "/upload-invoice";
    }
  }
};

const getAuthRepPath = (userDetail: any) => {
  const role = userDetail?.orgRole?.[0]?.role?.name;

  if (!userDetail?.didRegistrationId) {
    if (userDetail?.didPassportType) {
      return "/thanks-page";
    } else {
      return "/auth-rep-did-setup";
    }
  }

  if (role === Persona.INVESTOR) {
    if (!userDetail?.declarationAISubmitted) {
      return "/declaration-ai";
    }
  } else {
    if (userDetail?.allShareHoldersDirectorsOnBoarded) {
      return "/bmca-form";
    }
  }
};

const getMerchantPath = (userDetail: any) => {
  const hasCreditLimit = userDetail?.orgRole?.[0]?.creditLimit > 0;

  if (userDetail?.organization === undefined) {
    return "/onboarding-guide-merchant-loan";
  }

  if (userDetail?.organization !== undefined) {
    if (!userDetail?.didRegistrationId && userDetail?.isAuthRep) {
      if (userDetail?.didPassportType) {
        return "/thanks-page";
      } else {
        return "/borrower-identify-me";
      }
    }

    if (userDetail?.kycStatus === KYCStatus.PENDING || !hasCreditLimit) {
      return "/thanks-page";
    }

    if (
      userDetail?.kycStatus === KYCStatus.APPROVED &&
      hasCreditLimit &&
      !userDetail?.allShareHoldersDirectorsOnBoarded
    ) {
      return "/pending-shareholder-onboard";
    }

    if (
      userDetail?.kycStatus === KYCStatus.APPROVED &&
      hasCreditLimit &&
      userDetail?.allShareHoldersDirectorsOnBoarded &&
      userDetail?.isAuthRep
    ) {
      return "/bmca-form";
    }
  }
};

const getMerchantSpvPath = (userDetail: any) => {
  const hasCreditLimit = userDetail?.orgRole?.[0]?.creditLimit > 0;

  if (!userDetail?.didRegistrationId && userDetail?.isAuthRep) {
    if (userDetail?.didPassportType) {
      return "/thanks-page";
    } else {
      return "/borrower-identify-me";
    }
  }

  if (userDetail?.kycStatus === KYCStatus.PENDING || !hasCreditLimit) {
    return "/thanks-page";
  }

  if (userDetail?.spvStatus === SpvStatus.PENDING_INITIATION) {
    if (
      userDetail?.kycStatus === KYCStatus.APPROVED &&
      hasCreditLimit &&
      !userDetail?.allDirectorsSignedResolutionSPV
    ) {
      return "/pending-spv-board-resolution";
    }

    if (
      userDetail?.kycStatus === KYCStatus.APPROVED &&
      hasCreditLimit &&
      userDetail?.allDirectorsSignedResolutionSPV
    ) {
      return "/setup-spv";
    }
  }

  if (userDetail?.spvStatus === SpvStatus.INITIATED) {
    return "/pending-spv";
  }

  if (userDetail?.spvStatus === SpvStatus.COMPLETED) {
    if (userDetail?.spvDetails?.kycStatus === KYCStatus.PENDING) {
      return "/spv-kyc-pending";
    }

    if (!userDetail?.allShareHoldersDirectorsOnBoarded) {
      return "/pending-shareholder-onboard";
    }

    if (
      userDetail?.allShareHoldersDirectorsOnBoarded &&
      userDetail?.isAuthRep
    ) {
      return "/bmca-form";
    }
  }
};

const getMerchantShareholderPath = (userDetail: any) => {
  if (!userDetail?.didRegistrationId) {
    if (userDetail?.didPassportType) {
      return "/thanks-page";
    } else {
      return "/shareholder-did-setup";
    }
  }
};

const getMerchantDirectorPath = (userDetail: any) => {
  const role = userDetail?.orgRole?.[0]?.role?.name;
  if (role === Persona.MERCHANT_SPV) {
    if (!userDetail?.didRegistrationId) {
      if (userDetail?.didPassportType) {
        return "/thanks-page";
      } else {
        return "/director-did-setup";
      }
    }

    if (!userDetail?.orgSpvName && !userDetail?.signedBoardResolutionSpv) {
      return "/director-board-resolution-spv";
    }

    if (userDetail?.orgSpvName && !userDetail?.spvDetails) {
      return "/director-board-resolution";
    }

    if (userDetail?.orgSpvName && userDetail?.spvDetails) {
      for (const director of userDetail?.spvDetails?.boardOfDirectors) {
        if (userDetail?.email === director?.email) {
          if (userDetail?.spvDetails?.kycStatus === KYCStatus.PENDING) {
            return "/spv-kyc-pending";
          } else {
            return "/director-board-resolution";
          }
        }
      }
    }
  }

  if (role === Persona.MERCHANT) {
    if (!userDetail?.didRegistrationId) {
      if (userDetail?.didPassportType) {
        return "/thanks-page";
      } else {
        return "/director-did-setup";
      }
    }

    if (userDetail?.didRegistrationId) {
      return "/director-board-resolution";
    }
  }
};

const getInvestorShareholderPath = (userDetail: any) => {
  if (!userDetail?.didRegistrationId) {
    if (userDetail?.didPassportType) {
      return "/thanks-page";
    } else {
      return "/shareholder-did-setup";
    }
  }
};

const getInvestorDirectorPath = (userDetail: any) => {
  if (!userDetail?.didRegistrationId) {
    if (userDetail?.didPassportType) {
      return "/thanks-page";
    } else {
      return "/director-did-setup";
    }
  }

  if (userDetail?.didRegistrationId) {
    return "/director-board-resolution";
  }
};

const getIspvFundingSpvPath = (userDetail: any) => {
  if (userDetail?.organization === undefined) {
    return "/welcome";
  }

  if (
    userDetail?.organization !== undefined &&
    userDetail?.kycStatus === KYCStatus.PENDING
  ) {
    if (!userDetail?.didRegistrationId) {
      return "/identify-me";
    } else {
      return "/thanks-page";
    }
  }

  if (userDetail?.kycStatus === KYCStatus.APPROVED) {
    return "/funding-issuing-spv-onboard-completed";
  }
};

const navigateBasedOnCompletedOnboarding = (userDetail: any) => {
  const hasCreditLimit = userDetail?.orgRole?.[0]?.creditLimit > 0;

  if (
    userDetail?.orgRole?.[0]?.role?.name === Persona.INVESTOR &&
    userDetail?.entityType === EntityTypeEnum.INDIVIDUAL
  ) {
    return "/note-offer-room";
  }

  if (
    userDetail?.orgRole?.[0]?.role?.name === Persona.INVESTOR &&
    userDetail?.entityType === EntityTypeEnum.INSTITUTION
  ) {
    for (const director of userDetail?.boardOfDirectors) {
      if (director?.email === userDetail?.email) {
        if (!userDetail?.isAuthRep) {
          return null;
        }
      }
    }

    if (userDetail?.isAuthRep) {
      return "/note-offer-room";
    } else {
      return "/subscribed-invested-notes";
    }
  }

  if (
    userDetail?.orgRole?.[0]?.role?.name === Persona.MERCHANT ||
    userDetail?.orgRole?.[0]?.role?.name === Persona.MERCHANT_SPV
  ) {
    if (hasCreditLimit && !userDetail?.bmcaSigned) {
      toast.warning(
        "Your Master SKU agreement is pending FundedHere's signature."
      );
    }

    if (hasCreditLimit && userDetail?.bmcaSigned) {
      return "/merchant-dashboard/Overall";
    } else if (userDetail?.isAuthRep && userDetail?.bmcaSigned) {
      return "/loan-agreement";
    }
  }

  if (
    userDetail?.orgRole?.[0]?.role?.name === SVPCategory.ISPV ||
    userDetail?.orgRole?.[0]?.role?.name === SVPCategory.FUNDINGSPV
  ) {
    return "/funding-issuing-spv-onboard-completed";
  }

  if (userDetail?.orgRole?.[0]?.role?.name === Persona.DISTRIBUTOR) {
    return "/upload-invoice";
  }
};
