import { createStyles } from "@mantine/styles";

export const useStyle = createStyles(() => ({
  instruction: {
    width: "480px",
    top: "140px",
    left: "259px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    fontStyle: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    marginTop: "15px",
  },
  buttonWrapper: {
    paddingRight: "10px",
  },
  download1: {
    marginTop: "30px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginBottom: "10px",
  },
  doc: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  docHead: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    marginBottom: "30px",
  },
  checkbox: {
    marginBottom: "70px",
  },
}));
