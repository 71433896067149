import { FC, useEffect } from "react";
import { useStyles } from "./Auth.styles";
import {
  Checkbox,
  Group,
  MantineProvider,
  Select,
  Tooltip,
} from "@mantine/core";
import {
  EMAIL_REGEX,
  InputGroup,
  NAME_REGEX,
} from "@funded-here-interface/common";

interface IProps {
  numOfDirectors: number;
  allDirectorsEmailPresent: boolean;
  directorChecked: boolean;
  setDirectorChecked: (value: boolean) => void;
  error: {
    repEmail: string;
    representative_first_name: string;
    representative_last_name: string;
    representative_title: string;
  };
  repEmail: string;
  directorEmails: string[];
  handleAuthDirectorEmailChange: (email: string) => void;
  setRepEmail: (value: string) => void;
  setRepresentativeFirstName: (value: string) => void;
  representativeFirstName: string;
  setRepresentativeLastName: (value: string) => void;
  representativeLastName: string;
  setRepresentativeTitle: (value: string) => void;
  representative_title: string;
}

const Auth: FC<IProps> = (props) => {
  const { classes } = useStyles();
  const {
    numOfDirectors,
    allDirectorsEmailPresent,
    directorChecked,
    setDirectorChecked,
    error,
    repEmail,
    directorEmails,
    handleAuthDirectorEmailChange,
    setRepEmail,
    setRepresentativeFirstName,
    representativeFirstName,
    setRepresentativeLastName,
    representativeLastName,
    setRepresentativeTitle,
    representative_title,
  } = props;

  useEffect(() => {
    if (numOfDirectors < 1 || !allDirectorsEmailPresent) {
      setDirectorChecked(false);
    }
  }, [numOfDirectors, allDirectorsEmailPresent, setDirectorChecked]);

  return (
    <>
      <Group style={{ width: "640px" }}>
        <p className={classes.authTitle}>Authorized Representative</p>
        <MantineProvider theme={{ cursorType: "pointer" }}>
          <Tooltip
            label="Requires at least 1 Director"
            disabled={numOfDirectors > 1 && allDirectorsEmailPresent}
          >
            <div>
              <Checkbox
                disabled={numOfDirectors === 0 || !allDirectorsEmailPresent}
                checked={directorChecked}
                labelPosition="left"
                label="Is the authorized representative one of the above directors?"
                onChange={(event) =>
                  setDirectorChecked(event.currentTarget.checked)
                }
              />
            </div>
          </Tooltip>
        </MantineProvider>
      </Group>
      {directorChecked && (
        <Select
          error={error.repEmail}
          label="Email"
          value={repEmail}
          placeholder="Select Director"
          data={directorEmails}
          onChange={handleAuthDirectorEmailChange}
          styles={{ input: { height: "42px", marginTop: "3px" } }}
        />
      )}
      {!directorChecked && (
        <>
          <InputGroup
            isErrored={error.repEmail}
            onChange={(value: any) => {
              error.repEmail = "";
              setRepEmail(value.replace(EMAIL_REGEX, "").trim());
            }}
            labelText="Email"
            id="repEmail"
            value={repEmail}
          />
          <InputGroup
            isErrored={error.representative_first_name}
            onChange={(value: any) => {
              error.representative_first_name = "";
              setRepresentativeFirstName(value.replace(NAME_REGEX, ""));
            }}
            labelText="First / given name (as in passport)"
            value={representativeFirstName}
            id="representative_first_name"
          />
          <InputGroup
            isErrored={error.representative_last_name}
            onChange={(value: any) => {
              error.representative_last_name = "";
              setRepresentativeLastName(value.replace(NAME_REGEX, ""));
            }}
            labelText="Last name (as in passport)"
            value={representativeLastName}
            id="representative_last_name"
          />
          <InputGroup
            isErrored={error.representative_title}
            onChange={(value: any) => {
              error.representative_title = "";
              setRepresentativeTitle(value.replace(NAME_REGEX, ""));
            }}
            labelText="Role in the company"
            id="representative_title"
            value={representative_title}
          />
        </>
      )}
    </>
  );
};

export default Auth;
