import { FC } from "react";
import LTVAcceptanceSKUConfirm from "../LTVacceptance-Confirmed/LTVAcceptanceSKUConfirm";
import { useStyles } from "./AuthRepLoanAgreement.styles";
import { Text } from "@mantine/core";

const AuthRepLoanAgreement: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.loanApplicationContainer}>
      <Text className={classes.heading}>Loan Application</Text>
      <LTVAcceptanceSKUConfirm />
    </div>
  );
};

export default AuthRepLoanAgreement;
