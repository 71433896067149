import {
  Button,
  InputGroup,
  InputSelect,
  NAME_REGEX,
  NO_REGEX,
  SWIFT_CODE_REGEX,
  color,
} from "@funded-here-interface/common";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyle } from "../../styles/BankDetailsCss";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import {
  setBankNameRedux,
  setAccountNoRedux,
  setSwiftCodeReduct,
  setAccountTypeRedux,
  setAccountHolderNameRedux,
} from "../../features/indinvestor/bankSliceInd";
import { bankAccountTypes } from "../../constants/bank";
import BankAccNameDisclaimer from "../../components/BankAccNameDisclaimer/BankAccNameDisclaimer";
import { toast } from "react-toastify";

const BankDetailsInd: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { userFirstName, userLastName } = useSelector(
    (state: RootState) => state.auth
  );

  const {
    bankName: bank_name,
    accountNumber: bankAccountNo,
    accountType: accounttype,
    swiftCode: swiftCodeRedux,
    accountHolderName: accountHolderNameRedux,
  } = useSelector((state: RootState) => state.bankInd);

  const [bankName, setBankName] = useState(bank_name || "");
  const [accountNumber, setAccountNumber] = useState(bankAccountNo || "");
  const [accountType, setAccountType] = useState(accounttype || "");
  const [swiftCode, setSwiftCode] = useState(swiftCodeRedux || "");
  const [accountHolderName, setAccountHolderName] = useState(
    accountHolderNameRedux || ""
  );
  const handleBack = () => {
    dispatch(setBankNameRedux({ bankName }));
    dispatch(setAccountNoRedux({ accountNumber }));
    dispatch(setSwiftCodeReduct({ swiftCode }));
    dispatch(setAccountTypeRedux({ accountType }));
    dispatch(setAccountHolderNameRedux({ accountHolderName }));

    Navigate("/ind-biz-profile");
  };

  const [error, setError] = useState({
    bank_name: "",
    bankAccountNo: "",
    account_type: "",
    swift_code: "",
    accountType: "",
    accountHolderName: "",
  });

  const handleNext = () => {
    const customErr = { ...error };
    let valid = true;

    if (!bankName) {
      valid = false;
      customErr.bank_name = "Bank Name is required";
    }
    if (!swiftCode) {
      valid = false;
      customErr.swift_code = "Swift Code is required";
    }
    if (!accountNumber) {
      valid = false;
      customErr.bankAccountNo = "Bank Account no is required";
    }

    if (!accountType) {
      valid = false;
      customErr.accountType = "Account Type is required";
    }

    if (userFirstName && userLastName) {
      const userRegisteredName = `${userFirstName.toLowerCase()} ${userLastName.toLowerCase()}`;

      if (accountHolderName.toLowerCase() !== userRegisteredName) {
        valid = false;
        toast.warn("Account Holder Name should be the same as registered name");
      }
    }

    if (valid) {
      dispatch(setBankNameRedux({ bankName }));
      dispatch(setAccountNoRedux({ accountNumber }));
      dispatch(setSwiftCodeReduct({ swiftCode }));
      dispatch(setAccountTypeRedux({ accountType }));
      dispatch(setAccountHolderNameRedux({ accountHolderName }));

      Navigate("/doc-upload-ind");
    } else {
      setError(customErr);
    }
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <InputGroup
        isErrored={error.bank_name}
        onChange={(value: string) => {
          error.bank_name = "";
          setBankName(value.replace(NAME_REGEX, ""));
        }}
        labelText="Bank Name"
        id="bank_name"
        value={bankName}
      />

      <InputGroup
        isErrored={error.bankAccountNo}
        onChange={(value: string) => {
          error.bankAccountNo = "";
          setAccountNumber(value.replace(NO_REGEX, ""));
        }}
        labelText="Bank Account no."
        id="account_number"
        value={accountNumber}
      />
      <InputSelect
        isErrored={error?.accountType}
        onChange={(value: string) => {
          error.accountType = "";
          setAccountType(value);
        }}
        labelText="Account type"
        id="accountType"
        value={accountType}
        data={bankAccountTypes}
      />

      <InputGroup
        isErrored={error.swift_code}
        onChange={(value: string) => {
          error.swift_code = "";
          setSwiftCode(value.replace(SWIFT_CODE_REGEX, ""));
        }}
        labelText="SWIFT Code"
        value={swiftCode}
        id="swift_code"
      />
      <BankAccNameDisclaimer />
      <InputGroup
        isErrored={error.accountHolderName}
        onChange={(value: string) => {
          error.accountHolderName = "";
          setAccountHolderName(value);
        }}
        labelText="Account Holder Name (should match with registered name)"
        value={accountHolderName}
        id="account_holder_name"
      />

      <div className={classes.buttonDiv}>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={handleBack}
            backgroundColor={color.WHITE}
            textColor={color.FHGREEN}
            children="Back"
            border="1px solid #338F86"
            width="100%"
          />
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={handleNext}
            textColor={color.WHITE}
            backgroundColor={color.FHGREEN}
            children="Next"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default BankDetailsInd;
