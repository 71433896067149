import { Group, MantineProvider, Modal, Radio } from "@mantine/core";
import { FC, useState } from "react";
import { useStyle } from "./InvestorDeclarationModal.styles";
import { Button, color } from "@funded-here-interface/common";
import useInvestorDeclaration from "../../../../hooks/useInvestorDeclaration";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setInvestorDeclarationQualifiedRedux } from "../../../../features/institutionalInvestor/bizSlice";

interface IProps {
  opened: boolean;
  close: () => void;
  onError: (value: string) => void;
}

const InvestorDeclarationModal: FC<IProps> = (props) => {
  const { classes } = useStyle();
  const { opened, close, onError } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [isQualified, setIsQualified] = useState(false);
  const useInvestorDeclarationMutation = useInvestorDeclaration();
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onRadioClick = (isYes: boolean) => {
    setIsChecked(true);
    setIsQualified(isYes);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    useInvestorDeclarationMutation.mutate(
      { token, isQualified },
      {
        onSuccess: () => {
          dispatch(
            setInvestorDeclarationQualifiedRedux({
              investorDeclarationQualified: isQualified,
            })
          );

          close();
          setIsLoading(false);
        },
        onError: (e) => {
          setIsLoading(false);
          onError((e as Error).message);
        },
      }
    );
  };

  return (
    <Modal
      size="30rem"
      withCloseButton={false}
      closeOnClickOutside={false}
      opened={opened}
      onClose={close}
    >
      <div className={classes.container}>
        <h1 className={classes.header}>
          Before we proceed please help us answer this
        </h1>
        <p className={classes.body}>
          Your corporation’s net assets are exceeding S$10 million in value (or
          its equivalent in a foreign currency) based on its most recent audited
          or certified balance sheet
        </p>
        <MantineProvider theme={{ cursorType: "pointer" }}>
          <Radio.Group className={classes.radio}>
            <Group mt="xs">
              <Radio
                label="Yes"
                value="yes"
                onClick={() => onRadioClick(true)}
              />
              <Radio
                label="No"
                value="no"
                onClick={() => onRadioClick(false)}
              />
            </Group>
          </Radio.Group>
        </MantineProvider>

        <div className={classes.buttonGroup}>
          <Button
            disabled={!isChecked || isLoading}
            width="100%"
            textColor={color.WHITE}
            backgroundColor={color.FHGREEN}
            children="OK"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InvestorDeclarationModal;
