import { Text } from "@mantine/core";
import { FC } from "react";
import line from "@funded-here-interface/common/src/assets/Line6.png";
import { Button, color } from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useStyle } from "./WelcomeIndividual.styles";
import useLogoutMutation from "../../hooks/useLogout";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";

const WelcomeIndividual: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const token = useSelector((state: RootState) => state.auth.token);
  const logoutMutation = useLogoutMutation();

  const handleSubmit = () => {
    Navigate("/ind-biz-profile");
  };

  const handleLogout = async () => {
    logoutMutation.mutate(
      { token },
      {
        onSuccess: () => {
          Navigate("/login");
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <OnboardingLayout
      step={0}
      content={
        <div style={{ marginTop: "0px" }}>
          <Text className={classes.headerText}>Welcome to FundedHere!</Text>
          <Text className={classes.instruction} style={{ marginTop: "10px" }}>
            As part of our commitment to maintaining the highest standards of
            security and compliance, we kindly request you to submit your
            details.
          </Text>
          <Text className={classes.instruction} style={{ marginTop: "20px" }}>
            You will need approximately 10 minutes & please keep the following
            details/documents handy.
          </Text>
          <Text className={classes.subHeading}>STEPS</Text>
          <Text className={classes.text}>Basic Registration (Completed)</Text>
          <div className={classes.stepsToComplete}>
            <div className={classes.progress}>
              <div className={classes.eclipse}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <circle cx="5" cy="5" r="5" fill="#151515" />
                </svg>
              </div>
              <div className={classes.line}>
                <img src={line} alt="line" />
              </div>
            </div>
            <ol
              style={{
                paddingLeft: "50px",
                paddingTop: "0px",
                marginTop: "10px",
              }}
            >
              <li>
                <Text className={classes.list}>Individual profile</Text>
              </li>
              <li>
                <Text className={classes.list}>
                  Bank details (for deposits & withdrawals)
                </Text>
              </li>
              <li>
                <Text className={classes.list}>
                  Documents upload (provide full list of docs)
                </Text>
              </li>
              <li>
                <Text className={classes.list}>
                  Agreement signing- Declaration of AI
                </Text>
              </li>
            </ol>
          </div>
          <div className={classes.buttonDiv}>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleSubmit}
                textColor={color.WHITE}
                backgroundColor={color.FHGREEN}
                children="Let's Go"
                width="254px"
              />
            </div>
            <ToastContainer />
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleLogout}
                backgroundColor={color.WHITE}
                textColor={color.FHGREEN}
                children="I'll do it later"
                border="1px solid #338F86"
                width="300px"
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default WelcomeIndividual;
