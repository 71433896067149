import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(() => ({
  mainContainer: {
    width: "100%",
    height: "max-content",
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    margin: 0,
    position: "relative",
  },
  sideBarContainer: {
    position: "fixed",
    height: "100vh",
    backgroundColor: "#15292B",
  },
  stepContainer2: {
    position: "absolute",
    width: "80%",
    height: 337,
    top: 50,
    left: 250,
    gap: 8,
    marginBottom: 60,
  },
}));
