import { Text } from "@mantine/core";
import { InputGroup, ForgotPasswordInput } from "@funded-here-interface/common";
import { ChangeEvent, FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setToken,
  setUserID,
  setUserSpv,
  setOnboardingType,
  setUserEmail,
  setRole,
  setOrgId,
  setUserFirstName,
  setUserLastName,
  setAgreedToMembershipAgreement,
  setOrgRoleId,
  setIsAuthRep,
  setHasCreditLimit,
  setEntityType,
  setIsAdminAuthRep,
  resetAuthState,
  setSpvName,
} from "../../features/auth/authSlice";
import { useStyles } from "./LoginCss";
import { ToastContainer, toast } from "react-toastify";
import useLoginMutation from "@funded-here-interface/common/src/hooks/useLogin";
import "react-toastify/dist/ReactToastify.css";
import Flogo from "../../assets/Funded_Here_LogoA4.svg";
import {
  KYCStatus,
  Persona,
} from "@funded-here-interface/common/src/constant/enum";
import useGetUser from "@funded-here-interface/common/src/hooks/useGetUsers";
import { roleToPath } from "../../Utils/navigation";

const Login: FC = () => {
  const { classes } = useStyles();
  const { redirectPath } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [persona, setPersona] = useState("");
  const [error, setError] = useState({
    email_error: "",
    pass_error: "",
    persona_error: "",
  });
  const navigate = useNavigate();
  const mutation = useLoginMutation();
  const getUserMutation = useGetUser();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    if (!persona) {
      toast.error("Select a persona");

      return;
    }
    if (email && password && error && persona) {
      mutation.mutate(
        { email, password, persona: persona },
        {
          onSuccess: async (data) => {
            sessionStorage.setItem("Auth", data);
            const token = data;
            dispatch(setToken({ token }));

            getUserMutation.mutate(
              { token },
              {
                onSuccess: (userDetail) => {
                  sessionStorage.setItem(
                    "orgRoleId",
                    userDetail?.currentOrgRoleId
                  );

                  if (userDetail?.kycStatus === KYCStatus.REJECTED) {
                    toast.error("KYC Application has been Rejected.");

                    return;
                  }

                  if (
                    userDetail?.spvDetails?.kycStatus === KYCStatus.REJECTED
                  ) {
                    toast.error("SPV KYC Application has been Rejected.");

                    return;
                  }

                  const hasCreditLimit =
                    userDetail?.orgRole?.[0]?.creditLimit > 0;

                  dispatch(
                    setAgreedToMembershipAgreement({
                      agreedToMembershipAgreement:
                        userDetail?.agreedToMembershipAgreement,
                    })
                  );
                  dispatch(
                    setHasCreditLimit({
                      hasCreditLimit,
                    })
                  );
                  dispatch(
                    setIsAuthRep({
                      isAuthRep: userDetail?.isAuthRep,
                    })
                  );
                  dispatch(
                    setIsAdminAuthRep({
                      isAdminAuthRep: userDetail?.isAdminAuthRep,
                    })
                  );
                  dispatch(
                    setEntityType({ entityType: userDetail?.entityType })
                  );
                  dispatch(setUserID({ userId: userDetail?.id }));
                  dispatch(setUserEmail({ userEmail: userDetail?.email }));
                  dispatch(setUserSpv({ spvStatus: userDetail?.spvStatus }));
                  dispatch(setSpvName({ spvName: userDetail?.spvName }));
                  dispatch(
                    setOnboardingType({ type: userDetail?.pendingOnBoardingAs })
                  );
                  dispatch(
                    setRole({ role: userDetail?.orgRole?.[0]?.role?.name })
                  );
                  dispatch(setOrgId({ orgId: userDetail?.organization }));
                  dispatch(
                    setOrgRoleId({ orgId: userDetail?.currentOrgRoleId })
                  );
                  dispatch(
                    setUserFirstName({
                      userFirstName: userDetail?.firstName || null,
                    })
                  );
                  dispatch(
                    setUserLastName({
                      userLastName: userDetail?.lastName || null,
                    })
                  );

                  if (redirectPath) {
                    navigate(`/${redirectPath}`);
                  } else {
                    const path = roleToPath(userDetail);

                    if (path) {
                      navigate(path);
                    } else {
                      dispatch(resetAuthState());
                      toast.warn("No action required at this time");
                    }
                  }
                },
                onError: (e) => {
                  toast.error((e as Error).message);
                },
              }
            );
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    } else {
      const newError = { ...error };
      if (!email) {
        newError.email_error = "Email is required";
      }
      if (!password) {
        newError.pass_error = "Password is required";
      }
      setError(newError);
    }
  };

  const handlePersonaChange = (e: any) => {
    setPersona(e.target.value);
    setError((prevState) => ({ ...prevState, persona_error: "" }));
  };

  return (
    <div className={classes.parentContainer}>
      <div className={classes.loginContainer}>
        <div className={classes.loginHeadingsub}>
          <div>
            <img src={Flogo} className={classes.logo} alt="" />
          </div>
        </div>
        <div className={classes.inputGroupContainer}>
          <div style={{ marginBottom: "20px" }}>
            <Text className={classes.roleLabel}>Select Role</Text>
            <div className={classes.radioButtons}>
              <label className={classes.label}>
                <input
                  type="radio"
                  name="persona"
                  value="investor"
                  checked={persona === Persona.INVESTOR}
                  onChange={handlePersonaChange}
                />
                Investor
              </label>
              <label className={classes.label}>
                <input
                  type="radio"
                  name="persona"
                  value="merchant"
                  checked={persona === Persona.MERCHANT}
                  onChange={handlePersonaChange}
                />
                Merchant
              </label>
              <label className={classes.label}>
                <input
                  type="radio"
                  name="persona"
                  value="distributor"
                  checked={persona === Persona.DISTRIBUTOR}
                  onChange={handlePersonaChange}
                />
                Supplier
              </label>
              <label className={classes.label}>
                <input
                  type="radio"
                  name="persona"
                  value="others"
                  checked={persona === Persona.OTHERS}
                  onChange={handlePersonaChange}
                />
                Others
              </label>
            </div>
          </div>
          <div className={classes.inputstyle}>
            <InputGroup
              labelText="Email"
              id="your-email"
              value={email}
              placeHolder="Enter Email Address"
              onChange={(value: string) => setEmail(value)}
              isErrored={error.email_error}
            />
          </div>

          <div className={classes.inputstyle}>
            <ForgotPasswordInput
              className="LoginForgotPasswordInput"
              name="password"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value.trim());
              }}
              forgotPassword={() => navigate("/password-reset")}
              isErrored={error.pass_error}
            />
          </div>

          <div className={classes.buttonDiv}>
            <input
              className={classes.input}
              type="submit"
              value="Login"
              onClick={handleLogin}
            />
          </div>
          <div className={classes.signInFooter}>
            <Text className={classes.noAccount}>Don't have an account?</Text>

            <Text
              className={classes.signUp}
              onClick={() => navigate("/registration")}
            >
              Sign up
            </Text>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Login;
