import { color } from "@funded-here-interface/common";
import { createStyles } from "@mantine/styles";

export const useStyle = createStyles(() => ({
  container: {
    padding: "60px",
  },
  header: {
    fontSize: "20px",
  },
  body: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  buttonGroup: {
    marginTop: "30px",
    borderRadius: "10px",
  },
  radio: {
    marginTop: "15px",
  },
  list: {
    marginBottom: "50px",
  },
  tooltip: {
    color: color.FHGREEN,
    cursor: "pointer",
    fontWeight: 700,
  },
  subList: {
    marginTop: "20px",
    marginLeft: "20px",
  },
}));
