import { ComponentPropsWithoutRef, FC } from "react";
import VQR from "./VQR";
import { BASE_LUMINOR_ID_URL } from "../constants/domain";

interface VQRRegisterProps extends ComponentPropsWithoutRef<"div"> {
  onScan?: (data: any) => void;
  onInit?: (data: any) => void;
  appSchemePrefix?: string;
  appSchemeSuffix?: string;
  correlationId: string;
  queryId: string;
  queryOwner: string;
}
// const queryId = REGISTER_LUMINOR_DID_QUERY_ID + "," + CLIENT_ID_LUMINOR_DID;

export const VQRRegister: FC<VQRRegisterProps> = ({
  onScan,
  onInit,
  correlationId,
  queryId,
  queryOwner,
  ...props
}) => {
  const metadataObj = {
    correlationId: correlationId,
  };
  const metadata = JSON.stringify(metadataObj).replace(/"/g, "'");

  return (
    <VQR
      style={{ display: "flex", justifyContent: "left" }}
      queryId={encodeURIComponent(queryId)}
      mode="dynamic"
      qrcodeOwner={queryOwner}
      appSchemePrefix={encodeURIComponent(
        `${BASE_LUMINOR_ID_URL}/?link=https%3A%2F%2Fsharering.network%2Fvql%3F`
      )}
      appSchemeSuffix={encodeURIComponent(
        "apn=network.sharering.luminor.id&isi=6474107601&ibi=network.sharering.luminor.id"
      )}
      onInit={onInit}
      onScan={onScan}
      metadata={metadata}
      {...props}
    />
  );
};
