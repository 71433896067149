import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  resetMssgContainer: {
    width: "530px",
    height: "190px",
    borderRadius: "4px",
    padding: "25px 5px",
    boxShadow:
      "0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)",
    gap: "16px",
  },
  headingText: {
    height: "46px",
    padding: "25px 87px 13px 87px",
    fontFamily: "SF Pro Display",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  crossIcon: {
    marginLeft: "70px",
    marginTop: "25px",
  },

  emailSentMessage: {},
  text: {
    textAlign: "left",
    letterSpacing: "0em",
    fontFamily: "SF Pro Text",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.09px",
    wordWrap: "break-word",
    overflow: "visible",
  },
  subHeading: {
    height: "46px",
    padding: "25px 87px 13px 87px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },
}));
