import { FC, useState, useEffect } from "react";
import { Button } from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useStyle } from "./LoanAcceptance.styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyTable from "../../components/LoanTable/LoanTable";
import { Text } from "@mantine/core";
import useGetSkusPendingMerchantSigning from "../../hooks/useGetSkusPendingMerchantSigning";

type LTVAcceptanceSKUProps = {
  onEvent?: (eventName: string) => void;
};

const LTVAcceptanceSKUConfirm: FC<LTVAcceptanceSKUProps> = ({ onEvent }) => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const { token, isAuthRep } = useSelector((state: RootState) => state.auth);
  const [skuSigningData, setSkuSigningData] = useState([]);
  const [selectedSKUIds, setSelectedSKUIds] = useState<string[]>([]);
  const useGetSkusPendingMerchantSigningMutation =
    useGetSkusPendingMerchantSigning();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    useGetSkusPendingMerchantSigningMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setSkuSigningData(data);
          setIsLoading(false);
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  }, []);

  const handleCheckboxChange = async (skuId: string) => {
    if (selectedSKUIds.includes(skuId)) {
      setSelectedSKUIds(selectedSKUIds.filter((id) => id !== skuId));
    } else {
      setSelectedSKUIds([...selectedSKUIds, skuId]);
    }
  };

  const handleOnVerifyMeNext = () => {
    Navigate("/loanApplication");
  };

  const isButtonDisabled =
    !skuSigningData || skuSigningData.length === 0 || isLoading;

  return (
    <div>
      <div>
        <Text>
          We have received the deposit for the following loans and we have
          obtained <br /> the funding for them. All we need is a signed
          agreement from you.
        </Text>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <MyTable
            data={skuSigningData}
            selectedSKUId={selectedSKUIds}
            onCheckboxChange={handleCheckboxChange}
          />
        </div>

        {isAuthRep && (
          <div style={{ borderLeft: "2px solid black" }}>
            <div style={{ marginLeft: 20 }}>
              <Text style={{ fontWeight: 700 }} className={classes.buttonDiv}>
                Sign agreement for these SKU’s
              </Text>
              <div className={classes.buttonDiv}>
                <div className={classes.buttonWrapper}>
                  <Button
                    disabled={isButtonDisabled}
                    onClick={handleOnVerifyMeNext}
                    textColor="#ffff"
                    backgroundColor="#338F86"
                    children="Next"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LTVAcceptanceSKUConfirm;
