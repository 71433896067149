import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import HttpClientXml from "../../../../Utils/HttpClientXmInv";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const DataTableown = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table {...getTableProps()} style={{ borderSpacing: "0", width: "100%" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  borderBottom: "1px solid black",
                  background: "#f2f2f2",
                  padding: "8px",
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={{ borderBottom: "1px solid black", padding: "8px" }}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const App = () => {
  const [AllData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const orgRoleId = parseInt(localStorage.getItem("orgRoleId"));
  const token = useSelector((state) => state.auth.token);
  // console.log(AllData, "orgRoleId");
  const columns = [
    { Header: "NOTE ID", accessor: "noteId" },
    { Header: "Date", accessor: "datetime" },
    { Header: "Description", accessor: "description" },
    {
      Header: "Inflow",
      accessor: "inFlowAmount",
      Cell: ({ value }) => value.toLocaleString("en-US"),
    },
    {
      Header: "Outflow",
      accessor: "outFlowAmount",
      Cell: ({ value }) => value.toLocaleString("en-US"),
    },
    // { Header: "Cash Balance", accessor: "cash_balance" },

    // { Header: "Total Asset", accessor: "total_asset" },
  ];

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData(
        `get-e-statement`,
        {},
        token,
        "POST"
      );
      console.log(res, "resEstatement");
      if (res && res.status) {
        setIsLoading(false);

        const sortedData = res.data.sort((a, b) => {
          return (
            moment(b.datetime, "YYYY-MM-DD HH:mm:ss").valueOf() -
            moment(a.datetime, "YYYY-MM-DD HH:mm:ss").valueOf()
          );
        });
        setAllData(sortedData);
      } else {
        setAllData([]);
        toast.error(res?.message || "Something Wrong");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return <DataTableown columns={columns} data={AllData} />;
};

export default App;
