import { MantineTheme, createStyles } from "@mantine/styles";

const bgImage =
  "https://app.fundedhere.com/static/media/newlogin.8929ecba6fdc8969fdb68bda8aa1d044.svg";

export const useStyles = createStyles((theme: MantineTheme) => ({
  parentContainer: {
    marginTop: "100px",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    minHeight: "100vh",
    alignItems: "center",
    paddingTop: "10%",
    paddingRight: "15%",
    paddingBottom: "10%",
    background: `url(${bgImage}) no-repeat center center`,
    backgroundSize: "cover",
    boxSizing: "border-box",
    [theme.fn.smallerThan("sm")]: {
      padding: "0",
      justifyContent: "center",
      backgroundPosition: `left`,
      backgroundPositionX: "-200px",
    },
  },
  loginContainer: {
    width: "500px",
    background: "#ffffffe6",
    minHeight: "530px",
    gap: "28px",
    borderRadius: 6,
    padding: "20px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
    transition: "all 0.3s ease",
    boxSizing: "border-box",
    [theme.fn.smallerThan("sm")]: {
      width: "90%",
      height: "auto",
      padding: "10px",
    },
  },
  roleLabel: {
    fontSize: "14px",
    color: "#333",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  radioButtons: {
    display: "flex",
    fontSize: "16px",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "10px",
    [theme.fn.smallerThan("sm")]: {
      fontSize: "12px",
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "5px",
    [theme.fn.smallerThan("sm")]: {
      fontSize: "12px",
    },
  },

  loginHeading1: {
    position: "relative",
    marginBottom: "20px",
    padding: "25px",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29px",
    textAlign: "left",
  },
  loginHeading: {
    fontSize: "20px",
    fontWeight: 800,
    paddingTop: 8,
    textAlign: "left",
  },
  loginHeadingsub: {
    paddingLeft: "60px",
    [theme.fn.smallerThan("sm")]: {
      paddingLeft: "20px",
    },
  },
  logo: {
    marginTop: "30px",
    width: "auto",
    height: 65,
    [theme.fn.smallerThan("sm")]: {
      height: 45,
    },
  },
  inputstyle: {
    width: "100%",
    marginLeft: "0px",
  },
  inputGroupContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px",
    [theme.fn.smallerThan("sm")]: {
      padding: "20px",
    },
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "6px",
  },
  buttonDiv: {
    paddingTop: "5px",
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
  },

  signInFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "20px",
    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },
  noAccount: {
    fontSize: "19px",
    fontWeight: 400,
    color: "#4F566B",
    [theme.fn.smallerThan("sm")]: {
      fontSize: "16px",
    },
  },
  signUp: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#338F86",
    cursor: "pointer",
  },
  li: {
    color: "#595959",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "normal",
  },
  input: {
    padding: 10,
    marginBottom: 10,
    backgroundColor: "#2e7d72",
    color: "#000",
    border: "1px solid #2e7d72",
    borderRadius: 4,
    fontSize: "17px",
    fontWeight: 700,
    cursor: "pointer",
    width: "100%",
    height: 45,
    marginTop: 10,
    transition: "background-color 0.3s, transform 0.3s",
    "&:hover": {
      border: "1px solid #ddd",
      backgroundColor: "#2e7d72",
      color: "#fff",
      transform: "translateY(-2px)",
    },
  },
}));
