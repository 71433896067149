import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "24px",
  },
  loanApplicationContainer: {
    width: "calc(100vw - 270px - 96px)",
  },
}));
