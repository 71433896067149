import { FC, ReactNode } from "react";
import { MantineTheme, createStyles, Text } from "@mantine/core";

const useStyles = createStyles((theme: MantineTheme) => ({
  checkboxContainer: {
    width: "247px",
    height: "20px",
    marginBottom: "6px",
  },
}));

type Props = {};

const EmptyDiv: FC<Props> = (props) => {
  const { classes } = useStyles();
  return <div className={classes.checkboxContainer}></div>;
};

export default EmptyDiv;
