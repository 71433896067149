export const isEmailValid = (emailId: any): boolean => {
  if (/^\S+@\S+$/.test(emailId)) {
    return true;
  } else {
    return false;
  }
};

export const isPasswordValid = (password: any): boolean => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-+!@#$%^&*])[a-zA-Z0-9-+!@#$%^&*]{8,}$/;
  console.log(regex.test(password));
  return regex.test(password);
};
