import React, { useEffect, useState } from "react";
import moneyimg1 from "@funded-here-interface/common/src/dashboard/Images/Moneyimg1.PNG";
import moneyimg2 from "@funded-here-interface/common/src/dashboard/Images/Moneyimg2.PNG";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { getMerchant } from "../../../features/merchant/MerchantSlice/MerchantSlice";

import AuthServices from "../../../services/AuthServices";

const Graphamount = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);

  const dispatch = useDispatch();

  const [merchantDetails1, setMerchantDetails1] = useState({});
  const token = useSelector((state) => state.auth.token);

  const [orgRole_id, setOrgRole_id] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        const res = await AuthServices.getMerchant(token);
        setMerchantDetails1(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoadingData(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const orgRoleId = JSON.parse(localStorage.getItem("orgRoleId"));
    if (orgRoleId) {
      setOrgRole_id(orgRoleId);
    }
  }, []);

  const loop = [
    {
      id: 1,
      head: "Funding Limit",
      num: merchantDetails1?.[0]?.funding_limit,
    },
    { id: 2, head: "Loan Amount", num: merchantDetails1?.[0]?.loan_amount },
    { id: 3, head: "Repaid Amount", num: merchantDetails1?.[0]?.repaid_amount },
    {
      id: 4,
      head: "Balance Amount",
      num: merchantDetails1?.[0]?.balance_amount,
    },
  ];

  const loop2 = [
    { id: 1, head: "QC", num: merchantDetails1?.[0]?.moePaid },
    { id: 2, head: "SF", num: merchantDetails1?.[0]?.sinking_fund_paid },
    {
      id: 3,
      head: "ASF",
      num: merchantDetails1?.[0]?.additionalSinkingFund_paid,
    },
  ];

  return (
    <>
      <div className="graphAmtSec">
        <div className="graphAmtDiv">
          <div className="graphInner">
            {loop.map((details, index) => {
              return (
                <div className="graphAmtDivInn" key={index}>
                  <h5 className="graphPoint">{details.head}</h5>
                  <p className="graphNum">{details.num}</p>
                </div>
              );
            })}
          </div>
          <figure className="graphamtFig">
            <img src={moneyimg1} alt="img" />
          </figure>
        </div>

        <div className="graphAmtDiv">
          <div className="graphInner">
            {loop2.map((details, index) => {
              return (
                <div className="graphAmtDivInn" key={index}>
                  <h5 className="graphPoint">{details.head}</h5>
                  <p className="graphNum">{details.num}</p>
                </div>
              );
            })}
          </div>
          <figure className="graphamtFig">
            <img src={moneyimg2} alt="img" />
          </figure>
        </div>
      </div>
    </>
  );
};

export default Graphamount;
