import { FC } from "react";
import { MantineTheme, createStyles, Text, Group } from "@mantine/core";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import CountryCode from "react-phone-number-input";
export type CountryCode =
  | "AC"
  | "AD"
  | "AE"
  | "AF"
  | "AG"
  | "AI"
  | "AL"
  | "AM"
  | "AO"
  | "AR"
  | "AS"
  | "AT"
  | "AU"
  | "AW"
  | "AX"
  | "AZ"
  | "BA"
  | "BB"
  | "BD"
  | "BE"
  | "BF"
  | "BG"
  | "BH"
  | "BI"
  | "BJ"
  | "BL"
  | "BM"
  | "BN"
  | "BO"
  | "BQ"
  | "BR"
  | "BS"
  | "BT"
  | "BW"
  | "BY"
  | "BZ"
  | "CA"
  | "CC"
  | "CD"
  | "CF"
  | "CG"
  | "CH"
  | "CI"
  | "CK"
  | "CL"
  | "CM"
  | "CN"
  | "CO"
  | "CR"
  | "CU"
  | "CV"
  | "CW"
  | "CX"
  | "CY"
  | "CZ"
  | "DE"
  | "DJ"
  | "DK"
  | "DM"
  | "DO"
  | "DZ"
  | "EC"
  | "EE"
  | "EG"
  | "EH"
  | "ER"
  | "ES"
  | "ET"
  | "FI"
  | "FJ"
  | "FK"
  | "FM"
  | "FO"
  | "FR"
  | "GA"
  | "GB"
  | "GD"
  | "GE"
  | "GF"
  | "GG"
  | "GH"
  | "GI"
  | "GL"
  | "GM"
  | "GN"
  | "GP"
  | "GQ"
  | "GR"
  | "GT"
  | "GU"
  | "GW"
  | "GY"
  | "HK"
  | "HN"
  | "HR"
  | "HT"
  | "HU"
  | "ID"
  | "IE"
  | "IL"
  | "IM"
  | "IN"
  | "IO"
  | "IQ"
  | "IR"
  | "IS"
  | "IT"
  | "JE"
  | "JM"
  | "JO"
  | "JP"
  | "KE"
  | "KG"
  | "KH"
  | "KI"
  | "KM"
  | "KN"
  | "KP"
  | "KR"
  | "KW"
  | "KY"
  | "KZ"
  | "LA"
  | "LB"
  | "LC"
  | "LI"
  | "LK"
  | "LR"
  | "LS"
  | "LT"
  | "LU"
  | "LV"
  | "LY"
  | "MA"
  | "MC"
  | "MD"
  | "ME"
  | "MF"
  | "MG"
  | "MH"
  | "MK"
  | "ML"
  | "MM"
  | "MN"
  | "MO"
  | "MP"
  | "MQ"
  | "MR"
  | "MS"
  | "MT"
  | "MU"
  | "MV"
  | "MW"
  | "MX"
  | "MY"
  | "MZ"
  | "NA"
  | "NC"
  | "NE"
  | "NF"
  | "NG"
  | "NI"
  | "NL"
  | "NO"
  | "NP"
  | "NR"
  | "NU"
  | "NZ"
  | "OM"
  | "PA"
  | "PE"
  | "PF"
  | "PG"
  | "PH"
  | "PK"
  | "PL"
  | "PM"
  | "PR"
  | "PS"
  | "PT"
  | "PW"
  | "PY"
  | "QA"
  | "RE"
  | "RO"
  | "RS"
  | "RU"
  | "RW"
  | "SA"
  | "SB"
  | "SC"
  | "SD"
  | "SE"
  | "SG"
  | "SH"
  | "SI"
  | "SJ"
  | "SK"
  | "SL"
  | "SM"
  | "SN"
  | "SO"
  | "SR"
  | "SS"
  | "ST"
  | "SV"
  | "SX"
  | "SY"
  | "SZ"
  | "TA"
  | "TC"
  | "TD"
  | "TG"
  | "TH"
  | "TJ"
  | "TK"
  | "TL"
  | "TM"
  | "TN"
  | "TO"
  | "TR"
  | "TT"
  | "TV"
  | "TW"
  | "TZ"
  | "UA"
  | "UG"
  | "US"
  | "UY"
  | "UZ"
  | "VA"
  | "VC"
  | "VE"
  | "VG"
  | "VI"
  | "VN"
  | "VU"
  | "WF"
  | "WS"
  | "XK"
  | "YE"
  | "YT"
  | "ZA"
  | "ZM"
  | "ZW";

const useStyles = createStyles((theme: MantineTheme) => ({
  checkboxContainer: {
    width: "80%",
    //marginBottom: '6px'
  },
  text: {
    paddingBottom: "0px",
    paddingLeft: "5px",
    color: "#3C4257",
    fontFamily: "SF Pro Text",
    textAlign: "left",
  },
  input: {
    width: "100%",
    padding: 20,
    marginBottom: 10,
    border: "1px solid #ccc",
    borderRadius: 4,
  },
  inputBox: {
    "& .input": {
      height: "30px",
    },
  },
}));

type Props = {
  onChange: CallableFunction;
  isErrored?: string;
  value: string;
  country?: CountryCode;
};

const PhoneInputGroup: FC<Props> = ({
  onChange,
  isErrored,
  value,
  country,
}) => {
  const { classes } = useStyles();
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".

  const OnChange = (value: any) => {
    if (isValidPhoneNumber(String(value))) {
      onChange(value);
    }
  };
  return (
    <div>
      <Group position="apart" mb={5}>
        <Text className={classes.text} component="label" size="md">
          {"Phone Number"}
        </Text>
      </Group>
      <PhoneInput
        defaultCountry={country}
        value={value as any}
        onChange={OnChange}
        style={{
          padding: "10px",
          width: "396px",
          innerHeight: "60px",
          outerHeight: "60px",
        }}
        keyboardtype="phone-pad"
        autocompletetype="tel"
        error={isErrored}
        className={classes.inputBox}
      />

      <p
        style={{
          color: "#fa5252",
          fontSize: "14px",
          marginTop: "-8px",
          marginLeft: "5px",
        }}
      >
        {isErrored}
      </p>
    </div>
  );
};

export default PhoneInputGroup;
