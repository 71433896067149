import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  csvTable: {
    width: "750px",
    marginTop: "30px",
  },
  th: {
    color: "#343a40",
    fontSize: "8px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14.889px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderBottom: "2px solid #dfdfdf",
  },
  td: {
    paddingLeft: "5px",
    borderBottom: "0.6px solid #dfdfdf",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "8px",
    color: "#6c757d",
    fontWeight: 400,
  },
  tr: {
    borderBottom: "1px solid #dfdfdf",
  },
  rowCheckbox: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  icons: {
    display: "inline-flex",
    flexDirection: "column",
    marginLeft: "5px",
    marginBottom: "-5px",
    position: "relative",
    bottom: "5px",
  },
  sortableHeader: {},
  tableFlex: {
    display: "flex",
  },
  sheading: {
    paddingLeft: "2px",
  },
  srow: {
    paddingLeft: "3px",
  },
  overlay: {
    marginTop: "24px",
  },
  selectedRowTable: {
    width: "100%",
  },
}));
