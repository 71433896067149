import { FC, useEffect, useState } from "react";
import {
  Button,
  InputGroup,
  InputSelect,
  NO_REGEX,
  PhoneInputGroup,
  color,
} from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../styles/BizProfileCss";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import {
  setCompanyRedux,
  setRegNumberRedux,
  setLegalStructureRedux,
  setAddressRedux,
  setPhoneNumberRedux,
  setCountryOfIncorpRedux,
  setLegalStructureOthersRedux,
  setCountryOfDomicileRedux,
  setPostalCodeRedux,
  setWebsiteRedux,
  setIndustryOthersRedux,
  setIndustryRedux,
  setInvestmentAmountRedux,
} from "../../features/institutionalInvestor/bizSlice";
import {
  countries,
  countryCodes,
  CountryCode,
} from "@funded-here-interface/common/src/constant/country";
import {
  legalStructures,
  industry as industryList,
} from "../../constants/dropdownValues";
import { Industry, LegalStructure } from "../../constants/enums";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";
import { Persona } from "@funded-here-interface/common/src/constant/enum";
import { useDisclosure } from "@mantine/hooks";
import InvestorDeclarationModal from "./Components/InvestorDeclarationModal/InvestorDeclarationModal";
import { toast } from "react-toastify";

const BizProfile: FC = () => {
  const { classes } = useStyles();
  const Navigate = useNavigate();
  const type = useSelector((state: RootState) => state.auth.type);

  const {
    company: comp,
    regNumber: reg,
    legalStructure: ls,
    legalStructureOthers: legalStructureOthersRedux,
    countryOfDomicile: countryOfDomicileRedux,
    countryOfIncorp: countryOfIncorpRedux,
    address: add,
    phoneNumber: pn,
    website: websiteRedux,
    postal: postalRedux,
    industry: industryRedux,
    industryOthers: industryOthersRedux,
    investmentAmount: investmentAmountRedux,
    investorDeclarationQualified,
  } = useSelector((state: RootState) => state.biz);

  const isInvestor = type === Persona.INVESTOR;
  const [opened, { close }] = useDisclosure(
    isInvestor && !investorDeclarationQualified
  );
  const [industry, setIndustry] = useState(industryRedux || "");
  const [isOtherIndustry, setIsOtherIndustry] = useState(
    industryRedux === Industry.OTHERS
  );
  const [industryOthers, setIndustryOthers] = useState(
    industryOthersRedux || ""
  );
  const [company, setCompany] = useState(comp || "");
  const [registration_number, setRegNumber] = useState(reg || "");
  const [legal_structure, setLegStr] = useState(ls || "");
  const [isOtherLegalStrcuture, setIsOtherLegalStructure] = useState(
    legal_structure === LegalStructure.OTHERS
  );
  const [legalStructureOthers, setLegalStructurOthers] = useState(
    legalStructureOthersRedux || ""
  );
  const [countryOfIncorp, setCountryCountryOfIncorp] = useState(
    countryOfIncorpRedux || ""
  );
  const [countryOfDomicile, setCountryCountryOfDomicile] = useState(
    countryOfDomicileRedux || ""
  );
  const [address, setAddress] = useState(add || "");
  const [phone_number, setPhone] = useState(pn || "");
  const [postalCode, setPostalCode] = useState(postalRedux || "");
  const [website, setWebsite] = useState(websiteRedux || "");
  const [countryCode, setCountryCode] = useState<CountryCode | undefined>();
  const [investmentAmount, setInvestmentAmount] = useState(
    investmentAmountRedux || 0
  );
  const [error, setError] = useState({
    company: "",
    registration_number: "",
    legal_structure: "",
    legalStructureOthers: "",
    countryOfIncorp: "",
    countryOfDomicile: "",
    address: "",
    postal_code: "",
    website: "",
    phone_number: "",
    industry: "",
    industryOthers: "",
    investmentAmount: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (investorDeclarationQualified === false) {
      Navigate("/unqualified-investor");
    }
  }, [investorDeclarationQualified]);

  const handleSubmit = () => {
    const customError = { ...error };
    let valid = true;
    if (!company) {
      valid = false;
      customError.company = "Company Name is required";
    }
    if (!registration_number) {
      valid = false;
      customError.registration_number = "Registration Name is required";
    }
    if (!legal_structure) {
      valid = false;
      customError.legal_structure = "Legal Structure is required";
    }
    if (isOtherLegalStrcuture && !legalStructureOthers) {
      valid = false;
      customError.legalStructureOthers =
        "Legal Strcuture Specification is required";
    }
    if (!industry) {
      valid = false;
      customError.industry = "Industry is required";
    }
    if (isOtherIndustry && !industryOthers) {
      valid = false;
      customError.industryOthers = "Industry Specification is required";
    }
    if (!countryOfIncorp) {
      valid = false;
      customError.countryOfIncorp = "Country of Incorporation is required";
    }
    if (!countryOfDomicile) {
      valid = false;
      customError.countryOfDomicile = "Country of Domicile is required";
    }
    if (!address) {
      valid = false;
      customError.address = "Address is required";
    }
    if (!postalCode) {
      valid = false;
      customError.postal_code = "Postal Code is required";
    }
    if (!website) {
      valid = false;
      customError.website = "Website is required";
    }

    if (type === Persona.INVESTOR && !investmentAmount) {
      valid = false;
      customError.investmentAmount = "Planned Investment Amount is required";
    }
    if (!phone_number) {
      valid = false;
      customError.phone_number = "Phone Number is required";
    }
    if (valid) {
      dispatch(setCompanyRedux({ company: company }));
      dispatch(setRegNumberRedux({ regNumber: registration_number }));
      dispatch(setLegalStructureRedux({ legalStructure: legal_structure }));
      dispatch(setLegalStructureOthersRedux({ legalStructureOthers }));
      dispatch(setCountryOfIncorpRedux({ countryOfIncorp }));
      dispatch(setCountryOfDomicileRedux({ countryOfDomicile }));
      dispatch(setAddressRedux({ address }));
      dispatch(setPostalCodeRedux({ postal: postalCode }));
      dispatch(setWebsiteRedux({ website }));
      dispatch(setPhoneNumberRedux({ phoneNumber: phone_number }));
      dispatch(setIndustryRedux({ industry }));
      dispatch(
        setIndustryOthersRedux({
          industryOthers,
        })
      );
      dispatch(
        setInvestmentAmountRedux({
          investmentAmount,
        })
      );

      if (type === Persona.INVESTOR) {
        Navigate("/institutional-rep");
      } else {
        Navigate("/representative-details");
      }
    } else {
      setError(customError);
    }
  };

  const handleCountryOfDomicileChange = (value: string) => {
    error.countryOfDomicile = "";
    setCountryCountryOfDomicile(value);
    const code = countryCodes[value as keyof typeof countryCodes];
    setCountryCode(code as CountryCode);
  };

  const onLegalStrcutureChange = (value: LegalStructure) => {
    if (value === LegalStructure.OTHERS) {
      setIsOtherLegalStructure(true);
    } else {
      setIsOtherLegalStructure(false);
      setLegalStructurOthers("");
    }

    setLegStr(value);
    error.legal_structure = "";
  };

  const onIndustryChange = (value: Industry) => {
    if (value === Industry.OTHERS) {
      setIsOtherIndustry(true);
    } else {
      setIsOtherIndustry(false);
      setIndustryOthers("");
    }

    setIndustry(value);
    error.industry = "";
  };

  return (
    <OnboardingLayout
      step={1}
      showSteps={true}
      content={
        <>
          <InvestorDeclarationModal
            onError={(value: string) => {
              toast.error(value);
            }}
            opened={opened}
            close={close}
          />
          <div style={{ marginTop: "70px" }}>
            <InputGroup
              isErrored={error?.company}
              onChange={(value: string) => {
                error.company = "";
                setCompany(value);
              }}
              labelText="Company Name"
              id="company"
              value={company}
            />
            <InputGroup
              isErrored={error?.registration_number}
              onChange={(value: string) => {
                error.registration_number = "";
                setRegNumber(value);
              }}
              labelText="Registration Number"
              id="registration_number"
              value={registration_number}
            />
            <InputSelect
              isErrored={error?.legal_structure}
              onChange={onLegalStrcutureChange}
              labelText="Legal Structure"
              id="legal_structure"
              value={legal_structure}
              data={legalStructures}
            />
            {isOtherLegalStrcuture && (
              <InputGroup
                isErrored={error.legalStructureOthers}
                onChange={(value: string) => {
                  error.legalStructureOthers = "";
                  setLegalStructurOthers(value);
                }}
                labelText="Please Specify Legal Structure"
                id="legal_structure_others"
                value={legalStructureOthers}
              />
            )}
            <InputSelect
              isErrored={error.industry}
              onChange={onIndustryChange}
              labelText="Industry"
              value={industry}
              id="industry"
              data={industryList}
            />
            {isOtherIndustry && (
              <InputGroup
                isErrored={error.industryOthers}
                onChange={(value: string) => {
                  error.industryOthers = "";
                  setIndustryOthers(value);
                }}
                labelText="Please Specify Industry"
                id="industryOthers"
                value={industryOthers}
              />
            )}
            <InputSelect
              isErrored={error?.countryOfIncorp}
              onChange={(value: string) => {
                error.countryOfIncorp = "";
                setCountryCountryOfIncorp(value);
              }}
              labelText="Country of Incorporation"
              id="country"
              value={countryOfIncorp}
              data={countries}
            />
            <InputSelect
              isErrored={error?.countryOfDomicile}
              onChange={handleCountryOfDomicileChange}
              labelText="Country of Domicile"
              id="countryOfDomicile"
              value={countryOfDomicile}
              data={countries}
            />
            <InputGroup
              isErrored={error?.address}
              onChange={(value: string) => {
                error.address = "";
                setAddress(value);
              }}
              labelText="Address"
              id="address"
              value={address}
            />
            <InputGroup
              isErrored={error.postal_code}
              onChange={(value: string) => {
                error.postal_code = "";
                setPostalCode(value.replace(NO_REGEX, ""));
              }}
              labelText="Postal Code"
              id="postalcode"
              value={postalCode}
            />
            <InputGroup
              isErrored={error.website}
              onChange={(value: string) => {
                error.website = "";
                setWebsite(value);
              }}
              labelText="Website"
              id="companywebsite"
              value={website}
            />
            {isInvestor && (
              <InputGroup
                isErrored={error.investmentAmount}
                onChange={(value: string) => {
                  error.investmentAmount = "";
                  setInvestmentAmount(parseInt(value.replace(NO_REGEX, "")));
                }}
                labelText="Planned Investment Amount"
                id="investmentAmount"
                value={`${investmentAmount || 0}`}
              />
            )}
            <div className={classes.phoneNumberWrapper}>
              <PhoneInputGroup
                isErrored={error?.phone_number}
                value={phone_number}
                country={countryCode}
                onChange={(value: string) => {
                  error.phone_number = "";
                  setPhone(value);
                }}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleSubmit}
                textColor={color.WHITE}
                backgroundColor={color.FHGREEN}
                children="Next"
                width="100%"
              />
            </div>
          </div>
        </>
      }
    />
  );
};

export default BizProfile;
