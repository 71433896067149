import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "24px",
  },
  docSign: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    color: "#338F86",
  },

  docContain: {
    display: "flex",
    marginTop: "30px",
    marginLeft: "30px",
  },
  docText: {
    fontSize: "18px",
    width: "400px",
    textTransform: "capitalize",
  },
  downloadIcon: {
    cursor: "pointer",
    display: "flex",
  },
  signedGroup: {
    marginBottom: "10px",
  },
  panel: {
    backgroundColor: "#f5f2f2",
  },
  item: {
    backgroundColor: "#dee2e6",
    marginBottom: "10px",
    borderRadius: "10px",
  },
  control: {
    "&:hover": {
      borderRadius: "10px",
    },
  },
}));
