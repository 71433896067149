import { createSlice } from "@reduxjs/toolkit";

const bulkSlice = createSlice({
  name: "skubulk",
  initialState: {
    skus: [],
  },
  reducers: {
    setRegisterBulk: (state, { payload: { skus } }) => {
      state.skus = skus;
    },
  },
});

export const { setRegisterBulk } = bulkSlice.actions;

export default bulkSlice.reducer;
