export const setDecimalPlaces = (
  string: string,
  decimalPlaces: number
): string => {
  const formattedNumber = parseFloat(string);
  return formattedNumber.toFixed(decimalPlaces);
};

export const formatPercentageFromRatio = (
  number: string,
  decimalPlaces: number = 2
): string => {
  const mulipliedRatio = parseFloat(number) * 100;
  return mulipliedRatio.toFixed(decimalPlaces);
};
