import { FC, useState, useEffect } from "react";
import { Button } from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useStyle } from "./LoanAcceptance.styles";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyTable from "./Table"; // Adjust the path based on your file structure

type LTVAcceptanceSKUProps = {
  onEvent: (eventName: string) => void;
};

const LTVAcceptanceSKU: FC<LTVAcceptanceSKUProps> = ({ onEvent }) => {
  const { classes } = useStyle();
  const Navigate = useNavigate();

  const notify = () => toast.success("Submitted successfully");

  const token = useSelector((state: RootState) => state.auth.token);

  const [data, setData] = useState([]);

  const [selectedSKUIds, setSelectedSKUIds] = useState<string[]>([]);

  const getLoans = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/sku-finance/loan-application/approved`,
        {
          headers: {
            "Content-Type": "application/json",
            //   "authentication": token,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      throw new Error("Network response was not ok");
    }
  };

  useEffect(() => {
    getLoans()
      .then((skus) => {
        setData(skus);
      })
      .catch((error) => {
        toast.error(
          "Error encountered when fetching data, please try again later."
        );
      });
  }, [selectedSKUIds]);

  const handleCheckboxChange = async (skuId: string) => {
    // Check if skuId is already in the array, and toggle its selection
    if (selectedSKUIds.includes(skuId)) {
      setSelectedSKUIds(selectedSKUIds.filter((id) => id !== skuId));
    } else {
      selectedSKUIds.push(skuId);
      setSelectedSKUIds(selectedSKUIds);
    }
  };

  const SubmitForFunding = async (skuIds: string[], token: string) => {
    const payload = {
      skuIds: skuIds,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/sku-finance/loan-application/submit-for-funding`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      toast.error("Error submitting, please try again later.");
    }
  };

  const handleNext = async () => {
    if (selectedSKUIds.length === 0) {
      toast.error("Please select at least 1 SKU.");
      return;
    }

    try {
      const res = await SubmitForFunding(selectedSKUIds, token);
      onEvent("loan-payment");
      notify();
    } catch (error) {
      toast.error("Error submitting, please try again later.");
    }
  };

  return (
    <div>
      <div>
        <MyTable data={data} onCheckboxChange={handleCheckboxChange} />
      </div>
      <ToastContainer />
      <div className={classes.buttonDiv}>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => handleNext()}
            textColor="#ffff"
            backgroundColor="#338F86"
            children="Next"
            width="200px"
          />
        </div>
      </div>
    </div>
  );
};

export default LTVAcceptanceSKU;
