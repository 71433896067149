import axios from "axios";
import { AuthenticateDIDType } from "../types/login.type";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";

const authenticateDID = async ({
  ledgerAddress,
  token,
}: AuthenticateDIDType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/auth/authenticate-did`,
      { LedgerAddress: ledgerAddress },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Network response was not ok");
  }
};

export default authenticateDID;
