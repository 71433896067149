import { FC } from "react";
import { Text } from "@mantine/core";
import { AcknowledgementLayout } from "@funded-here-interface/common";
import { useStyles } from "./UnqualifiedInvestor.styles";

const UnqualifiedInvestor: FC = () => {
  const { classes } = useStyles();

  return (
    <AcknowledgementLayout
      showLogo={false}
      content={
        <div className={classes.container}>
          <Text className={classes.headerText}>
            Investor Qualification Update
          </Text>
          <Text className={classes.text}>
            We regret to inform you that you do not currently meet the investor
            requirements for our platform. Thank you for your interest.
          </Text>
        </div>
      }
    />
  );
};

export default UnqualifiedInvestor;
