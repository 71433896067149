import { createSlice } from "@reduxjs/toolkit";

interface InstitutionalBankState {
  bankName: string | null;
  accountNumber: string | null;
  accountType: string | null;
  accountHolderName: string | null;
  swiftCode: string | null;
}

const bankSlice = createSlice({
  name: "bank",
  initialState: {
    bankName: null,
    accountNumber: null,
    accountType: null,
    accountHolderName: null,
    swiftCode: null,
  } as InstitutionalBankState,
  reducers: {
    setBankNameRedux: (state, { payload: { bankName } }) => {
      state.bankName = bankName;
    },
    setAccountNoRedux: (state, { payload: { accountNumber } }) => {
      state.accountNumber = accountNumber;
    },
    setAccountTypeRedux: (state, { payload: { accountType } }) => {
      state.accountType = accountType;
    },
    setAccountHolderNameRedux: (state, { payload: { accountHolderName } }) => {
      state.accountHolderName = accountHolderName;
    },
    setSwiftCodeReduct: (state, { payload: { swiftCode } }) => {
      state.swiftCode = swiftCode;
    },
  },
});

export const {
  setBankNameRedux,
  setAccountNoRedux,
  setAccountTypeRedux,
  setAccountHolderNameRedux,
  setSwiftCodeReduct,
} = bankSlice.actions;

export default bankSlice.reducer;
